import { Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import styles from "./DocumentTableView.module.scss";
import { DocumentData, formatDate, formatFileSize } from "../DocumentView";
import { CalendarDays } from "lucide-react";
import { DownloadOutlined } from "@ant-design/icons";

interface IDocumentTableViewProps {
  loading: boolean;
  currentPage: number;
  pageSize: number;
  data: DocumentData[];
  onPageChange: (page: number) => void;
  onSelectionChange: (selectedRows: DocumentData[]) => void;
  onExport: (doc: DocumentData[]) => void;
}

const DocumentTableView: React.FC<IDocumentTableViewProps> = ({
  loading,
  currentPage,
  pageSize,
  data,
  onPageChange,
  onSelectionChange,
  onExport,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[], selectedRows: DocumentData[]) => {
      setSelectedRowKeys(selectedKeys);
      onSelectionChange(selectedRows);
    },
  };

  const getUniqueKey = (record: DocumentData) => {
    return record.app_id + "_" + record.file_name + "_" + record.file_type;
  };

  const columns: ColumnsType<DocumentData> = [
    {
      title: "File name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "File size",
      dataIndex: "file_size",
      key: "file_size",
      width: 100,
      render: (fileSize: number) => (
        <div className="text-center">
          <span className="text-[#8D8D8D]">{formatFileSize(fileSize)}</span>
        </div>
      ),
    },
    {
      title: "File type",
      dataIndex: "file_type",
      key: "file_type",
      width: 100,
      render: (fileType: string) => (
        <div className="text-center">
          <span className="text-[#8D8D8D]">{fileType}</span>
        </div>
      ),
    },
    {
      title: "Create date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (createdAt: string) => (
        <div className="text-[#8D8D8D] gap-1 flex items-center">
          <CalendarDays size={14} />
          {formatDate(createdAt)}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
      render: (category: string) => (
        <div className="text-center">
          <Tag color="blue" className="rounded-3xl">
            {category}
          </Tag>
        </div>
      ),
    },
    {
      title: "Export",
      dataIndex: "key_data",
      key: "key_data",
      width: 100,
      render: (_, record) => (
        <div className="text-center">
          <button
            className={styles.btnActive}
            onClick={() => onExport([record])}
          >
            <DownloadOutlined />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <Table
        loading={loading}
        className={styles.table}
        scroll={{ x: "max-content", y: "calc(100vh - 400px)" }}
        columns={columns}
        dataSource={data}
        rowKey={getUniqueKey}
        rowSelection={rowSelection}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: data.length,
          showSizeChanger: false,
          onChange: onPageChange,
        }}
      />
    </div>
  );
};
export default DocumentTableView;
