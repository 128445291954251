import { FileTextOutlined, DownloadOutlined, CopyOutlined  } from "@ant-design/icons";
import { Button, Card, Skeleton, Table, Tag, Layout } from "antd";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllInvoices, fetchBillingsByUserID } from "src/store/actions/payment/billingActions";
import { fetchStripeCustomerByUserID } from "src/store/actions/payment/customerActions";
import { billingSelector } from "src/store/slices/payments/billingSlice";
import { customerSelector } from "src/store/slices/payments/customerSlice";
import { useAppDispatch } from "src/store/store";
import styles from "./BillingHistory.module.scss";
import { loadStripe } from '@stripe/stripe-js';
import { motion } from "framer-motion";

import { stripeKey } from "src/page/subscription/PaymentsWrapper";
import { currencyFormat } from "src/components/payments/PaymentSummary";
import { InvoiceResponse } from "src/model/invoice";
import { ColumnGroupType, ColumnType } from "antd/es/table";
import { processPaymentMethod } from "src/store/actions/payment/paymentActions";
const stripe = loadStripe(stripeKey);
const { Content } = Layout;

function BillingHistory() {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [accumulatedData, setAccumulatedData] = useState<any[]>([]);

    const dispatch = useAppDispatch();
    const { customerData } = useSelector(customerSelector);
    const { invoices, loading, error, errorMsg } = useSelector(billingSelector);

    const columns: (ColumnGroupType<InvoiceResponse> | ColumnType<InvoiceResponse>)[] = [
        {
            title: "Invoice",
            dataIndex: "invoice_number",
            key: "invoice",
            render: (_: any, record: InvoiceResponse) => (
                <div className="flex items-center gap-2">
                    <span className={styles.invoice}>
                        {record.status == "draft" ? `Waiting admin confirm` : `INVOICE_${record.invoice_number}`}
                    </span>
                    <Button 
                        type="text"
                        icon={<CopyOutlined />}
                        onClick={() => {
                            navigator.clipboard.writeText(record.invoice_id);
                        }}
                    />
                </div>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: string) => {
                const color = status.toLowerCase() === "paid" ? "green" :
                status.toLowerCase() === "pending" ? "orange" : "red";
                return <Tag color={color}>{status.toUpperCase()}</Tag>;
            },
        },
        {
            title: "Amount",
            dataIndex: "total_count",
            key: "total_count",
            render: (total_count: number) => (
                <span className={styles.amount}>{currencyFormat(total_count)}</span>
            ),
        },
        {
            title: "Created",
            dataIndex: "created_at",
            key: "created",
            render: (created_at: string) => moment(created_at).format('DD-MMM-YYYY hh:mm A'),
            sorter: (a: InvoiceResponse, b: InvoiceResponse) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf(),
            // defaultSortOrder: 'descend' as const
        },
        {
            title: "Action",
            key: "action",
            render: (_: any, record: InvoiceResponse) => (
                <div className="flex gap-2">
                    <Button
                        type="link"
                        icon={<FileTextOutlined />}
                        disabled={record.status.toLowerCase() === "draft"}
                        onClick={() => handleViewBill(record)}
                    >
                        View Bill
                    </Button>
                    <Button
                        type="link"
                        icon={<DownloadOutlined />}
                        disabled={record.status.toLowerCase() === "draft"}
                        onClick={() => handleDownloadBill(record)}
                    >
                        Download
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
            if (customerData) {
            dispatch(fetchAllInvoices({
                email: customerData.email,
                offset: 0,
                limit: pageSize
            }));

            dispatch(processPaymentMethod(customerData.customerId));
        }
    }, [customerData, dispatch, pageSize]);

    useEffect(() => {
        if (invoices?.data) {
            setAccumulatedData(prev => [...prev, ...invoices.data]);
        }
    }, [invoices?.data]);

    const handleTableChange = (pagination: any) => {
        const existingRecords = accumulatedData.length;
        const neededRecords = pagination.current * pagination.pageSize;

        if (neededRecords > existingRecords) {
            dispatch(fetchAllInvoices({
                offset: existingRecords,
                limit: neededRecords - existingRecords
            }));
        }
    };

    const handlePaginationChange = (page: number, size: number) => {
        setCurrentPage(page);
        setPageSize(size);

        const existingRecords = accumulatedData.length;
        const neededRecords = page * size;

        if (neededRecords > existingRecords) {
            dispatch(fetchAllInvoices({
                offset: existingRecords,
                limit: neededRecords - existingRecords
            }));
        }
    };

    useEffect(() => {
        dispatch(fetchBillingsByUserID());
    }, [dispatch]);

    const handleViewBill = async (record: InvoiceResponse) => {
        if (record.hosted_invoice_url) {
            window.open(record.hosted_invoice_url, '_blank', 'noopener,noreferrer');
        }
    };

    const handleDownloadBill = async (record: InvoiceResponse) => {
        if (record.invoice_pdf) {
            window.open(record.invoice_pdf, '_blank', 'noopener,noreferrer');
        }
    };

    if (loading && accumulatedData.length === 0) {
        return (
            <Card className={styles.billingCard}>
                <div className={styles.header}>
                    <div className={styles.titleSection}>
                        <Skeleton.Input style={{ width: 300 }} active />
                    </div>
                    <div className={styles.controls}>
                        <Skeleton.Button active style={{ width: 200, marginRight: 16 }} />
                        <Skeleton.Button active style={{ width: 150, marginRight: 16 }} />
                        <Skeleton.Button active style={{ width: 100 }} />
                    </div>
                </div>
                {[1, 2, 3, 4].map((item) => (
                    <div key={item} style={{ padding: '16px 0' }}>
                        <Skeleton.Input style={{ width: '100%' }} active />
                    </div>
                ))}
            </Card>
        );
    }

    return (
        <Layout style={{ height: '100%', overflow: 'hidden' }}>
            <Content style={{ overflow: 'auto', height: '100%' }}>
                <motion.div
                    className="flex-1 px-6 py-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                        overflowY: "auto",
                        scrollBehavior: "smooth",
                        height: "calc(100vh - 160px)",
                        backgroundColor: "#ffffff",
                        paddingBottom: "100px"
                    }}
                >
                    <Card className={`${styles.billingCard} mb-6`}>
                        <Table
                            columns={columns}
                            dataSource={accumulatedData}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: invoices?.total || 0,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                showTotal: (total) => `Total ${total} items`,
                                onChange: handlePaginationChange
                            }}
                            loading={loading}
                            onChange={handleTableChange}
                            className={styles.table}
                            scroll={{ x: 'max-content' }}
                            size="middle"
                            rowClassName={(record, index) => index % 2 === 0 ? styles.evenRow : styles.oddRow}
                        />
                    </Card>
                </motion.div>
            </Content>
        </Layout>
    );
}
export default BillingHistory;
