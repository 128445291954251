import React, { useState, useEffect, useRef } from "react";
import styles from "./PreviewScreensModal.module.scss";
import { ConfigProvider, Modal, Image, Skeleton } from "antd";
//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import CommentHtmlModal from "../modal/CommentHtmlModal";
import cardLoad from "src/assets/png/Card load complete.png";

interface Screen {
  screen_name: string;
  HTML_code: string;
  file_name: string;
}

interface PreviewScreensModalProps {
  open: boolean;
  onClose: () => void;
  resultPreview: {
    project_name: string;
    screens: Screen[];
  };
  onSelect: (html: string, name: string, fileName: string) => void;
  isViewMode: boolean;
}

const PreviewScreensModal: React.FC<PreviewScreensModalProps> = ({
  open,
  onClose,
  resultPreview,
  onSelect,
  isViewMode,
}) => {
  const [galleryImages, setGalleryImages] = useState<
    Array<{
      img: string;
      name: string;
      html: string;
      fileName: string;
    }>
  >([]);
  const iframeRefs = useRef<Array<HTMLIFrameElement | null>>([]);
  const [loading, setLoading] = useState(false);
  const [screenshot, takeScreenshot] = useScreenshot();
  const [screens, setScreens] = useState<Screen[]>([]);

  useEffect(() => {
    if (resultPreview?.screens?.length > 0) {
      setScreens(resultPreview.screens);
    }
  }, [resultPreview?.screens]);

  useEffect(() => {
    if (screens.length > 0) {
      console.log("screens : ", screens);
      handleTakeScreenshots();
    }
  }, [screens]);

  const handleTakeScreenshots = async () => {
    setLoading(true);
    const screenshots = await Promise.all(
      screens.map(async (screen, index) => {
        if (iframeRefs.current[index]) {
          iframeRefs.current[index]!.srcdoc = screen.HTML_code;

          const img = await new Promise<string>((resolve) => {
            iframeRefs.current[index]!.onload = async () => {
              const iframe = iframeRefs.current[index];
              const iframeDocument =
                iframe?.contentDocument || iframe?.contentWindow?.document;

              if (iframeDocument) {
                const images = iframeDocument.querySelectorAll("img");
                const allImagesLoaded = Array.from(images).every(
                  (img: HTMLImageElement) => img.complete
                );

                if (allImagesLoaded) {
                  const screenshot = await takeScreenshot(iframeDocument.body, {
                    width: iframe?.offsetWidth,
                    height: iframe?.offsetHeight,
                  });
                  resolve(screenshot as string);
                }
              }
            };
          });

          return {
            img,
            name: screen.screen_name,
            html: screen.HTML_code,
            fileName: screen.file_name,
          };
        }
        return null;
      })
    );
    console.log("screenshots : ", screenshots);
    setGalleryImages(
      screenshots.filter(
        (
          item
        ): item is {
          img: string;
          name: string;
          html: string;
          fileName: string;
        } => item !== null
      )
    );
    setLoading(false);
  };

  const handlePreviewClick = (html: string, name: string, fileName: string) => {
    // const newTab = window.open();
    // if (newTab) {
    //   newTab.document.body.innerHTML = `
    //     <iframe
    //       srcdoc="${html.replace(/"/g, '&quot;')}"
    //       style="width: 100%; height: 100vh; border: none;"
    //       title="Preview ${index}"
    //     />
    //   `;
    // }
    onSelect(html, name, fileName);
  };

  const modalStyles = {
    header: { borderRadius: 0, paddingInlineStart: 5 },
    body: { borderRadius: 5 },
    mask: { backdropFilter: "blur(10px)" },
    content: { boxShadow: "0 0 30px #999" },
  };

  return (
    <ConfigProvider modal={{ styles: modalStyles }}>
      <Modal
        title={`Generated Pages (${screens.length})`}
        width="75%"
        open={open}
        onCancel={onClose}
        footer={null}
        className={styles.modalContainer}
      >
        <div className={styles.gallery}>
          {loading
            ? Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className={styles.card}>
                  <Image
                    src={cardLoad}
                    className={styles.galleryImage}
                    preview={false}
                  />
                  <Skeleton
                    active
                    paragraph={{ rows: 0 }}
                    className={styles.skeleton}
                  />
                </div>
              ))
            : galleryImages.map(({ img, name, html, fileName }, index) => (
                <div
                  key={index}
                  className={styles.cardGallery}
                  onClick={() => handlePreviewClick(html, name, fileName)}
                >
                  <Image
                    src={img}
                    alt={`Screenshot ${name}`}
                    className={styles.galleryImage}
                    preview={false}
                  />
                  <div className={styles.titleName}>
                    <span className={styles.cardTitle}>Page: </span>
                    <span className={styles.fileName}>{name}</span>
                  </div>
                </div>
              ))}
        </div>

        <div className={styles.modalContent}>
          {screens?.map((item, index) => (
            <iframe
              key={index}
              //@ts-ignore
              ref={(el) => (iframeRefs.current[index] = el)}
              srcDoc={item?.HTML_code}
              style={{
                position: "absolute",
                top: "-9999px",
                left: "-9999px",
                width: "1000px",
                height: "1000px",
                border: "none",
                visibility: "hidden",
                zIndex: -1,
              }}
              title={`Preview ${index}`}
            />
          ))}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default PreviewScreensModal;
