import * as React from "react";

const UsageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="🔍-Product-Icons"
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    >
      <g id="ic_fluent_data_usage_24_regular" fill="#ffffff" fillRule="nonzero">
        <path
          id="🎨Color"
          d="M18.25 2.997A2.75 2.75 0 0 1 21 5.747v12.5a2.75 2.75 0 0 1-2.75 2.75H5.75A2.75 2.75 0 0 1 3 18.247v-12.5a2.75 2.75 0 0 1 2.75-2.75zm0 1.5H5.75c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25v-12.5c0-.69-.56-1.25-1.25-1.25M7.75 9c.38 0 .693.28.743.645l.007.102v6.506a.75.75 0 0 1-.75.747.75.75 0 0 1-.743-.645L7 16.253V9.747C7 9.334 7.336 9 7.75 9m8.5-2c.38 0 .693.274.743.63l.007.1v8.54a.74.74 0 0 1-.75.73.744.744 0 0 1-.743-.63l-.007-.1V7.73a.74.74 0 0 1 .75-.73m-4.275 4.997a.73.73 0 0 1 .732.62l.008.099.035 3.547a.73.73 0 0 1-.725.734.73.73 0 0 1-.732-.62l-.008-.1-.035-3.547a.73.73 0 0 1 .725-.733"
        ></path>
      </g>
    </g>
  </svg>
);

export default UsageIcon;