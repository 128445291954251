import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FileContainer from "../../File/FileContainer";
import SkeletonFile from "../../File/SkeletionFile";
import FileCard from "../../File/FileCard";
//@ts-ignore
import _ from "lodash";
import { FileContext, FileContextProps } from "../../File.context";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./ManageversionFileContainer.module.scss";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { APIFileEnpoint, fetchNextUtil, getFileFoldersMethodGet, KN_FILE_SERVICE_URL_MAIN } from "src/page/kn/util.service";
import { checkEmpty } from "src/services/util.service";

const defaultLitmitOfset = 30;
let link = KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.manageversions;

const ManageversionFileContainer = (props: any) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // const [folders, setFolders]: any = useState(null);
  // const [files, setFiles]: any = useState(null);
  // const [offsetFolder, setOffsetFolder]: any = useState(0);
  const [offsetFile, setOffsetFile]: any = useState(0);
  const [offsetFolder, setOffsetFolde]: any = useState(0);
  const fileContext = useContext<FileContextProps>(FileContext);
  const { t } = useTranslation();
  const [currentRes, setCurentRes]: any = useState({});
  const [loading, setloading]: any = useState(false);
  const [fileFolders, setfileFolders]: any = useState([]);
  const [hasMore, setHasMore]: any = useState(false);
  const [loadMore, setLoadMore]: any = useState(false);
  const [showMenuVersion, setShowMenuVersion] = useState(false)

  const fetchFileFolderData = async () => {
    console.log("fetchFileFolderData : ", offsetFile);
    if (offsetFile === 0) {
      setfileFolders([]);
      fileContext.setCountFileVersion([])
      setHasMore(true);
      setLoadMore(false);
    }

    let param = {
      fileId: fileContext.fileSelected?.file_app_id,
      offset: offsetFile,
      limitOfset: defaultLitmitOfset,
    };

    try {
      setloading(true);
      let response: any = await getFileFoldersMethodGet(
        param,
        link,
        fileContext
      );
      setCurentRes(response);
      setloading(false);

      let fileData: any[] = [];
      setfileFolders((prev: any) => {
        if (offsetFile === 0) {
          fileData = response?.data?.data;
          return fileData
        };
        if (checkEmpty(prev)) prev = [];
        fileData = [...prev, ...response?.data?.data]
        return fileData;
      });
      fileContext.setCountFileVersion(fileData)
      if (response?.data?.total === 0) {
        setHasMore(false);
      }
      if (
        fileFolders?.length + defaultLitmitOfset >=
        response?.data?.total
      ) {
        setHasMore(false);
      }
      let len = fileFolders?.length + response?.data?.data?.length;
      if (response?.data?.total <= len) {
        setHasMore(false);
      }
      fileContext.setTotalFileVersion(response?.data?.total);
    } catch (e) {
      console.error(e);
      // setloading(false);
    }
    fileContext.setIsSuccessDeleteForever(false);
  };

  useEffect(() => {
    console.log("fileContext.visibleManageVersion", fileContext.visibleManageVersion);
    if (fileContext.visibleManageVersion === true) {
      fetchFileFolderData();
      setShowMenuVersion(true)
    }
  }, [offsetFile, fileContext.visibleManageVersion]);

  async function setFolderDelete(lastVersion: any) {
    try {
      let files_ = fileContext.fileFolders;
      let file = Object.assign({}, fileContext.fileSelected);
      let i = files_.findIndex((item: any) => {
        console.log("ManageversionFileContainer item: ", item);
        console.log("ManageversionFileContainer item: ", item?.file_app_id);
        return item?.file_app_id === file.file_app_id;
      });
      console.log("ManageversionFileContainer i: ", i);

      if (i != -1) {
        files_[i] = lastVersion;
      }
  
      fileContext.setFiles(files_);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (fileContext.isSuccessDeleteForever === true) {
      setfileFolders((prev: any) => {
        let result = prev.filter((item: any) => item.file_version !== fileContext.fileSelected?.file_version)
        setFolderDelete(result[0])
        return result;
      });
      fileContext.setIsSuccessDeleteForever(false);
    }
  }, [fileContext.isSuccessDeleteForever]);

  useEffect(() => {
    setOffsetFile(0);
  }, [fileContext.groupId, fileContext.parentFolderId]);

  const getFileFolder = (type: string) => {
    // console.log("first: ", type)
    let ls = fileFolders.filter((item: any) => {
      return item.type_ === type;
    });
    return ls;
  };

  const handleCancel = () => {
    fileContext.setVisibleManageVersion(false);
  };
  const fetchNext = async () => {
    fetchNextUtil(
      fileContext,
      currentRes?.data,
      setOffsetFile,
      defaultLitmitOfset,
      offsetFile
    );
  };

  const getTextVersion = () => {
    let text = t("versions");
    if (fileContext.totalFileVersion === 0) text = t("version");
    return " (" + fileContext.totalFileVersion + " " + text + ") ";
  };
  return (
    <Modal
      visible={fileContext.visibleManageVersion}
      title={fileContext.fileSelected?.file_name}
      centered

      // onOk={handleOk}
      width={850}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
    >
      <div style={{ textAlign: "end", float: "left" }}>
        <div>{getTextVersion()}</div>
      </div>
      <InfiniteScroll
        dataLength={
          fileFolders !== null ? fileFolders?.length : 0
        }
        next={fetchNext}
        hasMore={hasMore}
        scrollableTarget={"FileFoldersContainer"}
        loader={!hasMore ? null : <SkeletonFile />}
        className={styles.customInfinitscroll}
      >
        <>
          <FileContainer
            header="Files"
            tableHeader={getFileFolder("FILE").length !== 0 ? true : false}
            visibleManageVersion={true}
          >
            {loading && !loadMore ? (
              <SkeletonFile />
            ) : (
              //@ts-ignore
              getFileFolder("FILE")?.map((file, idx) => {
                return <FileCard file={file} key={file.file_app_id} showMenuVersion={showMenuVersion} visibleManageVersion={true} />;
              })
            )}
          </FileContainer>
        </>
      </InfiniteScroll>
    </Modal>
    // <InfiniteScroll
    //   dataLength={
    //     fileFolders !== null ? fileFolders?.length : 0
    //   }
    //   next={fetchNext}
    //   hasMore={hasMore}
    //   scrollableTarget={"FileFoldersContainer"}
    //   loader={!hasMore ? null : <SkeletonFile />}
    //   className={styles.customInfinitscroll}
    // >
    //   <>
    //     <FileContainer
    //       header="Files"
    //       tableHeader={getFileFolder("FILE").length !== 0 ? true : false}
    //     >
    //       {loading && !loadMore ? (
    //         <SkeletonFile />
    //       ) : (
    //         //@ts-ignore
    //         getFileFolder("FILE")?.map((file, idx) => {
    //           return <FileCard file={file} key={file.file_app_id} />;
    //         })
    //       )}
    //     </FileContainer>
    //   </>
    // </InfiniteScroll>
  );
};

export default ManageversionFileContainer;
