import React from "react";

const AgentExpertise2 = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10657_2353)">
        <path
          d="M24 11C24 9.432 23.248 7.96 22 7.021V7C22 5.103 20.673 3.511 18.898 3.102C18.489 1.328 16.896 0 15 0C13.806 0 12.733 0.526 12 1.357C11.267 0.525 10.194 0 9 0C7.104 0 5.511 1.327 5.102 3.102C3.328 3.511 2 5.103 2 7V7.021C0.752 7.96 0 9.431 0 11C0 11.886 0.235 12.737 0.686 13.5C0.236 14.263 0 15.114 0 16C0 17.686 0.858 19.244 2.267 20.166C2.986 22.444 5.079 24 7.5 24C9.358 24 11.004 23.074 12 21.658C12.996 23.073 14.642 24 16.5 24C18.922 24 21.015 22.444 21.733 20.166C23.141 19.244 24 17.686 24 16C24 15.114 23.765 14.263 23.314 13.5C23.764 12.737 24 11.886 24 11ZM7.5 22C5.866 22 4.467 20.885 4.099 19.288C4.034 19.007 3.851 18.768 3.597 18.632C2.612 18.104 2 17.096 2 16.001C2 15.326 2.234 14.679 2.679 14.129C2.975 13.762 2.975 13.239 2.679 12.872C2.235 12.323 2 11.676 2 11C2 9.93 2.591 8.933 3.543 8.397C3.913 8.189 4.111 7.77 4.037 7.352C4.017 7.237 4 7.121 4 7C4 5.897 4.897 5 6 5C6.553 5 7 4.552 7 4C7 2.897 7.897 2 9 2C10.103 2 11 2.897 11 4V8H8.732C8.386 7.402 7.74 7 7 7C5.895 7 5 7.895 5 9C5 10.105 5.895 11 7 11C7.74 11 8.386 10.598 8.732 10H11V15H8.732C8.386 14.402 7.74 14 7 14C5.895 14 5 14.895 5 16C5 17.105 5.895 18 7 18C7.74 18 8.386 17.598 8.732 17H11V18.5C11 20.43 9.43 22 7.5 22ZM21.321 14.128C21.765 14.677 22 15.324 22 16C22 17.096 21.389 18.104 20.403 18.631C20.149 18.767 19.966 19.006 19.901 19.287C19.533 20.884 18.133 21.999 16.5 21.999C14.57 21.999 13 20.429 13 18.499V13.999H15C16.654 13.999 18 12.653 18 10.999V10.731C18.598 10.385 19 9.739 19 8.999C19 7.894 18.105 6.999 17 6.999C15.895 6.999 15 7.894 15 8.999C15 9.739 15.402 10.385 16 10.731V10.999C16 11.55 15.552 11.999 15 11.999H13V4C13 2.897 13.897 2 15 2C16.103 2 17 2.897 17 4C17 4.552 17.447 5 18 5C19.103 5 20 5.897 20 7C20 7.121 19.982 7.237 19.963 7.352C19.889 7.77 20.087 8.189 20.457 8.397C21.409 8.932 22 9.93 22 11C22 11.675 21.766 12.322 21.321 12.872C21.025 13.239 21.025 13.761 21.321 14.128Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10657_2353">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AgentExpertise2;
