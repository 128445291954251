import React from "react";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonFile = () => {
  const skeleton = [1, 2, 3];
  return (
    <>
      {skeleton.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              backgroundColor: "#DBDBDB",
              // maxWidth: "1253px",
              height: "99px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <SkeletonTheme
              {...({ color: "#E3E3E3", highlightColor: "#EEEEEE" } as any)}
            >
              <div
                style={{
                  padding: "27px 23px 36px 23px",
                }}
              >
                <div>
                  <Skeleton count={2} style={{}} />
                </div>
              </div>
            </SkeletonTheme>
          </div>
        );
      })}
    </>
  );
};

export default SkeletonFile;
