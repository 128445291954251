import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import * as Constant from "../FileConstants.const";
import PNGIcon from "src/assets/png/PNGIcon.png";
import XLSIcon from "src/assets/png/XLSIcon.png";
import PDFIcon from "src/assets/png/PDFIcon.png";
import UIFileType from "../type/UIFileType";
import styles from "./RecentFileContainer.module.scss";
import { getClassNameByTypeColor } from "../File/UtilFileCard";
import { supportViewer } from "../properies.file";
import { FileContext } from "../File.context";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; 
import { Modal } from "antd";
import ReactPlayer from "react-player";
import UIFileMenuPopover from "../component/UIFileMenuPopover";
import { getUrlFile, KN_FILE_SERVICE_URL_MAIN, previewFile } from "../../util.service";
import { AppContext } from "src/page/context/App.context";

interface RecentFileCardProps {
  file: any;
}
let recent_page = "recent_page";
const RecentFileCard: React.FC<RecentFileCardProps> = ({ file }) => {
  const appContext: any = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const fileContext: any = useContext(FileContext);
  const [urlPreviewImg, setUrlPreviewImg] = useState("");
  const [isOpenImg, setIsOpenImg] = useState(false);

  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [urlPreviewVideo, setUrlPreviewVideo] = useState("");

  const handleShowMenu = () => {
    if (file?.file_app_id === fileContext.currentShowMenuId) {
      fileContext.setCurrentShowMenuId(null);
    } else {
      fileContext.setCurrentShowMenuClick(recent_page);
      fileContext.setCurrentShowMenuId(file?.file_app_id);
    }
  };
  useEffect(() => {
    if (
      file?.file_app_id === fileContext.currentShowMenuId &&
      fileContext.currentShowMenuClick === recent_page
    ) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [fileContext.currentShowMenuId]);

  useEffect(() => {
    checkGetUrlFile();
  }, []);

  const [url, setUrl] = useState(null);
  const checkGetUrlFile = async () => {
    let url: any =
      KN_FILE_SERVICE_URL_MAIN +
      "/downloadFile?fileId=" +
      file?.file_app_id +
      "&size=300";
    url = await getUrlFile(url, file?.file_app_id, 300, file?.file_type, appContext.filePreviewTemp);
    setUrl(url);
  };

  const getIconType = (type: any) => {
    let iconType;
    if (type == Constant.fileType.PNG) {
      iconType = PNGIcon;
    } else if (type == Constant.fileType.XLS) {
      iconType = XLSIcon;
    } else if (type == Constant.fileType.PDF) {
      iconType = PDFIcon;
    }
    return iconType;
  };

  const getClassRecent = () => {
    if (
      supportViewer.images.indexOf(file?.file_type) > -1 &&
      file?.file_type !== "svg" &&
      file?.file_type !== "SVG"
    ) {
      return styles.boxBigImg;
    } else if (supportViewer.video.indexOf(file?.file_type) > -1) {
      return styles.boxBigImg;
    } else {
      return styles.boxBigFile;
    }
  };

  const clickPreviewImage = async () => {
    if (supportViewer.images.indexOf(file?.file_type) > -1) {
      let url: any =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        file?.file_app_id +
        "&size=1024";
      url = await getUrlFile(url, file?.file_app_id, 1024, file?.file_type, appContext.filePreviewTemp);
      console.log("url ; ", url)
      setUrlPreviewImg(url);
      setIsOpenImg(true);
    } else if (supportViewer.video.indexOf(file?.file_type) > -1) {
      let url =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        file?.file_app_id +
        "&onTrashReqIgnore=true";
      setUrlPreviewVideo(url);
      setIsOpenVideo(true);
    }
    previewFile(file?.file_app_id)
  };

  return (
    <>
      <div className={styles.recendbox}>
        {/* <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          width: "360px",
          height: 192,
        }}
      ></div> */}
        <div
          className={styles.boxBig}
          style={{ cursor: "pointer" }}
          onClick={clickPreviewImage}
        >
          <div
            style={{ height: 80, borderRadius: "5px" }}
            className={getClassNameByTypeColor(file?.file_type, false, false)}
          >
            <div className={getClassRecent()}>
              <UIFileType
                recentbig={true}
                file={file}
                urlImage={url}
              ></UIFileType>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 71,
            display: "inline-flex",
            alignItems: "center",
            padding: "0px 16px",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.boxTextIcon} style={{ width: "90%" }}>
            {/* <div
            style={{
              backgroundImage: `url(${getIconType(type)})`,
              backgroundSize: "cover",
              width: 25,
              height: 30,
            }}
          ></div> */}
            <div
              style={{
                backgroundSize: "cover",
                width: 25,
                maxHeight: "40px",
              }}
            >
              <UIFileType file={file} urlImage={null}></UIFileType>
            </div>
            <div style={{ marginLeft: 15, width: "100%", fontSize: "0.9rem" }}>
              <div className={styles.textOverflowListFileText}>
                <div>{file?.file_name}</div>
              </div>
            </div>
          </div>
          <div style={{ width: "10%", position: "relative" }}>
            {/* <div
              style={{ marginLeft: "0px", cursor: "pointer" }}
              onClick={handleShowMenu}
            >
              <MoreOutlined className={styles.iconMenu} />
            </div> */}
            {/* </Popover> */}
            <UIFileMenuPopover
              showMenu={showMenu}
              handleShowMenu={handleShowMenu}
              file={file}
              setShowMenu={setShowMenu}
              recentFile={true}
            ></UIFileMenuPopover>
          </div>
        </div>
      </div>
      {isOpenImg && (
        // <Lightbox
        //   wrapperClassName={"lightboxOverride"}
        //   mainSrc={urlPreviewImg}
        //   onCloseRequest={() => setIsOpenImg(false)}
        //   reactModalStyle={{
        //     content: {
        //       zIndex: "9999",
        //     },
        //     overlay: {
        //       zIndex: "9999",
        //     },
        //   }}
        // />
        <Lightbox
            wrapperClassName={"lightboxOverride"}
            mainSrc={urlPreviewImg}
            onCloseRequest={() => setIsOpenImg(false)}
            imagePadding={20}
        />
      )}
      {isOpenVideo && (
        <Modal
          centered
          className={styles.modalOveride}
          visible={true}
          footer={null}
          onCancel={() => setIsOpenVideo(false)}
        >
          <div
            style={{ width: "100%" }}
          // onClick={handleClickVideoModal}
          >
            <ReactPlayer
              // onReady={videAudioOnReady}
              controls={true}
              className={styles.reactPlayer}
              url={urlPreviewVideo}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default RecentFileCard;
