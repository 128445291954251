import React, { useEffect, useState } from "react";
import styles from "./DocumentCardView.module.scss";
import { Checkbox, Pagination, Skeleton } from "antd";
import { FilePdfOutlined, FileOutlined } from "@ant-design/icons";
import { DocumentData, formatDate, formatFileSize } from "../DocumentView";

interface IDocumentCardViewProps {
  loading: boolean;
  currentPage: number;
  pageSize: number;
  data: DocumentData[];
  onPageChange: (page: number) => void;
  onSelectionChange: (selectedRows: DocumentData[]) => void;
}

const DocumentCardView: React.FC<IDocumentCardViewProps> = ({
  loading,
  currentPage,
  pageSize,
  data,
  onPageChange,
  onSelectionChange,
}) => {
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentData[]>(
    []
  );

  const getUniqueId = (doc: DocumentData) => {
    return `${doc.app_id}_${doc.file_name}_${doc.file_type}`;
  };

  const isSelected = (doc: DocumentData) => {
    return selectedDocuments.some(
      (selected) => getUniqueId(selected) === getUniqueId(doc)
    );
  };

  const onSelect = (document: DocumentData) => {
    setSelectedDocuments((prev) => {
      const isSelected = prev.some(
        (doc) => getUniqueId(doc) === getUniqueId(document)
      );
      if (isSelected) {
        return prev.filter((doc) => getUniqueId(doc) !== getUniqueId(document));
      }
      return [...prev, document];
    });
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedDocuments(data);
    } else {
      setSelectedDocuments([]);
    }
  };

  useEffect(() => {
    onSelectionChange(selectedDocuments);
  }, [selectedDocuments, onSelectionChange]);

  const renderSkeleton = () => {
    return Array(pageSize)
      .fill(null)
      .map((_, index) => (
        <div key={index} className={styles.card}>
          <div className="w-[10%]">
            <Skeleton.Avatar active size="small" />
          </div>
          <div className="w-[80%] flex flex-col items-start gap-4">
            <Skeleton.Input active size="small" block />
            <div className="flex gap-2">
              <Skeleton.Node active style={{ width: 40, height: 20 }} />
              <Skeleton.Node active style={{ width: 80, height: 20 }} />
            </div>
          </div>
          <div className="w-[10%]">
            <Skeleton.Avatar active size="small" />
          </div>
        </div>
      ));
  };

  const renderCard = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = data.slice(startIndex, endIndex);
    return paginatedData.map((doc, key) => (
      <div
        key={key}
        className={`${styles.card} ${isSelected(doc) ? styles.selected : ""}`}
        onClick={() => onSelect(doc)}
      >
        <div className="w-[10%]">
          <Checkbox
            className="w[10%]"
            checked={isSelected(doc)}
            onClick={(e) => e.stopPropagation()}
            onChange={() => onSelect(doc)}
          />
        </div>
        <div className="w-[80%] flex flex-col items-start">
          <div className={styles.documentTitle}>{doc.file_name}</div>
          <div className={styles.documentMeta}>
            <span className={styles.fileSize}>
              {formatFileSize(doc.file_size)}
            </span>
            <span className={styles.date}>{formatDate(doc.created_at)}</span>
          </div>
        </div>
        <div className="w-[10%]">
          <div className={styles.iconContainer}>
            {doc.file_type?.toLowerCase() === "pdf" ? (
              <div className={`${styles.icon} ${styles.pdf}`}>
                <FilePdfOutlined />
              </div>
            ) : (
              <div className={`${styles.icon} ${styles.doc}`}>
                <FileOutlined />
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="flex flex-col justify-between gap-8">
      <div className="">
        <div className="flex flex-row justify-between items-center pb-4">
          <div className="flex flex-row items-center gap-4">
            <Checkbox
              checked={selectedDocuments.length === data.length}
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
            <span className="text-[#3C3C3C]">Select all</span>
          </div>
          <span className="text-[#8D8D8D]">Total {data.length}</span>
        </div>
        <div className={styles.cardContainer}>
          {loading ? renderSkeleton() : renderCard()}
        </div>
        {data.length === 0 && (
          <div className="flex flex-col justify-center items-center text-center text-gray-500 h-[30vh]">
            <span className="font-bold text-xl ">No results found</span>
            <span className="">
              Try searching for something else or explore our categories
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-end items-center">
        <Pagination
          defaultCurrent={currentPage}
          total={data.length}
          onChange={onPageChange}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default DocumentCardView;
