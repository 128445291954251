import React, { useState, useContext, useEffect } from "react";

import styles from "./KNLinkContainer.module.scss";
import { useTranslation } from "react-i18next";
import { tabName } from "../../properies.file";
import { FileContext } from "../../File.context";
import { Col, Row } from "antd";
// import { checkEmpty, isTrue } from "src/services/util.service";
import { checkEmpty, getToken, parseJwt } from "src/services/util.service";
import KNLinkItem  from "./KNLinkItem"
import InfiniteScroll from "react-infinite-scroll-component";
import useFetch, {CachePolicies} from "use-http";
import SkeletonLinkFile from "../linksFile/SkeletionLinkFile";
import { APIChatEnpoint, mainState, nodataFile, getAuthorization, KN_FILE_SERVICE_URL_MAIN } from "src/page/kn/util.service";

const defaultLitmitOfset = 10;

interface LinkParams {
  groupId: any;
  limit: number;
  offset: any;
}

interface LinkResponse {
  total: number;
  data: LinkData[];
  success: boolean;
  message: string;
}

interface LinkData {
  file_app_id: string;
  file_name: string;
  file_path: string;
  user_id: string;
  link_download: string;
  file_system_id: string;
  create_date: string;
  update_date: string;
  active_: string;
  type_: string;
  file_type: string;
  full_name_type: string;
  detail: string;
  owner_user_id: string;
}

const KNLinkContainer = (props: any) => {
  const { t, i18n } = useTranslation();
  const headerText = `${props.header}`;
  const fileContext: any = useContext(FileContext);
  const tableHeader = props.tableHeader;
  const [hasMore, setHasMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offsetFile, setOffsetFile]: any = useState(0);
  const [links, setLinks] = useState<LinkData[]>([]);


  useEffect(() => {
    console.log("fileContext.tab : ", fileContext.tab);
    if(links.length > 0 || offsetFile > 0){
      setLinks([])
      setOffsetFile(0)
    }
  }, []);

  useEffect(() => {
    console.log("fileContext.isDeleteLink : ", fileContext.isDeleteLink)
    if(fileContext.isDeleteLink){
      setOffsetFile(0)
      setLoadMore(false);
      setLinks([])
      fileContext.setIsDeleteLink(false)
      fetchLinkDataFirstTime()
    }
  }, [fileContext.isDeleteLink]);

  const getLinkData = useFetch<LinkResponse>(
    KN_FILE_SERVICE_URL_MAIN + "/getLinks",
    {
      // path: APIEnpoint.myApps,
      cachePolicy: CachePolicies.NO_CACHE,
      headers: {
        'Authorization': getAuthorization(), // หรือ token ที่คุณเก็บไว้
        'Content-Type': 'application/json'
      }
    }
  );


  const fetchLinkData = async () => {
    // let time = new window.Date().toISOString();
    //let time = getLastTimeLink();
    const params: LinkParams = {
      groupId: fileContext.groupId,
      limit: defaultLitmitOfset,
      offset: offsetFile,
    };
    
    if (offsetFile === 0) setLinks([]);
    
    const queryString = new URLSearchParams(params as any).toString();
    await getLinkData.get(`?${queryString}`);
    if (offsetFile === 0) {
      setHasMore(true);
      setLoadMore(false);
    }
    console.log("getLinkData.response?.data?.data ==> ", getLinkData.response?.data?.data)

    // setLinks((prev: any) => [...prev, getLinkData.response?.data?.data]);
    const newLinks = getLinkData.response?.data?.data || [];

    setLinks((prev: LinkData[]) => [...prev, ...newLinks]);
    
    if (
      (links?.length ?? 0) + defaultLitmitOfset >= 
      (isLinkResponse(getLinkData.response?.data) ? getLinkData.response?.data?.total : 0)
    ) {
      setHasMore(false);
    }
  };
  const fetchLinkDataFirstTime = async () => {
    setLoading(true);
    await fetchLinkData();
    setLoading(false);
  };

  useEffect(() => {
    if (fileContext.groupId !== mainState.init) fetchLinkDataFirstTime();
  }, [fileContext.groupId, offsetFile, fileContext.mainTab]);

  useEffect(() => {
    // if (links.length > 0) 
    console.log("links : ", links);
  }, [links]);

  const isLinkResponse = (response: unknown): response is LinkResponse => {
    return (
      response != null &&
      typeof response === 'object' &&
      'total' in response &&
      typeof (response as LinkResponse).total === 'number'
    );
  }

  // const links: any = [
  //   {
  //       "link_id": "01",
  //       "link": "https://www.youtube.com/watch?v=bjeurG0jH9w&t=4940s",
  //       "link_img": "https://www.youtube.com/watch?v=bjeurG0jH9w&t=4940s",
  //       "side_link": "https://www.youtube.com",
  //       "date_shared": "2022-05-03T07:41:05.995Z",
  //       "shared_by": "anu lastname",
  //       "file_type": "link"
  //   },
  //   {
  //       "link_id": "02",
  //       "link": "https://www.youtube.com/watch?v=bjeurG0jH9w&t=4940s",
  //       "link_img": "https://www.youtube.com/watch?v=bjeurG0jH9w&t=4940s",
  //       "side_link": "https://www.youtube.com",
  //       "date_shared": "2022-05-03T07:41:05.995Z",
  //       "shared_by": "anu lastname",
  //       "file_type": "link"
  //   }
  // ]

  const fetchNext = async () => {
    fetchNextLinks(
      fileContext,
      getLinkData.response?.data,
      setOffsetFile,
      defaultLitmitOfset,
      offsetFile
    );
  };

  function fetchNextLinks(
    fileContext: any,
    data: any,
    setOffsetFile: any,
    defaultLitmitOfset: any,
    offsetFile: any
  ) {
    console.log(" data : ", data);
    if (links?.length + defaultLitmitOfset >= data?.total) {
      setHasMore(false);
    }
    if (links?.length >= data?.total) {
      setHasMore(false);
    }
    if (links?.length < data?.total) {
      setLoadMore(true);
      setOffsetFile(offsetFile + defaultLitmitOfset);
    }
  }

  useEffect(() => {
    const target = document.getElementById('ted');
    console.log(target); // ตรวจสอบว่า target มีอยู่จริง
  }, []);


  return (
    <>
      <div>
        <Row
          justify="start"
          style={{ fontSize: "1rem", padding: "0px 8px 0px 6px" }}
        >
          <Col
            span={ 15 }
          >
            <div style={{ textAlign: "left" }} className={styles.groupHeadName}>
              {t("Name")}
            </div>
          </Col>

          <Col span={3}>
            <div className={styles.groupHeadName} style={{ textAlign: "left" }}>{t("Type")}</div>
          </Col>
          <Col span={6}>
            <div className={styles.groupHeadName} style={{ textAlign: "left" }}>{t("Last modified")}</div>
          </Col>
        </Row>
        <div id="ted"  style={{ height: '80vh', overflow: 'auto', position: 'relative' }}>
          <InfiniteScroll
            dataLength={
              links !== null ? links?.length : 0
              // 100
            }
            next={fetchNext}
            hasMore={hasMore}
            scrollableTarget={"ted"}
            loader={!hasMore ? null : <SkeletonLinkFile />}
            className={styles.customInfinitscroll}
          >
            <div
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 8px 0px 6px",
              }}
            >
              <div
                style={{ fontSize: "1rem", fontWeight: 600, color: "#428DFF" }}
              ></div>
            </div>
            {" "}
            
            <div className={styles.cardGroup}>
              {loading && !loadMore ? (
                <SkeletonLinkFile />
              ) : (
                links?.map((link: any) => (
                  <div key={`_item_link_m${link.file_app_id}`}>
                      <KNLinkItem link={link} />
                  </div>
                ))
              )}
            </div>

          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default KNLinkContainer;
