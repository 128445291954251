import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
//@ts-ignore
import _ from "lodash";
import {FileContext, FileContextProps} from "../File.context";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {tabName} from "../properies.file";
import { deleteFromTrash, getAboutLimitMain } from "../../util.service";
import { checkEmpty } from "src/services/util.service";
import CenterConfirmModal from "../Modal/CenterConfirmModal";

function UIModalConfrimDeleteForever(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [loading, setLoading]: any = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const getMessageDelete = () => {
    let typetemp = t("Folder");
    if (fileContext.fileSelected?.type_ !== "DIRECTORY") {
      typetemp = t("File");
    }

    let v = t('Are you sure to delete this "File" forever?').replace(
      '"File"',
      typetemp
    );
    return v;
  };

  // async function setFolderDelete(res: any, type: string) {
  //   let files_ = fileContext.fileFolders;
  //   // let { files, newLenght } = reflectorFileFoldersDelete(files_, type, res);
  //   let i = files_.findIndex((item: any) => {
  //     return item.file_app_id === res.file_app_id;
  //   });
  //   if (i != -1) {
  //     files_.splice(i, 1);
  //   }

  //   fileContext.setFiles(files_);
  // }

  const getAboutLimit = async () => {
    try {
      let data = await getAboutLimitMain({
        userId: fileContext.userId,
        groupId: fileContext.groupId,
      });
      fileContext.setAboutLimit(data);
      console.log("getAboutLimit : ", data);
    } catch (e) {
      console.error(e);
    }
  };

  const fnDoneDelete = async () => {
    if (loading === true) return;
    let onlySelectVersion = fileContext.visibleManageVersion;
    // if(fileContext.tab == tabName.manageversion) onlySelectVersion = true;
    try {
      setLoading(true);
      let groupId = fileContext.groupId;
      if (checkEmpty(groupId)) groupId = "";
      let res: any = await deleteFromTrash(
        fileContext.fileSelected,
        groupId,
        onlySelectVersion
      );
      let file = Object.assign({}, fileContext.fileSelected);
      // setFolderDelete(file, file.type_);
      fileContext.setDisplayConfirmDeleteForever(false);
      fileContext.setIsSuccessDeleteForever(true);
      getAboutLimit();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const [messageNoti, setMessageNoti]: any = useState(getMessageDelete());

  return (

    <CenterConfirmModal
      cancelText={t("Cancel")}
      confirmText={loading ? <Spin indicator={antIcon} /> : t("Ok")}
      text={messageNoti}
      visible={fileContext.displayConfirmDeleteForever}
      onCancel={() => {
        if (loading === false) fileContext.setDisplayConfirmDeleteForever(false);
      }}
      onConfirm={() => {
        fnDoneDelete();
      }}
    ></CenterConfirmModal>
    
    // <Modal
    //   // centered
    //   visible={fileContext.displayConfirmDeleteForever}
    //   className={styles.modalOveride}
    //   onCancel={() => {
    //     fileContext.setDisplayConfirmDeleteForever(false);
    //   }}
    //   closeIcon={<div />}
    //   closable={false}
    // >
    //   <div className={styles.boxCreate}>
    //     <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
    //       {t("Delete forever")}
    //     </div>

    //     <div style={{ fontSize: "1rem", color: "#5f5f5f" }}>{messageNoti}</div>

    //     <div className={styles.boxConfirmBtn}>
    //       <button
    //         className={styles.bottonBtnCancel}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div
    //           onClick={() => {
    //             if (loading === false)
    //               fileContext.setDisplayConfirmDeleteForever(false);
    //           }}
    //         >
    //           {t("Cancel")}
    //         </div>
    //       </button>
    //       <button
    //         className={styles.bottonBtnOk}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div onClick={fnDoneDelete}>
    //           {loading ? <Spin indicator={antIcon} /> : t("Ok")}
    //         </div>
    //       </button>
    //     </div>
    //   </div>
    // </Modal>
  );
}

export default UIModalConfrimDeleteForever;
