import { createSlice } from "@reduxjs/toolkit";
import { fetchActiveAgentsByUserID, IActiveAgents } from "src/store/actions/agent/agetAction";
import { RootState } from "src/store/store";

type AgentState = {
    activeAgents?: IActiveAgents;
    loading: boolean;
    error?: string;
  };
  
  const initialValues: AgentState = {
    loading: false,
  };
  
  const agentSlice = createSlice({
    name: "agent",
    initialState: initialValues,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchActiveAgentsByUserID.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchActiveAgentsByUserID.fulfilled, (state, action) => {
          state.activeAgents = action.payload;
          state.loading = false;
        })
        .addCase(fetchActiveAgentsByUserID.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Failed to load user";
        });
    },
  });
  
  // eslint-disable-next-line no-empty-pattern
  export const {} = agentSlice.actions;
  export const agentSelector = (store: RootState) => store.agentReducer;
  export default agentSlice.reducer;