
import React from "react";

const IconSearch = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M15.8045 14.8619L11.8252 10.8825C12.9096 9.55625 13.4428 7.86392 13.3144 6.15556C13.1861 4.44721 12.406 2.85354 11.1356 1.7042C9.86516 0.554862 8.20158 -0.0622172 6.48895 -0.019396C4.77632 0.0234252 3.14566 0.72287 1.93426 1.93426C0.72287 3.14566 0.0234252 4.77632 -0.019396 6.48895C-0.0622172 8.20158 0.554862 9.86516 1.7042 11.1356C2.85354 12.406 4.44721 13.1861 6.15556 13.3144C7.86392 13.4428 9.55625 12.9096 10.8825 11.8252L14.8619 15.8045C14.9876 15.926 15.156 15.9932 15.3308 15.9916C15.5056 15.9901 15.6728 15.92 15.7964 15.7964C15.92 15.6728 15.9901 15.5056 15.9916 15.3308C15.9932 15.156 15.926 14.9876 15.8045 14.8619ZM6.66652 11.9999C5.61169 11.9999 4.58054 11.6871 3.70348 11.101C2.82642 10.515 2.14283 9.68204 1.73916 8.7075C1.3355 7.73296 1.22988 6.6606 1.43567 5.62604C1.64145 4.59147 2.14941 3.64117 2.89529 2.89529C3.64117 2.14941 4.59147 1.64145 5.62604 1.43567C6.6606 1.22988 7.73296 1.3355 8.7075 1.73916C9.68204 2.14283 10.515 2.82642 11.101 3.70348C11.6871 4.58054 11.9999 5.61169 11.9999 6.66652C11.9983 8.08052 11.4359 9.43615 10.436 10.436C9.43615 11.4359 8.08052 11.9983 6.66652 11.9999Z" fill="#A0A1A9"/>
        </svg>
    );
}

export default IconSearch;
