export const pathRoutes = {
  Dashboard: "dashboard",
  MainDashboard: "main-dashboard",
  DataConnection: "database-connection",
  Story: "story",
  AppComponent: "app-component",
  DataReference: "data-reference",
  Specifications: "specifications",
  Prompt: "prompt",
  History: "history",
  Document: "document",

  Generate: "/generate",
  Login: "/login",
  Root: "/loading",
  Workflow: "workflow",
  Subscription: "subscription",
  Payment: "payment",
  ContractSales: "contract",
  InviteList: "invited-list",
  Billing: "billing",
  AgentsDashboard: "agents-dashboard",
  KnowledgeBase: "knowledgeBase",
  Usage: "usage",
};

export const sortType = {
  DESC: "DESC",
  ASC: "ASC",
};
