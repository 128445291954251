import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import {
  BILLING_HISTORY,
  BILLING_INVOICE,
} from "src/utils/endpoint/pmtx.endpoint";
import {  InvoiceResponse } from "../../../model/invoice";
import { PagingResponse } from "../../../model/paging";

export const fetchAllInvoices = createAsyncThunk<
  PagingResponse<InvoiceResponse>,
  { offset?: number; limit?: number; email?: string } // Optional query parameters
>(
  "invoices/fetchAllInvoices",
  async ({ offset = 0, limit = 10, email = "" }) => {
    const response = await axios.get<PagingResponse<InvoiceResponse>>(
      `${BILLING_INVOICE}`,
      {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
        params: {
          offset,
          limit,
          email,
        },
      }
    );

    return response.data;
  }
);

export const fetchBillingsByUserID = createAsyncThunk<InvoiceResponse>(
  "billing/fetchBillingsByUserID",
  async () => {
    const response = await axios.get(`${BILLING_HISTORY}`, {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    });

    return response.data;
  }
);
