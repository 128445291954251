import { createSlice } from "@reduxjs/toolkit";
import { fetchNumberOfAppsByUserID, INumOfApp } from "src/store/actions/apps/appAction";
import { RootState } from "src/store/store";

type AppState = {
    numOfApp?: INumOfApp;
    loading: boolean;
    error?: string;
  };
  
  const initialValues: AppState = {
    loading: false,
  };
  
  const appSlice = createSlice({
    name: "app",
    initialState: initialValues,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchNumberOfAppsByUserID.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchNumberOfAppsByUserID.fulfilled, (state, action) => {
          state.numOfApp = action.payload;
          state.loading = false;
        })
        .addCase(fetchNumberOfAppsByUserID.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Failed to load user";
        });
    },
  });
  
  // eslint-disable-next-line no-empty-pattern
  export const {} = appSlice.actions;
  export const appSelector = (store: RootState) => store.appReducer;
  export default appSlice.reducer;