import React, {useState} from 'react';
import {Layout, Menu, MenuProps, theme} from "antd";
import styles from './CenterPageLayout.module.scss'
// import HeaderSearch from "../../components/shared/headerSearch/HeaderSearch";
import {DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout

interface CenterPageLayoutProps {
    disabledHeader?: boolean
    children:any
    hasSider?: boolean
    isFromAppsGroupCommunity?: boolean
}

const { useToken } = theme;

export const CenterPageLayout: React.FunctionComponent<CenterPageLayoutProps> = (props) => {
    const { token } = useToken();
    const { disabledHeader, children, hasSider, isFromAppsGroupCommunity=false } = props

    return (
        <Layout className={`${styles.MyCenterLayout} ${isFromAppsGroupCommunity?styles.MyCenterLayoutFromApps:""}`} hasSider={hasSider}>
            {/* {!disabledHeader &&
            <HeaderSearch
                isQuickChat={true}
                // menu={<ProfileResponsiveMenu clickMenu={clickMenu} />}
            ></HeaderSearch>} */}
            {/*<CenterContent>*/}
                <Layout style={{ paddingTop: disabledHeader ? 0 : "0px"}} >
                    {children}
                </Layout>
            {/*</CenterContent>*/}
        </Layout>
    )
}