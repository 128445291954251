import React, { ChangeEvent, useState } from "react";
import { Button, Input, Popover } from "antd";
import { FaPlusCircle, FaTelegramPlane, FaUpload } from "react-icons/fa";
import { ME } from "../constants/chatbubble.constant";

type Props = {
  isLoading: boolean;
  addNewMessage: (message: any) => void;
};

export default function CommentMessageBox({ isLoading, addNewMessage }: Props) {
  let [comment, setComment] = useState<string>("");
  let [isOpenPopOver, setIsOpenPopOver] = useState(false);

  const handleChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.currentTarget.value;
    setComment(input);
  };

  const handleOpenPopover = () => {
    setIsOpenPopOver((prev) => !prev);
  };

  const handleSendMessageByButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    let newComment = {
      agent: ME,
      text: comment,
      chatColor: "#F8F9FA",
    };

    addNewMessage(newComment);
    setComment("");
  };

  const handleSendMessageByKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();

      let newComment = {
        agent: ME,
        text: comment,
        chatColor: "#F8F9FA",
      };

      addNewMessage(newComment);
      setComment("");
    }
  };

  return (
    <div className="w-full min-h-[60px] flex justify-center items-center pb-4">
      <div className="w-full border border-solid border-gray-100 mx-12 flex items-center gap-3 md:gap-4 bg-white rounded-2xl p-3 md:p-4 shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-0.5">
        <div className="flex justify-center items-center">
          <Popover
            content={<PopoverContent onClose={handleOpenPopover} />}
            trigger="click"
            open={isOpenPopOver}
            onOpenChange={handleOpenPopover}
          >
            <Button
              className="w-10 h-10 md:w-11 md:h-11 flex items-center justify-center border-none rounded-xl bg-gray-50 hover:bg-blue-500 hover:-translate-y-0.5 active:translate-y-0 transition-all duration-200 disabled:opacity-70 disabled:hover:bg-gray-50 disabled:hover:translate-y-0"
              disabled={isLoading}
            >
              <FaPlusCircle className="text-gray-400 text-xl md:text-2xl group-hover:text-white transition-colors" />
            </Button>
          </Popover>
        </div>

        <div className="flex-1">
          <Input
            value={comment}
            onChange={handleChangeComment}
            placeholder="Message PromptX"
            onKeyDown={handleSendMessageByKeyPress}
            disabled={isLoading}
            className="w-full rounded-xl py-2.5 px-4 text-[15px] leading-relaxed border-2 border-transparent bg-gray-50 hover:bg-gray-100 focus:bg-white focus:border-blue-500 focus:shadow-blue-100 transition-all duration-200 placeholder-gray-400 text-gray-800 disabled:bg-gray-100 disabled:cursor-not-allowed"
          />
        </div>

        <div className="flex justify-center items-center">
          <Button
            onClick={handleSendMessageByButtonClick}
            className="w-10 h-10 md:w-11 md:h-11 flex items-center justify-center border-none rounded-xl bg-gray-50 hover:bg-blue-500 hover:-translate-y-0.5 active:translate-y-0 transition-all duration-200 disabled:opacity-70 disabled:hover:bg-gray-50 disabled:hover:translate-y-0"
            disabled={isLoading}
          >
            <FaTelegramPlane className="text-gray-400 text-xl md:text-2xl group-hover:text-white transition-colors" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function PopoverContent({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex flex-col gap-2 p-3 bg-white rounded-lg shadow-lg">
      <button
        onClick={onClose}
        className="flex items-center gap-2 px-3 py-2 rounded-md hover:bg-gray-50 active:bg-gray-100 transition-colors duration-150 text-gray-700"
      >
        <FaUpload className="text-gray-500" />
        <span>Attachment</span>
      </button>
    </div>
  );
}
