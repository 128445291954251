import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom"
//@ts-ignore
import _ from "lodash";
import {FileContext} from "../../File.context";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./MediasContainer.module.scss";
import {useTranslation} from "react-i18next";
import UIMediaItem from "./UIMediaItem";
import classNames from "classnames";
import galleryStyles from "./gallery.module.scss";
import SkeletonMediaFile from "./SkeletionMetailFile";
import { APIFileEnpoint, getFileFoldersMethodGet, KN_FILE_SERVICE_URL_MAIN, mainState, nodataFile } from "src/page/kn/util.service";
import { checkEmpty, getToken, parseJwt } from "src/services/util.service";
import NoData from "../../NoDataDisplay/NoData";

const defaultLitmitOfset = 30;
let link = KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFiles;

const MediasContainer = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const [offsetFile, setOffsetFile]: any = useState(0);
  const [offsetFolder, setOffsetFolde]: any = useState(0);
  const fileContext: any = useContext(FileContext);
  const [fileMedias, setFileMedias]: any = useState([]);

  const [currentRes, setCurentRes]: any = useState({});
  const [loading, setloading]: any = useState(false);

  // const getFileFolderData = useFetch(
  //   KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFiles,
  //   {
  //     // path: APIEnpoint.myApps,
  //     cachePolicy: CachePolicies.NO_CACHE,
  //   }
  // );

  const getUserData = () => {
    let token = getToken();
    let userdata: any = null;
    if (!checkEmpty(token)) {
      userdata = parseJwt(token);
    }
    return userdata;
  };

  const fetchFileFolderData = async () => {
    setloading(true);
    if (offsetFile === 0) setFileMedias([]);

    let param = {
      groupId: fileContext.groupId,
      userId: getUserData()?.userID,
      offset: offsetFile,
      limitOfset: defaultLitmitOfset,
      tag: fileContext.mainTab,
      moduleName : fileContext.moduleFileAc
    };

  
    try {
      setloading(true);
      let response: any = await getFileFoldersMethodGet(
        param,
        link,
        fileContext
      );

      setCurentRes(response);
      setloading(false);
      
      if (offsetFile === 0) {
        setHasMore(true);
        setLoadMore(false);
      }
      setFileMedias((prev: any) => {
        if (offsetFile === 0) return response?.data?.data;
        if (checkEmpty(prev)) prev = [];
        return [...prev, ...response?.data?.data];
      });
      if (response?.data?.total === 0) {
        setHasMore(false);
      }
      if (fileMedias?.length + defaultLitmitOfset >= response?.data?.total) {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
      // setloading(false);
    }
  };
  useEffect(() => {
    if (fileContext.groupId !== mainState.init) fetchFileFolderData();
  }, [fileContext.groupId, offsetFile, fileContext.mainTab,fileContext.moduleFileAc]);

  useEffect(() => {
    setOffsetFile(0);
  }, [fileContext.mainTab]);

  const getFileFolder = (type: string) => {
    let ls = fileMedias?.filter((item: any) => {
      return item.type_ === type;
    });
    return ls;
  };

  const fetchNext = async () => {
    fetchNextMedia(
      fileContext,
      currentRes?.data,
      setOffsetFile,
      defaultLitmitOfset,
      offsetFile
    );
  };

  function fetchNextMedia(
    fileContext: any,
    data: any,
    setOffsetFile: any,
    defaultLitmitOfset: any,
    offsetFile: any
  ) {
    if (fileMedias?.length + defaultLitmitOfset >= data?.total) {
      setHasMore(false);
    }
    if (fileMedias?.length >= data?.total) {
      setHasMore(false);
    }
    if (fileMedias?.length < data?.total) {
      setLoadMore(true);
      setOffsetFile(offsetFile + defaultLitmitOfset);
    }
  }

  return (
    <>
      <InfiniteScroll
        dataLength={
          fileMedias !== null ? fileMedias?.length : 0
          // 100
        }
        next={fetchNext}
        hasMore={hasMore}
        scrollableTarget={"FileMediasContainer"}
        loader={!hasMore ? null : <SkeletonMediaFile />}
        className={styles.customInfinitscroll}
        style={{paddingTop:"10px"}}
      >
        {loading && !loadMore ? (
          <SkeletonMediaFile />
        ) : (
          <div
            key={"_item_media_inside"}
            className={galleryStyles.zard_gallery_container}
          >
            {
              //@ts-ignore
              fileMedias?.map((file, idx) => {
                return (
                  <div
                    key={"_item_media_m" + file?.file_app_id}
                    className={classNames(galleryStyles.zard_gallery_tile)}
                  >
                    <div>
                      <UIMediaItem file={file}></UIMediaItem>
                    </div>
                  </div>
                );
              })
            }
          </div>
        )}
        {!loading && fileMedias.length === 0 && (
          // <div className={styles.textEmpty}>{t("Empty")}</div>
          <NoData
            header={t(nodataFile.noDataHeaderFileText)}
            detail={t(nodataFile.nodataFiletextH) + ", " + t(nodataFile.nodataFiletextL) + "."}
          />
        )}
      </InfiniteScroll>
    </>
  );
};

export default MediasContainer;
