import React, {useContext, useEffect, useRef, useState} from "react";

import styles from "./UIModalShareFile.module.scss";
import {useTranslation} from "react-i18next";
import left_arrow from "src/assets/png/left-arrow.png";
import follow_file from "src/assets/png/follow.png";
import selectedItemShare from "src/assets/png/selectedItemShare.png";
//@ts-ignore
import _, {assign, fill} from "lodash";
import world_file from "src/assets/png/world.png";
import {FileContext, FileContextProps} from "../File.context";
import {Col, message, Row, Spin} from "antd";
import {Modal} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import useFetch, {CachePolicies} from "use-http";
import Skeleton from "react-loading-skeleton";
import UIGroupUserItem from "./UIGroupUserItem";
import $ from "jquery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import { addMemberToAccessBackend, CENTER_URL_MAIN, getFileObj, getLinkDownload, getUserDetail, KN_FILE_SERVICE_URL_MAIN } from "../../util.service";
import { checkEmpty } from "src/services/util.service";
import ButtonCenterCLose from "../ButtonCenterClose";
import CenterButton from "src/shared/button/Button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ShareContextFile = React.createContext({});
const DefaultLimit = 30;
const ShareFileProviderContext = (props: any) => {
  const [stampUsersSelected, setStampUsersSelected] = useState([]);
  const state = {
    stampUsersSelected: stampUsersSelected,
    setStampUsersSelected: setStampUsersSelected,
  };
  return (
    <ShareContextFile.Provider value={state}>
      {props.children}
    </ShareContextFile.Provider>
  );
};
//@ts-ignore
ShareFileProviderContext.Consumer = ShareContextFile.Consumer;

let limit = 30;
let mainTab = "mainTab";
let addTab = "addTab";
let editUserTab = "editUserTab";
let editMainTab = "editMainTab";
function UIModalShareFileContainer(props: any) {
  const [toAddLevelMain, setToAddLevelMain] = useState("anyoneWithTheLink");
  const [toAddLevelSheet1, setToAddLevelSheet1] = useState("viewer");
  const [toAddLevelSheet2, setToAddLevelSheet2] = useState("viewer");
  const [toAddLevelSheet1_person, setToAddLevelSheet1_person] = useState(
    "anyoneWithTheLink"
  );
  const [toAddLevelSheet2_person, setToAddLevelSheet2_person] = useState(
    "anyoneWithTheLink"
  );
  const selectedLevelSheet1_person: any = useRef(null);
  const selectedLevelSheet2_person: any = useRef(null);
  const selectedLevelSheet2: any = useRef(null);

  const [editMainLevel_person, setEditMainLevel_person] = useState(
    "anyoneWithTheLink"
  );
  const [editMainLevel, setEditMainLevel] = useState("viewer");
  const selectedEditMainLevel_person: any = useRef(null);
  const selectedEditMainLevel: any = useRef(null);

  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const shareContextFile: any = useContext(ShareContextFile);
  const [sharing, setSharing]: any = useState(false);
  const [users, setUsers]: any = useState([]);
  const [groups, setGroups]: any = useState([]);
  const [totalUser, setTotalUser]: any = useState(0);
  const [totalGroup, setTotalGroup]: any = useState(0);

  const [offsetUser, setOffsetUser] = useState(0);
  const [offsetGroup, setOffsetGroup] = useState(0);
  const [search, setSearch] = useState("");
  const [messageNotiShareGroup, setMessageNotiShareGroup]:any = useState(
    t("Not found group")
  );
  const [messageNotiShareUser, setMessageNotiShareUser]:any = useState(
    t("Not found user")
  );
  const [loadmoreUser, setLoadmoreUser]: any = useState(false);
  const [loadmoreGroup, setLoadmoreGroup]: any = useState(false);
  const [showNotiShare, setShowNotiShare] = useState(false);
  const [messageNotiShare, setMessageNotiShare] = useState("");
  const [addingMember, setAddingMember] = useState(false);
  const [editingMember, setEditingMember] = useState(false);
  const [editingMain, setEditingMain] = useState(false);
  const [titleAlert, setTitleAlert] = useState();
  const [detailAlert, setDetailAlert] = useState("");
  const [userEdit, setUserEdit]: any = useState({});
  const [mainUserOwner, setMainUserOwner]: any = useState({});
  const [whoHasAccesses, setWhoHasAccesses]: any = useState([]);

  const [canShare, setCanShare]: any = useState(false);
  const [tabContent, setTabContent]: any = useState(mainTab);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const inputLink1: any = useRef(null);
  const [levelUserEdit, setLevelUserEdit] = useState("");
  const selectedLevelUserEdit: any = useRef(null);
  const [linkDownload, setLinkDownload] = useState("");



  const getTextAnyoneLevel = (text: any) => {
    if (text === "anyoneWithTheLink") return t("Anyone with the link");
    else return t("Restricted");
  };

  const getTextAnyoneLevel_2 = (text: any) => {
    if (text === "anyoneWithTheLink")
      return t("Anyone on the internet with this link can view");
    else return t("Only people added can open with this link");
  };
  const onChanhValueSheet2_person = () => {
    console.log($(selectedLevelSheet2_person.current).val());
    //@ts-ignore
    setToAddLevelSheet2_person($(selectedLevelSheet2_person.current).val());
  };
  const onChanhValueSheet2 = () => {
    console.log($(selectedLevelSheet2.current).val());
    //@ts-ignore
    setToAddLevelSheet2($(selectedLevelSheet2.current).val());
  };

  const onChangValueEditLevelUser = () => {
    //@ts-ignore
    setLevelUserEdit($(selectedLevelUserEdit.current).val());
  };

  const onChanhValueEditMainLevel_person = () => {
    console.log($(selectedEditMainLevel_person.current).val());
    //@ts-ignore
    setEditMainLevel_person($(selectedEditMainLevel_person.current).val());
  };

  const onChanhValueEditMainLevel = () => {
    console.log($(selectedEditMainLevel.current).val());
    //@ts-ignore
    setEditMainLevel($(selectedEditMainLevel.current).val());
  };

  useEffect(() => {
    if (shareContextFile.stampUsersSelected?.length > 0) {
      setCanShare(true);
    } else setCanShare(false);
  }, [shareContextFile.stampUsersSelected]);

  useEffect(() => {
    if (fileContext.showShareFileModal) {
      setTabContent(mainTab);
      getSetMainUser();
      getAndSetWhoHasAccess();
      getAndSetLink();
      shareContextFile.setStampUsersSelected([]);
      fectDataFileSupportDir();
    }
  }, [fileContext.showShareFileModal]);

  const fectDataFileSupportDir = async () => {
    try {
      let file = await getFileObj(
        fileContext.userId,
        fileContext.groupId,
        fileContext.fileSelected
      );
      fileContext.setFileSelected(file);
    } catch (e) {
      console.error(e);
    }
  };

  const getUsers = useFetch(CENTER_URL_MAIN, {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  useEffect(() => {
    if (fileContext.showShareFileModal) {
      fectDataUser();
    }
  }, [fileContext.showShareFileModal, search]);

  useEffect(() => {
    if (fileContext.showShareFileModal) {
      fectDataUserLoadMore();
    }
  }, [offsetUser]);

  const fectDataUser = async () => {
    await getUsers.get(
      `/api/v1/users/friends?search=${search}&limit=${limit}&offset=${offsetUser}`
    );
    setUsers(getUsers?.response?.data?.data);
    setTotalUser(getUsers?.response?.data?.total);
  };

  const fectDataUserLoadMore = async () => {
    try {
      setLoadmoreUser(true);
      await getUsers.get(
        `/api/v1/users/friends?search=${search}&limit=${limit}&offset=${offsetUser}`
      );
      let users_t = Object.assign([], users);
      users_t = users_t.concat(getUsers.response?.data?.data);
      setUsers(users_t);
      setTotalUser(getUsers.response?.data?.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadmoreUser(false);
    }
  };

  const handleClickSelectUser = async (user: any) => {
    try {
      let s_users: any = shareContextFile.stampUsersSelected;
      let i = s_users.findIndex((item: any) => {
        return item.friendID === user.friendID;
      });
      if (i === -1) {
        s_users.push(user);
      } else {
        s_users.splice(i, 1);
      }
      shareContextFile.setStampUsersSelected(Object.assign([], s_users));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickMoreUser = () => {
    setOffsetUser(offsetUser + limit);
  };
  const handleClickMoreGroup = () => {
    setOffsetGroup(offsetGroup + limit);
  };

  const getPermission = (row: any) => {
    if (row.permission_level === "viewer") return t("Viewer");
    else if (row.permission_level === "editor") return t("Editor");
  };

  const getWhoHasAccess = useFetch(
    KN_FILE_SERVICE_URL_MAIN + "/getWhoHasAccess",
    {
      // path: APIEnpoint.myApps,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const getAndSetWhoHasAccess = async () => { 
    await getWhoHasAccess.get(
      `?fileId=${fileContext.fileSelected?.file_app_id}&userId=${fileContext.userId
      }&offset=${0}&limitOfset=${null}`
    );
   
    setWhoHasAccesses(Object.assign([], getWhoHasAccess.response?.data?.data));
  };

  const getSetMainUser = async () => {
    let userData: any = await getUserDetail(
      fileContext.fileSelected?.owner_user_id, ""
    );
    let userDataTemp: any = userData;
    userDataTemp.img = userData.imageProfileURL;
    setMainUserOwner(userDataTemp);
  };

  const getNameOfUser = (row: any) => {
    // return row.userData?.firstname + " " + row.userData?.lastname;
    let name = "";
    // return row?.firstname + " " + row?.lastname;
    name = row?.userData?.firstname;
    if (!checkEmpty(row?.userData?.lastname))
      name = row?.userData?.firstname + " " + row?.userData?.lastname;
    return name;
  };

  const getNameOfUserFriend = (row: any) => {
    let name = "";
    // return row?.firstname + " " + row?.lastname;
    name = row?.firstname;
    if (!checkEmpty(row?.lastname)) name = row?.firstname + " " + row?.lastname;
    return name;
  };

  const copyLink = (from: any) => {
    var copyText;

    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(linkDownload).select();
    document.execCommand("copy");
    $temp.remove();

    // setAlreadyCopy(true);
    message.success("Copied already");
  };

  const getAndSetLink = async () => {
    let linkData: any = await getLinkDownload(
      fileContext.fileSelected?.file_app_id
    );
    console.log("linkData", linkData);
    let link;
    link = window.location.origin;
    if (!link?.endsWith("/")) link = link + "/center-web/";
    link = link + "file-access?id=" + linkData.data.link_download;
    setLinkDownload(link);
    
    console.log("linkData.data.permission_anyone => ",linkData.data.permission_anyone);
    
    setToAddLevelMain(linkData.data.permission_anyone);
  };

  const addMemberToAccess = async () => {
    if (addingMember) return;

    let stampUsersSelected: any = shareContextFile.stampUsersSelected;
    console.log("stampUsersSelectedBefore => ",stampUsersSelected);
    for (let a = 0; a < shareContextFile.stampUsersSelected?.length; a++) {
      stampUsersSelected[a].userId = stampUsersSelected[a].friendID;
      stampUsersSelected[a].level = toAddLevelSheet1;
    }
    console.log("stampUsersSelectedAfter => ",stampUsersSelected);

    setAddingMember(true);
    setTitleAlert(t("Add member"));
    setDetailAlert(t("Adding")+"...");
    let stampUsersSelectedTemp = shareContextFile.stampUsersSelected;
    console.log("stampUsersSelectedBefore => ",stampUsersSelectedTemp);
    for (let a = 0; a < stampUsersSelectedTemp.length; a++) {
      stampUsersSelectedTemp[a].level = toAddLevelSheet1;
    }
    console.log("stampUsersSelectedAfter => ",stampUsersSelectedTemp);

    console.log("fileContext.fileSelected.file_app_id => ",fileContext.fileSelected.file_app_id);
    console.log("fileContext.userId => ",fileContext.userId);
    console.log("stampUsersSelected => ",stampUsersSelected);
    console.log("whoHasAccesses.length => ",whoHasAccesses.length);
    console.log("toAddLevelSheet1 => ",toAddLevelSheet1);
    console.log("$(selectedLevelSheet2_person.current).val() => ",$(selectedLevelSheet2_person.current).val());
    
    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      stampUsersSelected,
      [], //context.stampUsersUnSelected,
      whoHasAccesses.length,
      toAddLevelSheet1,
      $(selectedLevelSheet2_person.current).val()
    );
    getAndSetWhoHasAccess();

    let levelperson: any = $(selectedLevelSheet2_person.current).val();
    setAddingMember(false);
    setEditMainLevel_person(levelperson);
    setToAddLevelSheet1(toAddLevelSheet1);
    setToAddLevelSheet1_person(levelperson);

    setToAddLevelSheet2(toAddLevelSheet1);
    setToAddLevelSheet2_person(levelperson);
    setToAddLevelMain(levelperson);
    shareContextFile.setStampUsersSelected([]);
    setTabContent(mainTab);
  };

  const editUserAcces = (user: any) => {
    setLevelUserEdit(user.permission_level);
    setUserEdit(user);
    setTabContent(editUserTab);
  };

  const deleteFilePermissionLevelUser = async (user: any) => {
    console.log("user => ",user);
    
    if (addingMember) return;
    setAddingMember(true);
    setTitleAlert(t("Remove member"));
    setDetailAlert(t("Removing")+"...");
    let stampUsersDelete: any = [];
    user.userId = user.userData.id;
    stampUsersDelete.push(user);
    
    console.log("fileContext.fileSelected.file_app_id => ",fileContext.fileSelected.file_app_id);
    console.log("fileContext.userId => ",fileContext.userId);
    console.log("stampUsersDelete => ",stampUsersDelete);
    console.log("whoHasAccesses.length => ",whoHasAccesses.length);
    
    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      [],
      stampUsersDelete,
      whoHasAccesses.length,
      null,
      null
    );
    setAddingMember(false);
    getAndSetWhoHasAccess();
  };

  const editMember = async () => {
    if (editingMember) return;
    setEditingMember(true);
    let nowUserToChange = userEdit;
    nowUserToChange.userId = nowUserToChange.userData.id;
    nowUserToChange.level = levelUserEdit;
    let stampUsersSelected = [];
    stampUsersSelected.push(nowUserToChange);
    setTitleAlert(t("Update member"));
    setDetailAlert(t("Updating")+"...");
    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      stampUsersSelected,
      [],
      whoHasAccesses.length,
      null,
      null
    );

    setTabContent(mainTab);
    setEditingMember(false);
    getAndSetWhoHasAccess();
  };

  const editMain = async () => {
    if (editingMain) return;
    setEditingMain(true);
    setTitleAlert(t("Update member"));
    setDetailAlert(t("Updating")+"...");
    console.log("editMainLevel_person => ",editMainLevel_person);
    
    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      [],
      [],
      whoHasAccesses.length,
      null,
      editMainLevel_person
    );

    setToAddLevelMain(editMainLevel_person);
    setTabContent(mainTab);
    setEditingMain(false);
    getAndSetWhoHasAccess();
  };

  const gotoEditMainTab = () => {
    console.log(toAddLevelMain);
    setEditMainLevel_person(toAddLevelMain);
    setTabContent(editMainTab);
  };

  return (
    <>
      <Modal
        centered
        visible={fileContext.showShareFileModal}
        className={styles.modalOveride}
        onCancel={() => {
          fileContext.setShowShareFileModal(false);
        }}
      >
        <div
          onClick={() => {
            fileContext.setShowShareFileModal(false);
          }}
          className={styles.closeBtn}
        // style={{ backgroundImage: `url(${crossIcon})` }}
        >
          <ButtonCenterCLose></ButtonCenterCLose>
        </div>

        {/*edit main tab*/}
        {tabContent == editMainTab && (
          <div
            className={styles.boxCreate}
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              display: "block",
            }}
          >
            <div
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    textAlign: "center",
                    display: "block",
                  }}
                  onClick={() => {
                    setTabContent(mainTab);
                  }}
                  className={styles.iconheaderTab}
                >
                  <img src={left_arrow} width={"25px"}></img>
                </div>
              </div>

              <div>{t("Edit permission")}</div>
            </div>
            <Row
              justify="start"
              className={styles.rowwhohasaccess}
              style={{
                fontSize: "1rem",
                paddingTop: "40px",
              }}
            >
              <Col span={24} className={styles.iconuser}>
                <div>
                  <div
                    style={{ display: "flex" }}
                    className={styles.boxPermision}
                  >
                    <div style={{ margin: "auto", marginLeft: "10px" }}>
                      <div
                        className={styles.valueDetail}
                        style={{ width: "100%", color: "white" }}
                      >
                        <select
                          className={styles.selectPermission2}
                          value={editMainLevel_person}
                          ref={selectedEditMainLevel_person}
                          onChange={onChanhValueEditMainLevel_person}
                        >
                          <option value="anyoneWithTheLink">
                            {t("Anyone with the link")}
                          </option>
                          <option value="restricted">{t("Restricted")}</option>
                        </select>
                      </div>
                      <div
                        className={styles.valueDetail}
                        style={{
                          width: "100%",
                          fontSize: "0.7rem",
                          color: "white",
                        }}
                      >
                        {getTextAnyoneLevel_2(editMainLevel_person)}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div style={{ textAlign: "end" }}>
              {/* <button
                className={styles.bottonBtn}
                style={{ fontSize: "1.2rem", marginTop: "20px" }}
                onClick={editMain}
              >
                <div>
                  {editingMain ? <Spin indicator={antIcon} /> : t("Done")}
                </div>
              </button> */}

              <div style={{ fontSize: "1.2rem", marginTop: "39px" }}>
                <CenterButton onClick={editMain}>
                  <div>
                    {editingMember ? <Spin indicator={antIcon} /> : t("Done")}
                  </div>
                </CenterButton>
              </div>

            </div>
          </div>
        )}

        {/*edit user tab*/}
        {tabContent == editUserTab && (
          <div
            className={styles.boxCreate}
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              display: "block",
            }}
          >
            <div
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    textAlign: "center",
                    display: "block",
                  }}
                  onClick={() => {
                    setTabContent(mainTab);
                  }}
                  className={styles.iconheaderTab}
                >
                  <img src={left_arrow} width={"25px"}></img>
                </div>
              </div>

              <div>{t("Edit member")}</div>
            </div>
            <Row
              justify="start"
              className={styles.rowwhohasaccess}
              style={{
                fontSize: "1rem",
                paddingTop: "40px",
              }}
            >
              <Col span={22} className={styles.iconuser}>
                <div style={{ display: "flex" }}>
                  <div>
                    <UIGroupUserItem row={userEdit}></UIGroupUserItem>
                  </div>
                  <div className={styles.boxUsernametoEdit}>
                    <div className={styles.textOverflow}>
                      <div
                        className={[styles.headerDetail,styles.valueDetailEdit].join(" ")}
                        style={{ width: "100%" }}
                      >
                        {getNameOfUser(userEdit)}
                      </div>
                    </div>
                    <div className={styles.textOverflow}>
                      <div className={styles.valueDetailEdit} style={{ width: "100%" }}>
                        {userEdit.userData.username}
                      </div>
                    </div>
                  </div>
                </div>

              </Col>
              {/* <Col span={18} style={{ alignSelf: "center" }}>
                <div>
                  <div
                    className={styles.headerDetail}
                    style={{ width: "100%" }}
                  >
                    {getNameOfUser(userEdit)}
                  </div>
                  <div className={styles.valueDetail} style={{ width: "100%" }}>
                    {userEdit.userData.username}
                  </div>
                </div>
              </Col> */}
              <Col span={2} style={{ alignSelf: "center" }}>
                <div style={{ float: "right" }}>
                  <select
                    value={levelUserEdit}
                    ref={selectedLevelUserEdit}
                    className={styles.selectPermission1}
                    onChange={() => {
                      onChangValueEditLevelUser();
                    }}
                  >
                    <option value="viewer">{t("Viewer")}</option>
                    <option value="editor">{t("Editor")}</option>
                  </select>
                </div>
              </Col>
            </Row>

            <div style={{ textAlign: "end" }}>
              {/* <button
                className={styles.bottonBtn}
                style={{ fontSize: "1.2rem", marginTop: "39px" }}
                onClick={editMember}
              >
                <div>
                  {editingMember ? <Spin indicator={antIcon} /> : t("Done")}
                </div> */}
              {/* </button> */}
              <div style={{ fontSize: "1.2rem", marginTop: "39px" }}>
                <CenterButton onClick={editMember}>
                  <div>
                    {editingMember ? <Spin indicator={antIcon} /> : t("Done")}
                  </div>
                </CenterButton>
              </div>
            </div>
          </div>
        )}

        {/*add user tab*/}
        {tabContent == addTab && (
          <div
            className={styles.boxCreate}
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              display: "block",
            }}
          >
            <div
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <div>
                <div
                  style={{
                    textAlign: "center",
                    display: "block",
                  }}
                  onClick={() => {
                    setTabContent(mainTab);
                  }}
                  className={styles.iconheaderTab}
                >
                  <img src={left_arrow} width={"25px"}></img>
                </div>
              </div>

              <div>{t("Add member")}</div>
            </div>

            <div style={{ fontSize: "1rem", marginTop: "20px" }}>
              <input
                style={{ fontSize: "1rem" }}
                value={search}
                type="text"
                placeholder={t("search")}
                className={styles.boxCreateInput}
                onChange={(event) => {
                  let v = event.target.value;
                  setSearch(v.trim());
                }}
              />
            </div>

            <div className={styles.boxListUserGroup}>
              <div>{t("Selected friends")}</div>
              <div className={styles.boxListUser}>
                {getUsers.loading && !loadmoreUser ? (
                  <>
                    <div style={{ paddingTop: "10px" }}>
                      <Skeleton count={1} height={50} style={{}} />
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <Skeleton count={1} height={50} style={{}} />
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <Skeleton count={1} height={50} style={{}} />
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <Skeleton count={1} height={50} style={{}} />
                    </div>
                  </>
                ) : (
                  //@ts-ignore
                  users?.map((user, idx) => {
                    return (
                      <Row justify="start" className={styles.rowwhohasaccess}>
                        <Col span={24} className={styles.iconuser}>
                          <div style={{ display: "flex" }}>
                            <div>
                              <UICardUser
                                handleClickSelectUser={handleClickSelectUser}
                                user={user}
                                idx={idx}
                                key={user?.friendID}
                              />
                            </div>

                            <div style={{ textAlign: "start", alignSelf: "center", width: "calc(100% - 71px)" }} >
                              <div className={styles.textOverflow}>
                                <div
                                  className={styles.headerDetail}
                                  style={{ width: "100%" }}
                                >
                                  {getNameOfUserFriend(user?.friend)}
                                </div>
                              </div>
                              <div className={styles.textOverflow}>
                                <div
                                  className={styles.valueDetail}
                                  style={{ width: "100%" }}
                                >
                                  {user.friend?.username}
                                </div>
                              </div>


                            </div>
                          </div>
                        </Col>
                        {/* <Col
                          span={20}
                          style={{ textAlign: "start", alignSelf: "center" }}
                        >
                          <div
                            className={styles.headerDetail}
                            style={{ width: "100%" }}
                          >
                            {getNameOfUserFriend(user?.friend)}
                          </div>
                          <div
                            className={styles.valueDetail}
                            style={{ width: "100%" }}
                          >
                            {user.friend?.username}
                          </div>
                        </Col> */}
                      </Row>
                    );
                  })
                )}
              </div>
              {users?.length === 0 && <div>{messageNotiShareUser}</div>}
              {users?.length < totalUser && (
                <div className={styles.loadmore}>
                  <div
                    className={styles.loadmoreBtn}
                    onClick={handleClickMoreUser}
                  >
                    {loadmoreUser ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      t("Load More")
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={{ display: "flex" }} className={styles.boxPermision}>
              <div style={{ margin: "auto", marginLeft: "10px" }}>
                <div
                  className={styles.valueDetail}
                  style={{ width: "100%", color: "white" }}
                >
                  <select
                    className={styles.selectPermission2}
                    value={toAddLevelSheet2_person}
                    ref={selectedLevelSheet2_person}
                    onChange={onChanhValueSheet2_person}
                  >
                    <option value="anyoneWithTheLink">
                      {t("Anyone with the link")}
                    </option>
                    <option value="restricted">{t("Restricted")}</option>
                  </select>
                </div>
                <div
                  className={styles.valueDetail}
                  style={{
                    width: "100%",
                    fontSize: "0.9rem",
                    color: "white",
                  }}
                >
                  {getTextAnyoneLevel_2(toAddLevelSheet2_person)}
                </div>
              </div>
              <div style={{ float: "right" }}>
                <select
                  value={toAddLevelSheet2}
                  ref={selectedLevelSheet2}
                  className={styles.selectPermission1}
                  onChange={() => {
                    onChanhValueSheet2();
                  }}
                >
                  <option value="viewer">{t("Viewer")}</option>
                  <option value="editor">{t("Editor")}</option>
                </select>
              </div>
            </div>

            <div style={{ textAlign: "end" }}>
              <div style={{ fontSize: "1.2rem", marginTop: "39px" }}>
                <CenterButton onClick={addMemberToAccess}>
                  <div>
                    {addingMember ? <Spin indicator={antIcon} /> : t("Done")}
                  </div>
                </CenterButton>
              </div>

              {/* <button
                className={
                  canShare ? styles.bottonBtn : styles.bottonBtnNotActive
                }
                style={{ fontSize: "1.2rem", marginTop: "39px" }}
                
              >
                 */}
              {/* </button> */}
            </div>
          </div>
        )}

        {/* main tanb */}
        {tabContent == mainTab && (
          <div
            style={{
              display: "block",
            }}
            className={styles.boxCreate}
          >
            <div
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <div>{t("who has access")}</div>

              <div>
                <div
                  style={{
                    textAlign: "center",
                    display: checkEmpty(fileContext.groupId) ? "block" : "none",
                  }}
                  onClick={() => {
                    setTabContent(addTab);
                  }}
                  className={styles.iconheader}
                >
                  <img src={follow_file} width={"25px"}></img>
                </div>
              </div>
            </div>
            <div className={styles.boxListWhoAccesses}>
              <div className={styles.boxListWhoAccess}>
                <Row justify="start" className={styles.rowwhohasaccess}>
                  <Col span={22} className={styles.iconuser}>
                    <div style={{ display: "flex" }}>
                      <div><UIGroupUserItem
                        row={{ userData: mainUserOwner }}
                        imageProfileURL={mainUserOwner.imageProfileURL}
                      ></UIGroupUserItem>
                      </div>
                      <div style={{
                        width: "calc(100% - 68px)",
                        marginLeft: "8px"
                      }}>
                        <div className={styles.textOverflow}>
                          <div
                            className={styles.headerDetail}
                            style={{ width: "100%" }}
                          >
                            {getNameOfUser({ userData: mainUserOwner })}
                          </div>
                        </div>
                        <div className={styles.textOverflow}>
                          <div
                            className={styles.valueDetail}
                            style={{ width: "100%" }}
                          >
                            {mainUserOwner.username}
                          </div>
                        </div>
                        <div
                          className={styles.valueDetail}
                          style={{ width: "100%", color: "#428DFF" }}
                        >
                          {t("Owner")}
                        </div>
                      </div>
                    </div>


                  </Col>
                  {/* <Col span={20}>
                   
                  </Col> */}
                </Row>
                {whoHasAccesses?.map((user: any, idx: any) => {
                  return (
                    <Row justify="start" className={styles.rowwhohasaccess}>
                      <Col span={22} className={styles.iconuser}>
                        <div
                          onClick={() => {
                            editUserAcces(user);
                          }}
                          style={{ cursor: "pointer", display: "flex" }}
                        >
                          <div>
                            <UIGroupUserItem row={user}></UIGroupUserItem>
                          </div>
                          <div style={{
                            width: "calc(100% - 68px)",
                            marginLeft: "8px"
                          }}>
                            <div
                              onClick={() => {
                                editUserAcces(user);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className={styles.textOverflow}>
                                <div
                                  className={styles.headerDetail}
                                  style={{ width: "100%" }}
                                >
                                  {getNameOfUser(user)}
                                </div>
                              </div>
                              <div className={styles.textOverflow}>
                                <div
                                  className={styles.valueDetail}
                                  style={{ width: "100%" }}
                                >
                                  {user.userData.username}
                                </div>
                              </div>
                              <div className={styles.textOverflow}>
                                <div
                                  className={styles.valueDetail}
                                  style={{ width: "100%", color: "#428DFF" }}
                                >
                                  {getPermission(user)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {/* <Col span={18}>
                       
                      </Col> */}
                      <Col span={2} style={{margin:"auto"}}>
                        <div
                          style={{
                            fontSize: "1.6875rem",
                            color: "#064b83",
                            width: "100%",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash as IconProp}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              deleteFilePermissionLevelUser(user);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
            <div>
              <div className={styles.detailBox} style={{}}>
                <div
                  style={{
                    padding: "10px",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "50%",
                        color: "rgb(119, 120, 125)",
                        marginLeft: "15px",
                        fontSize: "1rem",
                      }}
                    >
                      <div>{t("Link")}</div>
                    </div>
                  </div>

                  <Row
                    className={styles.activityBox}
                    justify="start"
                    style={{
                      paddingTop: "10px",
                    }}
                  >
                    <Col className={styles.column21}>
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            width: "10%",
                            color: "rgb(119, 120, 125)",
                            minWidth: "55px"
                          }}
                        >
                          <div className={styles.imgWorldBox}>
                            <img
                              src={world_file}
                              className={styles.imgWorld}
                            ></img>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "90%",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px"
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div
                              className={styles.headerDetail}
                              style={{ width: "100%", fontSize: "1rem" }}
                            >
                              {getTextAnyoneLevel(toAddLevelMain)}
                            </div>
                            <div
                              className={styles.valueDetail}
                              style={{
                                width: "100%",
                                color: "#000000",
                                fontSize: "1rem",
                              }}
                            >
                              {getTextAnyoneLevel_2(toAddLevelMain)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className={styles.column3} style={{ alignSelf: "center" }}>
                      <div
                        style={{ float: "right", fontSize: "1rem" }}
                        onClick={() => {
                          gotoEditMainTab();
                        }}
                      >
                        <button type="button" className={styles.btnChange}>
                          {t("Change")}
                        </button>
                      </div>
                    </Col>
                  </Row>

                  <Row
                    justify="start"
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      width: "100%",
                      fontSize: "1rem",
                    }}
                  >
                    <Col className={styles.column21}>
                      <div className={styles.textOverflow}>
                        <div
                          className={styles.boxLink}
                          onClick={() => {
                            // myToast.toggle();
                            copyLink(1);
                          }}
                        >
                          {linkDownload}
                        </div>
                      </div>

                      <input
                        style={{ fontSize: "1rem", display: "none" }}
                        ref={inputLink1}
                        type="text"
                        value={linkDownload}
                        onChange={() => {
                          $(inputLink1.current).val(linkDownload);
                        }}
                        onClick={() => {
                          // myToast.toggle();
                          copyLink(1);
                        }}
                      ></input>
                    </Col>
                    <Col className={styles.column3} style={{ alignSelf: "center" }}>
                      <div style={{ float: "right", marginLeft: "15px" }}>
                        <button
                          type="button"
                          className={styles.btnCopy}
                          onClick={() => {
                            copyLink(2);
                          }}
                        >
                          <div className={styles.textOverflow}>
                            <div>{t("Copy link")}</div>
                          </div>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

function UICardGroup(props: any) {
  const fileContext: any = useContext(FileContext);
  const shareContextFile: any = useContext(ShareContextFile);
  const [showSelectItem, setShowSelectItem]: any = useState(false);

  return (
    <div
      key={props.group?.id}
      className={styles.itemgroup}
      onClick={() => {
        props.handleClickSelectGroup(props.group);
      }}
    >
      <div style={{ width: "10%", maxWidth: "65px" }}>
        <div
          className={styles.selectedItemShareBox}
          style={{
            display: showSelectItem ? "block" : "none",
          }}
        >
          <img
            src={selectedItemShare}
            className={styles.selectedItemShareGroup}
          ></img>
        </div>
        <div
          style={{
            backgroundImage: `url(${props.group?.coverImgURL})`,
            backgroundSize: "cover",
            width: 55,
            height: 55,
            borderRadius: "8px",
          }}
        ></div>
      </div>
      <div style={{ width: "90%" }}>
        <div className={styles.textOverflowListFileText}>
          <div className={styles.textItem}>{props.group.name}</div>
        </div>
      </div>
    </div>
  );
}

function UICardUser(props: any) {
  const fileContext: any = useContext(FileContext);
  const shareContextFile: any = useContext(ShareContextFile);
  const [showSelectItem, setShowSelectItem]: any = useState(false);

  const checkSelectUser = () => {
    let i = shareContextFile.stampUsersSelected.findIndex((item: any) => {
      return item.friendID === props.user?.friendID;
    });

    if (i === -1) {
      setShowSelectItem(false);
    } else {
      setShowSelectItem(true);
    }
  };
  useEffect(() => {
    checkSelectUser();
  }, [shareContextFile.stampUsersSelected]);

  return (
    <div
      key={props.user?.friendID}
      className={styles.itemuser}
      onClick={() => {
        props.handleClickSelectUser(props.user);
      }}

    // style={{ backgroundColor: checkSelectFolder() ? "#f7f7f7" : "white" }}
    >
      <div style={{ width: "100%" }}>
        <div
          className={styles.selectedItemShareBox}
          style={{
            display: showSelectItem ? "block" : "none",
          }}
        >
          <img
            src={selectedItemShare}
            className={styles.selectedItemShareUser}
          ></img>
        </div>

        <div
          style={{
            backgroundImage: `url(${props.user?.friend?.imageProfileURL})`,
            backgroundSize: "cover",
            width: 55,
            height: 55,
            borderRadius: "33px",
          }}
        ></div>
      </div>
      <div style={{ width: "100%" }}>
        <div className={styles.textOverflowListFileText}>
          {/* <div className={styles.textItemUser}>{getNameOfUser(props.user)}</div> */}
        </div>
      </div>
    </div>
  );
}

function UIModalShareFile(props: any) {
  return (
    <ShareFileProviderContext>
      <UIModalShareFileContainer />
    </ShareFileProviderContext>
  );
}

export default UIModalShareFile;
