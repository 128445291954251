import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
//@ts-ignore
import _ from "lodash";
import { FileContext } from "../../File.context";
import SkeletonFile from "../../File/SkeletionFile";

import { useTranslation } from "react-i18next";
import FileContainerActivitiesLog from "../../File/FileContainerActivityLog";
import FileCardActivityLog from "../../File/FileCardActivityLog";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { getLogFiles, nodataFile } from "src/page/kn/util.service";
import NoData from "../../NoDataDisplay/NoData";

type ActivityLogFileProps = {
    isAdminRoleFile? : boolean;
}

const ActivityLogFileContainer = (props: ActivityLogFileProps) => {
    const { t } = useTranslation();
    const { ref, inView } = useInView();
    // const myProfile = useAppSelector(profile);
    const fileContext: any = useContext(FileContext);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const defaultLitmitOfset = 20;
    const groupID = fileContext.groupId ? fileContext.groupId : "";
    // const userID = myProfile?.id ? myProfile?.id : "";
    const userID="";
    // const {
    //     data,
    //     hasNextPage,
    //     fetchNextPage,
    //     isFetchingNextPage,
    //     isFetching
    // } = useInfiniteQuery({
    //     queryKey: ["log-files",defaultLitmitOfset,groupID,userID,fileContext.fileSearchInput, fileContext.moduleFileAc],
    //     queryFn: ({ pageParam: offsetParam = 0 }) => getLogFiles(
    //         {
    //             offset: offsetParam * defaultLitmitOfset,
    //             limitOffset: defaultLitmitOfset,
    //             groupID: groupID,
    //             userID: userID,
    //             search: fileContext.fileSearchInput,
    //             module: fileContext.moduleFileAc,
    //             isAdminRoleFile: props.isAdminRoleFile || false
    //         }),
    //     enabled: !!groupID || !!userID || !!fileContext.fileSearchInput || !!fileContext.moduleFileAc,
    //     refetchOnWindowFocus: false,
    //     getNextPageParam: ((lastPage, allPages) => {
    //         return lastPage?.data?.length === 0 ? null : allPages?.length + 1;
    //     },
    // })

    // const logFiles: any = data ? data.pages.flatMap(((page:any) => page?.data ?? [])) : [];


    // console.log("response groupId:: ", groupID);
    // console.log("response myProfile?.id:: ", userID);

    // useEffect(() => {
    //     if (!isFetchingNextPage && hasNextPage && inView) {
    //         fetchNextPage();
    //     }
    // }, [isFetchingNextPage, hasNextPage, inView, fetchNextPage]);

    // useEffect(() => {
    //     console.log("response data:: ", data);
    //     console.log("response moduleFileAc:: ", fileContext.moduleFileAc);
    //     console.log("response SearchInput:: ", fileContext.fileSearchInput);
    //     setIsInitialLoad(true);
    // }, [fileContext.fileSearchInput,fileContext.moduleFileAc]);
    

    // useEffect(() => {
    //     if (data !== undefined) {
    //         setIsInitialLoad(false);
    //     }
    // }, [data]);

    // const getFileFolders = (type: string, code: string) => {
    //     let ls = logFiles;
    //     if (!checkEmpty(type)) {
    //         ls = ls?.filter((item: any) => item.type_ === type) || [];
    //     }
    //     return ls || [];
    // };

    return (
        // <>
        //     {!isInitialLoad && (
        //         <>
        //             {logFiles?.length === 0 && !isFetchingNextPage && (
        //                 <NoData
        //                     header={t(nodataFile.noDataHeaderFileText)}
        //                     // detail={t(nodataFile.nodataFiletextH) + ", " + t(nodataFile.nodataFiletextL) + "."}
        //                 />
        //             )}
        //             <FileContainerActivitiesLog
        //                 header={t("Files")}
        //                 tableHeader={logFiles?.length !== 0}
        //                 isAdminRoleFile={props.isAdminRoleFile || false}
        //             >
        //                 {logFiles?.map((file: any, idx: number) => {
        //                     return <FileCardActivityLog file={file} key={idx} isAdminRoleFile={props.isAdminRoleFile || false} />;
        //                 })}
        //                 {isFetchingNextPage && <SkeletonFile />}
        //                 {hasNextPage && <div ref={ref} style={{ width: '100%', height: '1px' }}></div>}
        //             </FileContainerActivitiesLog>
        //         </>
        //     )}
        //     {isFetching && <SkeletonFile />}
        // </>
        <></>
    );
};

export default ActivityLogFileContainer;
