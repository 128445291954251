import React from "react";

const AgentPersonality = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      className={className}
      style={style}
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10657_4784)">
        <path
          d="M12 12C13.1867 12 14.3467 11.6481 15.3334 10.9888C16.3201 10.3295 17.0892 9.39246 17.5433 8.2961C17.9974 7.19975 18.1162 5.99335 17.8847 4.82946C17.6532 3.66558 17.0818 2.59648 16.2427 1.75736C15.4035 0.918247 14.3344 0.346802 13.1706 0.115291C12.0067 -0.11622 10.8003 0.00259972 9.70391 0.456726C8.60755 0.910851 7.67048 1.67989 7.01119 2.66658C6.3519 3.65328 6.00001 4.81331 6.00001 6C6.0016 7.59081 6.63425 9.11602 7.75912 10.2409C8.884 11.3658 10.4092 11.9984 12 12ZM12 2C12.7911 2 13.5645 2.2346 14.2223 2.67412C14.8801 3.11365 15.3928 3.73836 15.6955 4.46927C15.9983 5.20017 16.0775 6.00444 15.9232 6.78036C15.7688 7.55629 15.3878 8.26902 14.8284 8.82843C14.269 9.38784 13.5563 9.7688 12.7804 9.92314C12.0044 10.0775 11.2002 9.99827 10.4693 9.69552C9.73837 9.39277 9.11366 8.88008 8.67413 8.22228C8.2346 7.56449 8.00001 6.79113 8.00001 6C8.00001 4.93914 8.42144 3.92172 9.17158 3.17158C9.92173 2.42143 10.9391 2 12 2Z"
          fill="currentColor"
        />
        <path
          d="M12 14.0007C9.61386 14.0033 7.32622 14.9524 5.63896 16.6396C3.95171 18.3269 3.00265 20.6145 3 23.0007C3 23.2659 3.10536 23.5202 3.29289 23.7078C3.48043 23.8953 3.73478 24.0007 4 24.0007C4.26522 24.0007 4.51957 23.8953 4.70711 23.7078C4.89464 23.5202 5 23.2659 5 23.0007C5 21.1442 5.7375 19.3637 7.05025 18.0509C8.36301 16.7382 10.1435 16.0007 12 16.0007C13.8565 16.0007 15.637 16.7382 16.9497 18.0509C18.2625 19.3637 19 21.1442 19 23.0007C19 23.2659 19.1054 23.5202 19.2929 23.7078C19.4804 23.8953 19.7348 24.0007 20 24.0007C20.2652 24.0007 20.5196 23.8953 20.7071 23.7078C20.8946 23.5202 21 23.2659 21 23.0007C20.9974 20.6145 20.0483 18.3269 18.361 16.6396C16.6738 14.9524 14.3861 14.0033 12 14.0007Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10657_4784">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AgentPersonality;
