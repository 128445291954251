import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL_PMTX,
  CANCLE_SUBSCRIPTION_ENDPOINT,
  PAYMENT_METHOD_ENDPOINT,
} from "src/utils/endpoint/pmtx.endpoint";
import { getAuthorizationPmtx } from "src/services/util.service";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { currencyFormat } from "src/components/payments/PaymentSummary";
import { PaymentMethodRequest } from "src/types/PaymentMethodType";

export const processPayment = createAsyncThunk(
  "payments/processPayment",
  async (paymentData: {
    email: string;
    cardHolderName: string;
    currPlan: any;
    paymentMethodId: any;
  }) => {
    const { email, cardHolderName, currPlan, paymentMethodId } = paymentData;
    const subscriptionData = {
      email: email,
      nameOnCard: cardHolderName,
      PaymentIntentID: paymentMethodId,
      priceId: currPlan?.monthly_prices.price_id,
      ProdId: currPlan?.prod_id,
      amount: currencyFormat(currPlan?.monthly_prices.unit_amount),
    };

    await axios.post(`${BASE_URL_PMTX}/create-subscription`, subscriptionData, {
      headers: {
        Authorization: getAuthorizationPmtx(),
        "Content-Type": "application/json",
      },
    });
  }
);

export const processCancleSubscription = createAsyncThunk(
  "payments/processCancleSubscription",
  async (subscriptionId: string, { dispatch }) => {
    const response = await axios.patch(
      `${CANCLE_SUBSCRIPTION_ENDPOINT}/${subscriptionId}`,
      {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      }
    );

    return response.data;
  }
);

export const processPaymentMethod = createAsyncThunk(
  "payments/processPaymentMethod",
  async (customerId: string, { dispatch }) => {
    const response = await axios.get(
      `${PAYMENT_METHOD_ENDPOINT}/${customerId}`,
      {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      }
    );

    console.log("processPaymentMethod :: ", response.data);
    

    return response.data;
  }
);


export const processCreatePaymentMethod = createAsyncThunk(
  "payments/processCreatePaymentMethod",
  async (payment: PaymentMethodRequest, { dispatch }) => {
    const response = await axios.post(
      `${PAYMENT_METHOD_ENDPOINT}`,
      payment,
      {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      }
    );

    return response.data;
  }
);

