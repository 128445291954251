import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface IAgentFilterForm {
  role: string | null;
  lastActive: number | null;
  minStats: number | null;
  maxStats: number | null;
}

const schema: yup.ObjectSchema<IAgentFilterForm> = yup.object().shape({
  role: yup.string().nullable().default(null),
  lastActive: yup
    .number()
    .nullable()
    .min(0, "Hours must be positive")
    .max(24, "Maximum 24 hours allowed")
    .default(null),
  minStats: yup
    .number()
    .nullable()
    .min(0, "Minimum statistics must be positive")
    .default(null),
  maxStats: yup
    .number()
    .nullable()
    .min(0, "Maximum statistics must be positive")
    .test(
      "max-greater-than-min",
      "Max must be greater than min",
      function (value) {
        const { minStats } = this.parent;
        if (!minStats || !value) return true;
        return value > minStats;
      }
    )
    .default(null),
});

export const useAgentFilterForm = () => {
  return useForm<IAgentFilterForm>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
};
