import { createSlice } from "@reduxjs/toolkit";
import { handelerContactSalesTeam } from "src/store/actions/payment/contactSaleAction";
import { ContactSale } from "src/model/contact-sale";
import { RootState } from "../../store";

type contactState = {
  response?: ContactSale;
  loading: boolean;
  error: boolean;
  errorMsg?: string;
};

const initialValues: contactState = {
  loading: false,
  error: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(handelerContactSalesTeam.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(handelerContactSalesTeam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.response = action.payload;
    });
    builder.addCase(handelerContactSalesTeam.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMsg = action.error.message;
    });
  },
});

// export const {} = contactSlice.actions;
export const contactSelector = (store: RootState) => store.contactReducer;
export default contactSlice.reducer;
