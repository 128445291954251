import React from "react";

import style from "./ButtonCenterClose.module.css";
import xIcon from "./image/xIcon.png";

type buttonCenterCloseProps = {
    small?: boolean
  loading?: boolean;
  onClick?(): void;
  height?: string;
};

const ButtonCenterCLose: React.FC<buttonCenterCloseProps> = (props) => {
  return (
    <div
      className={style.x}
      style={{
          height: (props?.height)? props?.height: ( props.small ? "35px" : "45px"),
        backgroundImage: `url(${xIcon})`,
        cursor: props?.loading ? "wait" : "pointer",
        pointerEvents: props?.loading ? "none" : "auto",
      }}
      onClick={(e) => {
        if (props.onClick) props.onClick();
      }}
    />
  );
};

export default ButtonCenterCLose;
