import { Input, notification, Radio } from "antd";
import { FileText, LayoutGrid, List } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./DocumentView.module.scss";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import DocumentTableView from "./components/DocumentTableView";
import DocumentCardView from "./components/DocumentCardView";
import dayjs from "dayjs";
import HeaderContentComponent from "../generate/components/HeaderContentComponent";
import JSZip from "jszip";
// @ts-ignore
import html2pdf from "html2pdf.js";
import saveAs from "file-saver";
import * as Auth from "src/services/auth.service";
import { getAuthorizationPmtx } from "src/services/util.service";
import axios from "axios";
import { GET_DOCUMENT_LIST_ENDPOINT } from "src/utils/endpoint/pmtx.endpoint";
import { set } from "lodash";
import { decodeBase64ToObject } from "../utils/FormatGenerate";
import { PagingResponse } from "src/model/paging";

type Document = {
  content?: string;
  category?: string;
  file_name: string;
};

interface ExtendedDocumentData extends Document {
  content?: string;
  category?: string;
  file_name: string;
}

export type DocumentData = {
  app_id: string;
  user_id: string;
  app_name: string;
  file_name: string;
  key_data: string;
  file_size: number;
  file_type: string;
  created_at: string;
  category: string;
};

const TagLists = [
  { label: "All", value: "all" },
  { label: "User Stories", value: "User Stories" },
  { label: "Functional", value: "Functional" },
  { label: "Screen Specifications", value: "Screen Specifications" },
  { label: "HTML", value: "HTML" },
  { label: "PDF", value: "PDF" },
  { label: "Doc", value: "DOC" },
];

export const simpleMarkdownToHTML = (markdown: string): string => {
  // Replace headers
  markdown = markdown
    .replace(/^# (.*)$/gm, "<h1>$1</h1>") // Replace # Header
    .replace(/^## (.*)$/gm, "<h2>$1</h2>") // Replace ## Sub-header
    .replace(/^### (.*)$/gm, "<h3>$1</h3>") // Replace ### Sub-sub-header
    .replace(/^#### (.*)$/gm, "<h4>$1</h4>"); // Replace #### Sub-sub-sub-header

  // Replace lists (unordered and ordered)
  markdown = markdown.replace(/^\s*[-*]\s+(.+)$/gm, "<ul><li>$1</li></ul>"); // Unordered list
  markdown = markdown.replace(/^\d+\.\s+(.+)$/gm, "<ol><li>$1</li></ol>"); // Ordered list

  // Merge consecutive list items (to avoid <ul><li></ul><ul><li></ul> structure)
  markdown = markdown.replace(/(<\/(ul|ol)>)\s*<\2>/g, "");

  // Handle nested lists
  markdown = markdown.replace(/(<ul><li>.*<\/li><\/ul>)/g, (match) => {
    return `<ul>${match.replace(/<\/li><\/ul><ul>/g, "")}</ul>`;
  });

  // Handle bold and italic text
  markdown = markdown.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"); // **bold**
  markdown = markdown.replace(/\*(.*?)\*/g, "<em>$1</em>"); // *italic*

  // Handle blockquotes
  markdown = markdown.replace(/^> (.*)$/gm, "<blockquote>$1</blockquote>");

  // Wrap paragraphs in <p> tags
  markdown = markdown.replace(
    /^(?!<h[1-4]|<ul|<ol|<blockquote)(.+)$/gm,
    "<p>$1</p>"
  );

  return markdown;
};

export const formatFileSize = (size: number) => {
  const KB = 1024;
  const MB = KB * 1024;
  if (size < KB) {
    return `${size} B`;
  } else if (size < MB) {
    return `${(size / KB).toFixed(1)} KB`;
  } else {
    return `${(size / MB).toFixed(1)} MB`;
  }
};

export const formatDate = (date: string) => {
  return dayjs(date).format("D MMM YYYY");
};

const DocumentView: React.FC = () => {
  const [layout, setLayout] = useState<"list" | "card">("list");
  const [tags, setTags] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDocs, setSelectedDocs] = useState<DocumentData[]>([]);
  const [dataDocument, setDataDocument] = useState<DocumentData[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const fetchDocumentList = async (
    offset: number,
    limit: number,
    search: string
  ) => {
    try {
      let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
      setLoading(true);
      const document = await axios.get<PagingResponse<DocumentData>>(
        GET_DOCUMENT_LIST_ENDPOINT,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorizationPmtx(),
          },
          params: {
            user_id: JSON.parse(userFromStorage).user_id,
            offset,
            limit,
            search,
          },
        }
      );
      setDataDocument(document.data.data);
      setLoading(false);
      return document;
    } catch (e) {
      console.error("Error fetching agent list:", e);
      throw e;
    }
  };

  useEffect(() => {
    fetchDocumentList(0, 0, "");
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleLayoutChange = (value: "list" | "card") => {
    setLayout(value);
  };

  const handleToggleTag = (tag: string) => {
    setTags(tag);
  };

  const handleSelection = (selectedRows: DocumentData[]) => {
    setSelectedDocs(selectedRows);
  };

  const containerStyle: React.CSSProperties = {
    position: "relative",
    height: "100vh",
  };

  const exportToZip = async (data: DocumentData[]) => {
    const key = "download-progress";
    notification.open({
      key,
      message: "Preparing Download",
      description: "Creating zip file...",
      duration: 0,
      icon: <DownloadOutlined style={{ color: "#1890ff" }} />,
    });

    const zip = new JSZip();

    try {
      for (const doc of data) {
        const decodedContent = decodeBase64ToObject(doc.key_data);
        // Handle multiple entries in decodedContent
        for (const content of decodedContent) {
          console.log("content", content);
          const elementContent = simpleMarkdownToHTML(content.text);
          console.log("elementContent", elementContent);

          if (doc.category === "HTML") {
            const htmlSections = elementContent
              .split("- Screen Name:")
              .filter(Boolean);

            htmlSections.forEach((section: string) => {
              const lines = section.trim().split("\n").filter(Boolean);
              const screenName = lines[0].trim();
              let htmlCode = section.split("- HTML Code:")[1];

              if (htmlCode) {
                htmlCode = htmlCode
                  .replace(/```html/g, "")
                  .replace(/```/g, "")
                  .trim();
                if (screenName && htmlCode.startsWith("<!DOCTYPE html>")) {
                  const fileName =
                    screenName.toLowerCase().replace(/\s+/g, "_") + ".html";
                  zip.file(`${doc.file_name}/${fileName}`, htmlCode);
                }
              }
            });
          } else {
            // Generate PDF
            const pdfOptions = {
              margin: 1,
              filename: `${doc.file_name}_message.pdf`,
              image: { type: "jpeg", quality: 0.98 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            let pdfFileName = doc.file_name
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "_");
            const pdfBlob = await html2pdf()
              .from(elementContent)
              .set(pdfOptions)
              .outputPdf("blob");
            zip.file(`${pdfFileName}/${doc.file_name}_message.pdf`, pdfBlob);

            // Generate DOC
            const header = `
            <html xmlns:o='urn:schemas-microsoft-com:office:office' 
                xmlns:w='urn:schemas-microsoft-com:office:word' 
                xmlns='http://www.w3.org/TR/REC-html40'>
            <head><meta charset='utf-8'><title>Export To Word</title></head><body>`;
            const footer = `</body></html>`;
            const fullContent = header + elementContent + footer;
            const wordBlob = new Blob([fullContent], {
              type: "application/msword",
            });
            let docFileName = doc.file_name
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "_");
            zip.file(`${docFileName}/${doc.file_name}.doc`, wordBlob);
          }
        }
      }

      notification.open({
        key,
        message: "Almost Done",
        description: "Generating final zip file...",
        duration: 0,
        icon: <DownloadOutlined style={{ color: "#1890ff" }} />,
      });

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `documents_export.zip`);

      notification.success({
        key,
        message: "Download Complete",
        description: "Your files have been downloaded successfully",
        duration: 3,
      });
    } catch (error) {
      notification.error({
        key,
        message: "Download Failed",
        description: "There was an error creating your download",
        duration: 3,
      });
    }
  };

  const filteredDocuments = useMemo(() => {
    return dataDocument.filter((doc) => {
      const matchTag =
        tags === "all"
          ? true
          : tags === "PDF"
            ? doc.file_type === "PDF"
            : tags === "DOC"
              ? doc.file_type === "DOC"
              : doc.category.toLowerCase() === tags.toLowerCase();

      const matchSearch =
        searchQuery === "" ||
        doc.file_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doc.file_type.toLowerCase().includes(searchQuery.toLowerCase());

      return matchTag && matchSearch;
    });
  }, [dataDocument, tags, searchQuery]);

  console.log("selectedDocs", selectedDocs);

  return (
    <div style={containerStyle}>
      <div className="h-[calc(100vh-4rem)] md:h-full overflow-y-auto scrollbar">
        <div className="min-h-full rounded-lg bg-white p-4 m-2">
          <div className="flex flex-col space-y-4">
            <HeaderContentComponent
              title="Document"
              icon={<FileText size={20} />}
              rightContent={
                <div className="flex flex-row items-center">
                  <Radio.Group
                    className="w-full h-full flex items-center justify-center"
                    value={layout}
                    onChange={(e) => handleLayoutChange(e.target.value)}
                  >
                    <Radio.Button value="list">
                      <div className="anticon">
                        <List size={16} />
                      </div>
                    </Radio.Button>
                    <Radio.Button value="card">
                      <div className="anticon ">
                        <LayoutGrid size={16} />
                      </div>
                    </Radio.Button>
                  </Radio.Group>
                </div>
              }
            />

            <div className="flex flex-col justify-center items-center text-center">
              <span className="font-bold text-xl">Explore your files</span>
              <span className="">
                Explore, install, use, and remix thousands of files and plugins
              </span>
            </div>
            <div className="flex flex-row justify-center items-center gap-2 flex-wrap">
              {TagLists.map((item, key) => (
                <button
                  key={key}
                  className={
                    tags === item.value ? styles.tagBtnActive : styles.tagBtn
                  }
                  onClick={() => handleToggleTag(item.value)}
                >
                  {item.label}
                </button>
              ))}
            </div>
            <div className="flex flex-row justify-center items-center gap-2">
              <Input
                placeholder="Search file and file type"
                suffix={<SearchOutlined />}
                className="w-[40%] rounded-3xl bg-gray-100 px-6 py-2"
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
              <button
                className={styles.tagBtnActive}
                onClick={() =>
                  exportToZip(selectedDocs.length ? selectedDocs : dataDocument)
                }
              >
                <DownloadOutlined />
              </button>
            </div>
            {layout === "list" ? (
              <DocumentTableView
                loading={loading}
                currentPage={currentPage}
                pageSize={pageSize}
                data={filteredDocuments}
                onPageChange={handlePageChange}
                onSelectionChange={handleSelection}
                onExport={exportToZip}
              />
            ) : (
              <DocumentCardView
                loading={loading}
                currentPage={currentPage}
                pageSize={pageSize}
                data={filteredDocuments}
                onPageChange={handlePageChange}
                onSelectionChange={handleSelection}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentView;
