import React from "react";
import ComingSoonIcon from "src/assets/svg/coming-soon";

const ErrorPage: React.FC = () => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <ComingSoonIcon />
      <span>Feature coming soon!</span>
    </div>
  );
};

export default ErrorPage;
