import React from "react";

const AppItemIcon = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      className={className}
      style={style}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.506836" width="32" height="32" rx="8" fill="#E8F4FF" />
      <g clip-path="url(#clip0_10284_6856)">
        <path
          d="M13.1735 8H11.1735C10.4663 8 9.78799 8.28095 9.28789 8.78105C8.78779 9.28115 8.50684 9.95942 8.50684 10.6667V12.6667C8.50684 13.3739 8.78779 14.0522 9.28789 14.5523C9.78799 15.0524 10.4663 15.3333 11.1735 15.3333H13.1735C13.8808 15.3333 14.559 15.0524 15.0591 14.5523C15.5592 14.0522 15.8402 13.3739 15.8402 12.6667V10.6667C15.8402 9.95942 15.5592 9.28115 15.0591 8.78105C14.559 8.28095 13.8808 8 13.1735 8ZM14.5068 12.6667C14.5068 13.0203 14.3664 13.3594 14.1163 13.6095C13.8663 13.8595 13.5271 14 13.1735 14H11.1735C10.8199 14 10.4807 13.8595 10.2307 13.6095C9.98065 13.3594 9.84017 13.0203 9.84017 12.6667V10.6667C9.84017 10.313 9.98065 9.97391 10.2307 9.72386C10.4807 9.47381 10.8199 9.33333 11.1735 9.33333H13.1735C13.5271 9.33333 13.8663 9.47381 14.1163 9.72386C14.3664 9.97391 14.5068 10.313 14.5068 10.6667V12.6667Z"
          fill="#3875F6"
        />
        <path
          d="M21.8401 8H19.8401C19.1328 8 18.4546 8.28095 17.9545 8.78105C17.4544 9.28115 17.1734 9.95942 17.1734 10.6667V12.6667C17.1734 13.3739 17.4544 14.0522 17.9545 14.5523C18.4546 15.0524 19.1328 15.3333 19.8401 15.3333H21.8401C22.5473 15.3333 23.2256 15.0524 23.7257 14.5523C24.2258 14.0522 24.5067 13.3739 24.5067 12.6667V10.6667C24.5067 9.95942 24.2258 9.28115 23.7257 8.78105C23.2256 8.28095 22.5473 8 21.8401 8ZM23.1734 12.6667C23.1734 13.0203 23.0329 13.3594 22.7829 13.6095C22.5328 13.8595 22.1937 14 21.8401 14H19.8401C19.4865 14 19.1473 13.8595 18.8973 13.6095C18.6472 13.3594 18.5067 13.0203 18.5067 12.6667V10.6667C18.5067 10.313 18.6472 9.97391 18.8973 9.72386C19.1473 9.47381 19.4865 9.33333 19.8401 9.33333H21.8401C22.1937 9.33333 22.5328 9.47381 22.7829 9.72386C23.0329 9.97391 23.1734 10.313 23.1734 10.6667V12.6667Z"
          fill="#3875F6"
        />
        <path
          d="M13.1735 16.6667H11.1735C10.4663 16.6667 9.78798 16.9476 9.28788 17.4477C8.78779 17.9478 8.50684 18.6261 8.50684 19.3333V21.3333C8.50684 22.0406 8.78779 22.7189 9.28788 23.219C9.78798 23.7191 10.4663 24 11.1735 24H13.1735C13.8807 24 14.559 23.7191 15.0591 23.219C15.5592 22.7189 15.8402 22.0406 15.8402 21.3333V19.3333C15.8402 18.6261 15.5592 17.9478 15.0591 17.4477C14.559 16.9476 13.8807 16.6667 13.1735 16.6667ZM14.5068 21.3333C14.5068 21.687 14.3664 22.0261 14.1163 22.2761C13.8663 22.5262 13.5271 22.6667 13.1735 22.6667H11.1735C10.8199 22.6667 10.4807 22.5262 10.2307 22.2761C9.98064 22.0261 9.84017 21.687 9.84017 21.3333V19.3333C9.84017 18.9797 9.98064 18.6406 10.2307 18.3905C10.4807 18.1405 10.8199 18 11.1735 18H13.1735C13.5271 18 13.8663 18.1405 14.1163 18.3905C14.3664 18.6406 14.5068 18.9797 14.5068 19.3333V21.3333Z"
          fill="#3875F6"
        />
        <path
          d="M21.8401 16.6667H19.8401C19.1328 16.6667 18.4546 16.9476 17.9545 17.4477C17.4544 17.9478 17.1734 18.6261 17.1734 19.3333V21.3333C17.1734 22.0406 17.4544 22.7189 17.9545 23.219C18.4546 23.7191 19.1328 24 19.8401 24H21.8401C22.5473 24 23.2256 23.7191 23.7257 23.219C24.2258 22.7189 24.5067 22.0406 24.5067 21.3333V19.3333C24.5067 18.6261 24.2258 17.9478 23.7257 17.4477C23.2256 16.9476 22.5473 16.6667 21.8401 16.6667ZM23.1734 21.3333C23.1734 21.687 23.0329 22.0261 22.7829 22.2761C22.5328 22.5262 22.1937 22.6667 21.8401 22.6667H19.8401C19.4865 22.6667 19.1473 22.5262 18.8973 22.2761C18.6472 22.0261 18.5067 21.687 18.5067 21.3333V19.3333C18.5067 18.9797 18.6472 18.6406 18.8973 18.3905C19.1473 18.1405 19.4865 18 19.8401 18H21.8401C22.1937 18 22.5328 18.1405 22.7829 18.3905C23.0329 18.6406 23.1734 18.9797 23.1734 19.3333V21.3333Z"
          fill="#3875F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_10284_6856">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8.50684 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppItemIcon;
