import React from "react";

const PlusCircleIcon = (props) => {
  const { className, style, width, height, fill } = props;
  return (
    <svg
      width={width || "46"}
      height={height || "45"}
      className={className}
      style={style}
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.507812" width="45" height="45" rx="22.5" fill="#E8F4FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.2578 16.25C24.2578 15.5596 23.6982 15 23.0078 15C22.3175 15 21.7578 15.5596 21.7578 16.25V21.25H16.7578C16.0675 21.25 15.5078 21.8096 15.5078 22.5C15.5078 23.1904 16.0675 23.75 16.7578 23.75H21.7578V28.75C21.7578 29.4404 22.3175 30 23.0078 30C23.6982 30 24.2578 29.4404 24.2578 28.75V23.75H29.2578C29.9482 23.75 30.5078 23.1904 30.5078 22.5C30.5078 21.8096 29.9482 21.25 29.2578 21.25H24.2578V16.25Z"
        fill="#1875F0"
        fill-opacity="0.9"
      />
    </svg>
  );
};

export default PlusCircleIcon;
