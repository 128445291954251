import React from "react";

import {Modal, Button} from "antd";
import style from "./CenterConfirmModal.module.scss";
import CenterButton from "src/shared/button/Button";

const CenterConfirmModal = (props: any) => {
  const { visible, onCancel, onConfirm, text, confirmText, cancelText } = props;
  return (
    <Modal
      centered
      open={visible}
      className={style.modalOveride}
      onCancel={onCancel}
      zIndex={9999}
    >
      <div className={style.modalContainer}>
        {text}
        <div className={style.buttonContainer}>
          {/* <button type="button" className={style.addBtn} onClick={onConfirm}>
            {confirmText}
          </button>
          <button
            type="button"
            className={style.cancelBtn}
            style={{ marginLeft: 10 }}
            onClick={onCancel}
          >
            {cancelText}
          </button> */}
          {/* <CenterButton small loading={props.confirmLoading} onClick={onConfirm}>{confirmText}</CenterButton> */}
          <Button type="primary" loading={props.confirmLoading} onClick={onConfirm}>{confirmText}</Button>

          <div style={{ marginLeft: 10 }}>
            {/* <CenterButton small ghost loading={props.cancelLoading} onClick={onCancel}>{cancelText}</CenterButton> */}
            <Button loading={props.cancelLoading} onClick={onCancel}>{cancelText}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(CenterConfirmModal);
