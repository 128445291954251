import {supportViewer} from "../properies.file";
import styles from "./FileContainer.module.scss";

export function getClassNameByType(
  type: any,
  isShowMoveBox: any,
  showRenameInlineCoFile: any
) {
  if (supportViewer.video.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_video_activeMove;
    return styles.fileTypeBox_video;
  } else if (supportViewer.images.indexOf(type) > -1) {
    if (supportViewer.svg.indexOf(type) > -1) {
      if (isShowMoveBox || showRenameInlineCoFile)
        return styles.fileTypeBox_svg_activeMove;
      return styles.fileTypeBox_svg;
    }

    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_pic_activeMove;
    return styles.fileTypeBox_pic;
  } else if (supportViewer.svg.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_svg_activeMove;
    return styles.fileTypeBox_svg;
  } else if (supportViewer.audio.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_audio_activeMove;
    return styles.fileTypeBox_audio;
  } else if (supportViewer.txt.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_text_activeMove;
    return styles.fileTypeBox_text;
  } else if (supportViewer.xls.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_xls_activeMove;
    return styles.fileTypeBox_xls;
  } else if (supportViewer.doc.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_doc_activeMove;
    return styles.fileTypeBox_doc;
  } else if (supportViewer.ppt.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_ppt_activeMove;
    return styles.fileTypeBox_ppt;
  } else if (supportViewer.pdf.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_pdf_activeMove;
    return styles.fileTypeBox_pdf;
  } else if (supportViewer.ai.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_ai_activeMove;
    return styles.fileTypeBox_ai;
  } else if (supportViewer.xd.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_xd_activeMove;
    return styles.fileTypeBox_xd;
  } else if (supportViewer.bin.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_bin_activeMove;
    return styles.fileTypeBox_bin;
  } else if (supportViewer.zip.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_zip_activeMove;
    return styles.fileTypeBox_zip;
  } else if (["dir"].indexOf(type) > -1) {
    return styles.directoryBox;
  } else {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_other_activeMove;
    return styles.fileTypeBox_other;
  }
}



export function getClassNameByTypeColor(
  type: any,
  isShowMoveBox: any,
  showRenameInlineCoFile: any
) {
  if (supportViewer.video.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_video_activeMove_color;
    return styles.fileTypeBox_video_color;
  } else if (supportViewer.images.indexOf(type) > -1) {
    if (supportViewer.svg.indexOf(type) > -1) {
      if (isShowMoveBox || showRenameInlineCoFile)
        return styles.fileTypeBox_svg_activeMove_color;
      return styles.fileTypeBox_svg_color;
    }

    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_pic_activeMove_color;
    return styles.fileTypeBox_pic_color;
  } else if (supportViewer.svg.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_svg_activeMove_color;
    return styles.fileTypeBox_svg_color;
  } else if (supportViewer.audio.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_audio_activeMove_color;
    return styles.fileTypeBox_audio_color;
  } else if (supportViewer.txt.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_text_activeMove_color;
    return styles.fileTypeBox_text_color;
  } else if (supportViewer.xls.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_xls_activeMove_color;
    return styles.fileTypeBox_xls_color;
  } else if (supportViewer.doc.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_doc_activeMove_color;
    return styles.fileTypeBox_doc_color;
  } else if (supportViewer.ppt.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_ppt_activeMove_color;
    return styles.fileTypeBox_ppt_color;
  } else if (supportViewer.pdf.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_pdf_activeMove_color;
    return styles.fileTypeBox_pdf_color;
  } else if (supportViewer.ai.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_ai_activeMove_color;
    return styles.fileTypeBox_ai_color;
  } else if (supportViewer.xd.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_xd_activeMove_color;
    return styles.fileTypeBox_xd_color;
  } else if (supportViewer.bin.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_bin_activeMove_color;
    return styles.fileTypeBox_bin_color;
  } else if (supportViewer.zip.indexOf(type) > -1) {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_zip_activeMove_color;
    return styles.fileTypeBox_zip_color;
  } else if (["dir"].indexOf(type) > -1) {
    return styles.directoryBox_color;
  } else {
    if (isShowMoveBox || showRenameInlineCoFile)
      return styles.fileTypeBox_other_activeMove_color;
    return styles.fileTypeBox_other_color;
  }
}