// import { Dropdown, Menu } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import Icon, { MoreOutlined } from "@ant-design/icons";
import { AiOutlineMore } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import styles from "./UIFileMenuPopover.module.scss";
import { FileContext, FileContextProps } from "../File.context";
import { tabName } from "../properies.file";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { getUserData } from "../../util.service";
import { isTrue } from "src/services/util.service";
import { AppContext } from "src/page/context/App.context";

function UIFileMenuPopover(props: any) {
  const { t } = useTranslation();
  const menuPopover: any = useRef(null);
  const fileContext = useContext<FileContextProps>(FileContext);
  const appContext: any = useContext(AppContext);
  const [isOwner, setIsOwner]: any = useState(false);

  // const handleClickManageVersionMenu = () => {
  //   fileContext.setVisibleManageVersion(true);
  //   mainClick();
  // };
  const handleClickManageVersionMenu = () => {
    fileContext.setVisibleManageVersion(true);
    mainClick();
  };

  const handleClickDetailMenu = () => {
    fileContext.setShowDetail(true);
    appContext.setShowDownload(false);

    mainClick();
  };

  const handleClickDeleteMenu = () => {
    fileContext.setDisplayConfirmDelete(true);
    mainClick();
  };

  const handleClickShareLinkMenu = () => {
    fileContext.setShowShareLinkModal(true);
    mainClick();
  }
  useEffect(() => {
    if (fileContext.showShareLinkModal) {
      fileContext.setShowShareModal(false);
    }
  }, [fileContext.showShareLinkModal]);

  useEffect(() => {
    console.log("fileContext.tab : ", fileContext.tab)
    console.log("props.file : ", props.file)
    console.log("getUserData()?.id : ", getUserData()?.id)
    console.log("showmenu : ", props)
  }, []);

  const handleClickShareMenu = () => {
    fileContext.setShowShareModal(true);
    mainClick();
  };

  const handleClickMoveMenu = () => {
    fileContext.setShowMoveModal(true);
    mainClick();
  };

  const handleClickRenameMenu = () => {
    fileContext.setShowRenameModal(true);
    mainClick();
  };

  const handleClickDownloadMenu = () => {
    props.setShowMenu(false);
    checkAndAddFile(props.file);
    console.log("handleClickDownloadMenu");
    appContext.setShowDownload(true);
    fileContext.setCurrentShowMenuId(null);
  };
  const checkAndAddFile = (file: any) => {
    let files: any = appContext.progressLoadFiles;
    let found = false;
    for (let a = 0; a < files.length; a++) {
      if (file.file_app_id === files[a]?.file?.file_app_id) {
        found = true;
        break;
      }
    }
    if (found === false) appContext.setNewFileDownload(file);
  };

  const handleClickRestoreMenu = () => {
    fileContext.setDisplayConfirmDeleteRestore(true);
    mainClick();
  };

  const mainClick = () => {
    props.setShowMenu(false);
    fileContext.setFileSelected(props.file);
    fileContext.setCurrentShowMenuId(null);
  };
  const handleClickDeleteFoever = () => {
    fileContext.setDisplayConfirmDeleteForever(true);
    mainClick();
  };




  return (
    <Menu menuButton={<MenuButton className={styles.menu} key={"popover_" + props.file?.file_app_id}><AiOutlineMore className={styles.iconMenu} /></MenuButton>}>
      {fileContext.tab === tabName.trash && (
        <>
          <MenuItem className={styles.itemsMenu} onClick={handleClickDeleteFoever}>{t("Delete forever")}</MenuItem>
          <MenuItem className={styles.itemsMenu} onClick={handleClickRestoreMenu}>{t("Restore")}</MenuItem>
        </>
      )}
      {/* {props.file?.file_name === "Shared with me" && (
        <>
          <MenuItem className={styles.itemMenu} onClick={handleClickDetailMenu}>{t("Detail")}</MenuItem>
        </>
      )
      } */}
      {(props.file?.owner_user_id !== getUserData()?.id && fileContext.visibleManageVersion !== true) && (
        <>
          <MenuItem className={styles.itemsMenu} onClick={handleClickDownloadMenu}>{t("Download")}</MenuItem>
          <MenuItem className={styles.itemsMenu} onClick={handleClickDetailMenu}>{t("Detail")}</MenuItem>

        </>
      )}

      {fileContext.tab !== tabName.trash &&
        fileContext.tab !== tabName.manageversion &&
        fileContext.visibleManageVersion !== true &&
        // props.file?.file_name !== "Shared with me" &&
        props.file?.owner_user_id === getUserData()?.id &&
        (
          !(props.file?.type_ === "LINK") ? (
            <>
              <MenuItem className={styles.itemsMenu} onClick={handleClickDownloadMenu}>{t("Download")}</MenuItem>

              {!((props.file?.type_ === "DIRECTORY") &&
                props.file?.file_path === "Shared with me/") ?
                (<>
                  <MenuItem className={styles.itemsMenu} onClick={handleClickRenameMenu}>{t("Rename File")}</MenuItem>
                  {!props.isShared &&
                    <MenuItem className={styles.itemsMenu} onClick={handleClickMoveMenu}>{t("Move")}</MenuItem>
                  }
                  <MenuItem className={styles.itemsMenu} onClick={handleClickDeleteMenu}>{t("Delete")}</MenuItem>
                  <MenuItem className={styles.itemsMenu} onClick={handleClickDetailMenu}>{t("Detail")}</MenuItem>
                </>) : <MenuItem className={styles.itemsMenu} onClick={handleClickDetailMenu}>{t("Detail")}</MenuItem>}

              {isTrue(fileContext?.currentFolder?.control_version) && (
                <MenuItem className={styles.itemsMenu} onClick={handleClickManageVersionMenu}>{t("Manage version")}</MenuItem>
              )}
              {props.file?.type_ === "DIRECTORY" && props.file?.file_path !== "Shared with me/" && !props.isShared && (
                <SubMenu label={t("Share")} >
                  {/* <MenuItem onClick={handleClickShareMenu}>{t("Share file to")}</MenuItem> */}
                  <MenuItem onClick={handleClickShareLinkMenu} >{t("Share link")}</MenuItem>
                </SubMenu>
              )}

              {props.file?.type_ === "FILE" && fileContext.tab !== "sharedWithMe" && props.file?.file_path !== "Shared with me/" && !props.isShared && (
                <SubMenu label={t("Share")}>
                  {/* <MenuItem onClick={handleClickShareMenu}>{t("Share file to")}</MenuItem> */}
                  <MenuItem onClick={handleClickShareLinkMenu} >{t("Share link")}</MenuItem>
                </SubMenu>
              )}
            </>
          ) : (
            <>
              {!props.isShared &&
                <MenuItem className={styles.itemsMenu} onClick={handleClickMoveMenu}>{t("Move")}</MenuItem>
              }
              <MenuItem className={styles.itemsMenu} onClick={handleClickDeleteMenu}>{t("Delete")}</MenuItem>
              <MenuItem className={styles.itemsMenu} onClick={handleClickDetailMenu}>{t("Detail")}</MenuItem>
            </>
          )
          
        )}
      {fileContext.visibleManageVersion === true && (
        <>
          <MenuItem className={styles.itemsMenu} onClick={handleClickDownloadMenu}>{t("Download")}</MenuItem>
          <MenuItem className={styles.itemsMenu} onClick={handleClickShareMenu}>{t("Share")}</MenuItem>
          {fileContext.countFileVersion?.length != 1 && (
            <MenuItem className={styles.itemsMenu} onClick={handleClickDeleteFoever}>{t("Delete forever")}</MenuItem>
          )}
        </>
      )}

    </Menu>
    
  );
}

export default UIFileMenuPopover;
