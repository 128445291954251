import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
//@ts-ignore
import _ from "lodash";
import { FileContext } from "../../File.context";
import FolderContainerTrash from "../../Folder/FolderContainerTrash";
import FileContainerTrash from "../../File/FileContainerTrash";
import SkeletonFile from "../../File/SkeletionFile";
import FileCard from "../../File/FileCard";
import FolderCard from "../../Folder/FolderCard";
import SkeletonFolder from "../../Folder/SkeletionFolder";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./TrashFileContainer.module.scss";
import { useTranslation } from "react-i18next";
import { APIFileEnpoint, fetchNextUtil, getFileFoldersMethodGet, KN_FILE_SERVICE_URL_MAIN, nodataFile } from "src/page/kn/util.service";
import { checkEmpty } from "src/services/util.service";
import NoData from "../../NoDataDisplay/NoData";

const defaultLitmitOfset = 30;
let link = KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFilesInTash;

const TrashFileContainer = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders]: any = useState(null);
  const [files, setFiles]: any = useState(null);
  const [loading, setloading]: any = useState(false);
  const [offsetFile, setOffsetFile]: any = useState(0);
  const fileContext: any = useContext(FileContext);
  const [currentRes, setCurentRes]: any = useState({});

  // const getFileData = useFetch(link, {
  //   // path: APIEnpoint.myApps,
  //   cachePolicy: CachePolicies.NO_CACHE,
  // });

  const fetchFileData = async () => {
    if (offsetFile === 0) {
      fileContext.setFileFolders([]);
      fileContext.setHasMore(true);
      fileContext.setLoadMore(false);
    }

    let param = {
      groupId: fileContext.groupId,
      userId: fileContext.userId,
      offset: offsetFile,
      limitOfset: defaultLitmitOfset,
      moduleName: fileContext.moduleFileAc
    };


    try {
      setloading(true);
      let response: any = await getFileFoldersMethodGet(
        param,
        link,
        fileContext
      );
      setCurentRes(response)
      setloading(false);

      fileContext.setFileFolders((prev: any) => {
        if (offsetFile === 0) return response?.data?.data;
        if (checkEmpty(prev)) prev = [];
        return [...prev, ...response?.data?.data];
      });
      if (response?.data?.total === 0) {
        fileContext.setHasMore(false);
      }
      if (
        fileContext.fileFolders?.length + defaultLitmitOfset >=
        response?.data?.total
      ) {
        fileContext.setHasMore(false);
      }
    } catch (e) {
      // setloading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    fetchFileData();
  }, [fileContext.groupId, offsetFile, fileContext.parentFolderId, fileContext.moduleFileAc]);

  useEffect(() => {
    setOffsetFile(0);
  }, [fileContext.groupId, fileContext.parentFolderId]);

  useEffect(() => {
    setOffsetFile(0);
  }, []);

  const getFileFolders = (type: string, code: string) => {
    try {
      if (checkEmpty(type)) {
        let ls = fileContext?.fileFolders;
        if (checkEmpty(ls)) ls = [];
        return ls;
      } else {
        let ls = fileContext?.fileFolders?.filter((item: any) => {
          console.log("TrashFileContainer item: ", item);
          console.log("TrashFileContainer item: ", item?.type_);
          return item?.type_ === type;
        });
        if (checkEmpty(ls)) ls = [];
        console.log("TrashFileContainer ls: ", ls);
        return ls;
      }
    } catch (error) {
      console.error(error)
    }
  };

  const fetchNext = async () => {
    fetchNextUtil(
      fileContext,
      currentRes?.data,
      setOffsetFile,
      defaultLitmitOfset,
      offsetFile
    );
  };

  return (
    <InfiniteScroll
      dataLength={
        fileContext.fileFolders !== null ? fileContext.fileFolders?.length : 0
      }
      next={fetchNext}
      hasMore={fileContext.hasMore}
      scrollableTarget={"FileFoldersContainer"}
      loader={!fileContext.hasMore ? null : <SkeletonFile />}
      className={styles.customInfinitscroll}
    >
      {fileContext.viewDir !== "y" && (
        <>
          <>
            <FolderContainerTrash
              header={t("Folders")}
              tableHeader={
                getFileFolders("DIRECTORY", "FD").length !== 0 ? true : false
              }
            >
              {loading && !fileContext.loadMore ? (
                <SkeletonFolder />
              ) : (
                //@ts-ignore
                getFileFolders("DIRECTORY")?.map((file, idx) => {
                  return <FolderCard file={file} key={file.file_app_id} />;
                })
              )}
            </FolderContainerTrash>
          </>
          <>
            <FileContainerTrash
              header={t("Files")}
              tableHeader={
                getFileFolders("FILE", "FL").length !== 0 ? true : false
              }
            >
              {loading && !fileContext.loadMore ? (
                <SkeletonFile />
              ) : (
                //@ts-ignore
                getFileFolders("FILE")?.map((file, idx) => {
                  return <FileCard file={file} key={file.file_app_id} isTrash={true} />;
                })
              )}
            </FileContainerTrash>
            {!loading && getFileFolders("", "FL").length === 0 && (
              <NoData
                header={t(nodataFile.noDataHeaderTextTrash)}
                detail={t(nodataFile.nodatatextTrashH) + ", " + t(nodataFile.nodatatextTrashL) + "."}
              />
            )}
          </>
        </>
      )}

      {/* <FolderContainerTrash header="Folders">
        {loading && !fileContext.loadMore ? (
          <SkeletonFolder />
        ) : (
          //@ts-ignore
          getFileFolders("DIRECTORY")?.map((file, idx) => {
            return <FolderCard file={file} key={file.file_app_id} />;
          })
        )}
      </FolderContainerTrash>
      {!loading && getFileFolders("DIRECTORY").length === 0 && (
        // <div className={styles.textEmpty}>{t("Empty")}</div>
        <NoData
          header={t(nodataFile.noDataHeaderTextTrash)}
          detail={t(nodataFile.nodatatextTrashH) + ", " + t(nodataFile.nodatatextTrashL) + "."}
        />
      )}
      <FileContainerTrash header="Files">
        {loading && !fileContext.loadMore ? (
          <SkeletonFile />
        ) : (
          //@ts-ignore
          getFileFolders("FILE")?.map((file, idx) => {
            return <FileCard file={file} key={file.file_app_id} />;
          })
        )}
      </FileContainerTrash>
      {!loading && getFileFolders("FILE").length === 0 && (
        // <div className={styles.textEmpty}>{t("Empty")}</div>
        <NoData
          header={t(nodataFile.noDataHeaderTextTrash)}
          detail={t(nodataFile.nodatatextTrashH) + ", " + t(nodataFile.nodatatextTrashL) + "."}
        />
      )} */}
    </InfiniteScroll>
  );
};

export default TrashFileContainer;
