import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { UserStripeCustomer } from "src/model/customer";
import { fetchStripeCustomerByUserID } from "src/store/actions/payment/customerActions";

type customerState = {
  customerData?: UserStripeCustomer;
  loading: boolean;
  error: boolean;
  errorMsg?: string;
};

const initialValues: customerState = {
  loading: false,
  error: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    // Stripe Customer
    builder.addCase(
      fetchStripeCustomerByUserID.fulfilled,
      (state, action: PayloadAction<UserStripeCustomer>) => {
        state.customerData = action.payload;
        state.loading = false;
        state.error = false;
      }
    );
    builder.addCase(fetchStripeCustomerByUserID.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchStripeCustomerByUserID.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMsg = action.error.message || "Something went wrong";
    });
  },
});

// export const {} = customerSlice.actions;
export const customerSelector = (store: RootState) => store.customerReducer;
export default customerSlice.reducer;
