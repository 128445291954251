
import React from "react";

const Calendar = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
            <g clip-path="url(#clip0_10471_18610)">
                <path d="M11.5833 1.16667H11V0.583333C11 0.428624 10.9385 0.280251 10.8291 0.170854C10.7197 0.0614582 10.5714 0 10.4167 0C10.262 0 10.1136 0.0614582 10.0042 0.170854C9.89479 0.280251 9.83333 0.428624 9.83333 0.583333V1.16667H5.16667V0.583333C5.16667 0.428624 5.10521 0.280251 4.99581 0.170854C4.88642 0.0614582 4.73804 0 4.58333 0C4.42862 0 4.28025 0.0614582 4.17085 0.170854C4.06146 0.280251 4 0.428624 4 0.583333V1.16667H3.41667C2.6434 1.16759 1.90208 1.47518 1.35529 2.02196C0.808514 2.56874 0.500926 3.31007 0.5 4.08333V11.0833C0.500926 11.8566 0.808514 12.5979 1.35529 13.1447C1.90208 13.6915 2.6434 13.9991 3.41667 14H11.5833C12.3566 13.9991 13.0979 13.6915 13.6447 13.1447C14.1915 12.5979 14.4991 11.8566 14.5 11.0833V4.08333C14.4991 3.31007 14.1915 2.56874 13.6447 2.02196C13.0979 1.47518 12.3566 1.16759 11.5833 1.16667ZM1.66667 4.08333C1.66667 3.6192 1.85104 3.17409 2.17923 2.8459C2.50742 2.51771 2.95254 2.33333 3.41667 2.33333H11.5833C12.0475 2.33333 12.4926 2.51771 12.8208 2.8459C13.149 3.17409 13.3333 3.6192 13.3333 4.08333V4.66667H1.66667V4.08333ZM11.5833 12.8333H3.41667C2.95254 12.8333 2.50742 12.649 2.17923 12.3208C1.85104 11.9926 1.66667 11.5475 1.66667 11.0833V5.83333H13.3333V11.0833C13.3333 11.5475 13.149 11.9926 12.8208 12.3208C12.4926 12.649 12.0475 12.8333 11.5833 12.8333Z" fill="#8D8D8D"/>
                <path d="M7.5 9.625C7.98325 9.625 8.375 9.23325 8.375 8.75C8.375 8.26675 7.98325 7.875 7.5 7.875C7.01675 7.875 6.625 8.26675 6.625 8.75C6.625 9.23325 7.01675 9.625 7.5 9.625Z" fill="#8D8D8D"/>
                <path d="M4.58324 9.625C5.06649 9.625 5.45824 9.23325 5.45824 8.75C5.45824 8.26675 5.06649 7.875 4.58324 7.875C4.09999 7.875 3.70824 8.26675 3.70824 8.75C3.70824 9.23325 4.09999 9.625 4.58324 9.625Z" fill="#8D8D8D"/>
                <path d="M10.4168 9.625C10.9 9.625 11.2918 9.23325 11.2918 8.75C11.2918 8.26675 10.9 7.875 10.4168 7.875C9.9335 7.875 9.54175 8.26675 9.54175 8.75C9.54175 9.23325 9.9335 9.625 10.4168 9.625Z" fill="#8D8D8D"/>
            </g>
            <defs>
            <clipPath id="clip0_10471_18610">
                <rect width="14" height="14" fill="white" transform="translate(0.5)"/>
            </clipPath>
            </defs>
        </svg>
    );
}

export default Calendar;
