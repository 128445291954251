import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch, { CachePolicies } from "use-http";
import RecentFileContainer from "../../RecentFile/RecentFileContainer";
import SkeletonRecentFile from "../../RecentFile/SkeletionRecentFile";
import RecentFileCard from "../../RecentFile/RecentFileCard";
import FolderContainer from "../../Folder/FolderContainer";
import SkeletonFolder from "../../Folder/SkeletionFolder";
import FolderCard from "../../Folder/FolderCard";
import FileContainer from "../../File/FileContainer";
import SkeletonFile from "../../File/SkeletionFile";
import FileCard from "../../File/FileCard";
//@ts-ignore
import _ from "lodash";

import { FileContext, FileContextProps } from "../../File.context";
import { tabName } from "../../properies.file";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./MyFileContainer.module.scss";
import { useTranslation } from "react-i18next";
import {
  APIFileEnpoint,
  fetchNextUtil,
  getFileFoldersMethodGet,
  getUserDataMeto,
  KN_FILE_SERVICE_URL_MAIN,
  mainState,
  nodataFile,
} from "src/page/kn/util.service";
import { checkEmpty, getToken, parseJwt } from "src/services/util.service";
import NoData from "../../NoDataDisplay/NoData";
import * as Solr from "../../../solr.service";

const defaultLitmitOfset = 30;
let linkFiles = KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFiles;

let MyFileContainer = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [offsetFile, setOffsetFile]: any = useState(0);
  const [offsetFolder, setOffsetFolde]: any = useState(0);
  const [loading, setLoading]: any = useState(false);
  const [currentRes, setCurentRes]: any = useState({});
  const fileContext = useContext(FileContext);
  // const fileContext = useContext<FileContextProps>(FileContext);
  const myProfile: any = getUserDataMeto()
  const getFileRecentData = useFetch(
    KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFiles,
    {
      // path: APIEnpoint.myApps,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  // const getFileFolderData = useFetch(linkFiles, {
  //   // path: APIEnpoint.myApps,
  //   cachePolicy: CachePolicies.NO_CACHE,
  // });

  const getUserData = () => {
    let token = getToken();
    let userdata: any = null;
    if (!checkEmpty(token)) {
      userdata = parseJwt(token);
    }
    return userdata;
  };

  const fetchFileRecentData = async (data: any) => {
    fileContext.setRecentFiles([]);
    if (
      fileContext.viewDir === "y" ||
      !checkEmpty(fileContext.parentFolderId)
    ) {
      return;
    }

    let ts = [];
    let files: any = data?.data;
    let ls = files?.filter((item: any) => {
      return item.type_ === "FILE";
    });
    for (let a = 0; a < ls?.length && a < 4; a++) {
      ts.push(ls[a]);
    }
    fileContext.setRecentFiles(ts);
  };

  // useEffect(() => {
  //   console.log("recentFiles : ", fileContext.recentFiles)
  // }, [fileContext.fileFolders]);

  useEffect(() => {
    setOffsetFile(0);
    if (fileContext.tab != tabName.search) {
      fileContext.setFileSearchInput("");
    }
  }, [fileContext.tab]);

  useEffect(() => {
    setOffsetFile(0);
  }, [fileContext.fileSearchInput, fileContext.isAdvacedSearch]);

  useEffect(() => {
    // console.log("isShared myfile", props.isShared);
    console.log("solr file folders: ", fileContext.fileFolders);
  }, [fileContext.fileFolders]);

  const fetchFileFolderDataInit = async (recent: any) => {
    if (
      fileContext.clickFromGroupTab &&
      sessionStorage.getItem("group-tab-groupId") !== fileContext.groupId
    )
      return;
    sessionStorage.removeItem("group-tab-groupId");
    fileContext.setClickFromGroupTab(false);
    console.log(
      "fetchFileFolderDataInit fetchFileFolderDataInit",
      fileContext.groupId
    );

    fileContext.setFileFolders([]);
    // if (offsetFile === 0) fileContext.setFileFolders([]);

    let keyword = "";
    if (!checkEmpty(fileContext.fileSearchInput)) {
      keyword = fileContext.fileSearchInput;
    }
    let param = {
      groupId: fileContext.groupId,
      userId: getUserData()?.userID,
      offset: 0,
      // offset: offsetFile,
      limitOfset: defaultLitmitOfset,
      parentFolderId: fileContext.parentFolderId,
      tag: fileContext.tab,
      keyword: keyword,
      moduleName: fileContext.moduleFileAc,
      searchDir: "Y",
    };

    try {
      setLoading(true);
      if (fileContext.isAdvacedSearch) {
        console.log("solr keyword: ", keyword);
        let response = await Solr.search(
          keyword,
          myProfile.id,
          0,
          defaultLitmitOfset
        );
        console.log("solr res: ", response);
        const mappedData = {
          success: true,
          message: "get file Successfully.",
          total: response.response.numFound,
          data: response.response.docs.map((doc: any) => ({
            module_name: null,
            owner_user_id: doc.user_id,
            update_date_real: doc.update_date,
            prefix_version: "1",
            control_version: false,
            user_id: doc.user_id,
            file_version: "1",
            encrypt_type: "DEFAULT",
            mime_type:
              doc.file_type === "docx"
                ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                : "application/pdf",
            parent_folder_id: "",
            file_app_id: doc.id,
            file_name: doc.file_name,
            file_path: doc.file_path || "",
            group_id: null,
            link_download: "",
            file_system_id: doc.id,
            create_date: doc.update_date,
            update_date: doc.update_date,
            active_: "Y",
            type_: "FILE",
            file_type: doc.file_type,
            size_: null,
            countFile: null, // ปรับตามที่ต้องการ
            checkOutObj: null,
            create_date_real: doc.update_date,
            highlighting:
              response.highlighting[doc.id]?.content &&
              Object.keys(response.highlighting[doc.id]?.content).length > 0
                ? response.highlighting[doc.id]?.content
                : [],
          })),
        };
        let curr = {
          data: mappedData,
        };
        // console.log("solr data: ", curr)
        console.log("solr mappedData: ", mappedData);
        setCurentRes(curr);

        fileContext.setHasMore(true);
        fileContext.setLoadMore(false);

        // if (offsetFile === 0 || fileContext.isAdvacedSearch) {
        //   fileContext.setHasMore(true);
        //   fileContext.setLoadMore(false);
        // }
        if (!checkEmpty(mappedData?.data)) {
          fileContext.setFileFolders(mappedData?.data);
          if (response?.response?.numFound === 0) {
            fileContext.setHasMore(false);
          }
          if (
            fileContext.fileFolders?.length + defaultLitmitOfset >=
            response?.response?.numFound
          ) {
            fileContext.setHasMore(false);
          }
        } else {
          fileContext.setFileFolders([]);
        }
      } else {
        let response: any = await getFileFoldersMethodGet(
          param,
          linkFiles,
          fileContext
        );
        setCurentRes(response);

        if (offsetFile === 0) {
          fileContext.setHasMore(true);
          fileContext.setLoadMore(false);
        }
        if (!checkEmpty(response?.data)) {
          fileContext.setFileFolders(response?.data?.data);
          if (response?.data?.total === 0) {
            fileContext.setHasMore(false);
          }
          if (
            fileContext.fileFolders?.length + defaultLitmitOfset >=
            response?.data?.total
          ) {
            fileContext.setHasMore(false);
          }
        } else {
          fileContext.setFileFolders([]);
        }
        fetchFileRecentData(response?.data);
      }
    } catch (e) {
      console.error(e);
      // setLoading(false);
    }
    setLoading(false);
  };

  const fetchFileFolderData = async () => {
    if (offsetFile === 0) fileContext.setFileFolders([]);
    let keyword = "";
    if (!checkEmpty(fileContext.fileSearchInput)) {
      keyword = fileContext.fileSearchInput;
    }

    try {
      setLoading(true);
      if (fileContext.isAdvacedSearch) {
        console.log("solr keyword: ", keyword);
        let response = await Solr.search(
          keyword,
          myProfile.id,
          offsetFile,
          defaultLitmitOfset
        );
        console.log("solr res: ", response);
        const mappedData = {
          success: true,
          message: "get file Successfully.",
          total: response.response.numFound,
          data: response.response.docs.map((doc: any) => ({
            module_name: null,
            owner_user_id: doc.user_id,
            update_date_real: doc.update_date,
            prefix_version: "1",
            control_version: false,
            user_id: doc.user_id,
            file_version: "1",
            encrypt_type: "DEFAULT",
            mime_type:
              doc.file_type === "docx"
                ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                : "application/pdf",
            parent_folder_id: "",
            file_app_id: doc.id,
            file_name: doc.file_name,
            file_path: doc.file_path || "",
            group_id: null,
            link_download: "",
            file_system_id: doc.id,
            create_date: doc.update_date,
            update_date: doc.update_date,
            active_: "Y",
            type_: "FILE",
            file_type: doc.file_type,
            size_: null,
            countFile: null, // ปรับตามที่ต้องการ
            checkOutObj: null,
            create_date_real: doc.update_date,
            highlighting:
              response.highlighting[doc.id]?.content &&
              Object.keys(response.highlighting[doc.id]?.content).length > 0
                ? response.highlighting[doc.id]?.content
                : [],
          })),
        };
        let curr = {
          data: mappedData,
        };
        // console.log("solr data: ", curr)
        console.log("solr mappedData: ", mappedData);
        setCurentRes(curr);

        if (offsetFile === 0) {
          fileContext.setHasMore(true);
          fileContext.setLoadMore(false);
        }
        if (!checkEmpty(mappedData?.data)) {
          if (offsetFile === 0) {
            fileContext.setFileFolders(mappedData?.data);
          } else {
            fileContext.setFileFolders((prev: any[]) => {
              if (checkEmpty(prev)) prev = [];
              return [...prev, ...mappedData?.data];
            });
          }
          if (response?.response?.numFound === 0) {
            fileContext.setHasMore(false);
          }
          if (
            fileContext.fileFolders?.length + defaultLitmitOfset >=
            response?.response?.numFound
          ) {
            fileContext.setHasMore(false);
          }
        } else {
          fileContext.setFileFolders([]);
        }
      } else {
        let param = {
          groupId: fileContext.groupId,
          userId: getUserData()?.userID,
          offset: offsetFile,
          limitOfset: defaultLitmitOfset,
          parentFolderId: fileContext.parentFolderId,
          tag: fileContext.tab,
          keyword: keyword,
          moduleName: fileContext.moduleFileAc,
          searchDir: "Y",
        };

        let response: any = await getFileFoldersMethodGet(
          param,
          linkFiles,
          fileContext
        );
        setCurentRes(response);
        // setLoading(false);

        if (offsetFile === 0) {
          fileContext.setHasMore(true);
          fileContext.setLoadMore(false);
          fileContext.setFileFolders(response?.data?.data);
        } else {
          fileContext.setFileFolders((prev: any[]) => {
            if (checkEmpty(prev)) prev = [];
            return [...prev, ...response?.data?.data];
          });
        }
        if (response?.data?.total === 0) {
          fileContext.setHasMore(false);
        }
        if (
          fileContext.fileFolders?.length + defaultLitmitOfset >=
          response?.data?.total
        ) {
          fileContext.setHasMore(false);
        }
      }
    } catch (e) {
      console.error(e);
      // setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (fileContext.groupId !== mainState.init) {
      fetchFileFolderData();
    }
  }, [
    // fileContext.groupId,
    offsetFile,
    // fileContext.parentFolderId,
    // fileContext.tab,
  ]);

  useEffect(() => {
    console.log("fileContext.parentFolderId : ", fileContext.parentFolderId);
    if (fileContext.groupId !== mainState.init) fetchFileFolderDataInit(true);
  }, [
    fileContext.groupId,
    // offsetFile,
    fileContext.parentFolderId,
    fileContext.tab,
    fileContext.fileSearchInput,
    fileContext.moduleFileAc,
    fileContext.isAdvacedSearch,
  ]);

  useEffect(() => {}, [fileContext.recentFiles]);

  useEffect(() => {
    setOffsetFile(0);
  }, [fileContext.groupId, fileContext.parentFolderId]);

  const getFileFolder = (type: string) => {
    // console.log("fileContext?.currentFolder", fileContext?.currentFolder)
    if (fileContext?.currentFolder?.file_name == "Shared with me") {
      let is_shared_folder_found = false,
        is_shared_file_found = false;
      let ls = fileContext?.fileFolders?.filter((item: any) => {
        // console.log("MyFileContainer item: ", item);
        // console.log("MyFileContainer item: ", item?.type_);
        return item?.type_ === type;
      });
      if (checkEmpty(ls)) ls = [];
      // console.log("MyFileContainer ls: ", ls);
      let finals = [];
      for (let a = 0; a < ls.length; a++) {
        let item = ls[a];
        if (item.file_app_id.startsWith("shared_folder_")) {
          if (!is_shared_folder_found) {
            is_shared_folder_found = true;
            finals.push(item);
            continue;
          }
        } else if (item.file_app_id.startsWith("shared_file_")) {
          if (!is_shared_file_found) {
            is_shared_file_found = true;
            finals.push(item);
            continue;
          }
        } else {
          finals.push(item);
        }
      }
      // console.log("MyFileContainer finals: ", finals);
      return finals;
    } else {
      try {
        let ls = fileContext?.fileFolders?.filter((item: any) => {
          console.log("MyFileContainer item: ", item);
          // console.log("MyFileContainer item: ", item?.type_);
          if(type === 'FILE'){
            return item?.type_ === type || item?.type_ === 'LINK';
          }else{
            return item?.type_ === type
          }
        });
        if (checkEmpty(ls)) ls = [];
        console.log("MyFileContainer ls: ", ls);
        return ls;
      } catch (error) {
        console.error(error);
      }
    }
  };

  /*
  useEffect(() => {
    if (
      fileContext.tab === tabName.sharedWithMe &&
      checkEmpty(fileContext?.currentSharedWith?.file_app_id)
    ) {
      fileContext.setFiles(getFileData.response?.data?.data);
      return;
    }
    if (fileContext.groupId !== mainState.init) fetchFileData();
  }, [
    fileContext.groupId,
    offsetFile,
    fileContext.parentFolderId,
    fileContext.viewDir,
  ]);
  */

  // useEffect(() => {
  //   if (
  //     fileContext.tab === tabName.sharedWithMe &&
  //     checkEmpty(fileContext?.currentSharedWith?.file_app_id)
  //   ) {
  //     fileContext.setFolders(getFileData.response?.data?.data);
  //     return;
  //   }
  //   if (fileContext.groupId !== mainState.init) fetchFoldersData();
  // }, [
  //   fileContext.groupId,
  //   offsetFolder,
  //   fileContext.parentFolderId,
  //   fileContext.viewDir,
  // ]);

  useEffect(() => {
    if (
      fileContext.tab === tabName.sharedWithMe &&
      checkEmpty(fileContext?.currentSharedWith?.file_app_id)
    ) {
      fileContext.setRecentFiles([]);
      return;
    }

    // fetchFileRecentData();
  }, []);

  const fetchNext = async () => {
    console.log("fetchNext fetchNext");
    fetchNextUtil(
      fileContext,
      currentRes?.data,
      setOffsetFile,
      defaultLitmitOfset,
      offsetFile
    );
  };
  if (
    loading &&
    getFileFolder("DIRECTORY").length == 0 &&
    getFileFolder("FILE").length == 0
  ) {
    return <SkeletonFile />;
  }
  return (
    <>
      <InfiniteScroll
        dataLength={
          fileContext.fileFolders !== null ? fileContext.fileFolders?.length : 0
          // 100
        }
        next={fetchNext}
        hasMore={fileContext.hasMore}
        scrollableTarget={"FileFoldersContainer"}
        loader={!fileContext.hasMore ? null : <SkeletonFile />}
        className={styles.customInfinitscroll}
        style={{ overflow: "none !important" }}
      >
        {checkEmpty(fileContext.parentFolderId) &&
          fileContext.recentFiles?.length > 0 &&
          !(
            fileContext.tab === tabName.mediavideo ||
            fileContext.tab === tabName.mediaimage ||
            fileContext.tab === tabName.other ||
            fileContext.tab === tabName.doc ||
            fileContext.tab === tabName.unknow ||
            fileContext.tab === tabName.trash ||
            fileContext.tab === tabName.search
          ) &&
          fileContext.showRecentFiles === true && (
            <RecentFileContainer header={t("Recent Files")}>
              {getFileRecentData.loading ? (
                <SkeletonRecentFile />
              ) : (
                fileContext.recentFiles?.map((recentFile: any, idx: number) => {
                  return (
                    <RecentFileCard
                      key={recentFile?.file_app_id}
                      file={recentFile}
                    />
                  );
                })
              )}
            </RecentFileContainer>
          )}

        {fileContext.viewDir !== "y" && (
          <>
            {getFileFolder("DIRECTORY").length ||
            getFileFolder("FILE").length > 0 ? (
              <>
                {(checkEmpty(fileContext.tab) ||
                  !(
                    fileContext.tab === tabName.mediavideo ||
                    fileContext.tab === tabName.mediaimage ||
                    fileContext.tab === tabName.other ||
                    fileContext.tab === tabName.doc ||
                    fileContext.tab === tabName.unknow ||
                    fileContext.tab === tabName.search
                  )) && (
                  <>
                    <FolderContainer
                      header={t("Folders")}
                      tableHeader={
                        getFileFolder("DIRECTORY").length !== 0 ? true : false
                      }
                    >
                      {loading && !fileContext.loadMore ? (
                        <SkeletonFolder />
                      ) : (
                        //@ts-ignore
                        getFileFolder("DIRECTORY")?.map((file, idx) => {
                          return (
                            <FolderCard
                              file={file}
                              key={file.file_app_id}
                              isShared={props.isShared}
                            />
                          );
                        })
                      )}
                    </FolderContainer>
                  </>
                )}
                <FileContainer
                  header={t("Files")}
                  tableHeader={
                    getFileFolder("FILE").length !== 0 ? true : false
                  }
                >
                  {loading && !fileContext.loadMore ? (
                    <SkeletonFile />
                  ) : (
                    //@ts-ignore
                    getFileFolder("FILE")?.map((file, idx) => {
                      return (
                        <FileCard
                          file={file}
                          key={file.file_app_id}
                          isShared={props.isShared}
                        />
                      );
                    })
                  )}
                </FileContainer>
              </>
            ) : (
              <NoData
                header={t(nodataFile.noDataHeaderFileText)}
                detail={
                  t(nodataFile.nodataFiletextH) +
                  ", " +
                  t(nodataFile.nodataFiletextL) +
                  "."
                }
              />
            )}
          </>
        )}

        {/* {fileContext.viewDir === "y" && (
          <>
            <FolderContainer header={t("Folders")}>
              {loading ? (
                <SkeletonFolder />
              ) : (
                //@ts-ignore
                getFileFolder("DIRECTORY")?.map((file, idx) => {
                  return <FolderCard file={file} key={file.file_app_id} />;
                })
              )}
            </FolderContainer>
            {!loading && getFileFolder("DIRECTORY").length === 0 && (
              <div className={styles.textEmpty}>{t("Empty")}</div>
            )}
          </>
        )} */}
      </InfiniteScroll>
    </>
  );
};

export default MyFileContainer;
