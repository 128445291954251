import React, { useContext, useEffect, useState } from "react";

import styles from "./UIModalMove.module.scss";
import { useTranslation } from "react-i18next";
import nextIcon from "src/assets/png/next.png";
import backIcon from "src/assets/png/newBack.png";
import FolderIcon from "src/assets/png/FolderIcon.png";
import NewFolderIcon from "src/assets/png/newFolderIcon.png";
//@ts-ignore
import _, { assign, fill } from "lodash";
import FolderDocument from "src/assets/png/FolderDocument.png";
import KeyLock from "src/assets/png/KeyLock.png";
import { FileContext, FileContextProps } from "../File.context";
import { notification, Spin, Button} from "antd";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useFetch, { CachePolicies } from "use-http";
import Skeleton from "react-loading-skeleton";
import { APIFileEnpoint, getCurrentFolderMain, getFileByCurrentAndName, getFileFoldersMethodGet, getUserData, KN_FILE_SERVICE_URL_MAIN, mainState, moveFile, nodataFile, reflectorFileFolders, reflectorFileFoldersDelete, reverseArr } from "../../util.service";
import { checkEmpty, isTrue } from "src/services/util.service";
import ButtonCenterCLose from "../ButtonCenterClose";
import NoData from "../NoDataDisplay/NoData";
import CenterButton from "src/shared/button/Button";

function UIModalMove(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [moveLoading, setMoveLoading]: any = useState(false);
  const [searchTo, setSearchTo]: any = useState("");
  const [folders, setFolders] = useState<any[]>([]);
  const [offsetFolders, setOffsetFolders] = useState(0);
  const [parentFolderId, setParentFolderId] = useState(null);
  const [currentFolder, setCurrentFolder]: any = useState(null);
  const [currentFolderToMove, setCurrentFolderToMove]: any = useState(null);
  const [canMove, setCanMove] = useState<boolean>(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );
  let linkFiles = KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFiles;
  const getFolderData = useFetch(
    linkFiles,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const handleMove = async () => {
    checkMove();
  };

  useEffect(() => {
    if (fileContext.showMoveModal === false) {
      setCurrentFolder(null);
      setCurrentFolderToMove(null);
      setCanMove(false);
      setParentFolderId(null);
    } else {
      checkCanMove(null);
    }
  }, [fileContext.showMoveModal]);

  useEffect(() => {
    fectDataFolder(parentFolderId);
  }, [fileContext.fileSelected, parentFolderId]);

  useEffect(() => {
    if (fileContext.showMoveModal) fectDataFolder("");
  }, [fileContext.showMoveModal]);

  const fectDataFolder = async (parentId: any) => {
    await getFolderData.get(
      `?groupId=${fileContext.groupId}&userId=${fileContext?.userId}
      &offset=${offsetFolders}&limitOfset=&type=DIRECTORY&parentFolderId=${parentId}`
    );
    setFolders(getFolderData.response?.data?.data);
  };

  async function handleSearchChange(event: any) {
    let values = event.target.value
    console.log("value: ", values);
    // keyword=w&searchDir=only
    setSearchTo(values)
    let param = {
      groupId: fileContext.groupId,
      userId: getUserData()?.userID,
      offset: 0,
      limitOfset: "",
      parentFolderId: fileContext.parentFolderId,
      tag: "search",
      keyword: values || "",
      moduleName: fileContext.moduleFileAc,
      searchDir: "only",
      type: "DIRECTORY",
    };
    if(values === ''){
      fectDataFolder(parentFolderId);
    }

    try {
      let response: any = await getFileFoldersMethodGet(
        param,
        linkFiles,
        fileContext
      );
      // console.log("folders: ", folders);
      // console.log("response: ", response.data.data);
      setFolders(response.data.data)
    } catch (error) {
      console.error("error search : ", error)
    }

  }

  // const handleClickFolder = () => {};
  const clickSelectFolder = (file: any) => {
    if (file?.file_app_id === currentFolderToMove?.file_app_id) {
      setCurrentFolderToMove(currentFolder);
      checkCanMove(currentFolder);
    } else {
      setCurrentFolderToMove(file);
      checkCanMove(file);
    }
  };
  const checkSelectFolder = (file: any) => {
    if (fileContext.fileSelected?.file_app_id === file.file_app_id)
      return false;
    if (file.file_app_id === currentFolderToMove?.file_app_id) {
      return true;
    } else return false;
  };
  const openFolder = (file: any) => {
    if (fileContext.fileSelected?.file_app_id === file.file_app_id) return;
    // setCurrentFolder(file)
    setParentFolderId(file.file_app_id);
    getCurrentFolder(file.file_app_id);
  };

  const getRecursiveParents = () => {
    if (checkEmpty(currentFolder?.recursiveParents)) {
      return [];
    } else {
      let recursiveParents = reverseArr(currentFolder?.recursiveParents);
      return recursiveParents;
    }
  };

  const getCurrentFolder = async (file_app_id: any) => {
    // if (checkEmpty(props.dirId)) return;
    if (checkEmpty(file_app_id)) {
      setCurrentFolder(null);
      setCurrentFolderToMove(null);
      checkCanMove(null);
      return;
    }
    try {
      let data = await getCurrentFolderMain({
        groupId: fileContext.groupId,
        userId: fileContext.userId,
        file_app_id: file_app_id,
      });
      setCurrentFolder(data);
      setCurrentFolderToMove(data);
      checkCanMove(data);
    } catch (e) {
      console.error(e);
    }
  };
  const checkCanMove = (data: any) => {
    let p1 = fileContext.fileSelected?.parent_folder_id;
    let p2 = data?.file_app_id;
    if (checkEmpty(p1)) p1 = "";
    if (checkEmpty(p2)) p2 = "";

    if (p1 === p2 || fileContext.fileSelected?.file_app_id === p2) {
      setCanMove(false);
      return false;
    } else {
      setCanMove(true);
      return true;
    }
  };

  const handleClickBack = () => {
    let id = currentFolder.parent_folder_id;
    if (checkEmpty(id)) id = "";
    setParentFolderId(id);
    getCurrentFolder(id);
  };

  async function setFolder(res: any, type: string) {
    let files = fileContext.files;
    if (type === "DIRECTORY") files = fileContext.folders;
    let { fileFolders, newLenght } = reflectorFileFolders(files, type, res);
    if (type === "DIRECTORY") fileContext.setFolders(fileFolders);
    else fileContext.setFileFolders(fileFolders);
  }
  async function setFolderDelete(res: any, type: string) {
    let files_ = fileContext.fileFolders;
    if (type === "DIRECTORY") files_ = fileContext.fileFolders;
    let { files, newLenght } = reflectorFileFoldersDelete(files_, type, res);
    if (type === "DIRECTORY") fileContext.setFolders(files);
    else fileContext.setFileFolders(files);
  }

  const updateDetail = () => {
    let p1 = fileContext.currentFolder?.file_app_id;
    let p2 = fileContext.fileSelected?.parent_folder_id;

    let files_ = fileContext.fileFolders;
    for (let a = 0; a < files_.length; a++) {
      if (files_[a].file_app_id === currentFolderToMove?.file_app_id) {
        files_[a].countFile = files_[a].countFile + 1;
      }
    }
    fileContext.setFolders(files_);

  }

  async function checkMove() {
    try {
      if (canMove && !moveLoading) {
        setMoveLoading(true);
        console.log("1");

        let cMaove = true;
        let fileNameToShare = fileContext.fileSelected.file_name;
        let length = await getFileByCurrentAndName(
          fileContext.fileSelected.file_name,
          currentFolderToMove,
          fileContext.groupId,
          fileContext.userId,
          fileContext.fileSelected
        );
        if (length > 0) {
          let text = 1;
          console.log("2");

          while (true) {
            let name = fileContext.fileSelected.file_name + " (" + text + ")";
            length = await getFileByCurrentAndName(
              fileContext.fileSelected.file_name,
              currentFolderToMove,
              fileContext.groupId,
              fileContext.userId,
              fileContext.fileSelected
            );
            if (length > 0) {
              text++;
            } else {
              fileNameToShare = name;
              break;
            }
          }
        }

        if (cMaove) {
          console.log("3");

          let response: any = await moveFile(
            fileContext.groupId,
            fileContext.userId,
            fileContext.fileSelected.file_app_id,
            fileNameToShare,
            currentFolderToMove?.file_app_id
          );

          let file = Object.assign({}, fileContext.fileSelected);
          let oldparent_folder_id = fileContext.fileSelected.parent_folder_id;

          fileContext.setRecentFiles((prev: any) => {
            return prev.filter((file: any) => { return file.file_app_id !== response.file_app_id })
          })

          // let fileParentId = response.parent_folder_id;
          //   if (checkEmpty(fileParentId)) fileParentId = "root";
          //   let total = await handleUpdateStructureOnlyOne(
          //     response,
          //     keyStorgae,
          //     "add",
          //     null,
          //     fileParentId
          //   );

          // if (checkEmpty(oldparent_folder_id)) oldparent_folder_id = "root";
          //   let totalOld = await handleUpdateStructureOnlyOne(
          //     fileContext.fileSelected,
          //     keyStorgae,
          //     "delete",
          //     null,
          //     oldparent_folder_id
          //   );

          if (
            fileContext.currentFolder?.file_app_id ===
            currentFolderToMove?.file_app_id
          ) {
            setFolder(file, file.type_);
            console.log("4");

          }

          let p1 = fileContext.currentFolder?.file_app_id;
          let p2 = fileContext.fileSelected?.parent_folder_id;
          if (checkEmpty(p1)) p1 = "";
          if (checkEmpty(p2)) p2 = "";
          if (p1 === p2) {
            setFolderDelete(file, file.type_);
          }
          updateDetail()

          fileContext.setShowMoveModal(false);
          fileContext.setFileSelected(null);
          fileContext.setCurrentShowMenuId(null);
          setMoveLoading(false);
          notification["success"]({
            message: t("File moved successfully.")
          })
        } else {
          // hideModal();
          setMoveLoading(false);
          notification["error"]({
            message: t("Unable to move file. Please try again.")
          })
        }
      } else {
        notification["info"]({
          message: t("Please select a destination folder to move files.")
        })
      }
    } catch (e) {
      console.error(e);
      setMoveLoading(false);
    }
  }

  return (
    <Modal
      centered
      visible={fileContext.showMoveModal}
      className={styles.modalOveride}
      onCancel={() => {
        fileContext.setShowMoveModal(false);
        setSearchTo("")
      }}
    >
      <div>
        <div
          onClick={() => {
            fileContext.setShowMoveModal(false);
            setSearchTo("")
          }}
          className={styles.closeBtn}
        // style={{ backgroundImage: `url(${crossIcon})` }}
        ><ButtonCenterCLose></ButtonCenterCLose></div>
        <div className={[styles.boxCreate].join(" ")}>
          <div className={styles.insidemodal1}>
            {checkEmpty(currentFolder) && (
              <div
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "500",
                }}
              >
                {t("My Files")}
              </div>
            )}
            {!checkEmpty(currentFolder) && (
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  display: "flex",
                }}
              >
                <div className={styles.backIcon} onClick={handleClickBack}>
                  <img src={backIcon}></img>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  {currentFolder.file_name}
                </div>
              </div>
            )}

            <div style={{ fontSize: "1rem", marginTop: "20px" }}>
              <input
                style={{ fontSize: "1rem" }}
                value={searchTo}
                type="text"
                placeholder={t("Search")}
                className={styles.boxCreateInput}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className={styles.boxList}>
            {getFolderData?.loading ? (
              <Skeleton count={8} height={40} style={{}} />
            ) : folders?.length > 0 ? (
              //@ts-ignore
              folders?.map((file, idx) => {
                return (
                  <UICardFolder
                    file={file}
                    idx={idx}
                    clickSelectFolder={clickSelectFolder}
                    selectFolder={checkSelectFolder(file)}
                    openFolder={openFolder}
                    key={file.file_app_id}
                  />
                );
              })
            ) : (
              <NoData
                top={15}
                header={t(nodataFile.noDataHeaderFileText)}
                detail={
                  t(nodataFile.nodataFiletextH) +
                  ", " +
                  t(nodataFile.nodataFiletextL) +
                  "."
                }
              />
            )}
          </div>
          {canMove ? (
            <div style={{ marginTop: "25px", paddingBottom: "75px" }} className={[styles.insidemodalBtn, canMove ? styles.bottonBtn : styles.bottonBtnNotActive].join(" ")}>

              {/* <CenterButton> */}
              <Button >
                <div onClick={handleMove}>
                  {moveLoading ? <Spin indicator={antIcon} /> : t("Move")}
                </div>

              </Button>
              {/* </CenterButton> */}
            </div>
          ) : (
            <div style={{ marginTop: "25px", paddingBottom: "75px" }} className={[styles.insidemodalBtn, canMove ? styles.bottonBtn : styles.bottonBtnNotActive].join(" ")}>

              {/* <CenterButton ghost> */}
              <Button >
                <div onClick={handleMove}>
                  {moveLoading ? <Spin indicator={antIcon} /> : t("Move")}
                </div>

              </Button>
              {/* </CenterButton> */}
            </div>
          )}


          {/* <button
            className={[styles.insidemodalBtn,canMove ? styles.bottonBtn : styles.bottonBtnNotActive].join(" ")}
            style={{ fontSize: "1.2rem", marginTop: "25px" }}
          >
            <div onClick={handleMove}>
              {moveLoading ? <Spin indicator={antIcon} /> : t("Move")}
            </div>
          </button> */}
        </div>
      </div>
    </Modal>
  );
}

function UICardFolder(props: any) {
  const fileContext: any = useContext(FileContext);
  const [showOpenItem, setShowOpenItem]: any = useState(false);
  const handleClickSelectFolder = () => {
    props.clickSelectFolder(props.file);
  };
  const checkSelectFolder = () => {
    if (props.selectFolder || showOpenItem) return true;
    else return false;
  };
  return (
    <div
      key={props.idx}
      className={styles.itemFolders}
      onClick={props.handleClickFolder}
      onMouseOver={() => {
        setShowOpenItem(true);
      }}
      onMouseLeave={() => {
        setShowOpenItem(false);
      }}
      style={{ backgroundColor: checkSelectFolder() ? "#f7f7f7" : "white" }}
    >
      <div style={{ width: "10%", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={handleClickSelectFolder}>
        <div
          style={{
            backgroundImage: `url(${NewFolderIcon})`,
            backgroundSize: "cover",
            width: 29,
            height: 25,
          }}
        ></div>
        {props.file.encrypt_type === mainState.RSA_2048 ? <div
          style={{
            backgroundImage: `url(${props.file.encrypt_type === mainState.RSA_2048 && KeyLock})`,
            backgroundSize: "cover",
            width: 15,
            height: 21,
            position: "static",
            marginLeft: 20,
            marginTop: -20,
          }}
        ></div> : null
        }

      </div>
      <div style={{ width: "80%" }} onClick={handleClickSelectFolder}>
        <div className={styles.textOverflowListFileText}>
          <div className={styles.textItem}>{props.file.file_name}</div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${isTrue(props.file.control_version) ? FolderDocument : ""
            })`,
          backgroundSize: "cover",
          width: 19,
          height: 22,
          position: "static",
          // marginLeft: -15,
          // marginTop: -26,
        }}
      ></div>
      <div style={{ width: "10%" }}>
        <div
          className={
            fileContext.fileSelected?.file_app_id === props.file.file_app_id
              ? styles.nextIconNotActive
              : styles.nextIcon
          }
          style={{ visibility: checkSelectFolder() ? "visible" : "hidden" }}
          onClick={() => {
            props.openFolder(props.file);
          }}
        >
          <img src={nextIcon} width="12" height={22}></img>
        </div>
      </div>
    </div>
  );
}

export default UIModalMove;
