import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Type } from "lucide-react";
import { processCancleSubscription } from "src/store/actions/payment/paymentActions";

interface ISubscription {
  id: string;
  user_id: string;
  customer_id: string;
  subscription_id: string;
  product_id: string;
  email: string;
  name_on_card: string;
  price_id: string;
  status: string;
}
type SubscriptionState = {
  subscription?: ISubscription;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
};

const initialValues: SubscriptionState = {
  loading: false,
  error: false,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      processCancleSubscription.pending,
      (state: SubscriptionState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      processCancleSubscription.fulfilled,
      (state: SubscriptionState, action: PayloadAction<ISubscription>) => {
        state.subscription = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      processCancleSubscription.rejected,
      (state: SubscriptionState, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload;
      }
    );

    // builder.addCase(
    //   fetchSubscriptionById.pending,
    //   (state: SubscriptionState) => {
    //     state.loading = true;
    //   }
    // );
    // builder.addCase(
    //   fetchSubscriptionById.fulfilled,
    //   (state: SubscriptionState, action: PayloadAction<ISubscription>) => {
    //     state.loading = false;
    //     state.subscription = action.payload;
    //   }
    // );
    // builder.addCase(
    //   fetchSubscriptionById.rejected,
    //   (state: SubscriptionState, action: PayloadAction<any>) => {
    //     state.loading = false;
    //     state.error = true;
    //     state.errorMessage = action.payload;
    //   }
    // );
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = subscriptionSlice.actions;
export const subscriptionSelector = (store: RootState) =>
  store.subscriptionReducer;
export default subscriptionSlice.reducer;
