import React, { useContext, useEffect, useRef, useState } from "react";

import styles from "./UIModalUpload.module.scss";
import { useTranslation } from "react-i18next";
import upload_icon from "../file-icon/upload_icon.png";
import folder_upload from "../file-icon/folder-upload-icon2.png"
//@ts-ignore
import _, { assign } from "lodash";
import { FileContext, FileContextProps } from "../File.context";
import { Progress, Spin, notification, Button } from "antd";
import { Modal,  } from "antd";
import { LoadingOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { getClassNameByType } from "../File/UtilFileCard";
import UIFileType from "../type/UIFileType";
// import { setTimeout } from "timers";
import UIModalConfrim from "./UIModalConfrim";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { bytesToSize, calPercentProgressBtnMain, calPercentProgressBtnPercent, cancelUpload, createFolderRequest, onChangeSelectFileMain, reflectorFileFolders, uploadFileRequestChunk, uploadFolderFileRequestChunk } from "../../util.service";
import { checkEmpty } from "src/services/util.service";
import ButtonCenterCLose from "../ButtonCenterClose";
import CenterButton from "src/shared/button/Button";
import { AppContext } from "src/page/context/App.context";
const { confirm } = Modal;

function UIModalUpload(props: any) {
  const { t, i18n } = useTranslation();

  const fileContext = useContext<FileContextProps>(FileContext);
  const appContext: any = useContext(AppContext);
  const inputFileToUploadEl: any = useRef(null);
  const [percentProgress, setPercentProgress]: any = useState(0);
  const [merging, setMerging]: any = useState(false);
  const [disableBtn, setDisableBtn]: any = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const onChangeSelectFile = async (event: any) => {
    onChangeSelectFileMain(event, fileContext, inputFileToUploadEl,confirm,t);
  };

  const checkCanSelectFile = () => {
    let res = calPercentProgressBtnMain(fileContext.fileToUpload);
    if (fileContext.uploading) {
      if (res.status === "cancel") {
        return true;
      } else {
        if (res.percent === 100) return true;
        else return false;
      }
    } else return true;
  };
  const handleClickToSelectFiles = () => {
    if (fileContext.uploading) return;
    if (checkCanSelectFile()) {
      let res = calPercentProgressBtnMain(fileContext.fileToUpload);
      if (res.status === "cancel" || res.percent === 100) {
        fileContext.setFileToUpload(Object.assign([], []));
      }
      fileContext.setUploading(false);
      inputFileToUploadEl.current.click();
    }
  };



  async function setFolder(res: any, type: string) {
    let { fileFolders, newLenght } = reflectorFileFolders(
      fileContext.fileFolders,
      type,
      res
    );
    fileContext.setFileFolders(fileFolders);
  }

  const handleClickCheckUpload = () => {
    console.log("fileToUpload : ", fileContext.fileToUpload);

    let res = calPercentProgressBtnMain(fileContext.fileToUpload);
    if (res.status === "cancel") {
      fileContext.setShowModalUpload(false);
      setTimeout(() => {
        fileContext.setFileToUpload(Object.assign([], []));
      }, 200);
    } else {
      if (res.percent === 100) {
        fileContext.setShowModalUpload(false);
        setTimeout(() => {
          fileContext.setFileToUpload(Object.assign([], []));
        }, 200);
      } else handleClickUpload();
    }
  };

  const handleClickUpload = async () => {
    try {
      let continueUpload = true;
      if (fileContext.fileToUpload.length === 0) {
        // setMessageNoti(t("Please select files to upload."));
        continueUpload = false;
      }
      if (continueUpload) {
        fileContext.setUploading(true);
        let res: any = await uploadFileRequestChunk(
          fileContext.fileToUpload,
          fileContext.groupId,
          fileContext.userId,
          fileContext.currentFolder,
          false,
          fileContext.fileSelected,
          fileContext,
          appContext
        );
        // fileContext.setShowModalUpload(false);
        console.log("res ", res);
        fileContext.setUploading(false);
        setTimeout(() => {
          setFolder(res, "FILE");
          let recentArr = new Array();
          let sizeOfRecent = fileContext.recentFiles?.length;
          for (let index = 0; index < 4; index++) {
            if (res.length < 3) {
              if (index < res.length) {
                recentArr.push(res[index]);
              } else {
                let indexOldRecent = index - res.length;
                if (indexOldRecent < sizeOfRecent)
                  recentArr.push(fileContext.recentFiles[indexOldRecent]);
              }
            } else {
              recentArr.push(res[index]);
            }
          }
          console.log("recentArr :", recentArr);
          fileContext.setRecentFiles(recentArr);
        }, 200);

        // getAboutLimit_call();
        // if (isCheckIn) {
        //   updateDataOneChange(false);
        // }
        fileContext.setSyncAboutLimit(fileContext.syncAboutLimit + 1);
      }
    } catch (e) {
      console.error(e);
    }
    console.log("finally");
    inputFileToUploadEl.current.value = null;

    // setUploaded(true);
  };

  const handleClickUploadFolder = async () => {
    console.log("fileToUpload : ", fileContext.fileToUpload);
    fileContext.setShowModalFolderUpload(false);

    let folder: any = await createFolderRequest(
      fileContext.createFolderName,
      "",
      "",
      "",
      false,
      false,
      fileContext.moduleFileAc
    );
    fileContext.setCurrentFolder(folder)
    console.log("CurrentFolder ", folder.file_system_id);
    setFolder(folder, "DIRECTORY");



    setTimeout(async () => {
      await uploadFileinFolder(folder.file_system_id);
    }, 200);
  };

  const uploadFileinFolder = async (pathFolder: any) => {
    try {
      let continueUpload = true;
      if (fileContext.fileToUpload.length === 0) {
        // setMessageNoti(t("Please select files to upload."));
        continueUpload = false;
      }
      if (continueUpload) {
        console.log("upload ");
        fileContext.setUploading(true);
        let res: any = await uploadFolderFileRequestChunk(
          fileContext.fileToUpload,
          fileContext.groupId,
          fileContext.userId,
          pathFolder,
          false,
          fileContext.fileSelected,
          fileContext
        );
        console.log("res ", res);
        fileContext.setUploading(false)
      }
    } catch {

    }
  }

  const getStatusItem = (item: any) => {
    if (!fileContext.uploading && checkEmpty(item.percent)) return <></>;
    if (item.status === "cancel") {
      return (
        <div className={styles.progressWarning}>
          <Progress
            strokeColor={"#ff9c00"}
            percent={item.percent}
            status={"exception"}
          />
        </div>
      );
    } else if (item.status === "error") {
      return (
        <div className={styles.progressError}>
          <Progress
            strokeColor={"red"}
            percent={item.percent}
            status={"exception"}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.progressNormal}>
          <Progress
            strokeColor={"#3875F6"}
            percent={item.percent}
            status={item.percent === 100 ? "normal" : "active"}
          />
        </div>
      );
    }
  };
  const getTextStatusUpload = (item: any) => {
    if (item.status === "cancel") {
      return t("Cancel");
    }
    if (item.status === "error") {
      return t("Error");
    } else {
      if (item.percent === 100) return t("Done");
      else return ""; //item.percent + "%";
    }
  };
  const toCancelUpload = () => {
    console.log("uploadItemToCancel", fileContext.uploadItemToCancel);
    if (checkEmpty(fileContext.uploadItemToCancel)) {
      for (var i = 0; i < fileContext.fileToUpload.length; i++) {
        if (!checkEmpty(fileContext.fileToUpload[i].cancel)) {
          for (var j = 0; j < fileContext.fileToUpload[i].total; j++) {
            try {
              if (!checkEmpty(fileContext.fileToUpload[i].cancel[j]))
                fileContext.fileToUpload[i].cancel[j]();
            } catch (e) {
              console.log(e);
            }
          }
          if (
            !checkEmpty(fileContext.fileToUpload[i].useName) &&
            !checkEmpty(fileContext.fileToUpload[i].hash)
          ) {
            cancelUpload(
              fileContext.fileToUpload[i].useName,
              fileContext.fileToUpload[i].hash
            );
          }
        }
      }
    } else {
      try {
        for (var j = 0; j < fileContext.uploadItemToCancel.total; j++) {
          console.log(
            "fileContext.uploadItemToCancel.cancel[j]",
            fileContext.uploadItemToCancel.cancel[j]
          );
          try {
            if (!checkEmpty(fileContext.uploadItemToCancel.cancel[j]))
              fileContext.uploadItemToCancel.cancel[j]();
          } catch (e) {
            console.log(e);
          }
        }
        if (
          !checkEmpty(fileContext.uploadItemToCancel.useName) &&
          !checkEmpty(fileContext.uploadItemToCancel.hash)
        ) {
          cancelUpload(
            fileContext.uploadItemToCancel.useName,
            fileContext.uploadItemToCancel.hash
          );
        }

        // fileContext.uploadItemToCancel.cancel();
        fileContext.setUploadItemToCancel(null);
      } catch (e) {
        console.error(e);
      }
    }
    fileContext.setFnShowModal(false);
  };

  const checkCloseUploadModal = (item: any) => {
    if (!checkEmpty(item) && fileContext.uploading) {
      fileContext.setFnTitle(t("Cancel Upload"));
      fileContext.setFnMessage(
        t("Are you sure you want to cancel this upload") + "?"
      );
      fileContext.setFnShowModal(true);
    } else {
      let uploadStatus = calPercentProgressBtnMain(fileContext.fileToUpload);
      if (
        uploadStatus.status != "cancel" &&
        uploadStatus.percent != 100 &&
        fileContext.uploading
      ) {
        fileContext.setFnTitle(t("Cancel Upload"));
        fileContext.setFnMessage(
          t("Are you sure you want to cancel all upload") + "?"
        );
        fileContext.setFnShowModal(true);
      } else {
        fileContext.setUploading(false);
        fileContext.setShowModalUpload(false);
        setTimeout(() => {
          fileContext.setFileToUpload(Object.assign([], []));
        }, 200);
      }
    }
  };

  const checkCloseUploadFolderModal = (item: any) => {
    if (!checkEmpty(item) && fileContext.uploading) {
      fileContext.setFnTitle(t("Cancel Upload"));
      fileContext.setFnMessage(
        t("Are you sure you want to cancel this upload") + "?"
      );
      fileContext.setFnShowModal(true);
    } else {
      let uploadStatus = calPercentProgressBtnMain(fileContext.fileToUpload);
      if (
        uploadStatus.status != "cancel" &&
        uploadStatus.percent != 100 &&
        fileContext.uploading
      ) {
        fileContext.setFnTitle(t("Cancel Upload"));
        fileContext.setFnMessage(
          t("Are you sure you want to cancel all upload") + "?"
        );
        fileContext.setFnShowModal(true);
      } else {
        fileContext.setUploading(false);
        fileContext.setShowModalFolderUpload(false);
        setTimeout(() => {
          fileContext.setFileToUpload(Object.assign([], []));
        }, 200);
      }
    }
  };

  const checkClickCancelItemUpload = (item: any) => {
    if (item.status === "progress") {
      fileContext.setUploadItemToCancel(item);
      checkCloseUploadModal(item);
    } else {
      let fileToUploadT: any = Object.assign([], fileContext.fileToUpload);
      for (var i = 0; i < fileToUploadT.length; i++) {
        if (item.fullName === fileToUploadT[i].fullName) {
          fileToUploadT.splice(i, 1);
          i--;
          break;
        }
      }
      fileContext.setFileToUpload(Object.assign([], fileToUploadT));
      inputFileToUploadEl.current.value = null;
    }
  };

  const getTextBtn = () => {
    let res = calPercentProgressBtnMain(fileContext.fileToUpload);
    if (res.status === "cancel") {
      return t("Cancel all");
    } else {
      if (res.percent === 100)
        return t("Done")
      else return t("Upload Files");
    }
  };
  const antIcon_2 = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const checkPercentProgress = (percent: any) => {
    console.log("percent : ", percent);

  }

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
      notification[type]({
          message: message,
          description: description,
      });
  };

  useEffect(() => {
    console.log("fileToUpload : ", fileContext.fileToUpload);
    console.log("fileContext.fileFolders : ", fileContext.fileFolders);
    let fileToUpload = fileContext.fileToUpload;
    let folderToUpload = fileContext.fileFolders;
    if (fileToUpload.length !== 0) { 
      // fix folder files count defect
      let folder_name = fileToUpload[0]?.webkitRelativePath?.split("/")[0]
      if(folder_name){
        if(folderToUpload[0]){
          folderToUpload?.forEach((item:any) => {
            if(item.type_ === 'DIRECTORY' && item.file_name === folder_name){
              item.countFile = fileToUpload.length
            }
          })
          fileContext.setFileFolders(folderToUpload)  

        }

      }
      let total = 0,
        used = 0;
      let cancleSize = 0;
      let oldPercent = 0;
      for (let a = 0; a < fileToUpload?.length; a++) {
        if (
          fileToUpload[a].status !== "cancel" &&
          fileToUpload[a].status !== "error"
        ) {
          used += fileToUpload[a]?.loaded ? fileToUpload[a]?.loaded : 0;
          total += fileToUpload[a]?.size ? fileToUpload[a]?.size : 0;
        }
        if (
          fileToUpload[a].status === "cancel" ||
          fileToUpload[a].status === "error"
        ) {
          cancleSize++;
          oldPercent += fileToUpload[a]?.percent;
        }
      }
      console.log("fileContext.uploading ", fileContext.uploading)
      console.log("total size: ", total)
      if(total > 20000000){
        console.log("> max size 20mb")
        openNotificationWithIcon('error', t("File size too large"), t("Please select file less than 20MB"));
        setDisableBtn(true)
      }else{
        setDisableBtn(false)
        let status = "normal";
        if (cancleSize === fileToUpload.length) {
          status = "cancel";
          percent = oldPercent / fileToUpload.length;
        } else {
          var percent = (used / total) * 100;
          percent = Math.round(percent);
          if (percent > percentProgress) {
            setPercentProgress(percent)
          }
        }
      }
    } else {
      setPercentProgress(0);
    }

  }, [fileContext.fileToUpload])
  useEffect(() => {

    if (percentProgress === 100) {
      setMerging(true)
    } else {
      setMerging(false)
    }
  }, [percentProgress])
  return (
    <>
      <UIModalConfrim fnDone={toCancelUpload}></UIModalConfrim>
      {/* <Spin indicator={antIcon_2} spinning={fileContext.checkingFile||true}> */}
      <div className={styles.overideBlockUI}>
        <BlockUi
          tag="div"
          blocking={fileContext.checkingFile}
          message={t("Checking") + "..."}
        ></BlockUi>
      </div>
      <Modal
        centered
        visible={fileContext.showModalFolderUpload}
        className={styles.modalOveride}
        onCancel={() => {
          checkCloseUploadFolderModal(null);
        }}
      >
        <div
          onClick={() => {
          }}
          className={styles.closeBtn}
        >
          <ButtonCenterCLose
            onClick={() => {
              checkCloseUploadFolderModal(null);
            }}
          ></ButtonCenterCLose>
        </div>
        <div className={styles.boxCreate}>
          <div style={{ display: "flex", fontSize: "1rem" }}>
            <div className={styles.textUpload}>{t("Upload Folder")}</div>

          </div>
          {/* ส่วนเนื้อหาอื่น ๆ ของ Modal */}
          <div className={styles.listUploadFileContainer}>
            <div
              key={"file_previrew_download"}
              className={styles.listUploadFile}
            >
              <div style={{ width: "95%" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: "#674dff !important",
                      margin: "auto",
                      borderRadius: "5px",
                      minWidth: "60px",
                      height: "60px",
                      textAlign: "center",
                    }}
                  >

                    <div className={styles.verticalCenter}>
                      <div className="image">
                        <div className="" style={{ width: "50px", height: "50px", borderRadius: "8px", backgroundColor: "#3875F6", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={folder_upload} height={26} width={29} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      marginLeft: "15px",
                      width: "85%",
                    }}
                  >
                    <div className={styles.textOverflowListFileText_upload}>
                      <div>Folder : {fileContext.createFolderName}</div>
                    </div>
                    <div>
                      {/* <div className={styles.sizeUpload}>
                        00.00 KB
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* <div style={{ marginTop: "30px" }}></div> */}
          <div style={{ display: !fileContext.uploading ? "block" : "none" }}>
            {/* <CenterButton disabled={disableBtn === false ? false : true}> */}
            <Button type="primary" disabled={disableBtn === false ? false : true}>
              <div onClick={handleClickUploadFolder}>
                {fileContext.uploading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  getTextBtn()
                )}
              </div>
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        visible={fileContext.showModalUpload}
        className={styles.modalOveride}
        onCancel={() => {
          checkCloseUploadModal(null);
        }}
      // closeIcon={

      // }
      // closable={false}
      >
        <div
          onClick={() => {
            // checkCloseUploadModal(null);
          }}
          className={styles.closeBtn}
        // style={{ backgroundImage: `url(${crossIcon})` }}
        >
          <ButtonCenterCLose
            onClick={() => {
              checkCloseUploadModal(null);
            }}
          ></ButtonCenterCLose>
        </div>
        <div className={styles.boxCreate}>
          <input
            ref={inputFileToUploadEl}
            className={styles.inputfile}
            type="file"
            multiple={!fileContext.isCheckIn}
            onChange={(event) => {
              onChangeSelectFile(event);
            }}
          ></input>
          <div style={{ display: "flex", fontSize: "1rem" }}>
            <div className={styles.textUpload}>{t("Upload Files")}</div>

            <div
              className={styles.uploadBtn}
              onClick={() => {
                handleClickToSelectFiles();
              }}
            >
              {/* <img src={upload_icon} height={22} width={31} /> */}
              <CloudUploadOutlined style={{ color: "#3875F6"}} />
            </div>
          </div>

          <div className={styles.listUploadFileContainer}>
            {fileContext.fileToUpload?.map((item: any, index: number) => {
              return (
                <div
                  key={"file_previrew_download" + index}
                  className={styles.listUploadFile}
                >
                  <div style={{ width: "95%" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        className={[
                          getClassNameByType(item.type_, false, false),
                        ].join(" ")}
                        style={{
                          backgroundColor: "#674dff !important",
                          margin: "auto",
                        }}
                      >
                        <div className={styles.verticalCenter}>
                          <UIFileType
                            file={item}
                            type={item.type_}
                          // urlImage={getUrlBlob(
                          //   !checkEmpty(item.file) ? item.file : item
                          // )}
                          ></UIFileType>
                        </div>
                      </div>
                      <div
                        style={{
                          margin: "auto",
                          marginLeft: "15px",
                          width: "85%",
                        }}
                      >
                        <div className={styles.textOverflowListFileText_upload}>
                          <div>{item?.fullName}</div>
                        </div>
                        <div>
                          <div className={styles.sizeUpload}>
                            {bytesToSize(item.size_)}
                          </div>
                        </div>
                        <div>{getStatusItem(item)}</div>
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ width: "80%", margin: "auto" }}>
                    <div className={styles.textOverflowListFileText_upload}>
                      <div>{item?.fullName}</div>
                    </div>
                    <div>
                      <div className={styles.sizeUpload}>
                        {bytesToSize(item.size_)}
                      </div>
                    </div>
                    <div>{getStatusItem(item)}</div>
                  </div> */}

                  <div
                    style={{
                      alignSelf: "center",
                      display:
                        calPercentProgressBtnPercent(fileContext.fileToUpload) <
                          100
                          ? "block"
                          : "none",
                      width: "5%",
                    }}
                    onClick={() => {
                      if (
                        calPercentProgressBtnPercent(fileContext.fileToUpload) <
                        100
                      )
                        checkClickCancelItemUpload(item);
                    }}
                  >
                    <div style={{ float: "right" }}>
                      {/* <div
                        className={styles.closeBtnItem}
                        style={{
                          backgroundImage: `url(${crossIcon})`,
                          backgroundSize: "18px",
                        }}
                      ></div> */}
                      <ButtonCenterCLose
                        onClick={() => {
                          if (
                            calPercentProgressBtnPercent(
                              fileContext.fileToUpload
                            ) < 100
                          )
                            checkClickCancelItemUpload(item);
                        }}
                      ></ButtonCenterCLose>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: fileContext.uploading ? "block" : "none",
              marginTop: "25px",
            }}
            className={
              calPercentProgressBtnPercent(fileContext.fileToUpload) >= 50
                ? styles.boxbtnUploadingMore50
                : styles.boxbtnUploading
            }
          >
            <Progress
              strokeWidth={52}
              strokeColor={"#3875F6"}
              percent={percentProgress === 100 ? percentProgress : percentProgress}
              // format={(percent) => merging ? t("merging...") : percent + "%"}
              format={(percent) => (
                <span style={{ color: merging ? "#FFFFFF" : "currentColor" }}>
                  {merging ? t("merging...") : percent + "%"}
                </span>
              )}

              status={
                calPercentProgressBtnPercent(fileContext.fileToUpload) === 100
                  ? "normal"
                  : "active"
              }
            />
          </div>
          {/* 
          <button
            className={styles.bottonBtnUpload}
            style={{
              fontSize: "1.2rem",
              marginTop: "25px",
              display: !fileContext.uploading ? "block" : "none",
            }}
          >

          </button> */}
          <div style={{ display: !fileContext.uploading ? "block" : "none" }}>
            {/* <CenterButton disabled={disableBtn === false ? false : true}> */}
            <Button type="primary" disabled={disableBtn === false ? false : true}>
              <div onClick={handleClickCheckUpload}>
                {fileContext.uploading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  getTextBtn()
                )}
              </div>
            </Button>
            {/* </CenterButton> */}
            
            
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UIModalUpload;
