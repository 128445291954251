import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
//@ts-ignore
import _ from "lodash";
import {FileContext, FileContextProps} from "../File.context";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import CenterConfirmModal from "../Modal/CenterConfirmModal";

function UIModalConfrim(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  return (
    // <Modal
    //   // centered
    //   visible={fileContext.fnShowModal}
    //   className={styles.modalOveride}
    //   onCancel={() => {
    //     fileContext.setFnShowModal(false);
    //   }}
    //   closeIcon={<div />}
    //   closable={false}
    // >
    //   <div className={styles.boxCreate}>
    //     <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
    //       {fileContext.fnTitle}
    //     </div>

    //     <div style={{ fontSize: "1rem", color: "#5f5f5f" }}>
    //       {fileContext.fnMessage}
    //     </div>

    //     <div className={styles.boxConfirmBtn}>
    //       <button
    //         className={styles.bottonBtnCancel}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div
    //           onClick={() => {
    //             fileContext.setFnShowModal(false);
    //           }}
    //         >
    //           {fileContext.fnLoading ? (
    //             <Spin indicator={antIcon} />
    //           ) : (
    //             t("Cancel")
    //           )}
    //         </div>
    //       </button>
    //       <button
    //         className={styles.bottonBtnOk}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div
    //           onClick={() => {
    //             console.log("props.fnDone : ", props.fnDone);
    //             props.fnDone();
    //           }}
    //         >
    //           {fileContext.fnLoading ? <Spin indicator={antIcon} /> : t("Ok")}
    //         </div>
    //       </button>
    //     </div>
    //   </div>
    // </Modal>
    <CenterConfirmModal
      cancelText={t("Cancel")}
      confirmText={fileContext.fnLoading ? <Spin indicator={antIcon} /> : t("Ok")}
      text={fileContext.fnMessage}
      visible={fileContext.fnShowModal}
      onCancel={() => {
        fileContext.setFnShowModal(false);
      }}
      onConfirm={() => {
        props.fnDone();
      }}
    ></CenterConfirmModal>
  );
}

export default UIModalConfrim;
