import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '../store';

interface DateRangeState {
  startDate: string;
  endDate: string;
}

const initialState: DateRangeState = {
  startDate: dayjs("2024-11-11").format("YYYY-MM-DD"),
  endDate: dayjs("2024-11-17").format("YYYY-MM-DD"),
};

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    setDateRange: (state, action: PayloadAction<[string, string]>) => {
      state.startDate = action.payload[0];
      state.endDate = action.payload[1];
    },
  },
});

export const { setDateRange } = dateRangeSlice.actions;
export const dateRangeSelector = (store: RootState) => store.dateRangeReducer;
export default dateRangeSlice.reducer;
