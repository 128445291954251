import { Modal, notification } from "antd";
import { Check, ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import AgentProfile from "src/assets/svg/agent-profile";
import AgentProfileContent from "./contents/AgentProfileContent";
import AgentPersonalContent from "./contents/AgentPersonalContent";
import AgentExpertiseContent from "./contents/AgentExpertiseContent";
import { Step, StepLabel, Stepper } from "@mui/material";
import AgentExpertise2 from "src/assets/svg/agent-expertise2";
import AgentPersonality2 from "src/assets/svg/agent-personality2";
import * as Auth from "src/services/auth.service";
import { IAgentList } from "./AgentsDashboard";
import {
  CREATE_AGENT_ENDPOINT,
  UPDATE_AGENT_ENDPOINT,
} from "src/utils/endpoint/pmtx.endpoint";
import axios from "axios";
import {
  getAuthorizationPmtx,
  parseJwt,
  checkEmpty,
} from "src/services/util.service";
import { createFolderRequest, getToken } from "src/page/kn/util.service";
import { Controller } from "react-hook-form";
import { useAgentValidationForm } from "./form/AgentsDashboardValidationForm";

interface AgentConfigureProps {
  onClose: () => void;
  isOpen: boolean;
  handleAddAgent: () => void;
  agent?: IAgentList;
}

const defaultData: IAgentList = {
  agentName: "",
  referUserId: "",
  role: "",
  personality: "",
  expertise: [
    {
      flow_id: "",
      expertise_detail: "",
    },
  ],
  lastActive: "",
  statistics: 0,
};

const AgentConfigure: React.FC<AgentConfigureProps> = ({
  onClose,
  isOpen,
  handleAddAgent,
  agent,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  // const [formData, setFormData] = useState<IAgentList>(defaultData);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useAgentValidationForm(currentStep, agent);

  const steps = [
    {
      title: "Profile",
      content: <AgentProfileContent control={control as any} errors={errors} />,
      icon: <AgentProfile width="20" height="20" />,
    },
    {
      title: "Persona",
      content: (
        <AgentPersonalContent control={control as any} errors={errors} />
      ),
      icon: <AgentPersonality2 width="20" height="20" />,
    },
    {
      title: "Expertise",
      content: (
        <AgentExpertiseContent control={control as any} errors={errors} />
      ),
      icon: <AgentExpertise2 width="20" height="20" />,
    },
  ];

  const getUserData = () => {
    let token = getToken();
    let userdata: any = null;
    if (!checkEmpty(token)) {
      userdata = parseJwt(token);
    }
    return userdata;
  };

  const handlerAddAgent = async (values: any) => {
    let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
    try {
      const userData = {
        profile: {
          agent_name: values.agentName,
          refer_user_id: JSON.parse(userFromStorage).user_id + "",
          role: values.role,
          image_url: values.imageUrl || "",
        },
        personality: {
          personality: values.personality,
          commu_style: values.commuStyle || "",
        },
        expertise: values.expertise,
        communication: [
          {
            commu_key: values.commuKey || "",
            commu_contact: values.commuContact || "",
          },
        ],
      };

      const response = await axios.post(CREATE_AGENT_ENDPOINT, userData, {
        headers: {
          Authorization: getAuthorizationPmtx(),
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200 || response.status === 202) {
        console.log("getUserData()?.id ==> ", getUserData()?.user_id);
        console.log("response.data agentName ==> ", response.data.agentName);
        await handleCreateFolder(
          response.data.agentName,
          getUserData()?.user_id
        );
        notification.success({
          message: "The agent created successfully",
          description: "The invitation has been sent to the user.",
        });
        onClose();
        handleAddAgent();
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401) {
        notification.error({
          message: "Authorization Error",
          description: "Please check your login status and try again.",
        });
      } else if (error.response?.status === 400) {
        notification.error({
          message: "Bad Request",
          description: "Invalid data. Please check your inputs and try again.",
        });
      } else if (error.response?.status === 500) {
        notification.error({
          message: "Server Error",
          description:
            "There was an error on the server. Please try again later.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to add agent. Please try again.",
        });
      }
    }
  };

  const handleCreateFolder = async (name: string, userId: string) => {
    try {
      let can = true;
      let folder = await createFolderRequest(
        name,
        "", //fileContext.groupId,
        userId, //fileContext.userId,
        "", //fileContext.parentFolderId,
        false, //fileContext.encryptFolder,
        false, //fileContext.controlVersion,
        "" //fileContext.moduleFileAc,
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = async (values: any, agentId: string) => {
    let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
    try {
      const userData = {
        profile: {
          agent_name: values.agentName,
          refer_user_id: JSON.parse(userFromStorage).user_id + "",
          role: values.role,
          image_url: values.imageUrl || "",
        },
        personality: {
          personality: values.personality,
          commu_style: values.commuStyle || "",
        },
        expertise: values.expertise,
        communication: [
          {
            commu_key: values.commuKey || "",
            commu_contact: values.commuContact || "",
          },
        ],
      };

      const response = await axios.put(
        `${UPDATE_AGENT_ENDPOINT}/${agentId}`,
        userData,
        {
          headers: {
            Authorization: getAuthorizationPmtx(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 202) {
        notification.success({
          message: "The agent updated successfully",
          description: "The agent information has been updated.",
        });
        onClose();
        handleAddAgent(); // This could be renamed to handleRefresh or similar if it handles fetching updated data
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401) {
        notification.error({
          message: "Authorization Error",
          description: "Please check your login status and try again.",
        });
      } else if (error.response?.status === 400) {
        notification.error({
          message: "Bad Request",
          description: "Invalid data. Please check your inputs and try again.",
        });
      } else if (error.response?.status === 500) {
        notification.error({
          message: "Server Error",
          description:
            "There was an error on the server. Please try again later.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to update agent. Please try again.",
        });
      }
    }
  };

  const onSubmit = handleSubmit((data) => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (agent) {
        handleUpdate(data, agent.id as string);
      } else {
        handlerAddAgent(data);
      }
    }
  });

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal
      open={isOpen}
      title={agent ? "Edit Agent" : "Add New Agent"}
      onCancel={onClose}
      footer={null}
      width={window.innerWidth < 768 ? "90%" : "50%"}
      centered
      className="max-w-xl"
    >
      <div className="flex flex-col gap-8 pt-4">
        <div className="hidden sm:block">
          <Stepper
            activeStep={currentStep}
            alternativeLabel
            sx={{
              "& .MuiStepLabel-root .Mui-completed svg": {
                color: "#3b82f6",
                backgroundColor: "#E8F4FF",
                borderRadius: "50%",
                padding: "5px",
              },
              "& .MuiStepLabel-root .Mui-active svg": {
                color: "#E8F4FF",
                backgroundColor: "#3b82f6",
                borderRadius: "50%",
                padding: "5px",
              },
              "& .MuiStepLabel-root svg": {
                color: "#6A6A69",
                backgroundColor: "#F0F0F0",
                borderRadius: "50%",
                padding: "5px",
              },
            }}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel icon={step.icon}>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="block sm:hidden mb-4">
          <div className="flex items-center justify-center space-x-2">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentStep
                    ? "bg-blue-500"
                    : index < currentStep
                      ? "bg-blue-300"
                      : "bg-gray-300"
                }`}
              />
            ))}
          </div>
          <div className="text-center mt-2 text-sm font-medium text-gray-700">
            {steps[currentStep].title}
          </div>
        </div>
        <div className="w-full h-[250px]">
          <div className="h-full overflow-y-auto">
            {steps[currentStep].content}
          </div>
        </div>
        <div className="pt-4 sm:pt-6 border-t flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0">
          <button
            onClick={handleBack}
            className={`w-full sm:w-auto px-4 py-2 text-sm font-medium rounded-md border-none flex justify-center items-center cursor-pointer ${currentStep === 0 ? "invisible" : "text-gray-700 hover:text-gray-900 hover:bg-gray-200"}`}
          >
            <ChevronLeft size={20} />
            Back
          </button>
          <button
            onClick={onSubmit}
            className={`w-full sm:w-auto px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium rounded-md border-none flex justify-center items-center`}
          >
            {currentStep === steps.length - 1 ? (
              <>
                <Check size={20} />
                Finish
              </>
            ) : (
              <>
                Next
                <ChevronRight size={20} />
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default AgentConfigure;
