import React, { useContext, useEffect, useState } from "react";
import { ChevronsLeft } from "lucide-react";
import LogoPromptX from "src/assets/png/PromptX_white.png";
import { useLocation, useNavigate } from "react-router";
import { pathRoutes } from "../props/util.props";
import { GenerateContext } from "../context/Generate.context";
import axios from "axios";
import { apiPath, getUserInfo } from "src/utils/service.util";
import { getAuthorizationPmtx } from "src/services/util.service";
import { AppContext } from "../context/App.context";
import * as Auth from "src/services/auth.service";
import ModalWarning from "./modal/modalWarning";
import { menuItems } from "./components/MenuItems";
import { SidebarDrawerComponent } from "./components/SidebarDrawerComponent";
import { AvatarComponent } from "./components/AvatarComponent";
import { setSidebarTabActive } from "src/store/slices/history/historySlice";
import { useDispatch } from "react-redux";

interface ISidebarMenuProps {
  children?: React.ReactNode;
}

const SidebarMenu: React.FC<ISidebarMenuProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    messageData,
    setMessageData,
    projectName,
    setProjectName,
    uuidApp,
    setUuidApp,
  } = useContext(GenerateContext);
  const appContext: any = useContext(AppContext);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [user, setUser] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    menuItems.forEach((group) => {
      group.items.forEach((item) => {
        if (location.pathname.startsWith(item.path)) {
          setActiveTab(item.id);
        }
      });
    });
  }, [location.pathname]);

  useEffect(() => {
    let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
    let userInfo = JSON.parse(userFromStorage);
    console.log("userFromStorage", userInfo);
    if (userInfo) {
      setUser(userInfo);
    }
  }, []);

  const handleMenuClick = (key: string, path: string) => {
    if (key === "logout") {
      handleClickLogOut();
      return;
    }

    console.log("handleMenuClick e => ", key);
    navigate(path);

    switch (key) {
      case "home":
        gotoFirst();
        break;
      case "workflow":
        navigate(`/${pathRoutes.Generate}/${pathRoutes.Workflow}`);
        break;
      case "dashboard":
        navigate(`/pmtx-dashboard`);
        break;
      case "knowledgeBase":
        navigate(`/${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}`);
        break;
      case "history":
        dispatch(setSidebarTabActive("history"));
        navigate(`/${pathRoutes.Generate}/${pathRoutes.Specifications}`);
        break;
      default:
        break;
    }
    checkTokenApi();
    setActiveTab(key);
  };

  async function checkTokenApi() {
    //@ts-ignore
    let api = window["env"]["REACT_APP_ZERO_URL"];
    try {
      const response = await axios.get(api + apiPath.checkSession, {
        headers: { Authorization: getAuthorizationPmtx() },
      });
      // console.log("Data:", response.data);
      // resolve(response.data);
    } catch (error: any) {
      if (error.response) {
        // If the request was made and the server responded with a status code
        // outside the range of 2xx
        console.error("Error response:", error.response.data);
        console.error("Status code:", error.response.status);

        if (error.response.status === 401) {
          console.error("Unauthorized access - please check your credentials.");
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      // reject(error);
      appContext.setTokenExpire(true);
    }
  }

  let intervalId: any;

  //@ts-ignore
  const timecheckMin = window["env"]["REACT_APP_CHECK_EXPIRE_INTERVAL_MIN"];

  const startInterval = () => {
    let timekMin = parseInt(timecheckMin);
    clearInterval(intervalId);
    intervalId = setInterval(
      () => {
        checkTokenExpires(true);
      },
      timekMin * 60 * 1000
    ); // 5 minutes
  };

  const checkTokenExpires = (reInterval: any) => {
    if (document.visibilityState === "visible") {
      try {
        checkTokenApi();
      } catch (e) {
        console.log(e);
      }
      if (reInterval) startInterval();
    }
  };

  const gotoFirst = () => {
    localStorage.removeItem("projectName");
    localStorage.removeItem("promptId");
    localStorage.removeItem("appMessages");

    setProjectName("");
    setUuidApp("");
    setMessageData(null);

    navigate(`/${pathRoutes.Generate}`);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      checkTokenExpires(true);
    }
  };

  function ClickLogOut() {
    // Clear all auth related items
    localStorage.removeItem(Auth.AuthKeys.openID);
    localStorage.removeItem(Auth.AuthKeys._key_user);
    localStorage.removeItem(Auth.AuthKeys.user);

    // Clear app state
    localStorage.removeItem("projectName");
    localStorage.removeItem("promptId");
    localStorage.removeItem("appMessages");

    // Reset context states
    setProjectName("");
    setUuidApp("");
    setMessageData(null);

    handleCloseModal();
    navigate(pathRoutes.Root);
    window.location.reload(); // Ensure clean reload
  }

  const handleClickLogOut = () => {
    // แสดง modal
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    // ปิด modal
    setIsModalVisible(false);
  };

  useEffect(() => {
    // Execute immediately and start the interval
    console.log("==useEffect sidebar==");
    getUserInfo();
    checkTokenExpires(true);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clear interval and remove event listener on component unmount
    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const renderSidebar = () => {
    return (
      <div className="flex h-screen bg-gray-50 w-16">
        <div
          className={`bg-[#103863] text-white transition-all duration-300 ${
            isNavExpanded ? "w-64" : "w-16"
          } flex flex-col absolute z-[500] h-screen`}
        >
          <div className="p-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={LogoPromptX}
                alt="Logo"
                className="w-8 h-8 cursor-pointer"
                onClick={() => setIsNavExpanded(!isNavExpanded)}
              />
              {isNavExpanded && (
                <span
                  className="ml-3 font-semibold cursor-pointer"
                  onClick={gotoFirst}
                >
                  PromptX
                </span>
              )}
            </div>
            {isNavExpanded && (
              <div
                onClick={() => setIsNavExpanded(!isNavExpanded)}
                className=" text-white cursor-pointer"
              >
                <ChevronsLeft />
              </div>
            )}
          </div>

          <div
            className="flex-1 flex flex-col gap-2 overflow-y-auto "
            style={{ scrollbarWidth: "none" }}
            onMouseEnter={() => setIsNavExpanded(true)}
            onMouseLeave={() => setIsNavExpanded(false)}
          >
            {menuItems.map((group) => (
              <div key={group.group} className="mt-2">
                <div
                  className={`px-4 text-sm  ${isNavExpanded ? "text-gray-400" : "text-transparent"}`}
                >
                  {group.group}
                </div>
                {group.items.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleMenuClick(item.id, item.path)}
                    className={`w-full flex items-center px-5 py-3 transition-colors bg-[#103863] border-none text-white cursor-pointer ${
                      activeTab === item.id ? "bg-[#1861B4]" : "bg-[#103863]"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4">
                      <span className="">{item.icon}</span>
                      {isNavExpanded && (
                        <span className="w-max">{item.label}</span>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            ))}
          </div>

          <AvatarComponent user={user} isNavExpanded={isNavExpanded} />
        </div>
      </div>
    );
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="block md:hidden">
        <SidebarDrawerComponent
          user={user}
          activeTab={activeTab}
          gotoFirst={gotoFirst}
          handleClickLogOut={handleClickLogOut}
          checkTokenApi={checkTokenApi}
        />
      </div>
      <div className="flex h-screen bg-gray-50">
        <div className="hidden md:block">{renderSidebar()}</div>

        {children}

        {isModalVisible && (
          <ModalWarning
            onClose={handleCloseModal}
            open={isModalVisible}
            onLogOut={ClickLogOut}
          />
        )}
      </div>
    </div>
  );
};
export default SidebarMenu;
