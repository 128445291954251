import React, { useContext, useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import styles from "./UIFirstPage.module.scss";
import { useTranslation } from "react-i18next";

import * as Auth from "src/services/auth.service";
import { checkEmpty } from "src/services/util.service";
import LogoLoading from "./LogoLoading";
import * as Props from "../../page/props/util.props";
import { pathRoutes } from "src/page/props/util.props";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { apiPath, callApiGet, getUserInfo } from "src/utils/service.util";

const { Header, Footer, Sider, Content } = Layout;

function UIFirstPage(props: any) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const auth: any = localStorage.getItem(Auth.AuthKeys.openID);
  const parsedAuth = JSON.parse(auth);

  const checkLogin = () => {
    setTimeout(async () => {
      if (parsedAuth?.access_token) {
        try {
          let isExpired = false;
          let user = await getUserInfo();
          if (checkEmpty(user)) {
            isExpired = true;
          }

          if (isExpired) {
            console.log("Token has expired");
            navigate(pathRoutes.Login);
          } else {
            console.log("Token is valid");
            navigate(pathRoutes.Generate);
            // navigate(`${pathRoutes.Generate}/${pathRoutes.Prompt}`);
          }
        } catch (error) {
          console.log("Invalid token format");
          navigate(pathRoutes.Login);
        }
      } else {
        console.log("go to login due to missing token");
        navigate(pathRoutes.Login);
      }
    }, 1200);
  };

  const checkLoginFirst = async () => {
    try {
      const queryString = new URLSearchParams(location.search);
      let tokenUri: any = queryString.get("openID");

      const uriDecoded2 = decodeURIComponent(tokenUri);
      const authData = JSON.parse(uriDecoded2);

      console.log("authData:", authData);
      let token = authData?.access_token;
      let scope = authData?.scope;

      console.log("!checkEmpty(token) => ", !checkEmpty(token));

      if (!checkEmpty(token)) {
        console.log("!checkEmpty(token):", token);
        // if (checkEmpty(sessionStorage.getItem("custom_menu"))) {
        // await loadMenu();
        // }
        // if (scope?.indexOf("google") != -1) {
        //   console.log("login gg");
        //   navigate(pathRoutes.googleLogin + "?access_token=" + token);
        // } else if (scope?.indexOf("microsoft") != -1) {
        //   navigate(pathRoutes.azureLogin + "?access_token=" + token);
        // } else {
        //   console.log("login fb");
        //   navigate(pathRoutes.facebookLogin + "?access_token=" + token);
        // }
      } else {
        checkLogin();
      }
    } catch (e) {
      console.error(e);
      checkLogin();
    }
  };

  useEffect(() => {
    checkLoginFirst();
  }, [auth]);

  return (
    <Layout>
      <Content>
        <Row
          style={{
            height: "100vh",
            fontSize: "20px",
          }}
        >
          <Col className={styles.boxIcon} style={{ width: "100%" }}>
            <div className={styles.boxIconCenter}>
              <LogoLoading {...props} />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default UIFirstPage;
