import React, {useContext, useEffect, useState} from "react";

import styles from "./UIModalShare.module.scss";
import {useTranslation} from "react-i18next";
import selectedItemShare from "src/assets/png/selectedItemShare.png";
//@ts-ignore
import _, {assign, fill} from "lodash";
import defaultNoimg from "src/assets/png/No_Img.png";

import {FileContext, FileContextProps} from "../File.context";
import {Spin} from "antd";
import {Modal, Button} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import useFetch, {CachePolicies} from "use-http";
import Skeleton from "react-loading-skeleton";
import { CENTER_URL_MAIN, getFileObj } from "../../util.service";
import ButtonCenterCLose from "../ButtonCenterClose";
import { checkEmpty } from "src/services/util.service";
import CenterButton from "src/shared/button/Button";

const ShareContext = React.createContext({});

const ShareProviderContext = (props: any) => {
  const [stampGroupsSelected, setStampGroupsSelected] = useState([]);
  const [stampUsersSelected, setStampUsersSelected] = useState([]);
  const state = {
    stampGroupsSelected: stampGroupsSelected,
    setStampGroupsSelected: setStampGroupsSelected,
    stampUsersSelected: stampUsersSelected,
    setStampUsersSelected: setStampUsersSelected,
  };
  return (
    <ShareContext.Provider value={state}>
      {props.children}
    </ShareContext.Provider>
  );
};
//@ts-ignore
ShareProviderContext.Consumer = ShareContext.Consumer;

let limit = 30;
function UIModalShareContainer(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const shareContext: any = useContext(ShareContext);
  const [sharing, setSharing]: any = useState(false);
  const [users, setUsers]: any = useState([]);
  const [groups, setGroups]: any = useState([]);
  const [totalUser, setTotalUser]: any = useState(0);
  const [totalGroup, setTotalGroup]: any = useState(0);

  const [offsetUser, setOffsetUser] = useState(0);
  const [offsetGroup, setOffsetGroup] = useState(0);
  const [search, setSearch] = useState("");
  const [messageNotiShareGroup, setMessageNotiShareGroup]:any = useState(
    t("Not found group")
  );
  const [messageNotiShareUser, setMessageNotiShareUser] :any= useState(
    t("Not found user")
  );
  const [loadmoreUser, setLoadmoreUser]: any = useState(false);
  const [loadmoreGroup, setLoadmoreGroup]: any = useState(false);
  const [showNotiShare, setShowNotiShare] = useState(false);
  const [messageNotiShare, setMessageNotiShare] = useState("");

  const [canShare, setCanShare]: any = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  useEffect(() => {
    if (
      shareContext.stampGroupsSelected?.length > 0 ||
      shareContext.stampUsersSelected?.length > 0
    ) {
      setCanShare(true);
    } else setCanShare(false);
  }, [shareContext.stampGroupsSelected, shareContext.stampUsersSelected]);

  useEffect(() => {
    setCanShare(false);
    shareContext.setStampGroupsSelected([]);
    shareContext.setStampUsersSelected([]);
    if (fileContext.showShareModal) {
      fectDataFileSupportDir();
    }
  }, [fileContext.showShareModal]);

  const fectDataFileSupportDir = async () => {
    try {
      let file = await getFileObj(
        fileContext.userId,
        fileContext.groupId,
        fileContext.fileSelected
      );
      fileContext.setFileSelected(file);
    } catch (e) {
      console.error(e);
    }
  };

  const getUsers = useFetch(CENTER_URL_MAIN, {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const getGroups = useFetch(CENTER_URL_MAIN, {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  useEffect(() => {
    if (fileContext.showShareModal) {
      fectDataGroup();
      fectDataUser();
    }
  }, [fileContext.showShareModal, search]);

  useEffect(() => {
    if (fileContext.showShareModal) {
      fectDataGroupLoadMore();
    }
  }, [offsetGroup]);
  useEffect(() => {
    if (fileContext.showShareModal) {
      fectDataUserLoadMore();
    }
  }, [offsetUser]);

  const fectDataGroup = async () => {
    await getGroups.get(
      `/api/v2/groups/?search=${search}&limit=${limit}&offset=${offsetGroup}`
    );
    setGroups(getGroups.response?.data?.groups);
    setTotalGroup(getGroups.response?.data?.total);
  };

  const fectDataUser = async () => {
    await getUsers.get(
      `/api/v1/users/friends?search=${search}&limit=${limit}&offset=${offsetUser}`
    );
    setUsers(getUsers.response?.data?.data);
    setTotalUser(getUsers.response?.data?.total);
  };

  const fectDataGroupLoadMore = async () => {
    try {
      setLoadmoreGroup(true);
      await getGroups.get(
        `/api/v2/groups/?search=${search}&limit=${limit}&offset=${offsetGroup}`
      );
      let groups_t = Object.assign([], groups);
      groups_t = groups_t.concat(getGroups.response?.data?.groups);
      setGroups(groups_t);
      setTotalGroup(getGroups.response?.data?.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadmoreGroup(false);
    }
  };

  const fectDataUserLoadMore = async () => {
    try {
      setLoadmoreUser(true);
      await getUsers.get(
        `/api/v1/users/friends?search=${search}&limit=${limit}&offset=${offsetUser}`
      );
      let users_t = Object.assign([], users);
      users_t = users_t.concat(getUsers.response?.data?.data);
      setUsers(users_t);
      setTotalUser(getUsers.response?.data?.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadmoreUser(false);
    }
  };

  const canSelectToShare = async (user: any, group: any) => {
    // let resGroupDesc: any = await getGroupDesc(user?.friendID, group?.id);

    // let groupFullSize = resGroupDesc.groupFullSize;
    // let groupId = resGroupDesc.groupId;
    // let groupUsedSize = resGroupDesc.groupUsedSize;
    // let groupFreeSize = calFreeSize(groupUsedSize, groupFullSize);
    // let fileType = t("file");
    // let name = "";
    // if (!checkEmpty(user)) name = getNameOfUser(user);
    // if (!checkEmpty(group)) name = group.name;
    // if (fileContext.fileSelected.type_ === "DIRECTORY") fileType = t("folder");
    // console.log(fileContext.fileSelected?.size_ + " > " + groupFreeSize);
    // if (fileContext.fileSelected?.size_ > groupFreeSize) {
    //   setMessageNotiShare(
    //     t(
    //       "Failed to share {type} because {name} doues not have enough storage."
    //     )
    //       .replace("{type}", fileType)
    //       .replace("{name}", name)
    //   );
    //   setShowNotiShare(true);
    //   return false;
    // } else return true;
  };

  const handleClickSelectUser = async (user: any) => {
    // try {
    //   let canSelect = await canSelectToShare(user, null);
    //   if (!canSelect) {
    //     return;
    //   }
    //   let s_users: any = shareContext.stampUsersSelected;
    //   let i = s_users.findIndex((item: any) => {
    //     return item.friendID === user.friendID;
    //   });
    //   if (i === -1) {
    //     s_users.push(user);
    //   } else {
    //     s_users.splice(i, 1);
    //   }
    //   shareContext.setStampUsersSelected(Object.assign([], s_users));
    // } catch (e) {
    //   console.error(e);
    // }
  };

  const handleClickSelectGroup = async (group: any) => {
    // try {
    //   let canSelect = await canSelectToShare(null, group);
    //   if (!canSelect) {
    //     return;
    //   }
    //   let s_groups: any = shareContext.stampGroupsSelected;
    //   let i = s_groups.findIndex((item: any) => {
    //     return item.id === group.id;
    //   });
    //   if (i === -1) {
    //     s_groups.push(group);
    //   } else {
    //     s_groups.splice(i, 1);
    //   }
    //   shareContext.setStampGroupsSelected(Object.assign([], s_groups));
    // } catch (e) {
    //   console.error(e);
    // }
  };

  const handleClickMoreUser = () => {
    setOffsetUser(offsetUser + limit);
  };
  const handleClickMoreGroup = () => {
    setOffsetGroup(offsetGroup + limit);
  };
  const handleClickShare = async () => {
    // if (canShare) {
    //   let groupsToShare = Object.assign([], shareContext.stampGroupsSelected);
    //   let usersToShare = Object.assign([], shareContext.stampUsersSelected);
    //   for (let a = 0; a < groupsToShare.length; a++) {
    //     groupsToShare[a].groupId = groupsToShare[a].id;
    //     groupsToShare[a].groupName = groupsToShare[a].name;
    //   }
    //   for (let a = 0; a < usersToShare.length; a++) {
    //     usersToShare[a].userId = usersToShare[a].friendID;
    //   }
    //   try {
    //     setSharing(true);
    //     await shareFileToGroup(
    //       fileContext.groupId,
    //       fileContext.userId,
    //       groupsToShare,
    //       usersToShare,
    //       fileContext.fileSelected
    //     );
    //     shareContext.setStampGroupsSelected([]);
    //     shareContext.setStampUsersSelected([]);
    //     fileContext.setShowShareModal(false);
    //     setSearch("");
    //   } catch (e) {
    //     console.error(e);
    //   } finally {
    //     setSharing(false);
    //   }
    // }
  };

  return (
    <>
      <Modal
        centered
        visible={showNotiShare}
        className={styles.modalOverideNotSelect}
        onCancel={() => {
          setShowNotiShare(false);
        }}
      >
        <div
          onClick={() => {
            setShowNotiShare(false);
          }}
          className={styles.closeBtn}
          // style={{ backgroundImage: `url(${crossIcon})` }}
        >
          <ButtonCenterCLose></ButtonCenterCLose>
        </div>
        <div className={styles.boxTextAlert}>
          <div style={{ fontSize: "1.15rem" }}>{messageNotiShare}</div>
          <div style={{ textAlign: "center" }}>
            <button
              className={
                canShare ? styles.bottonBtn : styles.bottonBtnNotActive
              }
              style={{ fontSize: "1.25rem", marginTop: "39px" }}
              onClick={() => {
                setShowNotiShare(false);
              }}
            >
              <div>{t("Ok")}</div>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        visible={fileContext.showShareModal}
        className={styles.modalOveride}
        onCancel={() => {
          fileContext.setShowShareModal(false);
          setSearch("");
        }}
      >
        <div
          onClick={() => {
            fileContext.setShowShareModal(false);
            setSearch("");
          }}
          className={styles.closeBtn}
          // style={{ backgroundImage: `url(${crossIcon})` }}
        >
          {" "}
          <ButtonCenterCLose></ButtonCenterCLose>
        </div>
        <div className={styles.boxCreate}>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            {t("Share file to")}...
          </div>

          <div style={{ fontSize: "1rem", marginTop: "20px" }}>
            <input
              style={{ fontSize: "1rem" }}
              value={search}
              type="text"
              placeholder={t("search")}
              className={styles.boxCreateInput}
              onChange={(event) => {
                let v = event.target.value;
                setSearch(v.trim());
              }}
            />
          </div>

          <div className={styles.boxListUserGroup}>
            <div className={styles.boxListTitle}>{t("Groups")}</div>
            <div className={styles.boxList}>
              {getGroups.loading && !loadmoreGroup ? (
                <Skeleton count={4} height={40} style={{}} />
              ) : (
                //@ts-ignore
                groups?.map((group, idx) => {
                  return (
                    <UICardGroup
                      handleClickSelectGroup={handleClickSelectGroup}
                      group={group}
                      idx={idx}
                      key={group?.id}
                    />
                  );
                })
              )}
            </div>
            {groups?.length === 0 ||
              (checkEmpty(groups) && <div>{messageNotiShareGroup}</div>)}
            {groups?.length < totalGroup && (
              <div className={styles.loadmore} onClick={handleClickMoreGroup}>
                <div className={styles.loadmoreBtn}>
                  {loadmoreGroup ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    t("Load More")
                  )}
                </div>
              </div>
            )}
            <div className={styles.boxListTitle}>{t("Friends")}</div>
            <div className={styles.boxListUser}>
              {getUsers.loading && !loadmoreUser ? (
                <Skeleton count={4} height={40} style={{}} />
              ) : (
                //@ts-ignore
                users?.map((user, idx) => {
                  return (
                    <UICardUser
                      handleClickSelectUser={handleClickSelectUser}
                      user={user}
                      idx={idx}
                      key={user?.friendID}
                    />
                  );
                })
              )}
            </div>
            {users?.length === 0 && <div>{messageNotiShareUser}</div>}
            {users?.length < totalUser && (
              <div className={styles.loadmore}>
                <div
                  className={styles.loadmoreBtn}
                  onClick={handleClickMoreUser}
                >
                  {loadmoreUser ? <Spin indicator={antIcon} /> : t("Load More")}
                </div>
              </div>
            )}
          </div>
          {canShare ? (
            <div
              className={
                canShare ? styles.bottonBtn : styles.bottonBtnNotActive
              }
              style={{ fontSize: "1.2rem", marginTop: "25px" }}
              onClick={handleClickShare}
            >
              {/* <CenterButton> */}
              <Button>
                <div>{sharing ? <Spin indicator={antIcon} /> : t("Share")}</div>
              </Button>
            </div>
          ) : (
            <div
              className={
                canShare ? styles.bottonBtn : styles.bottonBtnNotActive
              }
              style={{ fontSize: "1.2rem", marginTop: "25px" }}
              onClick={handleClickShare}
            >
              {/* <CenterButton ghost> */}
              <Button>
                <div>{sharing ? <Spin indicator={antIcon} /> : t("Share")}</div>
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

function UICardGroup(props: any) {
  const fileContext: any = useContext(FileContext);
  const shareContext: any = useContext(ShareContext);
  const [showSelectItem, setShowSelectItem]: any = useState(false);
  const checkSelectGroup = () => {
    let i = shareContext.stampGroupsSelected.findIndex((item: any) => {
      return item.id === props.group?.id;
    });
    if (i === -1) {
      setShowSelectItem(false);
    } else {
      setShowSelectItem(true);
    }
  };
  useEffect(() => {
    checkSelectGroup();
  }, [shareContext.stampGroupsSelected]);
  return (
    <div
      key={props.group?.id}
      className={styles.itemgroup}
      onClick={() => {
        props.handleClickSelectGroup(props.group);
      }}
    >
      <div style={{ maxWidth: "55px" }}>
        <div
          className={styles.selectedItemShareBox}
          style={{
            display: showSelectItem ? "block" : "none",
          }}
        >
          <img
            src={selectedItemShare}
            className={styles.selectedItemShareGroup}
          ></img>
        </div>
        <div
          style={{
            backgroundImage: `url(${(props.group?.coverImgURL) ? props.group?.coverImgURL : defaultNoimg})`,
            backgroundSize: "cover",
            // backgroundColor: "#bcbcbc",
            width: 55,
            height: 55,
            borderRadius: "8px",
          }}
        ></div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <div className={styles.textOverflowListFileText}>
          <div className={styles.textItem}>{props.group.name}</div>
        </div>
      </div>
    </div>
  );
}

function UICardUser(props: any) {
  const fileContext: any = useContext(FileContext);
  const shareContext: any = useContext(ShareContext);
  const [showSelectItem, setShowSelectItem]: any = useState(false);

  const checkSelectUser = () => {
    let i = shareContext.stampUsersSelected.findIndex((item: any) => {
      return item.friendID === props.user?.friendID;
    });

    if (i === -1) {
      setShowSelectItem(false);
    } else {
      setShowSelectItem(true);
    }
  };
  useEffect(() => {
    checkSelectUser();
  }, [shareContext.stampUsersSelected]);

  return (
    <div
      key={props.user?.friendID}
      className={styles.itemuser}
      onClick={() => {
        props.handleClickSelectUser(props.user);
      }}

      // style={{ backgroundColor: checkSelectFolder() ? "#f7f7f7" : "white" }}
    >
      <div style={{}}>

        <div
          style={{
            backgroundImage: `url(${props.user?.friend?.imageProfileURL})`,
            backgroundSize: "cover",
            width: 50,
            height: 50,
            borderRadius: "33px",
            margin: "0 auto",
            position: "relative"
          }}
        >
          <img
            src={selectedItemShare}
            className={styles.selectedItemShareUser}
            style={{
              display: showSelectItem ? "block" : "none",
            }}
            alt=""
          ></img>
        </div>
      </div>
      <div style={{ width: "100%",marginTop:"12px" }}>
        <div className={styles.textOverflowListFileText} >
          {/* <div className={styles.textItemUser}>{getNameOfUser(props.user)}</div> */}
          <div className={styles.textItemUser}>{props.user?.friend.firstname}</div>
          <div className={styles.textItemUser}>{props.user?.friend.lastname}</div>
        </div>
      </div>
    </div>
  );
}

function UIModalShare(props: any) {
  return (
    <ShareProviderContext>
      <UIModalShareContainer />
    </ShareProviderContext>
  );
}

export default UIModalShare;
