import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
//@ts-ignore
import _ from "lodash";
import {FileContext, FileContextProps} from "../File.context";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import { APIFileEnpoint, deleteRequest, getAboutLimitMain, getFileFoldersMethodGet, KN_FILE_SERVICE_URL_MAIN, mainTab, reflectorFileFoldersDelete } from "../../util.service";
import { checkEmpty, getToken, parseJwt } from "src/services/util.service";
import CenterConfirmModal from "../Modal/CenterConfirmModal";

const defaultLitmitOfset = 30;
let linkFiles = KN_FILE_SERVICE_URL_MAIN + APIFileEnpoint.getFiles;

function UIModalConfrimDelete(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [loading, setLoading]: any = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );


  
  const getAboutLimit = async () => {
    try {
      let data = await getAboutLimitMain({
        userId: fileContext.userId,
        groupId: fileContext.groupId,
      });
      fileContext.setAboutLimit(data);
      console.log("getAboutLimit : ", data);
    } catch (e) {
      console.error(e);
    }
  };

  const getMessageDelete = () => {
    let typetemp = t("Folder");
    if (fileContext.fileSelected?.type_ !== "DIRECTORY") {
      typetemp = t("File");
    }
    let v = t("Are you sure to delete this {type}").replace(
      "{type}",
      typetemp
    )+"!";

    return v;
  };

  const getUserData = () => {
    let token = getToken();
    let userdata: any = null;
    if (!checkEmpty(token)) {
      userdata = parseJwt(token);
    }
    return userdata;
  };

  const fetchFileRecentData = async () => {
    // fileContext.setRecentFiles([]);
    if (
      fileContext.viewDir === "y" ||
      !checkEmpty(fileContext.parentFolderId)
    ) {
      return;
    }

    let param = {
      groupId: fileContext.groupId,
      userId: getUserData()?.userID,
      offset: 0,
      limitOfset: defaultLitmitOfset,
      parentFolderId: fileContext.parentFolderId,
      tag: fileContext.tab,
    };
    try {
      let response: any = await getFileFoldersMethodGet(
        param,
        linkFiles,
        fileContext
      );

      let ts = [];
      let files: any = response?.data?.data;
      let ls = files?.filter((item: any) => {
        return item.type_ === "FILE" || item.type_ === "LINK";
      });
      for (let a = 0; a < ls?.length && a < 4; a++) {
        ts.push(ls[a]);
      }
      fileContext.setRecentFiles(ts);
    } catch (e) {
      console.error(e);
      // setLoading(false);
    }

  };

  async function setFolderDelete(res: any, type: string) {
    let files_ = fileContext.fileFolders;
    if (type === "DIRECTORY") files_ = fileContext.fileFolders;
    let { files, newLenght } = reflectorFileFoldersDelete(files_, type, res);

    if (type === "DIRECTORY") fileContext.setFolders(files);
    else fileContext.setFiles(files);

    if (type === "FILE" || (type === 'LINK' && fileContext.tab !== mainTab.links)) {
      // let { files, newLenght } = reflectorFileFoldersDelete(
      //   fileContext.recentFiles,
      //   type,
      //   res
      // );
      // console.log("files : ", files);
      // fileContext.setRecentFiles((prev: any)=>{
      //   let i = prev.findIndex((item: any) => {
      //     return item.file_app_id === res.file_app_id;
      //   });
      //   if (i != -1) {
      //     prev.splice(i, 1);
      //   }
      //   return [...prev];
      // });
      fetchFileRecentData();
    }
    console.log("delete link : ", type)
    console.log("fileContext.tab : ", fileContext.tab)
    if(type === "LINK" && fileContext.tab === mainTab.links){
      // console.log("delete link ")
      // console.log("fileContext.tab : ", fileContext.tab)
      fileContext.setIsDeleteLink(true)
    }
  }

  const fnDoneDeleteForever = async () => {
    if (loading === true) return;
    try {
      setLoading(true);
      let res: any = await deleteRequest(fileContext.fileSelected);

      let file = Object.assign({}, fileContext.fileSelected);
      let p1 = fileContext.currentFolder?.file_app_id;
      let p2 = fileContext.fileSelected?.parent_folder_id;
      setFolderDelete(file, file.type_);
      fileContext.setDisplayConfirmDelete(false);

      getAboutLimit()
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const [messageNoti, setMessageNoti]: any = useState(getMessageDelete());

  return (
    // <Modal
    //   // centered
    //   visible={fileContext.displayConfirmDelete}
    //   className={styles.modalOveride}
    //   onCancel={() => {
    //     fileContext.setDisplayConfirmDelete(false);
    //   }}
    //   closeIcon={<div />}
    //   closable={false}
    // >
    //   <div className={styles.boxCreate}>
    //     <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
    //       {t("Delete file")}
    //     </div>

    //     <div style={{ fontSize: "1rem", color: "#5f5f5f" }}>{messageNoti}</div>

    //     <div className={styles.boxConfirmBtn}>
    //       <button
    //         className={styles.bottonBtnCancel}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div
    //           onClick={() => {
    //             if(loading === false)
    //             fileContext.setDisplayConfirmDelete(false);
    //           }}
    //         >
    //           {t("Cancel")}
    //         </div>
    //       </button>
    //       <button
    //         className={styles.bottonBtnOk}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div onClick={fnDoneDeleteForever}>
    //           {loading ? <Spin indicator={antIcon} /> : t("Ok")}
    //         </div>
    //       </button>
    //     </div>
    //   </div>
    // </Modal>

    <CenterConfirmModal
      cancelText={t("Cancel")}
      confirmText={loading ? <Spin indicator={antIcon} /> : t("Ok")}
      text={messageNoti}
      visible={fileContext.displayConfirmDelete}
      onCancel={() => {
        if (loading === false) fileContext.setDisplayConfirmDelete(false);
      }}
      onConfirm={() => {
        fnDoneDeleteForever();
      }}
    ></CenterConfirmModal>
  );
}

export default UIModalConfrimDelete;
