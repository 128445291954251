import { Button, Card, Input, Select, Modal, message, Layout } from 'antd';
import { useFormik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { plansSelector } from 'src/store/slices/payments/plansSlice';
import { useAppDispatch } from 'src/store/store';
import * as Yup from 'yup';
import { mapPlanItem } from './SubscriptionPage';
import { handelerContactSalesTeam } from 'src/store/actions/payment/contactSaleAction';
import { ContactSaleReq } from 'src/model/contact-sale';
import { contactSelector } from 'src/store/slices/payments/contactSlice';
import { motion } from "framer-motion";


const { TextArea } = Input;
const { Content } = Layout;

const validationSchema = Yup.object({
    fullName: Yup.string()
        .required('Full name is required')
        .min(2, 'Name must be at least 2 characters'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Work email is required')
        .matches(/@[^@]+$/, 'Must be a valid work email'),
    company: Yup.string()
        .required('Company name is required')
        .min(2, 'Company name must be at least 2 characters'),
    phone: Yup.string()
        .required('Phone number is required')
        .matches(/^[0-9+\-() ]+$/, 'Invalid phone number format'),
    companySize: Yup.string()
        .required('Please select your company size'),
    // message: Yup.string()
    //     .required('Message is required')
    //     .min(10, 'Message must be at least 10 characters')
});

 //@ts-ignore
 let marketingEmail = window["env"]["MARKETING_EMAIL"];

const ContractSale: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { selectPlan } = useSelector(plansSelector);
    const { response, loading, error, errorMsg } = useSelector(contactSelector);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            company: '',
            phone: '',
            companySize: '',
            message: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            let newValue: ContactSaleReq = {
                full_name: values.fullName,
                work_email: values.email,
                phone_number: values.phone,
                company_name: values.company,
                company_size: values.companySize,
                message: values.message,
                product_id: selectPlan?.prod_id
            }

            try {
                await dispatch(handelerContactSalesTeam(newValue));
                if (!error) {
                    message.success('Your request has been submitted successfully');
                    formik.resetForm();
                } else {
                    Modal.error({
                        title: 'Error',
                        content: errorMsg || 'Something went wrong. Please try again.',
                    });
                }
            } catch (err) {
                Modal.error({
                    title: 'Error',
                    content: 'Failed to submit form. Please try again.',
                });
            }
        }
    });

    return (
        <Layout style={{ height: '100%', overflow: 'hidden' }}>
            <div className="dashboard-header">
                <h2 className="dashboard-title">Contract Sales</h2>
            </div>
            <Content>
                <motion.div
                    className="flex-1 px-6 py-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                        overflowY: "auto",
                        scrollBehavior: "smooth",
                        height: "100vh",
                        backgroundColor: "#ffffff",
                        paddingBottom: "100px"
                    }}
                >
                    <div className="max-w-7xl mx-auto">
                        <div className="text-center mb-8">
                            <h1 className="text-3xl font-bold text-gray-900">Contract our sales teams</h1>
                            <p className="text-lg text-gray-600 mt-2">Get in touch with our expert sales team</p>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                            {/* Form Section */}
                            <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-10 rounded-lg shadow-lg">
                                <form onSubmit={formik.handleSubmit} className="space-y-6">
                                    <div className="grid grid-cols-2 gap-6">
                                        {/* Name and Email Fields */}
                                        <div className="col-span-2 md:col-span-1">
                                            <label className="block text-sm font-semibold mb-2">
                                                Full Name <span className="text-red-500">*</span>
                                            </label>
                                            <Input
                                                name="fullName"
                                                size="large"
                                                placeholder="Enter your name"
                                                value={formik.values.fullName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                status={formik.touched.fullName && formik.errors.fullName ? 'error' : ''}
                                                className="w-full"
                                            />
                                            {formik.touched.fullName && formik.errors.fullName && (
                                                <div className="text-red-500 text-sm mt-1">{formik.errors.fullName}</div>
                                            )}
                                        </div>

                                        <div className="col-span-2 md:col-span-1">
                                            <label className="block text-sm font-semibold mb-2">
                                                Work Email <span className="text-red-500">*</span>
                                            </label>
                                            <Input
                                                name="email"
                                                type="email"
                                                size="large"
                                                placeholder="Enter your email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                status={formik.touched.email && formik.errors.email ? 'error' : ''}
                                                className="w-full"
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-medium mb-2">
                                                Company <span className="text-red-500">*</span>
                                            </label>
                                            <Input
                                                name="company"
                                                size="large"
                                                placeholder="Your company name"
                                                value={formik.values.company}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                status={formik.touched.company && formik.errors.company ? 'error' : ''}
                                            />
                                            {formik.touched.company && formik.errors.company && (
                                                <div className="text-red-500 text-sm mt-1">{formik.errors.company}</div>
                                            )}
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-2">
                                                Phone <span className="text-red-500">*</span>
                                            </label>                                        <Input
                                                name="phone"
                                                size="large"
                                                placeholder="Your phone number"
                                                value={formik.values.phone}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                status={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                                            />
                                            {formik.touched.phone && formik.errors.phone && (
                                                <div className="text-red-500 text-sm mt-1">{formik.errors.phone}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-2">
                                            Company Size <span className="text-red-500">*</span>
                                        </label>
                                        <Select
                                            size="large"
                                            className="w-full"
                                            placeholder="Select company size"
                                            value={formik.values.companySize}
                                            onChange={(value) => formik.setFieldValue('companySize', value)}
                                            onBlur={() => formik.setFieldTouched('companySize', true)}
                                            status={formik.touched.companySize && formik.errors.companySize ? 'error' : undefined}
                                            options={[
                                                { value: 'micro', label: '1-10 persons' },
                                                { value: 'small', label: '11-50 persons' },
                                                { value: 'medium', label: '51-200 persons' },
                                                { value: 'large', label: '201-1000 persons' },
                                                { value: 'enterprise', label: '1000+ persons' }
                                            ]}
                                        />
                                        {formik.touched.companySize && formik.errors.companySize && (
                                            <div className="text-red-500 text-sm mt-1">{formik.errors.companySize}</div>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium mb-2">Message (optional)</label>
                                        <TextArea
                                            name="message"
                                            rows={4}
                                            placeholder="Tell us about your needs"
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            status={formik.touched.message && formik.errors.message ? 'error' : ''}
                                        />
                                        {formik.touched.message && formik.errors.message && (
                                            <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
                                        )}
                                    </div>

                                    <Button
                                        type="primary"
                                        size="large"
                                        className="w-full"
                                        htmlType="submit"
                                        loading={loading}
                                        disabled={
                                            !formik.isValid ||
                                            formik.isSubmitting ||
                                            !formik.values.fullName ||
                                            !formik.values.email ||
                                            !formik.values.company ||
                                            !formik.values.phone ||
                                            !formik.values.companySize
                                        }
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </div>

                            {/* Features Card Section */}
                            {/* Features Card Section */}
                            {selectPlan?.metadata && (
                                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-8 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-bold mb-6">Plan Features</h3>
                                    <ul className="space-y-4">
                                        {Object.entries(selectPlan.metadata).map(([key, value]) => (
                                            <li key={key} className="flex items-center justify-between">
                                                <div className="flex items-center gap-3">
                                                    <span className="flex-shrink-0 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center">
                                                        <span className="text-white text-sm">✓</span>
                                                    </span>
                                                    <span className="text-gray-700 font-medium">{mapPlanItem.get(key)}</span>
                                                </div>
                                                <span className="text-gray-700">{value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="mt-8 pt-6 border-t border-gray-200">
                                        <h4 className="text-lg font-semibold mb-3">Contact Us</h4>
                                        <div className="text-gray-700">
                                            <div>Phone: +66 2 633 9367</div>
                                            <div>Email: <a href={marketingEmail} className="text-blue-600 hover:text-blue-800">{marketingEmail}</a></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </motion.div>
            </Content>
        </Layout>
    );
};

export default ContractSale;
