import React, { useState } from "react";
import { Drawer } from "@mui/material";
import LogoPromptX from "src/assets/png/PromptX_white.png";
import { menuItems } from "./MenuItems";
import { useNavigate } from "react-router";
import { pathRoutes } from "src/page/props/util.props";
import { AvatarComponent } from "./AvatarComponent";

interface SidebarDrawerProps {
  user: any;
  activeTab: string;
  handleClickLogOut: () => void;
  gotoFirst: () => void;
  checkTokenApi: () => void;
}

export const SidebarDrawerComponent: React.FC<SidebarDrawerProps> = ({
  user,
  activeTab,
  handleClickLogOut,
  gotoFirst,
  checkTokenApi,
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenDrawer = () => {
    setMenuOpen(true);
  };

  const handleCloseDrawer = () => {
    setMenuOpen(false);
  };

  const handleMenuClick = (key: string, path: string) => {
    if (key === "logout") {
      handleClickLogOut();
      return;
    }
    navigate(path);

    switch (key) {
      case "home":
        gotoFirst();
        break;
      case "workflow":
        navigate(`/${pathRoutes.Generate}/${pathRoutes.Workflow}`);
        break;
      case "dashboard":
        navigate(`/pmtx-dashboard`);
        break;
      case "knowledgeBase":
        navigate(`/${pathRoutes.Generate}/${pathRoutes.KnowledgeBase}`);
        break;
      default:
        break;
    }
    checkTokenApi();
    handleCloseDrawer();
  };

  return (
    <React.Fragment>
      <div className="p-4 flex md:hidden items-center justify-between sticky bg-transparent">
        <div
          className="flex items-center cursor-pointer"
          onClick={handleOpenDrawer}
        >
          <img src={LogoPromptX} alt="Logo" className="w-8 h-8" />
          <span className="ml-3 font-semibold" onClick={gotoFirst}>
            PromptX
          </span>
        </div>
      </div>
      <Drawer
        anchor="left"
        ModalProps={{
          style: { zIndex: 1000 },
        }}
        open={menuOpen}
        onClose={handleCloseDrawer}
      >
        <div
          className="flex-1 overflow-y-auto bg-[#103863] w-64"
          style={{ scrollbarWidth: "none" }}
        >
          <div className="p-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={LogoPromptX}
                alt="Logo"
                className="w-8 h-8 cursor-pointer"
              />
              <span className="ml-3 font-semibold text-white">PromptX</span>
            </div>
          </div>
          {menuItems.map((group) => (
            <div key={group.group} className="mt-6">
              <div className="px-4 py-2 text-sm text-gray-400">
                {group.group}
              </div>
              {group.items.map((item) => (
                <button
                  key={item.id}
                  onClick={() => handleMenuClick(item.id, item.path)}
                  className={`w-full flex items-center px-5 py-3 transition-colors bg-[#103863] border-none text-white cursor-pointer ${
                    activeTab === item.id ? "bg-[#1861B4]" : "bg-[#103863]"
                  }`}
                >
                  <span className="w-8">{item.icon}</span>
                  <span className="ml-2">{item.label}</span>
                </button>
              ))}
            </div>
          ))}
          <AvatarComponent user={user} isMobile={true} />
        </div>
      </Drawer>
    </React.Fragment>
  );
};
