import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { plansSelector } from 'src/store/slices/payments/plansSlice';
import styles from './PaymentSummary.module.scss';
import React from 'react';

const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const currencyFormat = (price: number) =>
    Intl.NumberFormat("en-EN", { style: "currency", currency: "USD" }).format(price / 100);

export const PaymentSummary = () => {
    const { selectPlan } = useSelector(plansSelector);

    const SummaryArr = [
        {
            label: "Package",
            value: (
                <span className={styles.summaryPackage}>
                    {capitalizeFirstLetter(selectPlan?.name ?? "")} Plan ({selectPlan?.metadata.number_of_apps} Apps)
                </span>
            ),
        },
        {
            label: "Billing Cycle",
            value: (
                <span className={styles.summaryDetail}>
                    Monthly
                </span>
            ),
        },
        {
            label: "Transtions",
            value: (
                <span className={styles.summaryDetail}>
                    {currencyFormat(selectPlan?.monthly_prices.unit_amount ?? 0)}
                </span>
            ),
        },
        {
            label: "Discount for points",
            value: <span className={styles.summaryDetail}>{currencyFormat(0)}</span>,
        },
        {
            label: "VAT 7%",
            value: <span className={styles.summaryDetail}>{currencyFormat(0)}</span>,
        },
        {
            label: "Total price",
            value: (
                <span className={styles.summaryTotal}>
                    {currencyFormat(selectPlan?.monthly_prices.unit_amount ?? 0)}
                </span>
            ),
        },
    ];

    return (
        <Card className={styles.cardContainer}>
            <CardContent>
                <Typography variant="h6" pb={2}>Summary</Typography>
                <Stack spacing={1.5}>
                    {SummaryArr.slice(0, 5).map((item, index) => (
                        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" key={index}>
                            <span className={styles.desc}>{item.label}</span>
                            {item.value}
                        </Stack>
                    ))}
                    <Divider />
                    {SummaryArr.slice(5, 6).map((item, index) => (
                        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" key={index}>
                            <Typography>{item.label}</Typography>
                            <Typography>{item.value}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
};
