import React from "react";
import classNames from "classnames";
import {useInView} from "react-intersection-observer";
import defaultPlaceholder from "./placeholder.png";
import defaultNoimg from "./No_Img.png";

export class BgImgPreload extends React.PureComponent {
  constructor(props) {
    super(props);
    const { src } = this.props;
    this.state = {
      loading: src ? true : false,
    };
  }
  componentDidMount() {
    this.placeholder = this.props.placeholder || defaultPlaceholder;
    this.forceUpdate();
  }
  componentDidUpdate(prevProps, prevState) {
    const { src } = this.props;
    if (src !== prevProps.src && src) {
      this.img = new Image();
      this.img.onload = this.handleLoad;
      this.img.onerror = this.handleError;
      this.img.src = src;
      this.setState({ loading: true });
    }
  }

  componentWillUnmount() {
    if (this.img && this.img.src) {
      this.img = null;
    }
  }
  handleError = () => {
    this.setState({ loading: false, error: true });
  };
  handleLoad = () => {
    this.setState({ loading: false, error: false });
  };

  render() {
    const { loading, error } = this.state;
    const {
      className,
      alt,
      style,
      onClick,
      loadingClassName,
      ref,
      src,
    } = this.props;
    // console.log("src",src);
    const isFoundSrc = ( src ) => {
    // if (src.includes("onTrashReqIgnore=true")) return false;
    return true;
    
  };
    return (
      // <div
      //   className={classNames(className, {
      //     [loadingClassName || "loading"]: loading,
      //     error: error,
      //   })}
      //   ref={ref}
      //   alt={alt}
      //   style={{
      //     width: "100%",
      //     height: "100%",
      //     ...style,
      //     backgroundImage: `url(${!loading && src ? src : this.placeholder})`,
      //     backgroundSize: "cover",
      //   }}
      //   onClick={onClick}
      // ></div>
      <div
        className={classNames(className, {
          [loadingClassName || "loading"]: loading,
          error: error,
        })}
        ref={ref}
        alt={alt}
        style={{
          width: "100%",
          height: "100%",
          ...style,
          backgroundImage: `url(${(!loading && src) ? ((isFoundSrc(src))? src : defaultNoimg ) : this.placeholder})`,
          backgroundSize: "cover",
        }}
        onClick={onClick}
      ></div>
    );
  }
}

export const BgImgWithLazy = (props) => {
  let {
    src,
    placeholder,
    className,
    alt,
    style,
    onClick,
    loadingClassName,
    threshold,
    containerClassName,
    containerStyle,
  } = props;
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: threshold || 0,
    // triggerOnce: true,
  });

  return (
    <div ref={ref} className={containerClassName} style={containerStyle}>
      
      <BgImgPreload
        className={className}
        loadingClassName={loadingClassName}
        alt={alt}
        style={style}
        onClick={onClick}
        placeholder={placeholder}
        src={inView ? src : placeholder}
      />
    </div>
  );
};
