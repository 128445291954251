import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import * as Auth from "src/services/auth.service";
import { getAuthorizationPmtx } from "src/services/util.service";
import styles from "../invitation/InvitedList.module.scss";
import InviteModal from "./InviteModal";
import { GET_INVITED_USER_ENDPOINT } from "src/utils/endpoint/pmtx.endpoint";
import { PagingResponse } from "src/model/paging";

interface IInvitedList {
  id: string;
  user_id: string;
  refer_user_id: string;
  status: string;
  email: string;
}

function InvitedList() {
  const { Column } = Table;

  const [searchText, setSearchText] = useState("");
  const [isInviteModal, setIsInviteModal] = useState(false);
  const [userList, setUserList] = useState<PagingResponse<IInvitedList>>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(7);

  const handleSearchChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModal(false);
  };

  const handleInviteModal = () => {
    setIsInviteModal(true);
  };

  const fetchInviedUsers = async (
    offset: number,
    limit: number,
    search: string
  ) => {
    try {
      let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
      const { data } = await axios.get<PagingResponse<IInvitedList>>(
        GET_INVITED_USER_ENDPOINT,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorizationPmtx(),
          },
          params: {
            refer_user_id: JSON.parse(userFromStorage).user_id,
            offset,
            limit,
            search,
          },
        }
      );
      setUserList(data);
      return data;
    } catch (e) {
      console.error("Error fetching invited users:", e);
      throw e;
    }
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handlePaginationChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
    fetchInviedUsers((page - 1) * size, size, "");
  };

  useEffect(() => {
    fetchInviedUsers(0, 10, "");
  }, []);

  const getUser = () => {
    let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
    return JSON.parse(userFromStorage);
  };

  return (
    <>
      <div className={styles.container}>
        <div className="user-management-table">
          <h2>Invited Users</h2>
          <div
            className="header"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 16,
              width: "100%",
              borderRadius: "0 0 8px 8px",
            }}
          >
            <Input
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
              prefix={<SearchOutlined />}
              style={{ width: 400, marginRight: "auto" }}
            />
            <Button
              type="primary"
              style={{ marginLeft: 10, background: "#184c85" }}
              onClick={() => handleInviteModal()}
            >
              <PlusOutlined /> Invite
            </Button>
          </div>

          {userList?.data && (
            <Table
              dataSource={userList?.data?.filter((user: IInvitedList) =>
                user.email.toLowerCase().includes(searchText.toLowerCase())
              )}
              rowKey="key"
              showHeader={false}
              scroll={{ x: "max-content" }}
              style={{
                borderLeft: "1px solid #e8e8e8",
                borderRight: "1px solid #e8e8e8",
                borderBottom: "1px solid #e8e8e8",
              }}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: userList?.total,
                position: ["bottomCenter"],
                defaultPageSize: 5,
                showTotal: (total) => `Total ${total} items`,
                onChange: handlePaginationChange,
                hideOnSinglePage: true,
                responsive: true,
              }}
            >
              <Column
                title="Name"
                render={(text, record) => (
                  <Space>
                    <div className={styles.circle}>
                      <p className={styles.circleInner}>
                        {record.email.charAt(0)}
                      </p>
                    </div>
                    <div>
                      <div>
                        {record.username}
                        {record.username ===
                          getUser()?.username && (
                          <Tag style={{ marginLeft: 8 }} color="geekblue">
                            You
                          </Tag>
                        )}
                      </div>
                      <div>{record.email}</div>
                    </div>
                  </Space>
                )}
              />
              <Column
                title="Action"
                render={(text, record) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    {record.status !== "WAITING" ? (
                      <Button style={{ background: "#ECECF1", width: "50%" }}>
                        Joined
                      </Button>
                    ) : (
                      <Button style={{ background: "#ECECF1", width: "50%" }}>
                        Pending
                      </Button>
                    )}
                  </div>
                )}
              />
            </Table>
          )}
        </div>
      </div>

      {isInviteModal && (
        <InviteModal onClose={handleCloseInviteModal} isOpen={isInviteModal} />
      )}
    </>
  );
}

export default InvitedList;
