import React from "react";

const AccountCircleIcon = (props) => {
  const { className, style, width, height, fill } = props;
  return (
    <svg
      width={width || "56"}
      height={height || "55"}
      className={className}
      style={style}
      viewBox="0 0 56 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.5078 27.5C55.5078 42.6878 43.1956 55 28.0078 55C12.82 55 0.507812 42.6878 0.507812 27.5C0.507812 12.3122 12.82 0 28.0078 0C43.1956 0 55.5078 12.3122 55.5078 27.5Z"
        fill="#1C87F2"
        fill-opacity="0.15"
      />
      <g clip-path="url(#clip0_9805_42388)">
        <path
          d="M28.0066 27.5001C29.3882 27.5001 30.7387 27.0904 31.8874 26.3229C33.0361 25.5553 33.9314 24.4644 34.46 23.188C34.9887 21.9117 35.1271 20.5072 34.8575 19.1522C34.588 17.7972 33.9228 16.5526 32.9459 15.5757C31.969 14.5988 30.7243 13.9335 29.3694 13.664C28.0144 13.3945 26.6099 13.5328 25.3335 14.0615C24.0572 14.5902 22.9662 15.4855 22.1987 16.6342C21.4312 17.7829 21.0215 19.1334 21.0215 20.5149C21.0233 22.3669 21.7599 24.1426 23.0694 25.4521C24.379 26.7617 26.1546 27.4982 28.0066 27.5001ZM28.0066 15.8582C28.9276 15.8582 29.828 16.1313 30.5938 16.643C31.3596 17.1547 31.9564 17.882 32.3089 18.7329C32.6614 19.5838 32.7536 20.5201 32.5739 21.4234C32.3942 22.3267 31.9507 23.1565 31.2994 23.8078C30.6482 24.459 29.8184 24.9025 28.9151 25.0822C28.0118 25.2619 27.0755 25.1697 26.2246 24.8172C25.3736 24.4648 24.6464 23.8679 24.1347 23.1021C23.623 22.3363 23.3499 21.4359 23.3499 20.5149C23.3499 19.2799 23.8405 18.0954 24.7138 17.2221C25.5871 16.3488 26.7716 15.8582 28.0066 15.8582Z"
          fill="#1C87F2"
        />
        <path
          d="M28.007 29.8291C25.2291 29.8322 22.5658 30.9371 20.6015 32.9014C18.6373 34.8656 17.5324 37.5289 17.5293 40.3068C17.5293 40.6156 17.652 40.9117 17.8703 41.13C18.0886 41.3483 18.3847 41.471 18.6935 41.471C19.0022 41.471 19.2984 41.3483 19.5167 41.13C19.735 40.9117 19.8577 40.6156 19.8577 40.3068C19.8577 38.1455 20.7163 36.0727 22.2446 34.5444C23.7729 33.0161 25.8457 32.1575 28.007 32.1575C30.1683 32.1575 32.2412 33.0161 33.7694 34.5444C35.2977 36.0727 36.1563 38.1455 36.1563 40.3068C36.1563 40.6156 36.279 40.9117 36.4973 41.13C36.7156 41.3483 37.0118 41.471 37.3205 41.471C37.6293 41.471 37.9254 41.3483 38.1437 41.13C38.3621 40.9117 38.4847 40.6156 38.4847 40.3068C38.4816 37.5289 37.3767 34.8656 35.4125 32.9014C33.4482 30.9371 30.7849 29.8322 28.007 29.8291Z"
          fill="#1C87F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_9805_42388">
          <rect
            width="27.9405"
            height="27.9405"
            fill="white"
            transform="translate(14.0371 13.5298)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountCircleIcon;
