import React from "react";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import classNames from "classnames";
import galleryStyles from "./gallery.module.scss";

const SkeletonMediaFile = () => {
  const skeleton = [1, 2, 3];
  return (
    <>
      <div
        key={"_item_media_inside"}
        className={galleryStyles.zard_gallery_container}
      >
        {skeleton.map((item, key) => {
          return (
            <>
              <div
                key={"_item_media_skel_" + key}
                className={classNames(galleryStyles.zard_gallery_tile)}
              >
                <div>
                  <div
                    key={key}
                    style={{
                      backgroundColor: "#DBDBDB",
                      maxWidth: "290px",
                      height: "200px",
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <SkeletonTheme
                      {...({
                        color: "#E3E3E3",
                        highlightColor: "#EEEEEE",
                      } as any)}
                    >
                      <div
                        style={{
                          padding: "27px 23px 36px 23px",
                        }}
                      >
                        <div>
                          <Skeleton count={2} style={{}} />
                        </div>
                      </div>
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SkeletonMediaFile;
