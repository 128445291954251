import React from "react";

function SvgFileIcon(props) {
  let blur = "";
  if (props.activeMove) {
    blur = 40;
  }

  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
        <rect x="0.506836" width="32" height="32" rx="8" fill="#E8F4FF" />
        <g clip-path="url(#clip0_10745_5040)">
            <path
                d="M21.8063 11.6912L19.4837 9.36717C19.0512 8.9326 18.5369 8.58806 17.9705 8.35348C17.4041 8.1189 16.7967 7.99893 16.1837 8.0005H13.1737C12.2899 8.00156 11.4427 8.35309 10.8178 8.97798C10.1929 9.60288 9.84139 10.4501 9.84033 11.3338V20.6672C9.84139 21.5509 10.1929 22.3981 10.8178 23.023C11.4427 23.6479 12.2899 23.9994 13.1737 24.0005H19.8403C20.7241 23.9994 21.5713 23.6479 22.1962 23.023C22.8211 22.3981 23.1726 21.5509 23.1737 20.6672V14.9905C23.1753 14.3775 23.0553 13.7702 22.8206 13.2038C22.5859 12.6375 22.2412 12.1233 21.8063 11.6912ZM20.8637 12.6338C21.0672 12.8433 21.2423 13.0786 21.3843 13.3338H18.507C18.3302 13.3338 18.1606 13.2636 18.0356 13.1386C17.9106 13.0136 17.8403 12.844 17.8403 12.6672V9.78984C18.0956 9.93179 18.3312 10.1066 18.541 10.3098L20.8637 12.6338ZM21.8403 20.6672C21.8403 21.1976 21.6296 21.7063 21.2545 22.0814C20.8795 22.4565 20.3708 22.6672 19.8403 22.6672H13.1737C12.6432 22.6672 12.1345 22.4565 11.7595 22.0814C11.3844 21.7063 11.1737 21.1976 11.1737 20.6672V11.3338C11.1737 10.8034 11.3844 10.2947 11.7595 9.91962C12.1345 9.54455 12.6432 9.33384 13.1737 9.33384H16.1837C16.293 9.33384 16.399 9.35517 16.507 9.36517V12.6672C16.507 13.1976 16.7177 13.7063 17.0928 14.0814C17.4679 14.4565 17.9766 14.6672 18.507 14.6672H21.809C21.819 14.7752 21.8403 14.8805 21.8403 14.9905V20.6672Z"
                fill={"#3875F6"+blur} />
        </g>
        <defs>
            <clipPath id="clip0_10745_5040">
                <rect width="16" height="16" fill="white" transform="translate(8.50684 8)" />
            </clipPath>
        </defs>
    </svg>

  );
}

export default React.memo(SvgFileIcon);
