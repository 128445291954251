import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styles from "./UIShareLinkPopover.module.scss";
import { useTranslation } from "react-i18next";
//@ts-ignore
import _ from "lodash";
import { FileContext, FileContextProps } from "../File.context";
// import Modal from 'react-bootstrap/Modal';
import { Modal } from "antd";
import { Avatar, List, message, Switch, Button } from "antd";
import world_file from "src/assets/png/world.png";
import useFetch, { CachePolicies } from "use-http";
import { addMemberToAccessBackend, blockDownload, CENTER_URL_MAIN, getLinkDownload, KN_FILE_SERVICE_URL_MAIN } from "../../util.service";
import SvgPersonIcon from "../icon/SvgPersonIcon";
import ButtonCenterCLose from "../ButtonCenterClose";
import CenterButton from "src/shared/button/Button";

const ShareContextFile = React.createContext({});
function UIShareLinkPopover(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [selectId, setSelectId]: any = useState('0');
  const [search, setSearch] = useState("");
  const [users, setUsers]: any = useState([]);
  const [selectFriend, setSelectFriend]: any = useState([]);
  const [linkDownload, setLinkDownload] = useState("");
  const [permission, setPermission] = useState("anyoneWithTheLink");
  const shareContextFile: any = useContext(ShareContextFile);
  const [isBlockDownload, setIsBlockDownload]:any = useState(false);

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);

    setIsBlockDownload(checked)
    let status = "N";
    if (checked) {
      status = "Y"
    }
    blockDownload(
      fileContext.fileSelected.file_app_id,
      status
    );

  };

  const options: any = [
    { value: 0, title: "Anyone with the link", describe: "Anyone on the internet with  this link can view", background: "#DFECFF", permission: "anyoneWithTheLink" },
    // { value: 1, title: "Specific people", describe: "Only specific people can view this link", background: "#FFE3DA", permission: "restricted" },
  ];

  const formatOptionSelect = ({ value, title, describe, background }: any) => (
    <div style={{ display: "flex", gap: "20px" }}>
      <div className={styles.imgBox} style={{ background }}>


        {value == 0 && <img
          src={world_file}
          className={styles.imgWorld}
        ></img>}
        {value == 1 && <SvgPersonIcon />}
      </div>

      <div >
        <div style={{ fontWeight: "bold", color: "#000" }}>{title}</div>
        <div style={{ color: "#808080" }}>{describe}</div>
      </div>
    </div>
  );

  const formatOptionSearch = ({ label, img, username }: any) => (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar size={48} src={img} />}
        title={<span style={{ color: "#000" }}>{label}</span>}
        description={username}
      />
    </List.Item>
  );


  useEffect(() => {

    if (fileContext.showShareLinkModal) {//fileContext.showShareLinkModal

      getAndSetLink();
      setSelectFriend([])
      setSelectId("0")
      setUsers([])

      getAndSetWhoHasAccess();


    }
  }, [fileContext.showShareLinkModal]);


  const getAndSetWhoHasAccess = async () => {

    await getWhoHasAccess.get(
      `?fileId=${fileContext.fileSelected?.file_app_id}&userId=${fileContext.userId
      }&offset=${0}&limitOfset=${null}`
    );


    loadOptionWhoHasAccesses(getWhoHasAccess.response?.data?.data)
    fectDataUser(getWhoHasAccess.response?.data?.data);
  };

  const loadOptionWhoHasAccesses = (data: any) => {
    let listFriend: any = [];
    console.log("accesses : ")
    data?.forEach((data: any) => {
      let friendOption = {
        label: data?.userData?.firstname
          ? data?.userData?.firstname + " " + (data?.userData?.lastname || "")
          : data?.userData?.username,
        value: data?.userData?.id,
        img: data.userData?.imageProfileURL,
        username: data.userData?.username,
        data
      };

      listFriend.push(friendOption);
    });

    setSelectFriend(listFriend)
  }


  const getUsers = useFetch(CENTER_URL_MAIN, {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const apiFriends = useFetch(
    CENTER_URL_MAIN + "api/v1/users/friends",
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const getWhoHasAccess = useFetch(
    KN_FILE_SERVICE_URL_MAIN + "/getWhoHasAccess",
    {
      // path: APIEnpoint.myApps,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const fectDataUser = async (dataSelectedUser: any) => {
    let resFriends = await apiFriends.get();
    await getUsers.get(
      `/api/v1/users/friends?search=${search}&limit=${resFriends?.total}&offset=${0}`
    );
    const dataFriend = getUsers?.response?.data?.data

    let listFriend: any = [];
    dataFriend?.forEach((data: any) => {
      let friendOption = {
        label: data?.friend?.firstname
          ? data?.friend?.firstname + " " + (data?.friend?.lastname || "")
          : data?.friend?.username,
        value: data?.friend?.id,
        img: data.friend?.imageProfileURL,
        username: data.friend?.username,
        data
      };

      listFriend.push(friendOption);
    });

    listFriend = listFriend.filter((f: any) => {
      return dataSelectedUser.findIndex((f2: any) => f2.user_id === f.value) === -1
    })



    setUsers(listFriend)
  };

  const getAndSetLink = async () => {
    setIsBlockDownload(false)
    let linkData: any = await getLinkDownload(
      fileContext.fileSelected?.file_app_id
    );
    let link;
    link = window.location.origin;
    if (!link?.endsWith("/")) link = link + "/center-web/";
    link = link + "file-access?id=" + linkData.data.link_download;
    let linkEncode = encodeURI(link);
    setLinkDownload(linkEncode);
    setPermission(linkData.data?.permission_anyone)
    

    if (linkData.data?.permission_file === "blockDownload") {
      setIsBlockDownload(true)
    }
  };
  const copyLink = (from: any) => {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = linkDownload;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  
    message.success({ content: "Copied already" });
  };

  const addMemberToAccess = async (event: any) => {


    let isFound = false
    selectFriend?.map(((e: any) => {
      if (e.value === event.value) {
        isFound = true
        return
      }
    }))


    if (!isFound) {
      setUsers(
        users.filter(function (e: any) {
          return e.value !== event.value
        })
      )
      setSelectFriend([...selectFriend, event])

    }
    event.data.userId = event.data.friendID
    event.data.level = "viewer"


    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      [event.data],
      [], //context.stampUsersUnSelected,
      selectFriend.length,
      "viewer",
      permission
    );
    getAndSetWhoHasAccess();


  };

  const deleteFilePermissionLevelUser = async (user: any) => {

    setSelectFriend(
      selectFriend.filter(function (e: any) {
        return e.value !== user.value
      })
    )
    setUsers([...users, user])

    let stampUsersDelete: any = [];
    user.data.userId = user.data.userData.id;
    stampUsersDelete.push(user.data);
    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      [],
      stampUsersDelete,
      selectFriend.length,
      null,
      null
    );

    getAndSetWhoHasAccess();
  };

  const changePermission = async (data: any) => {

    console.log("data :",data)
    setPermission(data.permission)
    let newObj = await addMemberToAccessBackend(
      fileContext.fileSelected.file_app_id,
      fileContext.userId,
      [],
      [],
      selectFriend.length,
      null,
      data.permission
    );
    getAndSetLink()
    getAndSetWhoHasAccess();
    
  };

  const handleCancel = () => {
    fileContext.setShowShareLinkModal(false)
  }



  return (
    // <Modal
    //   show={fileContext.showShareLinkModal}
    //   // onHide={() => fileContext.setShowShareLinkModal(false)}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   className={`${styles.modalCopyLink} ${styles.customScroollbar}`}
    // >
    //   <Modal.Header style={{ padding: "24px", alignItems: "center" }}>
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       {t("Copy link")}
    //     </Modal.Title>
    //     <div className={styles.closeBtn}>
    //       <ButtonCenterCLose
    //         onClick={() => fileContext.setShowShareLinkModal(false)}
    //       ></ButtonCenterCLose>
    //     </div>

    //   </Modal.Header>

    //   <div style={{ padding: "24px" }}>
    //     {permission == "anyoneWithTheLink" && <Select
    //       defaultValue={options[0]}
    //       formatOptionLabel={formatOptionSelect}
    //       options={options}
    //       isSearchable={false}
    //       className={styles.boxSelect}
    //       onChange={(value:any) => changePermission(value)}
    //     />}
    //     {permission == "restricted" && <Select
    //       defaultValue={options[1]}
    //       formatOptionLabel={formatOptionSelect}
    //       options={options}
    //       isSearchable={false}
    //       className={styles.boxSelect}
    //       onChange={(value:any) => changePermission(value)}
    //     />
    //     }

    //   </div>




    //   {permission == "restricted" &&
    //     <>
    //       <div style={{ padding: "0 24px 24px" }}>
    //         <Select
    //           formatOptionLabel={formatOptionSearch}
    //           options={users}//options1
    //           value={[]}
    //           className={styles.boxSearch}
    //           onChange={addMemberToAccess}

    //           placeholder={t("Search")}
    //           components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    //         />
    //       </div>

    //       {permission == "restricted" && selectFriend.length != 0 && <div style={{ padding: "0 24px 24px", maxHeight: "300px", overflowY: "auto" }}>
    //         <List
    //           itemLayout="horizontal"
    //           dataSource={selectFriend}
    //           renderItem={(item: any) => (
    //             <List.Item>
    //               <List.Item.Meta
    //                 avatar={<Avatar size={48} src={item?.img} />}
    //                 title={<span style={{ color: "#000" }}>{item?.label}</span>}
    //                 description={item?.username}
    //               />
    //               <CenterButton ghost onClick={() => deleteFilePermissionLevelUser(item)} style={{ marginRight: "10px" }}>
    //                 {t("Remove")}
    //               </CenterButton>
    //             </List.Item>

    //           )}
    //         />
    //       </div>}
    //     </>
    //   }
    //   <div>
    //     <span className={styles.spanTextSetting}>{t("Other settings")}</span>
    //   </div>
    //   <div style={{ margin: "0 24px 24px" }}>
      
    //     <Switch defaultChecked={isBlockDownload} checked={isBlockDownload}  onChange={onChange} style={{ marginRight: "10px" }} />
    //     <span className={styles.spanTextBlocDownload}>{t("Block download")}</span>
    //   </div>

    //   <div style={{ marginLeft: "auto", marginRight: "24px", marginBottom: "24px" ,display :"inline-flex"}}>

    //     <CenterButton ghost onClick={() => fileContext.setShowShareLinkModal(false)} style={{ marginRight: "10px",width:"100px" }}>
    //       {t("Cancel")}
    //     </CenterButton>
    //     <CenterButton onClick={() => copyLink(2)} style={{width:"100px" }} >{t("Copy link")}</CenterButton>

    //   </div>
    // </Modal>
    <Modal
      visible={fileContext.showShareLinkModal}
      onCancel={handleCancel}
      title={ <p>{t("Copy link")}</p> }
      footer={null}
      centered
      width={800}
      className={`${styles.modalCopyLink} ${styles.customScroollbar}`}
    >
      <div style={{ padding: "24px" }}>
         {permission == "anyoneWithTheLink" && <Select
          defaultValue={options[0]}
          formatOptionLabel={formatOptionSelect}
          options={options}
          isSearchable={false}
          className={styles.boxSelect}
          onChange={(value:any) => changePermission(value)}
        />}
         {permission == "restricted" && <Select
          defaultValue={options[1]}
          formatOptionLabel={formatOptionSelect}
          options={options}
          isSearchable={false}
          className={styles.boxSelect}
          onChange={(value:any) => changePermission(value)}
        />
        }

      </div>
       {permission == "restricted" &&
        <>
          <div style={{ padding: "0 24px 24px" }}>
            <Select
              formatOptionLabel={formatOptionSearch}
              options={users}//options1
              value={[]}
              className={styles.boxSearch}
              onChange={addMemberToAccess}

              placeholder={t("Search")}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            />
          </div>

          {permission == "restricted" && selectFriend.length != 0 && <div style={{ padding: "0 24px 24px", maxHeight: "300px", overflowY: "auto" }}>
            <List
              itemLayout="horizontal"
              dataSource={selectFriend}
              renderItem={(item: any) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar size={48} src={item?.img} />}
                    title={<span style={{ color: "#000" }}>{item?.label}</span>}
                    description={item?.username}
                  />
                  <CenterButton ghost onClick={() => deleteFilePermissionLevelUser(item)} style={{ marginRight: "10px" }}>
                    {t("Remove")}
                  </CenterButton>
                </List.Item>

              )}
            />
          </div>}
        </>
      }
      <div>
        <span className={styles.spanTextSetting}>{t("Other settings")}</span>
      </div>
      <div style={{ margin: "0 24px 24px" }}>
      
        <Switch defaultChecked={isBlockDownload} checked={isBlockDownload}  onChange={onChange} style={{ marginRight: "10px" }} />
        <span className={styles.spanTextBlocDownload}>{t("Block download")}</span>
      </div>

      <div style={{ marginLeft: "auto", marginRight: "24px", marginBottom: "24px" ,display :"inline-flex"}}>

        {/* <CenterButton ghost onClick={() => fileContext.setShowShareLinkModal(false)} style={{ marginRight: "10px",width:"100px" }}>
          {t("Cancel")}
        </CenterButton>
        <CenterButton onClick={() => copyLink(2)} style={{width:"100px" }} >{t("Copy link")}</CenterButton> */}
        <Button onClick={() => fileContext.setShowShareLinkModal(false)}>Cancel</Button>
        <Button type='primary' style={{ marginLeft: "10px" }} onClick={() => copyLink(2)}>Copy link</Button>

      </div>
  </Modal>
  );
}

export default UIShareLinkPopover;
