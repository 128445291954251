import React from "react";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import classNames from "classnames";
import galleryStyles from "./gallery.module.scss";
import styles from "./LinksContainer.module.scss";

const SkeletonLinkFile = () => {
  const skeleton = [1, 2, 3];
  return (
    <>
      <div
        key={"_item_media_inside"}
        className={galleryStyles.zard_gallery_container}
      >
        {skeleton.map((item, key) => {
          return (
            <>
              <div
                key={"_item_media_skel_" + key}
                className={classNames(galleryStyles.zard_gallery_tile)}
              >
                <div style={{ display: "flex" }}>
                  <div
                    key={key}
                    style={{
                      backgroundColor: "#DBDBDB",
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px",
                    }}
                  >
                    <SkeletonTheme
                      {...({
                        color: "#E3E3E3",
                        highlightColor: "#EEEEEE",
                      } as any)}
                    >
                      <div
                        style={{
                          padding: "27px 23px 36px 23px",
                        }}
                      ></div>
                    </SkeletonTheme>
                  </div>

                  <div
                    style={{
                      width: "calc(100% - 100px)",
                      height: "100px",
                    }}
                  >
                    <div className={styles.boxtext}>
                      <SkeletonTheme
                        {...({
                          color: "#E3E3E3",
                          highlightColor: "#EEEEEE",
                        } as any)}
                      >
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    </div>
                    <div className={styles.boxtext}>
                      <SkeletonTheme
                        {...({
                          color: "#E3E3E3",
                          highlightColor: "#EEEEEE",
                        } as any)}
                      >
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    </div>
                    <div className={styles.boxtext}>
                      <SkeletonTheme
                        {...({
                          color: "#E3E3E3",
                          highlightColor: "#EEEEEE",
                        } as any)}
                      >
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    </div>
                    <div className={styles.boxtext}>
                      <SkeletonTheme
                        {...({
                          color: "#E3E3E3",
                          highlightColor: "#EEEEEE",
                        } as any)}
                      >
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SkeletonLinkFile;
