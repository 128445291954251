import { Card, Tabs } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Auth from "src/services/auth.service";
import { fetchAllInvoices } from "src/store/actions/payment/billingActions";
import { fetchStripeCustomerByUserID } from "src/store/actions/payment/customerActions";
import { billingSelector, setActiveTab } from "src/store/slices/payments/billingSlice";
import { useAppDispatch } from "src/store/store";
import "../dashboard/Dashboard.css";
import styles from "./BillingPage.module.scss";
import BillingHistory from "./components/BillingHistory";
import Overview from "./components/Overview";
import Subscription from "./components/Subscription";

function BillingPage() {
  const dispatch = useAppDispatch();
  const { activeTab } = useSelector(billingSelector);

  const items = [
    { key: "overview", label: "Overview", children: <Overview /> },
    { key: "subscription", label: "Subscription", children: <Subscription /> },
    // { key: "payment", label: "Payment methods", children: <PaymentMethods /> },
    { key: "history", label: "Billing history", children: <BillingHistory /> },
  ];

  useEffect(() => {
    let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
    let userInfo = JSON.parse(userFromStorage);
    if (userInfo) {
      dispatch(fetchAllInvoices({ email: userInfo.email }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStripeCustomerByUserID());
  }, [dispatch]);

  const handleTabChange = (tab: string) => {
    dispatch(setActiveTab(tab));
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="dashboard-header">
        <h2 className="dashboard-title">Billing</h2>
      </div>
      <Card className={styles.cardWrapper}>
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 150px)' }}>
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            items={items}
            type="card"
            size="large"
          />
          <div style={{ overflow: 'auto', flex: 1 }}>
            {items.find(item => item.key === activeTab)?.children}
          </div>
        </div>
      </Card>
    </div>
  );
}
export default BillingPage;
