import React, { useEffect, useRef } from "react";
import {
  Chart,
  ChartConfiguration,
  ChartData,
  DoughnutController,
  ArcElement,
  Tooltip,
} from "chart.js";

// Register the necessary components
Chart.register(DoughnutController, ArcElement, Tooltip);

interface DonutChartProps {
  data: ChartData<"doughnut">;
  options?: ChartConfiguration["options"];
  centerText?: string;
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  options,
  centerText,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");
    if (!ctx) return;

    // Destroy the old chart if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Define the chart configuration
    const config: ChartConfiguration = {
      type: "doughnut",
      data: data,
      options: {
        responsive: true,
        plugins: {
          ...options?.plugins,
          tooltip: {
            enabled: true,
          },
        },
      },
      plugins: [
        {
          id: "centerText",
          beforeDraw: (chart) => {
            const { ctx, chartArea } = chart;
            if (!chartArea) return;

            ctx.save();
            const textX = Math.round((chartArea.left + chartArea.right) / 2);
            const textY = Math.round((chartArea.top + chartArea.bottom) / 2);

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = "32px Arial";
            ctx.fillText(centerText as string, textX, textY);
            ctx.restore();
          },
        },
      ],
    };

    // Create a new chart instance
    chartInstanceRef.current = new Chart(ctx, config);

    // Destroy the chart when the component unmounts
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, options, centerText]); // Re-render when data, options, or centerText change

  return (
    <div style={{ width: "15rem", height: "15rem" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default DonutChart;
