import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { USER_STRIPE_CUSTOMERS } from "src/utils/endpoint/pmtx.endpoint";
import { UserStripeCustomer } from "../../../model/customer";
import { InvoiceResponse } from "../../../model/invoice";

export const fetchStripeCustomerByUserID = createAsyncThunk<UserStripeCustomer>(
  "stripeCustomer/fetchStripeCustomerByUserID",
  async () => {
    const response = await axios.get(`${USER_STRIPE_CUSTOMERS}`, {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    });
    
    return response.data;
  }
);
