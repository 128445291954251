import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { ACTIVE_AGENT_ENDPOINT, NUMBER_OF_APPS_ENDPOINT } from "src/utils/endpoint/pmtx.endpoint";

// http://localhost:3007/pmtx-api/v1/active-agents
export interface INumOfApp {
    number_of_apps_usage: number;
}

// /token-used-latest
export const fetchNumberOfAppsByUserID = createAsyncThunk<
INumOfApp>("number_of_apps/fetchNumberOfAppsByUserID", async () => {
  const response = await axios.get(
    `${NUMBER_OF_APPS_ENDPOINT}`,
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    }
  );

  return response.data.data;
});