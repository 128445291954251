import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom"
// import axiosCancel from "axios-cancel";
import {useTranslation} from "react-i18next";
import {Col, Progress, Row} from "antd";
import styles from "./BoxDetailFileDownload.module.scss";
import {FileContext} from "../../File.context";
import FolderIcon from "src/assets/png/FolderIcon.png";
import {getClassNameByType} from "../../File/UtilFileCard";
import UIFileType from "../../type/UIFileType";
import { bytesToSizeUtil, getUrl, KN_FILE_SERVICE_URL_MAIN } from "src/page/kn/util.service";
import { checkEmpty } from "src/services/util.service";
import ButtonCenterCLose from "../../ButtonCenterClose";
import CenterConfirmModal from "../../Modal/CenterConfirmModal";
import { AppContext } from "src/page/context/App.context";

const limitAc = 10;
// const BoxDetailFileDownloadItem = (props: any, ref: any) => {
function BoxDetailFileDownloadItem(props: any) {
  const { t } = useTranslation();
  const fileContext: any = useContext(FileContext);
  const appContext: any = useContext(AppContext);
  let text = t("Files");
  const navigate = useNavigate();
  const [urlFile, setUrlFile] = useState(null);
  const [imageOwnerURL, setImageOwnerURL] = useState(null);
  const [mainUserOwner, setMainUserOwner]: any = useState({});
  const [whoHasAccesses, setWhoHasAccesses] = useState([]);
  const [file, setFile]: any = useState(null);
  const [activities, setActivities] = useState([]);
  const [offsetAc, setOffsetAc] = useState(0);
  // const [size, setSize] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  useEffect(()=>{
    getUrlFile(props.item.file);
  }, [])

  const getUrlFile = async (file: any) => {
    let url: any =
      KN_FILE_SERVICE_URL_MAIN +
      "/downloadFile?fileId=" +
      file?.file_app_id +
      "&size=200";
    url = await getUrl(url, file?.file_app_id, 200, file?.file_type);
    setUrlFile(url)
  };

  const getColorLimitProgress = (percent: any) => {
    if (percent >= 100) {
      return "#3875F6";
      // return "#00a259";
    } else return "#3875F6";
  };

  const closeItemDownloadConfirm = () => {
    setShowConfirm(true);
  };

  const closeItemDownload = () => {
    let mapProgressTemp = appContext.mapProgress;

    //@ts-ignore
    let temp = mapProgressTemp[props.item.file?.file_app_id];
    if (!checkEmpty(temp)) {
      temp.requestAxiosCancel();
      temp.status = "cancel";
    }
    mapProgressTemp[props.item.file?.file_app_id] = temp;

    appContext.setMapProgress(Object.assign({}, mapProgressTemp));

    setShowConfirm(false);
  };

  const getPercent = () => {
    let mapProgressTemp = appContext.mapProgress;
    //@ts-ignore
    let temp = mapProgressTemp[props.item.file?.file_app_id];
    if (checkEmpty(temp)) return 0;
    let percent = (temp.progress / props.item?.size) * 100;
    percent = Math.round(percent);
    if (!checkEmpty(temp) && temp.progress === 0 && props.item?.size === 0)
      return 100;
    return percent;
  };

  const getLoadded = () => {
    let mapProgressTemp = appContext.mapProgress;
    //@ts-ignore
    let temp = mapProgressTemp[props.item.file?.file_app_id];
    if (checkEmpty(temp)) return 0;
    return temp.progress;
  };

  const isCancelled = () => {
    let mapProgressTemp = appContext.mapProgress;
    //@ts-ignore
    let temp = mapProgressTemp[props.item.file?.file_app_id];
    if (checkEmpty(temp)) return false;
    return temp.status === "cancel";
  };

  const getNameOfFile = (item: any) => {
    if (item.type_ === "DIRECTORY") {
      return item.file_name + ".zip";
    } else {
      return props.item.file.file_name + "." + props.item.file.file_type;
    }
  };

  return (
    <>
      <Row
        justify="start"
        style={{ width: "100%", alignItems: "center" }}
        className={styles.itemFile}
      >
        <Col span={5}>
          <div>
            <div
              className={getClassNameByType(
                props.item.file.file_type,
                false,
                false
              )}
              style={{
                //@ts-ignore
                textAlign:
                  props.item.file.type_ === "DIRECTORY"
                    ? "-webkit-center"
                    : "center",
              }}
            >
              <div className={[styles.verticalCenter].join(" ")}>
                {props.item.file.type_ === "DIRECTORY" ? (
                  <div
                    style={{
                      backgroundImage: `url(${FolderIcon})`,
                      backgroundSize: "cover",
                      width: 35,
                      height: 30,
                    }}
                  ></div>
                ) : (
                  <UIFileType
                    file={props.item.file}
                    urlImage={urlFile}
                  ></UIFileType>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col
          span={18}
          style={{
            fontSize: "0.7rem",
            margin: "auto",
          }}
        >
          <div>
            <div style={{ width: "79%" }}>
              <div>
                <div
                  className={styles.textOverflowListFileText}
                  style={{ alignContent: "left" }}
                >
                  <div>{getNameOfFile(props.item.file)}</div>
                </div>
              </div>
            </div>
            {!isCancelled() && getPercent() < 100 && (
              <div
                className={styles.closeItemBtn}
                style={
                  {
                    // backgroundImage: `url(${closeIcon})`,
                    // backgroundSize: "15px 15px",
                  }
                }
                onClick={() => {
                  closeItemDownloadConfirm();
                }}
              >
                <ButtonCenterCLose />
              </div>
            )}
            {isCancelled() && (
              <div
                className={styles.canceledText}
                style={{
                  backgroundSize: "15px 15px",
                }}
              >
                {t("cancelled")}
              </div>
            )}
          </div>

          <div>
            <div className={styles.boxProgressBar}>
              <Progress
                strokeWidth={7}
                strokeColor={getColorLimitProgress(getPercent())}
                percent={getPercent()}
                // status={"normal"}
              />
            </div>
          </div>
          <div>
            {bytesToSizeUtil(getLoadded()) +
              " of " +
              bytesToSizeUtil(props.item.size)}
          </div>
        </Col>
      </Row>

      <CenterConfirmModal
        cancelText={t("Cancel")}
        confirmText={"Ok"}
        text={t("Are you sure to cancel download?")}
        visible={showConfirm}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onConfirm={() => {
          closeItemDownload();
        }}
      ></CenterConfirmModal>

     
    </>
  );
}

export default BoxDetailFileDownloadItem;
