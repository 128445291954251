import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";
// import axiosCancel from "axios-cancel";
import { useTranslation } from "react-i18next";
import { Col, Progress, Row } from "antd";
import closeIcon from "src/assets/png/crossIcon.png";
import styles from "./BoxDetailFileDownload.module.scss";
import { FileContext } from "../../File.context";
import BoxDetailFileDownloadItem from "./BoxDetailFileDowloadItem";
import JSZip from "jszip";
import { bytesToSizeUtil, getFileDir, KN_FILE_SERVICE_URL_MAIN } from "src/page/kn/util.service";
import { checkEmpty, getAuthorization } from "src/services/util.service";
import ButtonCenterCLose from "../../ButtonCenterClose";
import CenterConfirmModal from "../../Modal/CenterConfirmModal";
import { AppContext } from "src/page/context/App.context";

const limitAc = 10;
// const BoxDetailFileDownload = (props: any, ref: any) => {
function BoxDetailFileDownload(props: any) {
  const { t } = useTranslation();
  const fileContext: any = useContext(FileContext);
  const appContext: any = useContext(AppContext);
  let text = t("Files");
  const navigate = useNavigate();
  const [urlFile, setUrlFile] = useState(null);
  const [imageOwnerURL, setImageOwnerURL] = useState(null);
  const [mainUserOwner, setMainUserOwner]: any = useState({});
  const [whoHasAccesses, setWhoHasAccesses] = useState([]);
  const [file, setFile]: any = useState(null);
  const [activities, setActivities] = useState([]);
  const [offsetAc, setOffsetAc] = useState(0);
  // const [size, setSize] = useState(0);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [fnDone, setFnDone]: any = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [toCloseModal, setToCloseModal] = useState(false);
  // const [appContext.mapProgress, setMapProgress] = useState({});

  // const buttonText = props.joined ? "Chat" : "join";
  useEffect(() => {
    if (appContext.newFileDownload !== null) {
      downloadFileProgress(appContext.newFileDownload);
      appContext.setNewFileDownload(null);
    }
  }, [appContext.newFileDownload]);

  useEffect(() => {
    if (appContext.toDownloadFiles) {
      let files = appContext.filesToDownload;
      console.log("appContext.filesToDownload : ", appContext.filesToDownload);
      if (files.length == 1) {
        downloadFileProgress(files[0]);
      } else if (files.length > 1) {
        downloadMultiFileProgress(files);
      }

      appContext.setToDownloadFiles(false);
    }
  }, [appContext.toDownloadFiles]);

  const downloadFileProgress = async (file: any) => {
    let size = file.size_;
    if (file.type_ === "DIRECTORY") {
      let res: any = await getFileDir(file.file_app_id);
      size = res?.data;
    }

    let files = appContext.progressLoadFiles;
    let fileObject = {
      file: file,
      status: "loading",
      size: size,
      prgress: 0,
      percent: 0,
    };
    let filesTemp = files.filter((item: any) => {
      return item.file.file_app_id === file.file_app_id;
    });
    if (filesTemp.length === 0) files.push(fileObject);

    appContext.setProgressLoadFiles(Object.assign([], files));

    let requestAxiosCancel: any = null;
    let CancelToken = axios.CancelToken;
    var config: any = {
      onDownloadProgress: (progressEvent: any) => {
        var percent = (progressEvent.loaded / size) * 100;
        percent = Math.round(percent);
        setProgress(
          file,
          progressEvent.loaded,
          percent,
          requestAxiosCancel,
          size
        );
      },
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: getAuthorization(),
        Accept: "application/octet-stream",
        responseType: "blob",
      },
      responseType: "blob",
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        requestAxiosCancel = c;
      }),
    };
    let url =
      KN_FILE_SERVICE_URL_MAIN +
      "/downloadFile" +
      "?fileId=" +
      file.file_app_id + "&logType=download";
    axios
      .get(url, config)
      .then(function (response: any) {
        var percent = (size / size) * 100;
        percent = Math.round(percent);
        setProgress(file, size, percent, requestAxiosCancel, size);

        let type = file.file_type;
        if (type === "dir") {
          type = "zip";
        }
        fileDownload(response.data, file.file_name + "." + type);
      })
      .catch(function (error: any) {
        if (axios.isCancel(error)) {
          console.log("request cancelled : ", error);
        } else {
          console.log("some other reason", error);
        }
      });
  };

  const downloadMultiFileProgress = async (file: any) => {
    let zip = new JSZip();

    for (let i = 0; i < file.length; i++) {
      let file_count = 0;
      let size = file[i].size_;
      if (file[i].type_ === "DIRECTORY") {
        let res: any = await getFileDir(file[i].file_app_id);
        size = res?.data;
      }

      let files = appContext.progressLoadFiles;
      let fileObject = {
        file: file[i],
        status: "loading",
        size: size,
        prgress: 0,
        percent: 0,
      };
      let filesTemp = files.filter((item: any) => {
        return item.file.file_app_id === file[i].file_app_id;
      });
      if (filesTemp.length === 0) files.push(fileObject);

      appContext.setProgressLoadFiles(Object.assign([], files));

      let requestAxiosCancel: any = null;
      let CancelToken = axios.CancelToken;
      var config: any = {
        onDownloadProgress: (progressEvent: any) => {
          var percent = (progressEvent.loaded / size) * 100;
          percent = Math.round(percent);
          setProgress(
            file[i],
            progressEvent.loaded,
            percent,
            requestAxiosCancel,
            size
          );
        },
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: getAuthorization(),
          Accept: "application/octet-stream",
          responseType: "blob",
        },
        responseType: "blob",
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          requestAxiosCancel = c;
        }),
      };
      let url =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile" +
        "?fileId=" +
        file[i].file_app_id + "&logType=download";
      axios
        .get(url, config)
        .then(function (response: any) {
          var percent = (size / size) * 100;
          percent = Math.round(percent);
          setProgress(file[i], size, percent, requestAxiosCancel, size);

          let type = file[i].file_type;
          if (type === "dir") {
            type = "zip";
          }
          zip.file(file[i].file_name + "." + type, response.data);
          console.log("multifile in progress: " + i);

          zip.forEach(function () {
            file_count++;
          });

          if (file_count == file.length) {
            zip.generateAsync({ type: "blob" })
              .then(function (content) {
                saveAs(content, "File.zip");
                console.log("finish multifile progress");
              });
          }
        })

        .catch(function (error: any) {
          if (axios.isCancel(error)) {
            console.log("request cancelled : ", error);
          } else {
            console.log("some other reason", error);
          }
        });
    }
  };

  const setProgress = (
    file: any,
    progress: any,
    percent: any,
    requestAxiosCancel: any,
    size: any
  ) => {
    let mapProgressTemp: any = appContext.mapProgress;
    let fileTemp = mapProgressTemp[file.file_app_id];
    if (checkEmpty(fileTemp)) {
      fileTemp = {};
    }

    let status = "loading";
    if (checkEmpty(progress)) progress = 0;
    if (percent >= 100) {
      status = "loaded";
    } else {
      status = "loading";
    }
    fileTemp = {
      status: status,
      progress: progress,
      percent: percent,
      requestAxiosCancel: requestAxiosCancel,
    };
    mapProgressTemp[file.file_app_id] = fileTemp;
    appContext.setMapProgress(mapProgressTemp);
  };

  const getColorLimitProgress = (percent: any) => {
    console.log("percent", percent);

    if (percent >= 100) {
      return "#00a259";
    } else return "#3875F6";
  };

  const getFileOnProgress = () => {
    let files = appContext.progressLoadFiles;
    let f = files.filter((item: any) => {
      let mapProgressTemp = appContext.mapProgress;
      //@ts-ignore
      let temp = mapProgressTemp[item.file.file_app_id];
      return checkEmpty(temp) || temp.status !== "cancel";
    });
    return f.length;
  };

  const getTextItem = (c: any) => {
    if (c === 1) return t("item");
    else return t("items");
  };

  const getAllLoadedOnProgess = (include: any) => {
    let files = appContext.progressLoadFiles;
    console.log("files : ", files);

    let progress = 0;
    for (let a = 0; a < files.length; a++) {
      let mapProgressTemp = appContext.mapProgress;
      //@ts-ignore
      let temp = mapProgressTemp[files[a].file.file_app_id];
      if (!checkEmpty(temp) && include === "cancel") {
        progress += parseInt(temp.progress);
      } else if (!checkEmpty(temp) && temp.status !== "cancel") {
        progress += parseInt(temp.progress);
      }
    }
    return progress;
  };

  const getAllSize = (include: any) => {
    let files = appContext.progressLoadFiles;
    let size = 0;

    for (let a = 0; a < files.length; a++) {
      let mapProgressTemp = appContext.mapProgress;
      //@ts-ignore
      let temp = mapProgressTemp[files[a].file.file_app_id];
      if (!checkEmpty(temp) && include === "cancel") {
        size += parseInt(files[a].size);
      } else if (!checkEmpty(temp) && temp.status !== "cancel")
        size += parseInt(files[a].size);
      else if (checkEmpty(temp)) size += parseInt(files[a].size);
    }
    return size;
  };

  const getAllSizeCheckCancel = () => {
    let include = null;
    if (isCancelAll()) {
      include = "cancel";
    }
    return getAllSize(include);
  };

  const isCancelAll = () => {
    let files: any = appContext.progressLoadFiles;
    let c = 0;
    for (let a = 0; a < files.length; a++) {
      if (isCancelled(files[a])) {
        c = c + 1;
      }
    }
    return c === files.length;
  };

  const getAllLoadedOnProgessCheckCancel = () => {
    let include = null;
    if (isCancelAll()) {
      include = "cancel";
    }
    return getAllLoadedOnProgess(include);
  };

  const getAllPercent = () => {
    let include = null;
    if (isCancelAll()) {
      include = "cancel";
    }
    let progress = getAllLoadedOnProgess(include);
    let size = getAllSize(include);
    let percent = (progress / size) * 100;
    percent = Math.round(percent);
    if (progress === 0 && size === 0) return 100;
    return percent;
  };

  const closeItemDownload = () => {
    if (toCloseModal) {
      appContext.setShowDownload(false);
    }
    let files = appContext.progressLoadFiles;
    let mapProgressTemp = appContext.mapProgress;
    for (let a = 0; a < files.length; a++) {
      //@ts-ignore
      let temp = mapProgressTemp[files[a].file?.file_app_id];
      if (!checkEmpty(temp) && temp.status !== "cancel") {
        temp.requestAxiosCancel();
        temp.status = "cancel";
      }
      mapProgressTemp[files[a].file?.file_app_id] = temp;
    }
    appContext.setMapProgress(Object.assign({}, mapProgressTemp));
    setShowConfirm(false);

    if (toCloseModal) {
      appContext.setProgressLoadFiles(Object.assign([], []));
    }
  };

  const confirmcloseModalDownload = () => {
    setToCloseModal(true);
    if (getAllPercent() < 100) {
      setMessage(t("Are you sure to cancel download and close download box") + "?");
      setShowConfirm(true);
    } else {
      appContext.setShowDownload(false);
      appContext.setProgressLoadFiles(Object.assign([], []));
    }
  };
  const closeAllItemDownload = () => {
    setToCloseModal(false);
    setMessage(t("Are you sure to cancel download") + "?");
    setShowConfirm(true);
  };

  const getTextMain = () => {
    let text =
      t("Downloading") +
      " " +
      getFileOnProgress() +
      " " +
      getTextItem(getFileOnProgress());
    let files: any = appContext.progressLoadFiles;

    let c = 0,
      l = 0;
    for (let a = 0; a < files.length; a++) {
      if (isCancelled(files[a])) {
        c = c + 1;
      } else if (isLoaded(files[a])) {
        l = l + 1;
      }
    }

    if (c === files.length) {
      text = t("Cancelled all");
    } else if (l === files.length || c + l === files.length) {
      text =
        t("Downloaded") +
        " " +
        getFileOnProgress() +
        " " +
        getTextItem(getFileOnProgress());
    }
    return text;
  };

  const isCancelled = (item: any) => {
    let mapProgressTemp = appContext.mapProgress;
    //@ts-ignore
    let temp = mapProgressTemp[item.file?.file_app_id];
    if (checkEmpty(temp)) return false;
    return temp.status === "cancel";
  };
  const isLoaded = (item: any) => {
    let mapProgressTemp = appContext.mapProgress;
    //@ts-ignore
    let temp = mapProgressTemp[item.file?.file_app_id];
    if (checkEmpty(temp)) return false;
    return temp.status === "loaded";
  };
  const checkCommunity = () => {
    let text = "/group-community";
    if (window.location.href?.indexOf(text) !== -1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    console.log("handleClickDownloadMenu",appContext.showDownload);
  }, [appContext.showDownload]);

  return appContext.showDownload ? (
    <>
      <div
        className={
          checkCommunity() ? styles.boxdetailCommunity : styles.boxdetail
        }
      >
        <div style={{}}>
          <Row justify="start" className={styles.headerBoxDetail}>
            <Col span={22}>
              <div style={{ display: "flex" }}>
                <div>{t("Progress")}</div>
                <div
                  className={styles.closeBtnBox}
                  style={
                    {
                      // backgroundImage: `url(${closeIcon})`,
                      // backgroundSize: "20px 20px",
                    }
                  }
                  onClick={() => {
                    confirmcloseModalDownload();
                  }}
                >
                  <ButtonCenterCLose />
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className={styles.itemFileMain}
            justify="start"
            style={{ width: "100%", alignItems: "center" }}
          >
            <Col
              style={{
                fontSize: "0.7rem",
                margin: "auto",
              }}
              span={22}
            >
              <div>
                <div style={{ width: "85%" }}>
                  <div>
                    <div
                      className={styles.textOverflowListFileText}
                      style={{ alignContent: "left" }}
                    >
                      <div>{getTextMain()}</div>
                    </div>
                  </div>
                </div>
                {!isCancelAll() && getAllPercent() < 100 && (
                  <div
                    className={styles.closeItemBtn}
                    style={{
                      backgroundImage: `url(${closeIcon})`,
                      backgroundSize: "15px 15px",
                    }}
                    onClick={() => {
                      closeAllItemDownload();
                    }}
                  ></div>
                )}
              </div>

              <div>
                <div className={getAllPercent() >= 50 ? styles.boxProgressBarAllMore50 : styles.boxProgressBarAll}>
                  <Progress
                    strokeWidth={7}
                    strokeColor={getColorLimitProgress(getAllPercent())}
                    percent={getAllPercent()}
                    status={"normal"}
                  />
                </div>
              </div>
              <div>
                {bytesToSizeUtil(getAllLoadedOnProgessCheckCancel()) +
                  " of " +
                  bytesToSizeUtil(getAllSizeCheckCancel())}
              </div>
            </Col>
          </Row>
          <Col className={styles.scrollableRow}>
            {appContext.progressLoadFiles?.map((item: any) => {
              return (
                <BoxDetailFileDownloadItem
                  item={item}
                  key={"download_file_" + item.file.file_app_id}
                />
              );
            })}
            <div></div>
          </Col>
        </div>
      </div>

      <CenterConfirmModal
        cancelText={t("Cancel")}
        confirmText={"Ok"}
        text={message}
        visible={showConfirm}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onConfirm={() => {
          closeItemDownload();
        }}
      ></CenterConfirmModal>


    </>
  ) : (
    <></>
  );
}

export default BoxDetailFileDownload;
