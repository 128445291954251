import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from './SyntaxLighter.module.scss';
import saveAs from "file-saver";

interface SyntaxLighterProps {
  codeString: string;
  language?: string;
  fileName?: string;
}

export default function SyntaxLighter({
  codeString,
  language = "latte",
  fileName
}: SyntaxLighterProps) {
  const CONTENT_HTML = "HTML";

  const codeTheme = {
    ...materialDark,
    'pre[class*="language-"]': {
      ...materialDark['pre[class*="language-"]'],
      background: '#1E1E1E',
    },
    'code[class*="language-"]': {
      ...materialDark['code[class*="language-"]'],
      color: '#D4D4D4'
    }
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(codeString);
  };

  const handleDownloadFile = () => {
    // Extract screen name from <title> tag
    const titleMatch = codeString.match(/<title>(.*?)<\/title>/);
    const screenName = titleMatch
      ? titleMatch[1].trim().toLowerCase().replace(/\s+/g, '_')
      : 'screen';

    // Create blob from HTML content
    const blob = new Blob([codeString], { type: 'text/plain' });

    // Use screen name from title for filename
    const extension = 'html';
    const filename = `${screenName}.${extension}`;

    saveAs(blob, filename);
  };

  return (
    <div className={styles['code-container']}>
      <div className={styles['code-toolbar']}>
        <div className={styles['file-tab']}>
          <span className={styles['file-name']}>{fileName}</span>
        </div>
        <div className={styles['toolbar-actions']}>
          <button
            className={styles['toolbar-button']}
            onClick={handleCopyCode}
          >
            Copy
          </button>
          <button
            className={styles['toolbar-button']}
            onClick={handleDownloadFile}
          >
            Download
          </button>
        </div>
      </div>
      <div className={styles['code-content']}>
        <div className={styles['line-numbers']}>
          {codeString.split('\n').map((_, i) => (
            <span key={i} className={styles['line-number']}>{i + 1}</span>
          ))}
        </div>
        <SyntaxHighlighter
          language={language}
          style={codeTheme}
          showLineNumbers={false}
          customStyle={{
            margin: 0,
            padding: '8px 0',
            fontSize: '13px',
            backgroundColor: '#1E1E1E',
            fontFamily: 'Consolas, Monaco, "Andale Mono", monospace'
          }}
          wrapLines={true}
          lineProps={() => ({
            style: {
              display: 'block',
              padding: '0 16px',
              backgroundColor: 'transparent',
              transition: 'background-color 0.2s'
            },
            className: styles['code-line']
          })}
        >
          {codeString}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}
