import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CancelCircleFillIcon from "src/assets/svg/cancel-circle-fill";
import CheckCircleFillIcon from "src/assets/svg/check-circle-fill";
import {
    fetchPlanProducts,
    fetchUserPlans,
    plansSelector,
    setSelectedPlan
} from "src/store/slices/payments/plansSlice";
import { useAppDispatch } from "src/store/store";
import { Product } from "src/types/PlanProduct.type";
import styles from "./Subscription.module.scss";
import { pathRoutes } from "src/page/props/util.props";
import { mapPlanItem } from "src/page/subscription/SubscriptionPage";
import { currencyFormat } from "src/components/payments/PaymentSummary";


const Subscription = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { plans } = useSelector(plansSelector);
    const { currPlan, loading, selectPlan } = useSelector(plansSelector);

    const onPayment = (currPlan: Product) => {
        dispatch(setSelectedPlan(currPlan));
        navigate(`${pathRoutes.Generate}/${pathRoutes.Payment}`);
    };

    const onContractSales = (currPlan: Product) => {
        dispatch(setSelectedPlan(currPlan));
        navigate(`${pathRoutes.Generate}/${pathRoutes.ContractSales}`);
    };

    const onSelectedPlan = (plan: Product) => {
        dispatch(setSelectedPlan(plan));
    };

    useEffect(() => {
        dispatch(fetchPlanProducts());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUserPlans());
    }, [dispatch]);


    const SubscriptionSkeleton = () => {
        return (
            <Container maxWidth="xl" sx={{ my: 6 }}>
                <Stack spacing={3}>
                    <Skeleton variant="text" width={400} height={60} sx={{ mx: "auto" }} />
                    <Grid container spacing={3}>
                        {/* Free Trial Skeleton */}
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Stack spacing={3} sx={{ pt: 3 }}>
                                                <Skeleton variant="text" width={200} height={40} />
                                                <Skeleton variant="text" width={250} height={60} />
                                                <Skeleton variant="rectangular" width={200} height={40} />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={8}>
                                            <Stack spacing={2}>
                                                {[...Array(11)].map((_, index) => (
                                                    <Stack key={index} direction="row" justifyContent="space-between" alignItems="center">
                                                        <Skeleton variant="text" width={150} />
                                                        <Skeleton variant="text" width={50} />
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Subscription Plans Skeleton */}
                        {[...Array(4)].map((_, index) => (
                            <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
                                <Card>
                                    <CardContent>
                                        <Stack spacing={3} sx={{ pt: 3 }}>
                                            <Stack spacing={2}>
                                                <Box sx={{ height: 100 }}>
                                                    <Skeleton variant="text" width={150} height={30} />
                                                    <Skeleton variant="text" width={200} height={60} />
                                                </Box>
                                                <Skeleton variant="text" width={120} height={50} />
                                                <Skeleton variant="rectangular" width="100%" height={40} />
                                            </Stack>
                                            <Stack spacing={1}>
                                                {[...Array(11)].map((_, idx) => (
                                                    <Stack key={idx} direction="row" justifyContent="space-between" alignItems="center">
                                                        <Skeleton variant="text" width={150} />
                                                        <Skeleton variant="text" width={50} />
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Container>
        );
    };

    if (loading) {
        return <SubscriptionSkeleton />;
    }

    const renderFreeTrial = (plan: Product) => {
        return (
            <Grid item key={plan.key} xs={12} onClick={() => onSelectedPlan(plan)}>
                <Card className={`${styles.toggleCard} ${plan.prod_id === selectPlan?.prod_id ? styles.selected : ''}`}>
                    <CardContent >
                        {plan.prod_id === currPlan?.prod_id && (
                            <Typography className={styles.currentPlanLabelFreeTrial}>
                                Current Plan
                            </Typography>
                        )}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Stack
                                    spacing={3}
                                    maxWidth={{ xs: 1, md: 250 }}
                                    sx={{ pt: plan.prod_id === currPlan?.prod_id ? '48px' : '32px' }}
                                >
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography variant="h6" pr={1} sx={{ fontSize: "24px" }}>
                                            Free Trial
                                        </Typography>
                                        <span>/ {plan.name}</span>
                                    </Stack>
                                    <Typography variant="caption">{plan.description}</Typography>
                                    <Button
                                        variant="outlined"
                                        sx={{ textTransform: "none", fontSize: "15px", fontWeight: 600 }}
                                        onClick={() => onPayment(plan)}
                                        disabled={plan.prod_id === currPlan?.prod_id}
                                    >
                                        {plan.prod_id === currPlan?.prod_id ? "Selected" : "Start Free Trial"}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={8}
                                sx={{ columnCount: { sm: 1, md: 2 }, columnGap: 10 }}
                            >
                                <Stack spacing={1}>
                                    {Object.entries(plan.metadata).map(([key, value]) => (
                                        <Stack
                                            key={key}
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                {plan.metadata[key] === "No" ? (
                                                    <CancelCircleFillIcon />
                                                ) : (
                                                    <CheckCircleFillIcon />
                                                )}
                                                <Typography
                                                    variant="caption"
                                                    pl={1}
                                                    className={styles.metadata}
                                                >
                                                    {mapPlanItem.get(key)}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="caption">
                                                {plan.metadata[key]}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    const renderSubscriptionPlans = (plan: Product) => {
        return <Grid
            item
            key={plan.key}
            xs={12}
            sm={6}
            md={6}
            lg={3}
            onClick={() => onSelectedPlan(plan)}
        >
            <Card className={`${styles.toggleCard} ${plan.prod_id === selectPlan?.prod_id ? styles.selected : ''}`}>
                <CardContent>
                    {plan.prod_id === currPlan?.prod_id && (
                        <Typography className={styles.currentPlanLabel}>
                            Current Plan
                        </Typography>
                    )}
                    <Stack
                        spacing={3}
                        sx={{ pt: plan.prod_id === currPlan?.prod_id ? '48px' : '32px' }}
                    >
                        <Stack spacing={2}>
                            <Box sx={{ height: 100 }}>
                                <Typography variant="h6">{plan.name}</Typography>
                                <Typography variant="caption">{plan.description}</Typography>
                            </Box>
                            {plan.key === "ENTERPRISE" ? (
                                <Typography variant="h4" pr={1}>
                                    Custom
                                </Typography>
                            ) : (
                                <Stack flexDirection="row" alignItems="center">
                                    <Typography variant="h4" pr={1} sx={{ fontSize: "36px" }}>
                                        {currencyFormat(plan?.monthly_prices.unit_amount ?? 0)}
                                    </Typography>
                                    <span>/ Month</span>
                                </Stack>
                            )}
                            <Button
                                variant="outlined"
                                sx={{ textTransform: "none", fontSize: "15px", fontWeight: 600 }}
                                onClick={plan.key === "ENTERPRISE" ? () => { onContractSales(plan) } : () => onPayment(plan)}
                                disabled={plan.prod_id === currPlan?.prod_id}
                            >
                                {plan.prod_id === currPlan?.prod_id ? "Selected" :
                                    plan.key === "ENTERPRISE" ? "Contact Sales" : "Subscribe Now"}
                            </Button>
                        </Stack>
                        <Stack spacing={1}>
                            {Object.entries(plan.metadata).map(([key, value]) => (
                                <Stack
                                    key={key}
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Stack
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <CheckCircleFillIcon />
                                        <Typography
                                            variant="caption"
                                            pl={1}
                                            className={styles.metadata}
                                        >
                                            {mapPlanItem.get(key)}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="caption">
                                        {plan.metadata[key]}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>;
    }

    return (
        <div className={styles.subscriptionPage}>
            <Container sx={{ my: 6, maxWidth: "1400px !important" }}>
                {plans?.response && (
                    <Grid container spacing={3}>
                        {
                            plans?.response.map((plan, key) =>
                                plan.key === "FREE" ?
                                    renderFreeTrial(plan) :
                                    renderSubscriptionPlans(plan)
                            )
                        }
                    </Grid>
                )}


                <div style={{ width: '100%', height: '300px' }} />

            </Container>
        </div>
    );
};

export default Subscription;