import axios from "axios";
import { checkEmpty } from "./util.service";
const APIEndpoint = {
  searchBy: "/select?",
};
//@ts-ignore
export const REACT_APP_SOLR_SERVER = window["env"]["REACT_APP_SOLR_SERVER"];
//@ts-ignore
export const REACT_APP_SOLR_CORENAME = window["env"]["REACT_APP_SOLR_CORENAME"];
export async function search(
  text: string,
  userID: string,
  offsetFile: number,
  limitOfset: number
) {
  let query = `start=${offsetFile}&rows=${limitOfset}&q=${encodeURIComponent(`user_id:${userID} AND file_status:Active`)}`;
  if (!checkEmpty(text)) {
    query += ` ${encodeURIComponent(`AND (content:${text} OR content:*${text}*)`)}`;
  }
  const url =
    REACT_APP_SOLR_SERVER +
    "/" +
    REACT_APP_SOLR_CORENAME +
    APIEndpoint.searchBy +
    query;
  console.log("solr ulr: ", url);
  let config: any = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {},
  };

  return axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
