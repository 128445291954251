import React, { useContext } from "react";

import styles from "./FileContainer.module.scss";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { FileContext } from "../File.context";
import { tabName } from "../properies.file";
import { checkEmpty } from "src/services/util.service";

const FileContainerTrash = (props: any) => {
  const { t, i18n } = useTranslation();
  const headerText = `${props.header}`;
  const fileContext: any = useContext(FileContext);
  const tableHeader = props.tableHeader;
  return (
    <>
      {tableHeader && (
        <div style={{ paddingBottom: "25px", paddingTop: "25px" }}>
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.cardGroupHeader}>{headerText}</div>
            <div
              style={{ fontSize: "1.125rem", fontWeight: 600, color: "#428DFF" }}
            ></div>
          </div>
          {/* <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          marginTop: 22,
        }}
      > */}{" "}
          <Row justify="start">
            <Col span={10}>
              <div style={{ textAlign: "left" }}>
                {t("Name")}
                {/* <ArrowUpOutlined style={{ marginLeft: 16 }} /> */}
              </div>
            </Col>
            <Col span={3}>
              <div style={{ textAlign: "left" }}>{t("Type")}</div>
            </Col>
            <Col span={5}>
              {" "}
              {(fileContext.tab === tabName.mediavideo ||
                fileContext.tab === tabName.mediaimage ||
                fileContext.tab === tabName.other ||
                fileContext.tab === tabName.doc ||
                fileContext.tab === tabName.trash ||
                fileContext.tab === tabName.search ||
                fileContext.tab === tabName.unknow) && (
                  <div style={{ textAlign: "left" }}>{t("File location")}</div>
                )}
              {fileContext.tab === tabName.myFile ||
                checkEmpty(
                  fileContext.tab && (
                    <div style={{ textAlign: "left" }}>{t("Member")}</div>
                  )
                )}
              {fileContext.tab === tabName.sharedWithMe && (
                <div style={{ textAlign: "left" }}>{t("Owner")}</div>
              )}
            </Col>
            <Col span={6}>
              <div style={{ textAlign: "left" }}>{t("Last modified")}</div>
            </Col>
          </Row>
          {/* </div> */}
          <div className={styles.cardGroup}>{props.children}</div>
        </div>
      )}
    </>
  );
};

export default FileContainerTrash;
