import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  processCancleSubscription,
  processCreatePaymentMethod,
  processPayment,
  processPaymentMethod,
} from "src/store/actions/payment/paymentActions";
import { PaymentMethod } from "src/types/PaymentMethodType";

interface PaymentsState {
  status: "loading" | "done" | "error";
  errorMessage: string | null;
  showLoadingDialog: boolean;
  paymentDetails: {
    paymentMethod: string;
    email: string;
    cardHolderName: string;
  };
  paymentMethods: PaymentMethod[];
}

const initialState: PaymentsState = {
  status: "loading",
  errorMessage: null,
  showLoadingDialog: false,
  paymentDetails: {
    paymentMethod: "visa",
    email: "",
    cardHolderName: "",
  },
  paymentMethods: [],
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<"loading" | "done" | "error">) => {
      state.status = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
    },
    setShowLoadingDialog: (state, action: PayloadAction<boolean>) => {
      state.showLoadingDialog = action.payload;
    },
    setPaymentDetails: (
      state,
      action: PayloadAction<Partial<PaymentsState["paymentDetails"]>>
    ) => {
      state.paymentDetails = { ...state.paymentDetails, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // Process Payment
    builder.addCase(processPayment.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(processPayment.fulfilled, (state) => {
      state.status = "done";
    });
    builder.addCase(processPayment.rejected, (state, action) => {
      state.status = "error";
      state.errorMessage = action.error.message || "An error occurred";

      if (action.error instanceof Error) {
        state.errorMessage = action.error.message;
      } else {
        state.errorMessage = "An unknown error occurred";
      }
    });

    // Cancle Subscription
    builder
      .addCase(processCancleSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(processCancleSubscription.fulfilled, (state) => {
        state.status = "done";
      })
      .addCase(processCancleSubscription.rejected, (state, action) => {
        state.status = "error";
        state.errorMessage = action.error.message || "An error occurred";
      });

    // Process Payment Method
    builder.addCase(processPaymentMethod.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(processPaymentMethod.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
      state.status = "done";
    });
    builder.addCase(processPaymentMethod.rejected, (state, action) => {
      state.status = "error";
      state.errorMessage = action.error.message || "An error occurred";
    });

    // Process Create Payment Method
    builder.addCase(processCreatePaymentMethod.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(processCreatePaymentMethod.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
      state.status = "done";
    });
    builder.addCase(processCreatePaymentMethod.rejected, (state, action) => {
      state.status = "error";
      state.errorMessage = action.error.message || "An error occurred";
    });
  },
});

export const {
  setStatus,
  setErrorMessage,
  setShowLoadingDialog,
  setPaymentDetails,
} = paymentsSlice.actions;
export const paymentsSelector = (store: RootState) => store.paymentsReducer;
export default paymentsSlice.reducer;
function dispatch(arg0: {
  payload: "loading" | "done" | "error";
  type: "payments/setStatus";
}) {
  throw new Error("Function not implemented.");
}
