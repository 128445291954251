import React from "react";

const StoryIcon = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width | "56"}
      height={height | "height"}
      viewBox="0 0 56 56"
      fill="none"
    >
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
      <rect
        x="4"
        y="4"
        width="48"
        height="48"
        rx="24"
        stroke="#FFFAEB"
        stroke-width="8"
      />
      <g clip-path="url(#clip0_7672_32497)">
        <path
          d="M28 16C25.6266 16 23.3066 16.7038 21.3332 18.0224C19.3598 19.3409 17.8217 21.2151 16.9135 23.4078C16.0052 25.6005 15.7676 28.0133 16.2306 30.3411C16.6936 32.6689 17.8365 34.8071 19.5147 36.4853C21.193 38.1635 23.3312 39.3064 25.6589 39.7694C27.9867 40.2324 30.3995 39.9948 32.5922 39.0866C34.7849 38.1783 36.6591 36.6402 37.9776 34.6668C39.2962 32.6935 40 30.3734 40 28C39.9966 24.8185 38.7312 21.7682 36.4815 19.5185C34.2318 17.2688 31.1815 16.0034 28 16V16ZM28 38C26.0222 38 24.0888 37.4135 22.4443 36.3147C20.7998 35.2159 19.5181 33.6541 18.7612 31.8268C18.0043 29.9996 17.8063 27.9889 18.1922 26.0491C18.578 24.1093 19.5304 22.3275 20.9289 20.9289C22.3275 19.5304 24.1093 18.578 26.0491 18.1921C27.9889 17.8063 29.9996 18.0043 31.8268 18.7612C33.6541 19.5181 35.2159 20.7998 36.3147 22.4443C37.4135 24.0888 38 26.0222 38 28C37.9971 30.6513 36.9426 33.1931 35.0679 35.0679C33.1931 36.9426 30.6513 37.9971 28 38Z"
          fill="#DC6803"
        />
        <path
          d="M28 21C27.7348 21 27.4804 21.1054 27.2929 21.2929C27.1054 21.4804 27 21.7348 27 22V30C27 30.2652 27.1054 30.5196 27.2929 30.7071C27.4804 30.8946 27.7348 31 28 31C28.2652 31 28.5196 30.8946 28.7071 30.7071C28.8947 30.5196 29 30.2652 29 30V22C29 21.7348 28.8947 21.4804 28.7071 21.2929C28.5196 21.1054 28.2652 21 28 21Z"
          fill="#DC6803"
        />
        <path
          d="M29 34C29 33.4477 28.5523 33 28 33C27.4477 33 27 33.4477 27 34C27 34.5523 27.4477 35 28 35C28.5523 35 29 34.5523 29 34Z"
          fill="#DC6803"
        />
      </g>
      <defs>
        <clipPath id="clip0_7672_32497">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(16 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default StoryIcon;
