import React, {useContext, useRef} from "react";

import styles from "./UIModalDownload.module.scss";
import {useTranslation} from "react-i18next";
import crossIcon from "src/assets/png/crossIcon.png";
import FolderIcon from "src/assets/png/FolderIcon.png";
//@ts-ignore
import _, {assign} from "lodash";
import {FileContext, FileContextProps} from "../File.context";
import {Progress} from "antd";
import {Modal} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {getClassNameByType} from "../File/UtilFileCard";
import UIFileType from "../type/UIFileType";
import UIModalConfrim from "./UIModalConfrim";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { checkEmpty } from "src/services/util.service";
import { bytesToSize, calPercentProgressBtnPercent, cancelUpload } from "../../util.service";
import { AppContext } from "src/page/context/App.context";

function UIModalUpload(props: any) {
  const { t, i18n } = useTranslation();

  const fileContext = useContext<FileContextProps>(FileContext);
  const appContext: any = useContext(AppContext);
  const inputFileToUploadEl: any = useRef(null);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const getStatusItem = (item: any) => {
    if (!fileContext.uploading && checkEmpty(item.percent)) return <></>;
    if (item.status === "cancel") {
      return (
        <div className={styles.progressWarning}>
          <Progress
            strokeColor={"#ff9c00"}
            percent={item.percent}
            status={"exception"}
          />
        </div>
      );
    } else if (item.status === "error") {
      return (
        <div className={styles.progressError}>
          <Progress
            strokeColor={"red"}
            percent={item.percent}
            status={"exception"}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.progressNormal}>
          <Progress
            strokeColor={"#0f4c82"}
            percent={item.percent}
            status={item.percent === 100 ? "normal" : "active"}
          />
        </div>
      );
    }
  };

  const toCancelUpload = () => {
    console.log("uploadItemToCancel", fileContext.uploadItemToCancel);
    if (checkEmpty(fileContext.uploadItemToCancel)) {
      for (var i = 0; i < fileContext.fileToUpload.length; i++) {
        if (!checkEmpty(fileContext.fileToUpload[i].cancel)) {
          for (var j = 0; j < fileContext.fileToUpload[i].total; j++) {
            try {
              if (!checkEmpty(fileContext.fileToUpload[i].cancel[j]))
                fileContext.fileToUpload[i].cancel[j]();
            } catch (e) {
              console.log(e);
            }
          }
          if (
            !checkEmpty(fileContext.fileToUpload[i].useName) &&
            !checkEmpty(fileContext.fileToUpload[i].hash)
          ) {
            cancelUpload(
              fileContext.fileToUpload[i].useName,
              fileContext.fileToUpload[i].hash
            );
          }
        }
      }
    } else {
      try {
        for (var j = 0; j < fileContext.uploadItemToCancel.total; j++) {
          console.log(
            "fileContext.uploadItemToCancel.cancel[j]",
            fileContext.uploadItemToCancel.cancel[j]
          );
          try {
            if (!checkEmpty(fileContext.uploadItemToCancel.cancel[j]))
              fileContext.uploadItemToCancel.cancel[j]();
          } catch (e) {
            console.log(e);
          }
        }
        if (
          !checkEmpty(fileContext.uploadItemToCancel.useName) &&
          !checkEmpty(fileContext.uploadItemToCancel.hash)
        ) {
          cancelUpload(
            fileContext.uploadItemToCancel.useName,
            fileContext.uploadItemToCancel.hash
          );
        }

        // fileContext.uploadItemToCancel.cancel();
        fileContext.setUploadItemToCancel(null);
      } catch (e) {
        console.error(e);
      }
    }
    fileContext.setFnShowModal(false);
  };

  const checkCloseDownloadModal = (item: any) => {
    appContext.setShowModalDownloadFiles(false);
  };

  const checkClickCancelItemUpload = (item: any) => {
    if (item.status === "progress") {
      fileContext.setUploadItemToCancel(item);
      checkCloseDownloadModal(item);
    } else {
      let fileToUploadT: any = Object.assign([], fileContext.fileToUpload);
      for (var i = 0; i < fileToUploadT.length; i++) {
        if (item.fullName === fileToUploadT[i]?.fullName) {
          fileToUploadT.splice(i, 1);
          i--;
          break;
        }
      }
      fileContext.setFileToUpload(Object.assign([], fileToUploadT));
      inputFileToUploadEl.current.value = null;
    }
  };

  const getNameOfFile = (item: any) => {
    if (item.type_ === "DIRECTORY") {
      return item.file_name + ".zip";
    } else {
      return item.fullName;
    }
  };

  const antIcon_2 = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <UIModalConfrim fnDone={toCancelUpload}></UIModalConfrim>
      <div className={styles.overideBlockUI}>
        <BlockUi
          tag="div"
          blocking={fileContext.checkingFile}
          message={t("Checking") + "..."}
        ></BlockUi>
      </div>

      <Modal
        centered
        visible={appContext.showModalDownloadFiles}
        className={styles.modalOveride}
        onCancel={() => {
          checkCloseDownloadModal(null);
        }}
      >
        <div
          onClick={() => {
            checkCloseDownloadModal(null);
          }}
          className={styles.closeBtn}
          style={{ backgroundImage: `url(${crossIcon})` }}
        />
        <div className={styles.boxCreate}>
          {/* <input
            ref={inputFileToUploadEl}
            className={styles.inputfile}
            type="file"
            multiple={!fileContext.isCheckIn}
            onChange={(event) => {
              onChangeSelectFile(event);
            }}
          ></input> */}
          <div style={{ display: "flex" }}>
            <div className={styles.textUpload}>{t("Download File")}</div>

            {/* <div
              className={styles.uploadBtn}
              onClick={() => {
                handleClickToSelectFiles();
              }}
            >
              <img src={upload_icon} height={22} width={31} />
            </div> */}
          </div>

          <div className={styles.listUploadFile}>
            {appContext.downloadFiles?.map((item: any, index: number) => {
              return (
                <div
                  key={"file_previrew_download" + index}
                  style={{
                    display: "flex",
                    paddingTop: "5px",
                  }}
                >
                  <div style={{ width: "15%" }}>
                    <div
                      className={getClassNameByType(item.type_, false, false)}
                      style={{ backgroundColor: "#674dff !important" }}
                    >
                      <div className={styles.verticalCenter}>
                        {item.type_ === "DIRECTORY" ? (
                          <div
                            style={{
                              backgroundImage: `url(${FolderIcon})`,
                              backgroundSize: "cover",
                              width: 43,
                              height: 36,
                            }}
                          ></div>
                        ) : (
                          <UIFileType
                            file={item}
                            type={item.type_}
                          ></UIFileType>
                        )}
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "80%", margin: "auto" }}>
                    <div className={styles.textOverflowListFileText_upload}>
                      <div>{getNameOfFile(item)}</div>
                    </div>
                    <div>
                      <div className={styles.sizeUpload}>
                        {bytesToSize(item.size_)}
                      </div>
                    </div>
                    <div>{getStatusItem(item)}</div>
                  </div>

                  <div
                    style={{
                      alignSelf: "center",
                      display:
                        calPercentProgressBtnPercent(fileContext.fileToUpload) <
                        100
                          ? "block"
                          : "none",
                      width: "5%",
                    }}
                    onClick={() => {
                      if (
                        calPercentProgressBtnPercent(fileContext.fileToUpload) <
                        100
                      )
                        checkClickCancelItemUpload(item);
                    }}
                  >
                    <div style={{ float: "right" }}>
                      <div
                        className={styles.closeBtnItem}
                        style={{
                          backgroundImage: `url(${crossIcon})`,
                          backgroundSize: "18px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* <div
            style={{
              display: fileContext.uploading ? "block" : "none",
              marginTop: "39px",
            }}
            className={
              calPercentProgressBtnPercent(fileContext.fileToUpload) >= 50
                ? styles.boxbtnUploadingMore50
                : styles.boxbtnUploading
            }
          >
            <Progress
              strokeWidth={52}
              strokeColor={"#0f4c82"}
              percent={calPercentProgressBtnPercent(fileContext.fileToUpload)}
              status={
                calPercentProgressBtnPercent(fileContext.fileToUpload) === 100
                  ? "normal"
                  : "active"
              }
            />
          </div> */}

          {/* <button
            className={styles.bottonBtnUpload}
            style={{
              fontSize: "1.25rem",
              marginTop: "39px",
              display: !fileContext.uploading ? "block" : "none",
            }}
          >
            <div onClick={handleClickCheckUpload}>
              {fileContext.uploading ? (
                <Spin indicator={antIcon} />
              ) : (
                getTextBtn()
              )}
            </div>
          </button> */}
        </div>
      </Modal>
    </>
  );
}

export default UIModalUpload;
