import React, { useContext, useEffect, useRef, useState, ChangeEvent } from "react";

import styles from "./UIUploadIcon.module.scss";
import { useTranslation } from "react-i18next";
import plus from "../file-icon/plus.png";
import minus from "../file-icon/minus.png";
import newMinus from "../file-icon/minus32.png";
import newPlus from "../file-icon/plus32.png";
import folder_write from "../file-icon/folder_write.png";
import NewFolderIcon from "../file-icon/folder-icon-light2.png";
import folder_upload from "../file-icon/folder-upload-icon2.png"
import upload_icon from "../file-icon/upload_icon.png";
import link_icon from "../file-icon/link-light.png";
import {CloudUploadOutlined} from '@ant-design/icons'

//@ts-ignore
import _ from "lodash";
import { FileContext, FileContextProps } from "../File.context";
import { notification } from "antd";
import { Modal } from "antd";
import { calPercentProgressBtnMain, getLsItem, onChangeSelectFileMain, onSetFolderFileMain, reflectorFileFolders } from "../../util.service";
const { confirm } = Modal;



function UIUploadIcon(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [expandUpload, setExpandUpload] = useState(false);
  const inputFileToUploadEl: any = useRef(null);
  const inputFolderToUploadEl = useRef<HTMLInputElement>(null);


  const getImg = () => {
    if (expandUpload) {
      return <img src={newMinus} height={25} width={25} />;
    } else {
      return <img src={newPlus} height={25} width={25} />;
    }
  };

  const onChangeSelectFile = async (event: any) => {
    onChangeSelectFileMain(event, fileContext, inputFileToUploadEl, confirm, t);
  };


  useEffect(() => {
    if (fileContext?.uploadErrorMessage && fileContext?.uploadErrorDescription) {
      notification["error"]({
        message: fileContext?.uploadErrorMessage,
        description: fileContext?.uploadErrorDescription,
      });

      //reset error message
      fileContext.setUploadErrorMessage(null);
      fileContext.setUploadErrorDescription(null);
    }
  }, [fileContext?.uploadErrorMessage, fileContext?.uploadErrorDescription]);

  useEffect(() => {
    if (fileContext.showCreateFolderModal || fileContext.showModalUpload) {
      setExpandUpload(false)
    }
  }, [fileContext.showCreateFolderModal, fileContext.showModalUpload]);

  const checkCanSelectFile = () => {
    let res = calPercentProgressBtnMain(fileContext.fileToUpload);
    if (fileContext.uploading) {
      if (res.status === "cancel") {
        return true;
      } else {
        if (res.percent === 100) return true;
        else return false;
      }
    } else return true;
  };



  const handleClickToSelectFiles = () => {
    if (checkCanSelectFile()) {
      let res = calPercentProgressBtnMain(fileContext.fileToUpload);
      if (res.status === "cancel" || res.percent === 100) {
        fileContext.setFileToUpload(Object.assign([], []));
      }
      fileContext.setUploading(false);
      inputFileToUploadEl.current.click();
    }

  };

  const handleClickToSelectFolder = () => {
    if (inputFolderToUploadEl.current) {
      // for fix defect when upload second folder files in folder is มั่ว
      fileContext.setFileToUpload(Object.assign([], []));
      inputFolderToUploadEl.current.value = '';
      inputFolderToUploadEl.current.setAttribute("webkitdirectory", "true");
      inputFolderToUploadEl.current.setAttribute("directory", "true");
      inputFolderToUploadEl.current.click();
    }
  };

  const handleClickToUploadLink = () => {
    console.log("handleClickToUploadLink()")
    fileContext.setIsShowModalUploadLink(true);
  };

  useEffect(() => {
    fileContext.setCreateFolderName("");
    fileContext.setEncryptFolder(false);
    fileContext.setControlVersion(false);
  }, [fileContext.showCreateFolderModal]);

  const onChangeSelectFolder = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      let folderSelected = false;

      const folderName = files[0].webkitRelativePath.split("/")[0];
      console.log(files);
      try {
        fileContext.setCreatingFolder(true);
        fileContext.setCreateFolderName(folderName);
        let can = true
        let folders = await getLsItem(
          "DIRECTORY",
          fileContext.parentFolderId,
          fileContext.groupId,
          fileContext.userId
        );
        for (let a = 0; a < folders.length; a++) {
          if (folders[a].file_name === folderName) {
            console.log("Name already existing!");
            can = false;
            fileContext.setDataErrorCreate(t("Name already existing!"));
            fileContext.setErrorCreate(true);

          }
        }
        if (can === false) {
          fileContext.setCreatingFolder(false);
          return;
        } else {
          fileContext.setShowCreateFolderModal(false);
          fileContext.setCreatingFolder(false);

          await onSetFolderFileMain(fileContext, files, confirm, t);

        }



      } catch (e) {
        console.log(e);
        fileContext.setCreatingFolder(false);
      }
    }
  };

  async function setFolder(res: any, type: string) {
    let { fileFolders, newLenght } = reflectorFileFolders(
      fileContext.fileFolders,
      type,
      res
    );
    fileContext.setFileFolders(fileFolders);
  }

  useEffect(() => {
    // console.log("isShared : ", props.isShared);
    // console.log("getRecursiveParents: ", props.getRecursiveParents)
  }, [props.isShared, props.getRecursiveParents])

  const getExpand = () => {
    if (expandUpload) {
      return (

        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", zIndex: "200" }}>
            {(props.isShared && props.getRecursiveParents?.length > 2) ?
              null : (

                <div
                  style={{
                    display: "flex",
                    marginTop: "22px",
                    transform: "translate(-130px, 0px)",
                  }}
                >
                  <span className={styles.tooltiparrowcontent}></span>
                  <div className={styles.tooltipcontent}>
                    <div className={styles.tooltipinner} role="tooltip">
                      {t("New Folder")}
                    </div>
                  </div>

                  <div
                    className={styles.searchBottonBtn}
                    onClick={() => {
                      fileContext.setShowCreateFolderModal(true);
                    }}
                  >
                    <img src={NewFolderIcon} height={22} width={25} />
                  </div>
                </div>
              )
            }


            <div
              style={{
                display: "flex",
                marginTop: "22px",
                transform: "translate(-130px, 0px)",
              }}
            >
              <span className={styles.tooltiparrowcontent}></span>
              <div className={styles.tooltipcontent}>
                <div className={styles.tooltipinner} role="tooltip">
                  {t("Upload Files")}
                </div>
              </div>
              <div
                className={styles.searchBottonBtn}
                onClick={() => {
                  handleClickToSelectFiles();
                }}
              >
                {/* <img src={upload_icon} height={22} width={31} /> */}
                <CloudUploadOutlined style={{ color:'#fff'}} />
              </div>
              <input
                ref={inputFileToUploadEl}
                className={styles.inputfile}
                type="file"
                multiple={!fileContext.isCheckIn}
                onChange={(event) => {
                  onChangeSelectFile(event);
                }}
              ></input>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "22px",
                transform: "translate(-130px, 0px)",
              }}
            >
              <span className={styles.tooltiparrowcontent}></span>
              <div className={styles.tooltipcontent}>
                <div className={styles.tooltipinner} role="tooltip">
                  {t("Upload Folder")}
                </div>
              </div>
              <div
                className={styles.searchBottonBtn}
                onClick={() => {
                  handleClickToSelectFolder();
                }}
              >
                <img src={folder_upload} height={22} width={25} />
              </div>
              <input
                ref={inputFolderToUploadEl}
                type="file"
                onChange={onChangeSelectFolder}
                multiple
                style={{ display: "none" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "22px",
                transform: "translate(-130px, 0px)",
              }}
            >
              <span className={styles.tooltiparrowcontent}></span>
              <div className={styles.tooltipcontent}>
                <div className={styles.tooltipinner} role="tooltip">
                  {t("Upload link")}
                </div>
              </div>
              <div
                className={styles.searchBottonBtn}
                onClick={() => {
                  handleClickToUploadLink();
                }}
              >
                <img src={link_icon} height={22} width={22} />
              </div>
            </div>


          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          className={styles.searchBottonBtn}
          onClick={() => {
            setExpandUpload(!expandUpload);
          }}
        >
          {getImg()}
        </div>
      </div>

      {getExpand()}
    </div>
  );
}

export default UIUploadIcon;
