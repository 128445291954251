import React from "react";

const AgentPersonality2 = (props) => {
  const { className, style, width, height, fill, opacity } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10657_4739)">
        <path
          d="M16.0002 21H16.4902C18.9902 21 21.1202 19.13 21.4502 16.66L21.6702 15H22.0002C23.3002 15 24.0002 13.97 24.0002 13C24.0002 11.58 21.0702 5.99002 19.8002 4.42002C17.3602 1.16002 13.4902 -0.449981 9.45024 0.110019C4.46024 0.790019 0.500237 4.95002 0.0502367 9.98002C-0.199763 12.75 0.590237 15.48 2.26024 17.67C2.74024 18.29 3.00024 19.13 3.00024 20.02V20.99C3.00024 22.64 4.35024 23.99 6.00024 23.99H13.0002C14.6502 23.99 16.0002 22.64 16.0002 20.99V21ZM18.2202 5.65002C19.5302 7.27002 21.8702 12.1 22.0002 13H20.8002C20.3002 13 19.8702 13.37 19.8102 13.87L19.4802 16.39C19.2802 17.88 18.0002 19 16.5102 19H15.0202C14.4702 19 14.0202 19.45 14.0202 20V21C14.0202 21.55 13.5702 22 13.0202 22H6.00024C5.45024 22 5.00024 21.55 5.00024 21V20.03C5.00024 18.7 4.59024 17.43 3.85024 16.46C2.48024 14.67 1.83024 12.43 2.04024 10.16C2.41024 6.04002 5.64024 2.65002 9.73024 2.09002C10.1602 2.03002 10.5902 2.00002 11.0102 2.00002C13.8402 2.00002 16.4702 3.30002 18.2302 5.65002H18.2202Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10657_4739">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AgentPersonality2;
