import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

interface AgentPersonalContentProps {
  control: Control<FieldValues>;
  errors: any;
}

const AgentPersonalContent: React.FC<AgentPersonalContentProps> = ({
  control,
  errors,
}) => {
  return (
    <div className="flex flex-col space-y-2">
      <span className="text-[#121927] text-lg font-medium">Persona</span>

      <div className="">
        <span className="text-[#121927] text-sm font-medium">Personality</span>
        <Controller
          name="personality"
          control={control}
          render={({ field }) => (
            <TextArea
              rows={4}
              {...field}
              status={errors.personality ? "error" : ""}
            />
          )}
        />
        {errors.personality && (
          <div className="text-red-500 text-sm mt-1">
            {errors.personality.message}
          </div>
        )}
      </div>

      {/* <div className="">
        <span className="text-[#121927] text-sm font-medium">
          Communication Style
        </span>
        <TextArea rows={4} maxLength={6} />
      </div> */}
    </div>
  );
};

export default AgentPersonalContent;
