import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenerateItemProps } from "src/page/generate/specification/Specifications";
import { RootState } from "src/store/store";

interface HistoryState {
  historyFiles: GenerateItemProps[];
  selectedHistoryItem: any | null;
  onHistoryItemClick: (item: any) => void;
  sidebarTabActive: "document" | "history" | "";
}

const initialState: HistoryState = {
  historyFiles: [],
  selectedHistoryItem: null,
  onHistoryItemClick: () => {},
  sidebarTabActive: "",
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setHistoryFiles(state, action: PayloadAction<GenerateItemProps[]>) {
      state.historyFiles = action.payload;
    },
    setSelectedHistoryItem(state, action: PayloadAction<any>) {
      state.selectedHistoryItem = action.payload;
    },
    setHistoryItemClickHandler(
      state,
      action: PayloadAction<(item: any) => void>
    ) {
      state.onHistoryItemClick = action.payload;
    },
    setSidebarTabActive(state, action: PayloadAction<"document" | "history">) {
      state.sidebarTabActive = action.payload;
    },
  },
});

export const {
  setHistoryFiles,
  setSelectedHistoryItem,
  setHistoryItemClickHandler,
  setSidebarTabActive,
} = historySlice.actions;

export const selectHistoryFiles = (state: RootState) =>
  state.history.historyFiles;
export const selectSelectedHistoryItem = (state: RootState) =>
  state.history.selectedHistoryItem;
export const selectHistoryItemClickHandler = (state: RootState) =>
  state.history.onHistoryItemClick;
export const selectSidebarTabActive = (state: RootState) =>
  state.history.sidebarTabActive;
export default historySlice.reducer;
