import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { ACTIVE_AGENT_ENDPOINT } from "src/utils/endpoint/pmtx.endpoint";

// http://localhost:3007/pmtx-api/v1/active-agents
export interface IActiveAgents {
    active_agent_usage: number;
}

// /token-used-latest
export const fetchActiveAgentsByUserID = createAsyncThunk<
IActiveAgents>("active_agents/fetchActiveAgentsByUserID", async () => {
  const response = await axios.get(
    // `ACTIVE_AGENT_ENDPOINT`,
    `${ACTIVE_AGENT_ENDPOINT}`,
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    }
  );

  return response.data;
});