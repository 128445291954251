import React, {useContext} from "react";

import styles from "./FolderContainer.module.scss";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom"
import * as Props from "../../../props/util.props";
import {FileContext} from "../File.context";
import { checkEmpty } from "src/services/util.service";
import { pathRoutes } from "../../util.service";

const FolderContainer = (props: any) => {
  const navigate = useNavigate();
  const headerText = `${props.header}`;
  const { t, i18n } = useTranslation();
  const fileContext: any = useContext(FileContext);
  const tableHeader = props.tableHeader

  const handleClickViewAllFolder = () => {
    let dirid = fileContext.currentFolder?.file_app_id;
    if (checkEmpty(dirid)) dirid = "";
    navigate(
      pathRoutes.File +
        "?id=" +
        fileContext.groupId +
        "&dirid=" +
        dirid +
        "&viewdir=y"
    );
  };

  return (
    <>
      {tableHeader && (
        <div style={{ paddingBottom: "25px" }}>
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.cardGroupHeader}>{headerText}</div>
            <div
              style={{ fontSize: "1.125rem", fontWeight: 600, color: "#428DFF" }}
            >
              {/* <div style={{ cursor: "pointer" }} onClick={handleClickViewAllFolder}>
                {t("View all")}
              </div> */}
            </div>
          </div>
          <div className={styles.cardGroup}>{props.children}</div>
        </div>
      )}
    </>
  );
};

export default FolderContainer;
