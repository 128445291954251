import React , {useState} from 'react'
import styles from "../history/History.module.scss";
import { Input } from 'antd';
import { Space, Switch, Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { style } from '@mui/system';
import IconSearch from 'src/assets/svg/icon-search'
import IconCarendar from 'src/assets/svg/calendar'
import IconTimePast from 'src/assets/svg/time-past'
import Pagination from 'src/components/pagination/pagination'

const { Search } = Input;

let PageSize = 10;

interface tableDataType {
  key: string;
  applicationName: string;
  createDate: string;
}

const dataSource: tableDataType[] = [
  { key: '1', applicationName: 'Task Assignment _ User Stories', createDate: '16 Oct 2024' },
  { key: '2', applicationName: 'Task Assignment _ User Stories', createDate: '16 Oct 2024' },
  { key: '3', applicationName: 'Task Assignment _ Functional', createDate: '16 Oct 2024'},
  { key: '4', applicationName: 'Task Assignment _ Functional', createDate: '16 Oct 2024'},
  { key: '5', applicationName: 'Task Assignment _ Screen Specifications', createDate: '16 Oct 2024' },
  { key: '6', applicationName: 'Task Assignment _ Screen Specifications', createDate: '16 Oct 2024' },
  { key: '7', applicationName: 'Task Assignment _ HTML', createDate: '16 Oct 2024'},
  { key: '8', applicationName: 'Task Assignment _ HTML', createDate: '16 Oct 2024'},
];

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

const columns: TableColumnsType<tableDataType> = [
  {
    title: 'Application name',
    dataIndex: 'applicationName',
    key: 'applicationName',
  },
  {
    title: 'Create date',
    dataIndex: 'createDate',
    key: 'createDate',
    width: '30%',
  }
];


function History() {

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const paginatedData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const rowSelection: TableRowSelection<tableDataType> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  
  const [valueSearch, setValueSearch] = useState<string>("");

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
    console.log(event.target.value);
  };
  
  const serachEventHandle = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchHistory();
    }
  };

  const searchHistory = () => {
    console.log("valueSearch : ", valueSearch)
  }

  


  return (
    <>
      <div className={styles.container}>
          <div className={styles.mainContent}>
              <div className={styles.headerContainer}>
                <IconTimePast/>
                <div className={styles.headerText}>
                  History
                </div>
              </div>
              <div className={styles.searchContainer}>
                <p className={styles.searchText}>Explore your history</p>
                <div className={styles.searchBox} >
                  <input 
                    onChange={onChangeSearch} 
                    onKeyPress={serachEventHandle} 
                    value={valueSearch}
                    className={styles.searchInput} 
                    placeholder="Search application name" 
                    type="string" >
                  </input>
                  <IconSearch/>
                </div>
              </div>
              <div className={styles.content}> 
                {/* Custom Table */}
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr className={styles.trH} >
                      <th className={styles.th}>Application name</th>
                      <th className={styles.th}>Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item : tableDataType) => (
                      <tr className={styles.trR}  key={item.key} style={{ textAlign: 'left' }}>
                        <td className={styles.td}>{item.applicationName}</td>
                        <td className={styles.td}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconCarendar/>
                            <p style={{ marginLeft : '10px', marginBottom : '0', marginTop : '0', width: 'fit-content'}}>
                              {item.createDate}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={dataSource.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
      </div>
    </>
  )
}

export default History