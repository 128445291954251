import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { CONTACT_SALE } from "src/utils/endpoint/pmtx.endpoint";
import { ContactSale, ContactSaleReq } from "../../../model/contact-sale";

export const handelerContactSalesTeam = createAsyncThunk<
  ContactSale,
  ContactSaleReq // Optional query parameters
>("contact/handelerContactSalesTeam", async (ContactSaleReq) => {
  const response = await axios.post(`${CONTACT_SALE}`, ContactSaleReq, {
    headers: {
      Authorization: getAuthorizationPmtx(),
    },
  });

  return response.data.response;
});
