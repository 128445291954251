import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAllInvoices } from "src/store/actions/payment/billingActions";
import { RootState } from "../../store";
import {
  InvoiceResponse,
} from "src/model/invoice";
import { PagingResponse } from "src/model/paging";

type billingState = {
  activeTab: string;
  invoices?: PagingResponse<InvoiceResponse>;
  loading: boolean;
  error: boolean;
  errorMsg?: string;
};

const initialValues: billingState = {
  activeTab: 'overview',
  loading: false,
  error: false,
};

const billingSlice = createSlice({
  name: "billing",
  initialState: initialValues,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllInvoices.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchAllInvoices.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.invoices = action.payload;
    });
    builder.addCase(fetchAllInvoices.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorMsg = action.error.message;
    });
  },
});

export const {setActiveTab} = billingSlice.actions;
export const billingSelector = (store: RootState) => store.billingReducer;
export default billingSlice.reducer;
