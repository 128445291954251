import React, {useContext, useEffect, useState} from "react";

import styles from "./UIModalRename.module.scss";
import {useTranslation} from "react-i18next";
//@ts-ignore
import _ from "lodash";
import {FileContext, FileContextProps} from "../File.context";
import {Spin} from "antd";
import {Modal, Button} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import { getLsItem, renameDir } from "../../util.service";
import { checkEmpty } from "src/services/util.service";
import ButtonCenterCLose from "../ButtonCenterClose";
import CenterButton from "src/shared/button/Button";

function UIModalRename(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [showRenameModal, setShowRenameModal]: any = useState(false);
  const [renameLoading, setRenameLoading]: any = useState(false);
  const [dataErrorReameData, setDataErrorReameData]: any = useState("");
  const [errorRename, setErrorRename]: any = useState("");
  const [renameTo, setRenameTo]: any = useState("");
  const [typeRename, setTypeRename]: any = useState("");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  // setShowRenameModal(props.showRenameModal);

  const handleRename = async () => {
    let renameTrim = renameTo.trim();
    setRenameLoading(true);
    if (renameTrim === fileContext.fileSelected?.file_name) {
      setRenameLoading(false);
      fileContext.setShowRenameModal(false);
      return;
    }
    try {
      let lstemp;
      if (fileContext.fileSelected?.type_ === "DIRECTORY") {
        lstemp = await getLsItem(
          "DIRECTORY",
          fileContext.parentFolderId,
          fileContext.groupId,
          fileContext.userId
        );
      } else {
        lstemp = await getLsItem(
          "FILE",
          fileContext.parentFolderId,
          fileContext.groupId,
          fileContext.userId
        );
      }

      let canRename = true;
      let type_ = t("file"),
        type_text = t("File");
      if (fileContext.fileSelected?.type_ === "DIRECTORY") {
        type_ = t("folder");
        type_text = t("Folder");
      }
      if (checkEmpty(renameTrim)) {
        setDataErrorReameData(
          t("{type} Name is Empty!").replace("{type}", type_text)
        );
        setErrorRename(true);
        canRename = false;
      }
      if (renameTrim?.length > 50) {
        console.log("more than 50");
        setDataErrorReameData(
          t("Allow maximum {type} name size value of 50 characters!").replace(
            "{type}",
            type_
          )
        );
        setErrorRename(true);
        canRename = false;
      }
      for (let a = 0; a < lstemp.length; a++) {
        if (lstemp[a].file_name === renameTrim && lstemp[a].file_type === typeRename) {
          canRename = false;
          setDataErrorReameData(t("Name already existing")+"!");
          setErrorRename(true);
        }
      }
      if (canRename) {
        let res: any = await renameDir(
          renameTrim,
          fileContext.groupId,
          fileContext.userId,
          fileContext.fileSelected?.file_app_id,
          fileContext.fileSelected?.file_name
        );
        console.log(res);
        let files = fileContext.fileFolders;
        let file = fileContext.fileSelected;
        if (fileContext.fileSelected?.type_ === "DIRECTORY") {
          files = fileContext.fileFolders;
        }
        let i = files.findIndex((item: any) => {
          return item.file_app_id === fileContext.fileSelected?.file_app_id;
        });
        let filesTemp = [];
        if (i != -1) {
          // files.splice(i,1)
          // filesTemp.push(res.data)
          // filesTemp = filesTemp.concat(files);
          filesTemp = files;
          filesTemp[i] = res.data;

          if (fileContext.fileSelected?.type_ === "DIRECTORY") {
            fileContext.setFileFolders(Object.assign([], filesTemp));
          } else {
            let ts = [];
            console.log(filesTemp);
            for (let a = 0; a < filesTemp?.length && ts.length != 4  ; a++) {
              if(filesTemp[a].type_ === "FILE"){
                console.log("AA");
                 ts.push(filesTemp[a]);
              }
            }
            fileContext.setRecentFiles(Object.assign([], ts));
            fileContext.setFileFolders(Object.assign([], filesTemp));
          }
          // handleUpdateSaveStorageStructure(
          //   {
          //     parent_folder_id: props.getId(),
          //     lsStructure: files,
          //   },
          //   props.getKeyStorage(props.groupId)
          // );
          fileContext.setShowRenameModal(false);
          setRenameLoading(false);
        }
      } else {
        setRenameLoading(false);
      }
    } catch (e) {
      console.error(e);
      setDataErrorReameData(t("Network Error")+"!");
      setErrorRename(true);
      setRenameLoading(false);
    }
  };

  useEffect(() => {
    setRenameTo(fileContext.fileSelected?.file_name);
    setTypeRename(fileContext.fileSelected?.file_type);
  }, [fileContext.fileSelected]);

  // useEffect(() => {
  //   setRenameTo(fileContext.fileSelected?.file_name);
  //   setTypeRename(fileContext.fileSelected?.file_type);
  // }, [fileContext.setShowRenameModal]);

  return (
    <Modal
      centered
      visible={fileContext.showRenameModal}
      className={styles.modalOveride}
      onCancel={() => {
        fileContext.setShowRenameModal(false);
        setErrorRename(false);
        setRenameTo(fileContext.fileSelected?.file_name);
        setTypeRename(fileContext.fileSelected?.file_type);
      }}
    // closeIcon={

    // }
    >
      <div
        onClick={() => {
          fileContext.setShowRenameModal(false);
          setErrorRename(false);
          setRenameTo(fileContext.fileSelected?.file_name);
          setTypeRename(fileContext.fileSelected?.file_type);
        }}
        className={styles.closeBtn}
      // style={{ backgroundImage: `url(${crossIcon})` }}

      ><ButtonCenterCLose></ButtonCenterCLose></div>
      <div className={styles.boxCreate}>
        <div style={{ fontSize: "1rem", fontWeight: "500" }}>
          {t("Rename")}
        </div>
        <div style={{ fontSize: "0.9rem", display: "flex", marginTop: "25px", paddingBottom: "25px" }}>
          <input
            style={{ fontSize: "0.9rem" }}
            value={renameTo}
            disabled={renameLoading}
            type="text"
            placeholder={t("File name")}
            className={styles.boxCreateInput}
            onChange={(event) => {
              if (event.target.value.length > 50) {
                let v = event.target.value.substring(0, 50);
                setDataErrorReameData(
                  t("Allow maximum folder name size value of 50 characters")+"!"
                );
                setErrorRename(true);
                setRenameTo(v);
              } else {
                setErrorRename(false);
                setRenameTo(event.target.value);
              }
            }}
          />
        </div>
        {errorRename ? (
          <div className={styles.BErrorMessage}>
            <div className="error-message">{dataErrorReameData}</div>
          </div>
        ) : null}

        {/* <button
          className={styles.bottonBtn}
          style={{ fontSize: "1.2rem", marginTop: "39px" }}
        >
          <div onClick={handleRename}>
            {renameLoading ? <Spin indicator={antIcon} /> : t("Save")}
          </div>
        </button> */}
        {/* <CenterButton > */}
        <Button type="primary">
          <div onClick={handleRename}>
            {renameLoading ? <Spin indicator={antIcon} /> : t("Save")}
          </div>

        </Button>
        {/* </CenterButton> */}
      </div>
    </Modal>
  );
}

export default UIModalRename;
