import React, { useEffect } from "react";

import * as Props from "../../props/util.props";
import { useLocation, useParams } from "react-router-dom";
import { FileProviderContext } from "./File.context";
import UICoFileContainer from "./UICoFileContainer";
import { checkEmpty, constKey, mainTab } from "../util.service";

interface UICoFileProps {
  isShared?: boolean
}

export default function UICoFile({ isShared=false }:UICoFileProps) {
  const { dir, id, tab } = useParams();
  const location = useLocation();

  const getId = () => {
    return id;
  };

  const getDirId = () => {
    // let dirid = match?.params?.dir; //new URLSearchParams(props.location.search).get("dirid");

    console.log("dir:" + dir);
    return dir;
  };

  const getTab = () => {
    // let tab = matchTab?.params?.tab; //new URLSearchParams(props.location.search).get("tab");
    return tab;
  };
  const getMainTab = () => {
    let maintab = new URLSearchParams(location.search).get("maintab");
    if (checkEmpty(maintab)) maintab = mainTab.files;
    // console.log("")
    return maintab;
  };
  useEffect(() => { }, []);

  return (
    <FileProviderContext>
      <UICoFileContainer
        id={id === constKey.myId ? "" : id}
        dirId={dir}
        isShared={isShared}
        tab={tab}
        maintab={getMainTab}
        isCommunity={false}
      />
    </FileProviderContext>
  );
}



