import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Card, CardContent, Container, Grid, Link, Typography, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import { LoadingDialog } from 'src/components/payments/LoadingDialog';
import { PaymentForm } from 'src/components/payments/PaymentForm';
import { PaymentSummary } from 'src/components/payments/PaymentSummary';
import { UpgradePlan } from 'src/components/payments/UpgradePlan';
import styles from './PaymentsPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

const PaymentsPage = () => {
  const navigate = useNavigate();
  return (
    <Layout style={{ height: '100vh' }}>
      <LoadingDialog />
      <Content
        style={{
          height: 'calc(100vh - 0px)',
          overflow: 'auto',
          padding: '32px 64px',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Box sx={{ maxWidth: 1440, margin: '0 auto' }}>
          <Box mb={5}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate('/generate/subscription')}
              >
                Pricing
              </Link>
              <Typography className={styles.headerBreadcrumb}>Payment</Typography>
            </Breadcrumbs>

            <Typography
              variant="h4"
              sx={{
                mt: 3,
                fontWeight: 600,
                maxWidth: 500
              }}
            >
              Start your journey with Essential perks and fast
            </Typography>
          </Box>
          <Card sx={{ borderRadius: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <CardContent>
                  <PaymentForm />
                </CardContent>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <PaymentSummary />
                    </Grid>
                    <Grid item xs={12}>
                      <UpgradePlan />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Card>

        </Box>
      </Content>
    </Layout>
  );
};

export default PaymentsPage;
