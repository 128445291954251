import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom"
import FolderIcon from "src/assets/png/newFolderIcon.png";
import KeyLock from "src/assets/png/KeyLock.png";
import FolderDocument from "src/assets/png/FolderDocument.png";
import { useTranslation } from "react-i18next";
import styles from "./FolderContainer.module.scss";
import { FileContext } from "../File.context";
import { tabName, tabNameText } from "../properies.file";
import UIFileMenuPopover from "../component/UIFileMenuPopover";

import connection_file from "src/assets/png/link.png";
import { checkEmpty, isTrue } from "src/services/util.service";
import { constKey, isCommunity, mainState, pathRoutes } from "../../util.service";

interface FolderCardProps {
  folderName: string;
  totalSubFolder: number;
  totalFile: number;
  isLock: boolean;
}

const FolderCard = (props: any) => {
  console.log("folder card:", props.file);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const fileContext: any = useContext(FileContext);
  const [showMenu, setShowMenu] = useState(false);

  const getTextCountFile = (v: any) => {
    if (v <= 1) return t("file");
    if (v > 1) return t("files");
  };
  
  // console.log("CHECK_FILE", props.file)

  const handleClickFolder = () => {
    if (fileContext.tab === tabName.trash) return;
    let id = fileContext.groupId;
    if (checkEmpty(id)) id = "";

    fileContext.setFileFolders([]);
    // fileContext.setGroupId(id);
    // fileContext.setParentFolderId(props.file?.file_app_id);
    if (checkEmpty(id)) id = constKey.myId;
    if (fileContext.isComunity) {
      navigate({
        pathname:
          pathRoutes.groupCommunity +
          "/" +
          id +
          "/folder/" +
          props.file?.file_app_id,
        search: `?id=${id}&tab=file`,
      });
    } else
      console.log("isShared ", props.isShared);
    if (props.file?.file_name == tabNameText.sharedWithMe) {
      navigate(
        pathRoutes.File + "/id/" + id + "/folder/shared-with-me/" + props.file?.file_app_id
      );
    } else
      if (props.isShared) {
        navigate(
          pathRoutes.File + "/id/" + id + "/folder/shared-with-me/" + props.file?.file_app_id
        );
      } else
        navigate(
          pathRoutes.File + "/id/" + id + "/folder/" + props.file?.file_app_id
        );
  };

  const handleShowMenu = () => {
    if (props.file.file_app_id === fileContext.currentShowMenuId) {
      fileContext.setCurrentShowMenuId(null);
    } else {
      fileContext.setCurrentShowMenuId(props.file.file_app_id);
    }
  };

  useEffect(() => {
    if (props.file.file_app_id === fileContext.currentShowMenuId) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [fileContext.currentShowMenuId]);

  useEffect(() => {
    setShowMenu(false);
    fileContext.setCurrentShowMenuId(null);
    // console.log(props.file);

  }, []);

  return (
    <div
      className={styles.boxFolder}
      style={{ background: (isCommunity() ? "transparent" : "") }}
    >
      <div
        style={{
          width: "100%",
          // height: 71,
          height: 60,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleClickFolder}
        >
          <div
            style={{
              backgroundImage: `url(${FolderIcon})`,
              backgroundSize: "cover",
              width: 43,
              height: 36,
            }}
          ></div>
          <div
            style={{
              backgroundImage: `url(${props.file.encrypt_type === mainState.RSA_2048 ? KeyLock : ""
                })`,
              backgroundSize: "cover",
              width: 15,
              height: 21,
              position: "relative",
              marginLeft: -11,
              marginTop: 46,
            }}
          ></div>
          <div style={{ marginLeft: 3, marginTop: 3 }}>
            <div
              style={{
                fontSize: "0.9rem",
                fontWeight: 500,
                color: "#36393A",
                width: "180px",
              }}
            >
              <div className={styles.textOverflowListFileText}>
                <div>{props.file.file_name}</div>
              </div>
            </div>
            <div
              style={{
                fontSize: "0.7rem",
                color: "#A0B1BD",
                display: "inline-flex",
                alignItems: "center",
                marginTop: 0,
              }}
            >
              {/* <div>{2} Sub folders</div>
              <div>|</div> */}
              <div>
              {/* <script>
                function log_console() {
                    console.log("CHECK_FILE_COUNT", props.file.file_name, props.file.countFile)
                }
              </script> */}
                {props.file.countFile +
                  " " +
                  getTextCountFile(props.file.countFile)}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginLeft: "-55px", display: "inline-flex", alignItems: "center" }}
        >
          {/* <div onClick={handleShowMenu} style={{ cursor: "pointer" }}>
            <MoreOutlined className={styles.iconMenu} /> */}

          <div
            style={{
              backgroundImage: `url(${isTrue(props.file.control_version) ? FolderDocument : ""
                })`,
              backgroundSize: "cover",
              width: 19,
              height: 22,
              marginTop: (props.isShared && isTrue(props.file.control_version)) ? "-30px" : ""
            }}
          >
            {props.isShared &&
              <div
                style={{
                  backgroundImage: `url(${connection_file})`,
                  backgroundSize: "cover",
                  opacity: 0.75,
                  width: 20,
                  height: 20,
                  marginTop: isTrue(props.file.control_version) ? "30px" : ""
                }}
              />
            }
          </div>
          <div style={{ width: "10%", position: "relative" }}>
            <UIFileMenuPopover
              showMenu={showMenu}
              handleShowMenu={handleShowMenu}
              file={props.file}
              setShowMenu={setShowMenu}
            />
          </div>
        </div>
      </div>
      {/* <div style={{ height: 71, display: "inline-flex", alignItems: "center", marginTop: 60 }}>
        <div style={{ fontSize: "1.6875rem", fontWeight: 500, color: "#0F4C82" }}>13.2G</div>
        <div style={{ fontSize: "0.75rem", color: "#0F4C82", alignItems: "center", marginLeft: 16 }}>
          <div>Gb</div>
          <div>Used</div>
        </div>
      </div> */}
      {/* <div
        style={{
          backgroundImage: `url(${FolderUsed})`,
          backgroundSize: "cover",
          width: "100%",
          height: 7
        }}
      ></div> */}
    </div>
  );
};

export default FolderCard;
