import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { PlanResponse, Product } from "src/types/PlanProduct.type";
import { getAuthorizationPmtx } from "src/services/util.service";
import { BASE_URL_PMTX } from "src/utils/endpoint/pmtx.endpoint";
import { UserPlanSelectorResponse } from "src/types/UserPlanSelect.type";

// สร้าง async action สำหรับดึงข้อมูลจาก API
export const fetchPlanProducts = createAsyncThunk<PlanResponse>(
  "products/fetchProducts",
  async () => {
    const response = await axios.get(BASE_URL_PMTX + "/products", {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    });
    return response.data;
  }
);

export const fetchUserPlans = createAsyncThunk(
  "plansSelector/fetchUserPlans",
  async () => {
    const response = await axios.get(BASE_URL_PMTX + "/user-plans-selector", {
      headers: {
        Authorization: getAuthorizationPmtx(),
      },
    });
    return response.data.response;
  }
);
type PlansState = {
  userSelectPlan?: UserPlanSelectorResponse;
  plans?: PlanResponse;
  currPlan?: Product;
  selectPlan?: Product;
  loading: boolean;
  error: boolean;
  errMessage?: string;
};

const initialValues: PlansState = {
  loading: false,
  error: false,
};

const plansSlice = createSlice({
  name: "plans",
  initialState: initialValues,
  reducers: {
    setCurrentPlan: (state, action) => {
      state.currPlan = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get All Plans
    builder
      .addCase(fetchPlanProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlanProducts.fulfilled, (state, action) => {
        let newProducts = action.payload;
        newProducts.response = action.payload?.response?.reverse();
        state.plans = newProducts;
        state.loading = false;
      })
      .addCase(fetchPlanProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errMessage = action.error.message || "Failed to load plans";
      });

    builder
      .addCase(fetchUserPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.userSelectPlan = action.payload;
        state.currPlan = action.payload?.product;
        state.selectPlan = action.payload?.product;
      })
      .addCase(fetchUserPlans.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setCurrentPlan, setSelectedPlan } = plansSlice.actions;
export const plansSelector = (store: RootState) => store.plansReducer;
export default plansSlice.reducer;
