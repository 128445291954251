import { Select, SelectProps } from "antd";
import React, { useEffect, useState } from "react";
import "./AgentExpertiseContent.css";
import axios from "axios";
import { Control, Controller, FieldValues } from "react-hook-form";

interface AgentExpertiseContentProps {
  control: Control<FieldValues>;
  errors: any;
}

// @ts-ignore
let workflow_url = `https://test-wf.oneweb.tech/api/v1/flows`;

const AgentExpertiseContent: React.FC<AgentExpertiseContentProps> = ({
  control,
  errors,
}) => {
  const [selectedExpertise, setSelectedExpertise] = useState<string[]>([]);
  const [expertiseList, setExpertiseList] = useState<any[]>([]);

  const fetchExpertiseList = async () => {
    const workflow_token = localStorage.getItem("wf_token");
    const workflow_projectId = localStorage.getItem("wf_project_id");
    try {
      const { data } = await axios.get(workflow_url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${workflow_token}`,
        },
        params: {
          projectId: workflow_projectId,
          limit: "1000",
        },
      });
      setExpertiseList(data.data);
    } catch (e) {
      console.error("Error fetching expertise list:", e);
    }
  };

  useEffect(() => {
    fetchExpertiseList();
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      <span className="text-[#121927] text-sm font-medium">Expertise</span>
      <div>
        <Controller
          name="expertise"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              style={{ width: "100%" }}
              placeholder="Please select"
              options={expertiseList.map((item) => ({
                value: item.version.id,
                label: item.version.displayName,
              }))}
              className="rounded-lg"
              popupClassName="rounded-lg"
              value={field.value?.map((item: any) => item.flow_id)}
              onChange={(value) => {
                const obj = expertiseList.find(
                  (item: any) => value === item.version.id
                );
                const flowName = obj?.version.displayName;

                const expertise = [
                  {
                    flow_id: value || "",
                    expertise_detail: flowName || "",
                  },
                ];
                field.onChange(expertise);
                setSelectedExpertise(value);
              }}
            />
          )}
        />
        {errors.expertise && (
          <div className="text-red-500 text-sm mt-1">
            {errors.expertise.message}
          </div>
        )}
      </div>
      {/* <div className="relative">
        {expertiseList && (
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            value={selectedExpertise}
            onChange={(e) => {
              let exp = [];

              for (let i = 0; i < e.length; i++) {
                let obj = expertiseList.find(
                  (item: any) => e[i] === item.version.id
                );

                let flowName = obj?.version.displayName;

                exp.push({
                  flow_id: e[i] || "",
                  expertise_detail: flowName,
                });
              }
              setFormData({ ...formData, expertise: exp });
              setSelectedExpertise(e);
            }}
            options={expertiseList.map((item) => ({
              value: item.version.id,
              label: item.version.displayName,
            }))}
            className="rounded-lg"
            popupClassName="rounded-lg"
            maxTagCount={0}
          />
        )}
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedExpertise.map((value, index) => {
            const option = expertiseList.find(
              (item: any) => value === item.version.id
            );

            const label = option?.version.displayName;
            return (
              <div
                key={index}
                className="inline-flex items-center gap-1 px-3 py-1 bg-[#EEF4FF] text-[#2970FF] rounded-full text-sm"
              >
                <span>{label}</span>
                <span
                  className="cursor-pointer"
                  style={{ fontSize: "27px" }}
                  onClick={() => {
                    const newValues = selectedExpertise.filter(
                      (_, i) => i !== index
                    );
                    setSelectedExpertise(newValues);
                  }}
                >
                  ×
                </span>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default AgentExpertiseContent;
