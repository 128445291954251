import { EmailOutlined as EmailIcon, PhoneOutlined as PhoneIcon } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleFillIcon from 'src/assets/svg/check-circle-fill';
// import { ListPlanItems } from 'src/page/subscription/SubscriptionPage';
import { plansSelector, setSelectedPlan } from 'src/store/slices/payments/plansSlice';
import { Product } from 'src/types/PlanProduct.type';
import { currencyFormat } from './PaymentSummary';
import styles from './UpgradePlan.module.scss';

export const UpgradePlan = () => {
    const dispatch = useDispatch();
    const { plans } = useSelector(plansSelector);
    const { currPlan, selectPlan } = useSelector(plansSelector);
    const [openEnterpriseDialog, setOpenEnterpriseDialog] = useState(false);
    const [openPlanDialog, setOpenPlanDialog] = useState(false);
    const [selectedPlanDetails, setSelectedPlanDetails] = useState<Product | undefined>(selectPlan);

    const handlePlanSelect = (plan: any) => {
        if (plan.key === "ENTERPRISE") {
            setOpenEnterpriseDialog(true);
        } else {
            setSelectedPlanDetails(plan);
            setOpenPlanDialog(true);
        }
    };

    const handleUpgrade = () => {
        dispatch(setSelectedPlan(selectedPlanDetails));
        setOpenPlanDialog(false);
    };

    return (
        <>
            <Card className={styles.cardContainer}>
                <CardContent>
                    <Typography variant="h6" pb={2}>Upgrade plan for more benefits</Typography>
                    <Stack spacing={1}>
                        {plans?.response.map((plan, key) => {
                            if (plan.key === currPlan?.key || plan.key === selectPlan?.key) return null;
                            return (
                                <Box
                                    key={key}
                                    className={styles.planBox}
                                    onClick={() => handlePlanSelect(plan)}
                                >
                                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <Typography>{plan.name}</Typography>
                                        <Typography>
                                            {plan.key === "ENTERPRISE" ? "Contact with sale" : `${currencyFormat(plan.monthly_prices.unit_amount)}`}
                                        </Typography>
                                    </Stack>
                                </Box>
                            );
                        })}
                    </Stack>
                </CardContent>
            </Card>

            {/* <Dialog
                open={openPlanDialog}
                onClose={() => setOpenPlanDialog(false)}
                maxWidth="md"
                className={styles.dialogWrapper}
            >
                <div className={styles.dialogContent}>
                    <div className={styles.dialogHeader}>
                        <Typography variant="h4">{selectedPlanDetails?.name}</Typography>
                        <Typography variant="h6">{selectedPlanDetails?.description}</Typography>
                    </div>

                    <div className={styles.priceContainer}>
                        {selectedPlanDetails?.name === "Enterprise" ? (
                            <Typography className={styles.priceAmount}>Custom</Typography>
                        ) : (
                            <div>
                                <span className={styles.priceAmount}>${selectedPlanDetails?.monthly_prices.unit_amount}</span>
                                <span className={styles.pricePeriod}>/month</span>
                            </div>
                        )}
                    </div>

                    <div className={styles.featureList}>
                        {ListPlanItems.map((item) => (
                            <div key={item.key} className={styles.featureItem}>
                                <div className={styles.featureContent}>
                                    <CheckCircleFillIcon className={styles.checkIcon} />
                                    <div className={styles.featureDetails}>
                                        <span className={styles.featureLabel}>{item.value}</span>
                                        <span className={styles.featureValue}>{selectedPlanDetails?.metadata[item.key]}</span>
                                    </div>
                                </div>
                                <div className={styles.featureDivider} />
                            </div>
                        ))}
                    </div>


                    <div className={styles.actionButtons}>
                        <Button
                            onClick={() => setOpenPlanDialog(false)}
                            className={styles.cancelBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUpgrade}
                            className={styles.upgradeBtn}
                        >
                            Upgrade Now
                        </Button>
                    </div>
                </div>
            </Dialog> */}

            <Dialog
                open={openPlanDialog}
                onClose={() => setOpenPlanDialog(false)}
                maxWidth="md"
                className={styles.dialogWrapper}
            >
                <div className={styles.dialogContent}>
                    <div className={styles.dialogHeader}>
                        <Typography variant="h4">{selectedPlanDetails?.name}</Typography>
                        <Typography variant="h6">{selectedPlanDetails?.description}</Typography>
                    </div>

                    <div className={styles.priceContainer}>
                        {selectedPlanDetails?.name === "Enterprise" ? (
                            <Typography className={styles.priceAmount}>Custom</Typography>
                        ) : (
                            <div>
                                <span className={styles.priceAmount}>{currencyFormat(selectedPlanDetails?.monthly_prices.unit_amount ?? 0)}</span>
                                <span className={styles.pricePeriod}>/month</span>
                            </div>
                        )}
                    </div>

                    {/* <div className={styles.featureList}>
                        {ListPlanItems.map((item) => (
                            <div key={item.key} className={styles.featureItem}>
                                <div className={styles.featureContent}>
                                    <CheckCircleFillIcon className={styles.checkIcon} />
                                    <div className={styles.featureDetails}>
                                        <span className={styles.featureLabel}>{item.value}</span>
                                        <span className={styles.featureValue}>{selectedPlanDetails?.metadata[item.key]}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}

                    <div className={styles.actionButtons}>
                        <Button onClick={() => setOpenPlanDialog(false)} className={styles.cancelBtn}>
                            Cancel
                        </Button>
                        <Button onClick={handleUpgrade} className={styles.upgradeBtn}>
                            Upgrade Now
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={openEnterpriseDialog}
                onClose={() => setOpenEnterpriseDialog(false)}
                maxWidth="md"
                fullWidth
                className={styles.enterpriseDialog}
            >
                <Box className={styles.dialogContainer}>
                    <Box className={styles.heroSection}>
                        <Box className={styles.heroContent}>
                            <Box className={styles.badge}>Enterprise</Box>
                            <Typography className={styles.heroTitle}>
                                Scale Your Business
                            </Typography>
                            <Typography className={styles.heroSubtitle}>
                                Get dedicated support and custom solutions
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={styles.contactSection}>
                        <Box className={styles.contactWrapper}>
                            <Typography className={styles.sectionTitle}>
                                Let's Connect
                            </Typography>

                            <Box className={styles.contactCards}>
                                <Box className={styles.contactCard}>
                                    <Box className={styles.iconBox}>
                                        <Box className={styles.iconInner}>📧</Box>
                                    </Box>
                                    <Box className={styles.cardContent}>
                                        <Typography className={styles.cardLabel}>
                                            Send us an email
                                        </Typography>
                                        <Typography className={styles.cardValue}>
                                            sales@company.com
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className={styles.contactCard}>
                                    <Box className={styles.iconBox}>
                                        <Box className={styles.iconInner}>📱</Box>
                                    </Box>
                                    <Box className={styles.cardContent}>
                                        <Typography className={styles.cardLabel}>
                                            Give us a call
                                        </Typography>
                                        <Typography className={styles.cardValue}>
                                            +1-234-567-8900
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Button
                                onClick={() => setOpenEnterpriseDialog(false)}
                                className={styles.actionButton}
                            >
                                Got it
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>

        </>
    );
};
