
import React from "react";

const Calendar = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clip-path="url(#clip0_10479_29172)">
                <path d="M8 0.103516C6.03034 0.10492 4.13075 0.834597 2.66667 2.15218V0.770182C2.66667 0.593371 2.59643 0.423802 2.47141 0.298778C2.34638 0.173754 2.17681 0.103516 2 0.103516C1.82319 0.103516 1.65362 0.173754 1.5286 0.298778C1.40357 0.423802 1.33333 0.593371 1.33333 0.770182V2.77018C1.33333 3.30062 1.54405 3.80932 1.91912 4.1844C2.29419 4.55947 2.8029 4.77018 3.33333 4.77018H5.33333C5.51015 4.77018 5.67971 4.69994 5.80474 4.57492C5.92976 4.4499 6 4.28033 6 4.10352C6 3.92671 5.92976 3.75714 5.80474 3.63211C5.67971 3.50709 5.51015 3.43685 5.33333 3.43685H3.33333C3.31085 3.43352 3.28859 3.42884 3.26667 3.42285C4.35301 2.32873 5.78542 1.64557 7.31938 1.48996C8.85333 1.33435 10.3938 1.71595 11.6777 2.56962C12.9616 3.42329 13.9095 4.69611 14.3595 6.17082C14.8094 7.64552 14.7337 9.23069 14.1451 10.6557C13.5564 12.0808 12.4915 13.2574 11.132 13.9847C9.77246 14.712 8.20266 14.9449 6.69054 14.6437C5.17843 14.3424 3.81772 13.5257 2.8407 12.333C1.86368 11.1402 1.33091 9.64535 1.33333 8.10352C1.33333 7.92671 1.2631 7.75714 1.13807 7.63211C1.01305 7.50709 0.843478 7.43685 0.666667 7.43685C0.489856 7.43685 0.320286 7.50709 0.195262 7.63211C0.0702379 7.75714 0 7.92671 0 8.10352C0 9.68577 0.469192 11.2325 1.34824 12.5481C2.22729 13.8637 3.47672 14.8891 4.93853 15.4946C6.40034 16.1001 8.00888 16.2585 9.56072 15.9498C11.1126 15.6411 12.538 14.8792 13.6569 13.7604C14.7757 12.6416 15.5376 11.2161 15.8463 9.66424C16.155 8.11239 15.9965 6.50386 15.391 5.04205C14.7855 3.58024 13.7602 2.33081 12.4446 1.45176C11.129 0.572708 9.58225 0.103516 8 0.103516Z" fill="black"/>
                <path d="M7.99995 4.10352C7.82314 4.10352 7.65357 4.17375 7.52855 4.29878C7.40352 4.4238 7.33329 4.59337 7.33329 4.77018V8.10352C7.33332 8.28031 7.40359 8.44985 7.52862 8.57485L9.52862 10.5749C9.65435 10.6963 9.82275 10.7635 9.99755 10.762C10.1723 10.7604 10.3396 10.6903 10.4632 10.5667C10.5868 10.4431 10.6569 10.2759 10.6584 10.1011C10.6599 9.92632 10.5927 9.75792 10.4713 9.63218L8.66662 7.82752V4.77018C8.66662 4.59337 8.59638 4.4238 8.47136 4.29878C8.34633 4.17375 8.17676 4.10352 7.99995 4.10352Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_10479_29172">
                <rect width="16" height="16" fill="white" transform="translate(0 0.103516)"/>
            </clipPath>
            </defs>
        </svg>
    );
}

export default Calendar;
