import React from "react";

function SvgFileIcon(props) {
  let blur = "";
  if (props.activeMove) {
    blur = 40;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="216"
      height="80"
      viewBox="0 0 216 80"
      fill="none"
    >
      <rect x="0.506836" width="216" height="80" rx="8" fill={"#E8F4FF"+blur} />
      <g clip-path="url(#clip0_10774_5036)">
        <path
          d="M125.771 31.3819L121.125 26.7339C120.26 25.8647 119.232 25.1756 118.099 24.7065C116.966 24.2373 115.751 23.9974 114.525 24.0005H108.505C106.738 24.0026 105.043 24.7057 103.794 25.9555C102.544 27.2053 101.841 28.8997 101.839 30.6672V49.3339C101.841 51.1013 102.544 52.7958 103.794 54.0456C105.043 55.2953 106.738 55.9984 108.505 56.0005H121.839C123.606 55.9984 125.301 55.2953 126.55 54.0456C127.8 52.7958 128.503 51.1013 128.505 49.3339V37.9805C128.509 36.7544 128.269 35.5398 127.799 34.4072C127.33 33.2745 126.64 32.2462 125.771 31.3819ZM123.885 33.2672C124.292 33.686 124.643 34.1568 124.927 34.6672H119.172C118.818 34.6672 118.479 34.5267 118.229 34.2767C117.979 34.0266 117.839 33.6875 117.839 33.3339V27.5792C118.349 27.8631 118.82 28.2127 119.24 28.6192L123.885 33.2672ZM125.839 49.3339C125.839 50.3947 125.417 51.4121 124.667 52.1623C123.917 52.9124 122.899 53.3339 121.839 53.3339H108.505C107.444 53.3339 106.427 52.9124 105.677 52.1623C104.927 51.4121 104.505 50.3947 104.505 49.3339V30.6672C104.505 29.6063 104.927 28.5889 105.677 27.8388C106.427 27.0886 107.444 26.6672 108.505 26.6672H114.525C114.744 26.6672 114.956 26.7099 115.172 26.7299V33.3339C115.172 34.3947 115.593 35.4121 116.344 36.1623C117.094 36.9124 118.111 37.3339 119.172 37.3339H125.776C125.796 37.5499 125.839 37.7605 125.839 37.9805V49.3339Z"
          fill={"#3875F6"+blur}
        />
      </g>
      <defs>
        <clipPath id="clip0_10774_5036">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(99.1719 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default React.memo(SvgFileIcon);
