import React from "react";

const AppAddIcon = (props) => {
  const { className, style, width, height, fill } = props;
  return (
    <svg
      width={width || "18"}
      height={height || "18"}
      className={className}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10108_3718)">
        <path
          d="M4.66667 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 4.66667C0 5.37391 0.280952 6.05219 0.781049 6.55229C1.28115 7.05238 1.95942 7.33333 2.66667 7.33333H4.66667C5.37391 7.33333 6.05219 7.05238 6.55229 6.55229C7.05238 6.05219 7.33333 5.37391 7.33333 4.66667V2.66667C7.33333 1.95942 7.05238 1.28115 6.55229 0.781049C6.05219 0.280952 5.37391 0 4.66667 0V0ZM6 4.66667C6 5.02029 5.85952 5.35943 5.60948 5.60948C5.35943 5.85952 5.02029 6 4.66667 6H2.66667C2.31304 6 1.97391 5.85952 1.72386 5.60948C1.47381 5.35943 1.33333 5.02029 1.33333 4.66667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H4.66667C5.02029 1.33333 5.35943 1.47381 5.60948 1.72386C5.85952 1.97391 6 2.31304 6 2.66667V4.66667Z"
          fill="white"
        />
        <path
          d="M4.66667 8.6665H2.66667C1.95942 8.6665 1.28115 8.94746 0.781049 9.44755C0.280952 9.94765 0 10.6259 0 11.3332L0 13.3332C0 14.0404 0.280952 14.7187 0.781049 15.2188C1.28115 15.7189 1.95942 15.9998 2.66667 15.9998H4.66667C5.37391 15.9998 6.05219 15.7189 6.55229 15.2188C7.05238 14.7187 7.33333 14.0404 7.33333 13.3332V11.3332C7.33333 10.6259 7.05238 9.94765 6.55229 9.44755C6.05219 8.94746 5.37391 8.6665 4.66667 8.6665ZM6 13.3332C6 13.6868 5.85952 14.0259 5.60948 14.276C5.35943 14.526 5.02029 14.6665 4.66667 14.6665H2.66667C2.31304 14.6665 1.97391 14.526 1.72386 14.276C1.47381 14.0259 1.33333 13.6868 1.33333 13.3332V11.3332C1.33333 10.9796 1.47381 10.6404 1.72386 10.3904C1.97391 10.1403 2.31304 9.99984 2.66667 9.99984H4.66667C5.02029 9.99984 5.35943 10.1403 5.60948 10.3904C5.85952 10.6404 6 10.9796 6 11.3332V13.3332Z"
          fill="white"
        />
        <path
          d="M13.3327 8.6665H11.3327C10.6254 8.6665 9.94716 8.94746 9.44706 9.44755C8.94697 9.94765 8.66602 10.6259 8.66602 11.3332V13.3332C8.66602 14.0404 8.94697 14.7187 9.44706 15.2188C9.94716 15.7189 10.6254 15.9998 11.3327 15.9998H13.3327C14.0399 15.9998 14.7182 15.7189 15.2183 15.2188C15.7184 14.7187 15.9993 14.0404 15.9993 13.3332V11.3332C15.9993 10.6259 15.7184 9.94765 15.2183 9.44755C14.7182 8.94746 14.0399 8.6665 13.3327 8.6665ZM14.666 13.3332C14.666 13.6868 14.5255 14.0259 14.2755 14.276C14.0254 14.526 13.6863 14.6665 13.3327 14.6665H11.3327C10.9791 14.6665 10.6399 14.526 10.3899 14.276C10.1398 14.0259 9.99935 13.6868 9.99935 13.3332V11.3332C9.99935 10.9796 10.1398 10.6404 10.3899 10.3904C10.6399 10.1403 10.9791 9.99984 11.3327 9.99984H13.3327C13.6863 9.99984 14.0254 10.1403 14.2755 10.3904C14.5255 10.6404 14.666 10.9796 14.666 11.3332V13.3332Z"
          fill="white"
        />
        <path
          d="M9.33268 4.6665H11.3327V6.6665C11.3327 6.84332 11.4029 7.01288 11.5279 7.13791C11.653 7.26293 11.8225 7.33317 11.9993 7.33317C12.1762 7.33317 12.3457 7.26293 12.4708 7.13791C12.5958 7.01288 12.666 6.84332 12.666 6.6665V4.6665H14.666C14.8428 4.6665 15.0124 4.59627 15.1374 4.47124C15.2624 4.34622 15.3327 4.17665 15.3327 3.99984C15.3327 3.82303 15.2624 3.65346 15.1374 3.52843C15.0124 3.40341 14.8428 3.33317 14.666 3.33317H12.666V1.33317C12.666 1.15636 12.5958 0.98679 12.4708 0.861766C12.3457 0.736742 12.1762 0.666504 11.9993 0.666504C11.8225 0.666504 11.653 0.736742 11.5279 0.861766C11.4029 0.98679 11.3327 1.15636 11.3327 1.33317V3.33317H9.33268C9.15587 3.33317 8.9863 3.40341 8.86128 3.52843C8.73625 3.65346 8.66602 3.82303 8.66602 3.99984C8.66602 4.17665 8.73625 4.34622 8.86128 4.47124C8.9863 4.59627 9.15587 4.6665 9.33268 4.6665Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10108_3718">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppAddIcon;
