import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
//@ts-ignore
import _ from "lodash";
import { FileContext, FileContextProps } from "../File.context";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getAboutLimitMain, reflectorFileFoldersDelete, restore } from "../../util.service";
import { checkEmpty } from "src/services/util.service";
import CenterConfirmModal from "../Modal/CenterConfirmModal";

function UIModalConfrimDeleteRestore(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const [loading, setLoading]: any = useState(false);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const getMessageDelete = () => {
    const fileType = fileContext.fileSelected?.type_ === "DIRECTORY" ? t("Folder") : t("File");
    const text = t("Are you sure to restore this {type}!")
      .replace("{type}", fileType);
    return text;
  };

  async function setFolderDelete(res: any, type: string) {
    let files_ = fileContext.fileFolders;
    let { files, newLenght } = reflectorFileFoldersDelete(files_, type, res);

    fileContext.setFiles(files);
  }


  const getAboutLimit = async () => {
    try {
      let data = await getAboutLimitMain({
        userId: fileContext.userId,
        groupId: fileContext.groupId,
      });
      fileContext.setAboutLimit(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fnDoneRestore = async () => {
    if (loading === true) return;
    try {
      setLoading(true);
      let groupId = fileContext.groupId;
      if (checkEmpty(groupId)) groupId = "";
      let res: any = await restore(fileContext.fileSelected);
      let file = Object.assign({}, fileContext.fileSelected);
      setFolderDelete(file, file.type_);
      fileContext.setDisplayConfirmDeleteRestore(false);


      getAboutLimit()
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const [messageNoti, setMessageNoti]: any = useState(getMessageDelete());

  return (
    // <Modal
    //   // centered
    //   visible={fileContext.displayConfirmDeleteRestore}
    //   className={styles.modalOveride}
    //   onCancel={() => {
    //     fileContext.setDisplayConfirmDeleteRestore(false);
    //   }}
    //   closeIcon={<div />}
    //   closable={false}
    // >
    //   <div className={styles.boxCreate}>
    //     <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
    //       {t("Restore file")}
    //     </div>

    //     <div style={{ fontSize: "1rem", color: "#5f5f5f" }}>{messageNoti}</div>

    //     <div className={styles.boxConfirmBtn}>
    //       <button
    //         className={styles.bottonBtnCancel}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div
    //           onClick={() => {
    //             if (loading === false)
    //               fileContext.setDisplayConfirmDeleteRestore(false);
    //           }}
    //         >
    //           {t("Cancel")}
    //         </div>
    //       </button>
    //       <button
    //         className={styles.bottonBtnOk}
    //         style={{ fontSize: "1.2rem", marginTop: "39px" }}
    //       >
    //         <div onClick={fnDoneRestore}>
    //           {loading ? <Spin indicator={antIcon} /> : t("Ok")}
    //         </div>
    //       </button>
    //     </div>
    //   </div>
    // </Modal>

    <CenterConfirmModal
      cancelText={t("Cancel")}
      confirmText={loading ? <Spin indicator={antIcon} /> : t("Ok")}
      text={messageNoti}
      visible={fileContext.displayConfirmDeleteRestore}
      onCancel={() => {
        if (loading === false) fileContext.setDisplayConfirmDeleteRestore(false);
      }}
      onConfirm={() => {
        fnDoneRestore();
      }}
    ></CenterConfirmModal>
  );
}

export default UIModalConfrimDeleteRestore;
