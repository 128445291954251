import React from "react";

function SvgClock(props) {
  let blur = "";
  if (props.activeMove) {
    blur = 40;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 14.494 14.494"
      style={{
        fill: "#7c7c7c",
        stroke: "707070",
        strokeWidth: "0.2px",
      }}
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.1 0.1)">
        <path
          class="a"
          d="M12.791,3.761a.6.6,0,0,0-.277.8,5.9,5.9,0,0,1,.59,2.591A5.956,5.956,0,1,1,7.148,1.191a5.884,5.884,0,0,1,3.7,1.285.6.6,0,1,0,.741-.932,7.148,7.148,0,1,0,2.709,5.6,7.074,7.074,0,0,0-.709-3.109.6.6,0,0,0-.8-.277Zm0,0"
          transform="translate(0 0)"
        />
        <path
          class="a"
          d="M176.6,64a.6.6,0,0,0-.6.6v4.169a.6.6,0,0,0,.6.6h2.978a.6.6,0,0,0,0-1.191h-2.382V64.6A.6.6,0,0,0,176.6,64Zm0,0"
          transform="translate(-169.448 -61.618)"
        />
      </g>
    </svg>
  );
}
export default React.memo(SvgClock);
