import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { PagingWithDaily } from "src/model/query/paging_with_daily";
import { TokenUsageTracking } from "src/model/usage-tracking";
import { getAuthorizationPmtx } from "src/services/util.service";
import { USER_AI_TOKEN_USED } from "src/utils/endpoint/pmtx.endpoint";

export interface AiTokenUsageDaily {
  id: string
  app_id_ai: string
  app_id_app_space: string
  userStories_token_usage: number
  functional_token_usage: number
  screenSpec_token_usage: number
  html_token_usage: number
  total_token_usage: number
  created_at: string
  updated_at: string
}

export interface UserTokenUsingResponse {
  id: string;
  appIdAi: string;
  appIdAppSpace?: string;
  userId: string;
  userGuestId?: string;
  userStoriesTokenUsage: number;
  functionalTokenUsage: number;
  screenSpecTokenUsage: number;
  htmlTokenUsage: number;
}

// /token-used-latest
export const fetchLatestAiTokenUsageDailyByUserID = createAsyncThunk<
AiTokenUsageDaily>("aiToken/fetchLatestAiTokenUsageDailyByUserID", async () => {
  const response = await axios.get(
    `${USER_AI_TOKEN_USED}-latest`,
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
        GuestUserID: localStorage.getItem("guest_user_id"),
      },
    }
  );

  return response.data;
});

// สร้าง async action สำหรับดึงข้อมูลจาก API
export const fetchAiTokenUsageDailyByUserID = createAsyncThunk<
  TokenUsageTracking<AiTokenUsageDaily>,
  PagingWithDaily
>("aiToken/fetchAiTokenUsageDailyByUserID", async (params) => {
  const response = await axios.get(
    `${USER_AI_TOKEN_USED}?offset=${params.offset}&limit=${params.limit}&start_date=${params.startDate}&end_date=${params.endDate}`,
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
        GuestUserID: localStorage.getItem("guest_user_id"),
      },
    }
  );

  return response.data;
});


// สร้าง async action สำหรับดึงข้อมูลจาก API
export const handlerCreateAiTokenByProjectID = createAsyncThunk<
  UserTokenUsingResponse,
  string
>("aiToken/handlerCreateAiTokenByProjectID", async (project_id: string) => {
  const response = await axios.post(
    `${USER_AI_TOKEN_USED}/${project_id}`,
    {},
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
        GuestUserID: localStorage.getItem("guest_user_id"),
      },
    }
  );
  return response.data;
});

// สร้าง async action สำหรับดึงข้อมูลจาก API
export const handleUpdateAiTokenByProjectID = createAsyncThunk<
  UserTokenUsingResponse,
  { project_id: string; appIdAppSpace?: string }
>("aiToken/updateAppIdAppSpace", async ({ project_id, appIdAppSpace }) => {
  const response = await axios.patch(
    `${USER_AI_TOKEN_USED}/${project_id}`, // Add 'create' to match backend route
    {},
    {
      headers: {
        Authorization: getAuthorizationPmtx(),
        GuestUserID: localStorage.getItem("guest_user_id"),
      },
    }
  );
  return response.data;
});
