/* eslint-disable no-unused-expressions */

import React from "react";

import styles from "./UIFileType.module.scss";
import FolderIcon from "src/assets/png/FolderIcon.png";
import SvgXd from "./icon/SvgXd";
import SvgAi from "./icon/SvgAi";
import SvgDoc from "./icon/SvgDoc";
import SvgeExcel from "./icon/SvgeExcel";
import SvgPdf from "./icon/SvgPdf";
import SvgPowerPoint from "./icon/SvgPowerPoint";
import SvgSvg from "./icon/SvgSvg";
import SvgAudio from "./icon/SvgAudio";
import SvgZip from "./icon/SvgZip";
import SvgOtherType from "./icon/SvgOtherType";
import SvgVideo from "./icon/SvgVideo";
import SvgPsd from "./icon/SvgPsd";
import SvgText from "./icon/SvgText";
import SvgImg from "./icon/SvgImg";
import SvgFileIcon from "./icon/SvgFileIcon";
import SvgLinkIcon from "./icon/SvgLinkIcon";
import LinkIconPrimary from "src/page/kn/filePage/file-icon/link-icon-primary.png";
import SvgFileIconBig from "./icon/SvgFileBig";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {supportViewer} from "../properies.file";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { checkEmpty } from "src/services/util.service";
import { BgImgWithLazy } from "../BgImgWithLazy/BgImgWithLazy";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { divide } from "lodash";

function UIFileType(props: any) {
  const { t } = useTranslation();
  let urlImage = null;
  let sharedWithMeFolder = "Shared with me";
  let defualtSize = "32";
  let defaultBigSize = "40";
  let boxSizeWidth = "32px";
  let boxSizeHeight = "32px";

  if (props.fromUI === "detail") {
    defualtSize = "150";
    defaultBigSize = "150";
  }
  if (!checkEmpty(props.size)) {
    defualtSize = props.size;
  }
  if (props.fromMedia) {
    defaultBigSize = "100%";
    defualtSize = "100%";
  }

  if (props.recentbig) {
    defaultBigSize = "45";
    defualtSize = "45";
    boxSizeWidth = "100%";
    boxSizeHeight = "93px";
  }
  if (props.recent2big) {
    defaultBigSize = "45";
    defualtSize = "45";
    boxSizeWidth = "100%";
    boxSizeHeight = "300px";
  }
  if (props.boxdetail) {
    boxSizeWidth = "100%";
    boxSizeHeight = "40px";
  }

  let uploadPreview = false;
  let type = props.file?.file_type;
  if (!checkEmpty(props.type)) {
    type = props.type;
    uploadPreview = true;
  }

  if (!checkEmpty(type)) type = type.toLowerCase();
  let disable = false;
  if (supportViewer.video.indexOf(type) > -1) {
    if (props.fromManageVersion) {
      return (
        // <div style={props.color != null ? { color: props.color } : {}}>
        <div className={`${styles.centercss} ${styles.flexcss}`}>
            {/* <SvgVideo */}
            <SvgFileIcon
              width={defaultBigSize}
              height={defaultBigSize}
              activeMove={disable}
            >
            </SvgFileIcon>
              
            {/* ></SvgVideo> */}
        </div>
        // </div>
      );
    } else {
      if (props.fromMedia) {
        defaultBigSize = "77px";
        defualtSize = "77px";
      }
      if (props.forMedias10) {
        defaultBigSize = "50px";
        defualtSize = "50px";
      }
      if (props.recentbig) {
        defaultBigSize = "64";
        defualtSize = "80";
        return (
          <SvgFileIconBig/>
        )
      }
      if (!checkEmpty(props.file?.file_app_id)) {
        urlImage = props.urlImage;
      }
      return (
        // <div className={styles.colorVideoType}>
        <div className={`${styles.centercss} ${styles.flexcss}`}>
          {/* <div className={styles.eyeViewVideo}>
            <i className="fas fa-eye"></i>
          </div> */}

          {/* {uploadPreview || checkEmpty(props.urlImage) ? ( */}
             {/* <SvgVideo
              width={defaultBigSize}
              height={"100%"}
              activeMove={disable}
              forMedias10={props.forMedias10}
            ></SvgVideo> */}
            <SvgFileIcon
              width={defaultBigSize}
              height={defaultBigSize}
              activeMove={disable}
            >
            </SvgFileIcon>
          {/* ) : ( */}
            {/* <div> */}
              {/* <div
                style={{
                  display: props.recentbig ? "none" : "block",
                  fontSize: props.fromMedia ? "20px" : "20px",
                }}
                className={[styles.playVideoIcon, styles.boxPlayer].join(" ")}
              > */}
              {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              > */}
                {/* <i className="fa fa-play" aria-hidden="true"></i> */}
                {/* <FontAwesomeIcon icon={faPlay as IconProp} /> */}
                {/* <SvgFileIcon
                  width={defaultBigSize}
                  height={defaultBigSize}
                  activeMove={disable}
                >
                </SvgFileIcon> */}
              {/* </div> */}
              {/* <div
                className={
                  props.fromMedia ? styles.imgFontTypeMedia : styles.imgFontType
                }
              > */}
                {/* <BgImgWithLazy
                  containerClassName="img"
                  key={props.file.file_app_id + "_view_file"}
                  classPlaceholder={"img-gray"}
                  src={urlImage}
                  style={{
                    filter: disable ? "sepia(1)" : "unset",
                    height: boxSizeHeight,
                    width: boxSizeWidth,
                    borderRadius: "8px",
                  }}
                /> */}
              {/* </div>
            </div> */}
          {/* )} */}
        </div>
      );
    }
  } else if (supportViewer.images.indexOf(type) > -1) {
    if (props.fromManageVersion) {
      return (
        // <div style={props.color != null ? { color: props.color } : {}}>
        <div className={`${styles.centercss} ${styles.flexcss}`}>
          {/* <SvgImg
            width={defaultBigSize}
            height={defaultBigSize}
            activeMove={disable}
          ></SvgImg> */}
           <SvgFileIcon
            width={defaultBigSize}
            height={defaultBigSize}
            activeMove={disable}
            >
          </SvgFileIcon>
        </div>
      );
    } else {
      if (!uploadPreview) {
        urlImage = props.urlImage;
      } else {
        try {
          urlImage = URL.createObjectURL(props.file);
        } catch {}
      }
      if (type === "svg" || type === "SVG") {
        return (
          // <div style={props.color != null ? { color: props.color } : {}}>
          <div className={`${styles.centercss} ${styles.flexcss}`}>
            {/* <SvgSvg
              width={defaultBigSize}
              height={defaultBigSize}
              activeMove={disable}
            ></SvgSvg> */}
            <SvgFileIcon
              width={defaultBigSize}
              height={defaultBigSize}
              activeMove={disable}
            >
            </SvgFileIcon>
          </div>
        );
      } else {
        if (checkEmpty(urlImage)) {
          if (props.recentbig) {
            defaultBigSize = "50";
            return (
              <SvgFileIconBig/>
            )
          }
          return (
            // <div style={props.color != null ? { color: props.color } : {}}>
            <div className={`${styles.centercss} ${styles.flexcss}`}>
              {/* <SvgSvg
                width={defaultBigSize}
                height={defaultBigSize}
                activeMove={disable}
              ></SvgSvg> */}
              <SvgFileIcon
                width={defaultBigSize}
                height={defaultBigSize}
                activeMove={disable}
              >
              </SvgFileIcon>
            </div>
          );
        }
        return (
          <>
            <div
              key={props.file.file_app_id + "_UIFileType" + props.key}
              className={
                disable ? styles.colorPicTypeActiveMove : styles.colorPicType
              }
              style={props.color != null ? { color: props.color } : {}}
            >
              <div
                style={{
                  display: uploadPreview ? "block" : "none",
                }}
                className={styles.eyeViewImg}
              >
                {/* <i className="fas fa-eye"></i> */}
              </div>

              <div
                className={
                  props.fromMedia ? styles.imgFontTypeMedia : styles.imgFontType
                }
              >
                <BgImgWithLazy
                  containerClassName="img"
                  key={props.file.file_app_id + "_view_file"}
                  src={urlImage}
                  style={{
                    filter: disable ? "sepia(1)" : "unset",
                    height: boxSizeHeight,
                    width: boxSizeWidth,
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </>
        );
      }
    }
  } else if (supportViewer.audio.indexOf(type) > -1) {
    if (props.fromMedia) {
      defaultBigSize = "77px";
      defualtSize = "77px";
    }
    if (props.forMedias10) {
      defaultBigSize = "50px";
      defualtSize = "50px";
    }
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorVideoTypeActiveMove : styles.colorVideoType
      //   }
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <div
          style={{
            display: false ? "block" : "none",
          }}
          className={styles.eyeViewVideo}
        >
          <i className="fas fa-eye"></i>
        </div> */}
        {/* <SvgAudio
          width={defaultBigSize}
          height={"100%"}
          activeMove={disable}
          forMedias10={props.forMedias10}
        ></SvgAudio> */}
        <SvgFileIcon
          width={defaultBigSize}
          height={defaultBigSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.txt.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgText
          width={defualtSize}
          height={defualtSize}
          activeMovTexte={disable}
        ></SvgText> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.bin.indexOf(type) > -1) {
    return (
      <div
        className={
          disable ? styles.colorBinTypeActiveMove : styles.colorBinType
        }
        style={props.color != null ? { color: props.color } : {}}
      >
        <i className="fas fa-trash"></i>
      </div>
    );
  } else if (["addicon"].indexOf(type) > -1) {
    return (
      <div style={props.color != null ? { color: props.color } : {}}>
        <i className="fas fa-plus"></i>
      </div>
    );
  } else if (supportViewer.xd.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgXd
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgXd> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.ai.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgAi
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgAi> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.doc.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorDocTypeActiveMove : styles.colorDocType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgDoc
          width={defaultBigSize}
          height={defaultBigSize}
          activeMove={disable}
        ></SvgDoc> */}
        <SvgFileIcon
          width={defaultBigSize}
          height={defaultBigSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.pdf.indexOf(type) > -1) {
    if (props.recentbig) {
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgPdf
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgPdf> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.xls.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgeExcel
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgeExcel> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.ppt.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgPowerPoint
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgPowerPoint> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.zip.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgZip
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgZip> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.psd.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      //   style={props.color != null ? { color: props.color } : {}}
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgPsd
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgPsd> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  } else if (supportViewer.link.indexOf(type) > -1) {
    if (props.recentbig) {
      defaultBigSize = "50";
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgLinkIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgLinkIcon> */}
        <img src={LinkIconPrimary} alt="" />
      </div>
    );
  }else if (props.isFolder === true) {
    return (
      <div className={styles.centercss}>
        <div
          style={{
            backgroundImage: `url(${FolderIcon})`,
            backgroundSize: "cover",
            width: 70,
            height: 61,
          }}
        ></div>
      </div>
    );
  } else {
    if(props.recentbig){
      return (
        <SvgFileIconBig/>
      )
    }
    return (
      // <div
      //   className={
      //     disable ? styles.colorOtherTypeActiveMove : styles.colorOtherType
      //   }
      // >
      <div className={`${styles.centercss} ${styles.flexcss}`}>
        {/* <SvgOtherType
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        ></SvgOtherType> */}
        <SvgFileIcon
          width={defualtSize}
          height={defualtSize}
          activeMove={disable}
        >
        </SvgFileIcon>
      </div>
    );
  }
}

export default React.memo(UIFileType);
