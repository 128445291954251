import { CameraOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

interface AgentProfileContentProps {
  control: Control<FieldValues>;
  errors: any;
}

const AgentProfileContent: React.FC<AgentProfileContentProps> = ({
  control,
  errors,
}) => {
  // const selectOptions = [
  //   { value: "sales", label: "Sales Agent" },
  //   { value: "support", label: "Support Agent" },
  //   { value: "marketing", label: "Marketing Agent" },
  // ];

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <span className="text-[#121927] text-lg font-medium">Profile</span>
        {/* <div className="flex items-center justify-center">
          <div className="relative">
            <Avatar shape="circle" icon={<UserOutlined />} size={120} />
            <CameraOutlined className="absolute bottom-0 right-0 p-2 rounded-full bg-[#E8F4FF] text-blue-500 hover:bg-blue-500 hover:text-white cursor-pointer" />
          </div>
        </div> */}
      </div>
      <div>
        <span className="text-[#121927] text-sm font-medium">Agent Name</span>
        <Controller
          name="agentName"
          control={control}
          render={({ field }) => (
            <Input
              size="large"
              {...field}
              status={errors.agentName ? "error" : ""}
            />
          )}
        />
        {errors.agentName && (
          <div className="text-red-500 text-sm mt-1">
            {errors.agentName.message}
          </div>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        <span className="text-[#121927] text-sm font-medium">Role</span>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <Input
              size="large"
              {...field}
              status={errors.role ? "error" : ""}
            />
          )}
        />
        {errors.role && (
          <div className="text-red-500 text-sm mt-1">{errors.role.message}</div>
        )}

        {/* <Select
          className="w-full"
          showSearch
          size="large"
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={selectOptions}
        /> */}
      </div>
    </div>
  );
};

export default AgentProfileContent;
