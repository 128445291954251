import React, { useCallback, useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaMicrophone } from "react-icons/fa";
import { Popover, Radio, RadioChangeEvent, Space } from "antd";
import { Languages } from "lucide-react";
import styles from "./SpeechToText.module.scss";
import classNames from "classnames";

interface SpeechToTextProps {
  onChange: (value: string) => void;
}

export const SpeechToText: React.FC<SpeechToTextProps> = ({ onChange }) => {
  const [isListening, setIsListening] = useState(false);
  const [language, setLanguage] = useState("en-US");
  const {
    transcript,
    interimTranscript,
    browserSupportsSpeechRecognition,
    resetTranscript,
  } = useSpeechRecognition();

  useEffect(() => {
    handleTranscriptUpdate();
  }, [transcript, interimTranscript]);

  const handleTranscriptUpdate = () => {
    const currentText = transcript || interimTranscript;
    onChange(currentText);
  };

  const handleLanguageChange = (e: RadioChangeEvent) => {
    setLanguage(e.target.value);
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    }
  };

  const handleToggleListen = useCallback(() => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    } else {
      SpeechRecognition.startListening({
        continuous: true,
        language: language,
      });
      setIsListening(true);
      resetTranscript();
    }
  }, [isListening, language]);

  if (!browserSupportsSpeechRecognition) {
    return (
      <div className="text-gray-500 text-sm">
        Browser doesn't support speech recognition.
      </div>
    );
  }

  const languageContent = (
    <div className={styles.radioGroup}>
      <Radio.Group value={language} onChange={handleLanguageChange}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Radio value="en-US">English</Radio>
          <Radio value="th-TH">Thai</Radio>
        </Space>
      </Radio.Group>
    </div>
  );

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.micButton, {
          [styles.listening]: isListening,
        })}
        onClick={handleToggleListen}
        title={isListening ? "Stop listening" : "Start listening"}
      >
        <FaMicrophone />
      </div>
      <Popover
        content={languageContent}
        title="Select Language"
        trigger={["hover", "click"]}
        placement="top"
        overlayStyle={{
          maxWidth: "90vw",
          padding: "8px",
          borderRadius: "12px",
        }}
        overlayInnerStyle={{
          borderRadius: "8px",
        }}
      >
        <div className={styles.languageButton} title="Change language">
          <Languages size={20} />
        </div>
      </Popover>
    </div>
  );
};
