import React from "react";

import styles from "./RecentFileContainer.module.scss";

const RecentFileContainer = (props:any) => {
  const headerText = `${props.header }`

  return (
    <div style={{ paddingBottom: "25px" }}>
      <div className={styles.cardGroupHeader}>{headerText}</div>
      <div className={styles.cardGroup}>{props.children}</div>
    </div>
  );
};

export default RecentFileContainer;