import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import styles from "./UIGroupUserItem.module.scss";
import { checkEmpty } from "src/services/util.service";

function UIGroupUserItem(props: any) {
  const { t } = useTranslation();
  const [urlImage, setUrlImage] = useState();

  async function getUrlObjectByUrl() {
    let url = props.row?.userData?.imageProfileURL;
    if (checkEmpty(url)) {
      
      url = props.row?.imageProfileURL;
      if (checkEmpty(url)) {
        url = props.imageProfileURL;
      }
    }
    setUrlImage(url);
  }
  useEffect(() => {
    getUrlObjectByUrl();
  }, []);
  function getClassName() {
    if (props.personalGroupList) {
      return styles.personalGroupList;
    } else {
      if (props.isGroup) return styles.imgShareGroup;
      else return styles.imgShare;
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${ checkEmpty(props.row?.userData?.imageProfileURL)?props.imageProfileURL:props.row?.userData?.imageProfileURL})`,
      }}
      className={getClassName()}
    ></div>
  );
}

export default React.memo(UIGroupUserItem);
