import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PlusCircleIcon from "src/assets/svg/plus-circle";
import { EllipsisVertical } from "lucide-react";
import AppItemIcon from "src/assets/svg/app-item";
import { USER_GENERATE_APP_ENDPOINT } from "../endpoints/generate.endpoint";
import {
  fetchApiDelete,
  fetchApiGet,
} from "src/services/specifications.service";
import { pathRoutes } from "src/page/props/util.props";
import { Modal, notification, Spin } from "antd";
import dayjs from "dayjs";

type GenerateItemProps = {
  app_id: string;
  app_name: string;
  created_at: string;
  is_generating_item: boolean;
};

const options = [
  // { label: "Download", value: "download" },
  // { label: "Rename", value: "rename" },
  // { label: "Move", value: "move" },
  { label: "Delete", value: "delete" },
  // { label: "Detail", value: "detail" },
  // { label: "Share", value: "share" },
];

const AppPage: React.FC = () => {
  const navigate = useNavigate();
  const [histories, setHistories] = useState<GenerateItemProps[]>([]);
  const [isOpen, setIsOpen] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<GenerateItemProps | null>(
    null
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    item: GenerateItemProps
  ) => {
    setSelectedItem(item);
    setIsOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  const handleMenuClick = (value: string) => {
    switch (value) {
      case "delete":
        setIsDeleteModalOpen(true);
        handleClose();
        break;
      default:
        handleClose();
    }
  };

  const onFetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchApiGet(USER_GENERATE_APP_ENDPOINT);
      setHistories(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedItem) return;

    try {
      setLoading(true);
      await fetchApiDelete(
        `${USER_GENERATE_APP_ENDPOINT}/${selectedItem.app_id}`
      );

      notification.success({
        message: "Success",
        description: "App deleted successfully",
        placement: "topRight",
      });

      await onFetchData();
    } catch (error) {
      console.error("Error deleting app:", error);
    } finally {
      setLoading(false);
      setIsDeleteModalOpen(false);
    }
  };

  const onCreateApp = () => {
    navigate("/generate");
  };

  const onClickApp = (item: GenerateItemProps) => {
    navigate(`${pathRoutes.Generate}/apps/${pathRoutes.Specifications}`, {
      state: {
        historyItem: item,
      },
    });
  };

  useEffect(() => {
    onFetchData();
  }, []);

  return (
    <div className="dashboard-container scrollbar">
      {/* Header */}
      <div className="bg-white border-b border-gray-200">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <h1 className="text-xl font-semibold text-gray-900">App</h1>
            <div className="flex items-center space-x-3">
              <span className="text-sm font-medium text-gray-600 hidden sm:block">
                New App
              </span>
              <IconButton
                onClick={onCreateApp}
                className="transition-transform hover:scale-105"
              >
                <PlusCircleIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Row */}
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-12 sm:col-span-9 text-sm font-medium text-gray-500">
            Name
          </div>
          <div className="hidden sm:block sm:col-span-3 text-sm font-medium text-gray-500">
            Last modified
          </div>
        </div>

        {loading ? (
          <div className="h-[70vh] flex justify-center items-center">
            <Spin spinning={loading}></Spin>
          </div>
        ) : (
          <div className="space-y-3">
            {histories.map((item, key) => (
              <div
                key={key}
                className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 p-4"
              >
                <div className="grid grid-cols-12 gap-4 items-center">
                  <div
                    className="col-span-12 sm:col-span-9 cursor-pointer"
                    onClick={() => onClickApp(item)}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <AppItemIcon />
                      </div>
                      <span className="text-gray-900 font-medium truncate">
                        {item.app_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-3 mt-2 sm:mt-0">
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-500">
                        {dayjs(item.created_at).format("D MMM, YYYY")}
                      </span>
                      <IconButton
                        onClick={(e) => handleClick(e, item)}
                        className="transition-colors hover:bg-gray-100"
                      >
                        <EllipsisVertical className="h-5 w-5 text-gray-500" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Empty State */}
        {!loading && histories.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500">
              No apps found. Create a new project to get started.
            </p>
          </div>
        )}

        {/* Menu */}
        <Menu
          anchorEl={isOpen}
          open={Boolean(isOpen)}
          onClose={handleClose}
          PaperProps={{
            className: "mt-2 shadow-lg",
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleMenuClick(option.value)}
              className="w-72"
            >
              <span className="text-gray-700">{option.label}</span>
            </MenuItem>
          ))}
        </Menu>

        {/* Delete Modal */}
        <Modal
          title="Confirm Delete"
          open={isDeleteModalOpen}
          onOk={handleDelete}
          onCancel={() => setIsDeleteModalOpen(false)}
          okText="Delete"
          cancelText="Cancel"
          okButtonProps={{
            danger: true,
            className: "hover:bg-red-600",
          }}
        >
          <p className="text-gray-600">
            Are you sure you want to delete{" "}
            <strong className="text-gray-900">{selectedItem?.app_name}</strong>?
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default AppPage;
