import {
  AppstoreOutlined,
  BarsOutlined,
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FilterOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { FaUser } from "react-icons/fa";
import { GoGitBranch } from "react-icons/go";
import { useNavigate } from "react-router";
import * as Auth from "src/services/auth.service";
import { getAuthorizationPmtx } from "src/services/util.service";
import {
  DELETE_AGENT_ENDPOINT,
  GET_AGENT_LIST_ENDPOINT,
} from "src/utils/endpoint/pmtx.endpoint";
import AgentConfigure from "./AgentConfigure";
import styles from "./AgentsDashboard.module.scss";
import {
  IAgentFilterForm,
  useAgentFilterForm,
} from "./form/AgentsDashboardFilterForm";
import { PagingResponse } from "src/model/paging";
dayjs.extend(relativeTime);

export interface IAgentList {
  id?: string;
  agentName: string;
  referUserId: string;
  role: string;
  // flow_id: string;
  personality: string;
  expertise: IExpertises[];
  lastActive: string;
  statistics: number;
}

export interface IExpertises {
  flow_id: string;
  expertise_detail: string;
}

const initialAgentTags = [{ label: "All Agents", value: "all" }];

const AgentsDashboard = () => {
  // const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<"grid" | "list">("grid");
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [agentTags, setAgentTags] = useState(initialAgentTags);
  const [isConfigureModal, setIsConfigureModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<IAgentList | undefined>();
  const [filterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState({
    role: undefined as string | undefined,
    // lastActive: undefined as number | undefined,
    // minStats: undefined as number | undefined,
    // maxStats: undefined as number | undefined,
  });
  const [agentList, setAgentList] = useState<PagingResponse<IAgentList>>();
  const [skeleton, setSkeleton] = useState(false);

  const { control, handleSubmit, reset } = useAgentFilterForm();

  const onSubmit = (values: IAgentFilterForm) => {
    setFilters({
      role: values.role || undefined,
      // lastActive: values.lastActive ? Number(values.lastActive) : undefined,
      // minStats: values.minStats ? Number(values.minStats) : undefined,
      // maxStats: values.maxStats ? Number(values.maxStats) : undefined,
    });
    setFilterVisible(false);
  };

  const agentTagList = useMemo(() => {
    if (!agentList) return [];
    const newTags = [...agentTags];
    (agentList.data || []).forEach((agent) => {
      if (!newTags.find((tag) => tag.value === agent.role)) {
        newTags.push({ label: agent.role, value: agent.role });
      }
    });
    setAgentTags(newTags);
  }, [agentList]);

  const handleFilterClick = () => {
    setFilterVisible(true);
  };

  const onFilterClose = () => {
    reset();
    setFilterVisible(false);
  };

  // const parseTimeToHours = (timeStr: string) => {
  //   const match = timeStr.match(/(\d+)([hm])/);
  //   if (!match) return 0;
  //   const [_, num, unit] = match;
  //   return unit === "h" ? parseInt(num) : parseInt(num) / 60;
  // };

  const filteredAgents = useMemo(() => {
    return agentList?.data?.filter((agent) => {
      if (agent !== null) {
        const matchesSearch =
          agent.agentName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          agent.role?.toLowerCase().includes(searchQuery.toLowerCase());
        // agent.personality.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesTab =
          activeTab === "all" ||
          agent.role.toLowerCase().includes(activeTab.toLowerCase());
        const matchesRole =
          !filters.role ||
          filters.role === "all" ||
          agent.role.toLowerCase() === filters.role.toLowerCase();
        // const matchesLastActive =
        //   !filters.lastActive ||
        //   parseTimeToHours(agent.last_active) <= filters.lastActive;
        // const matchesStats =
        //   (!filters.minStats ||
        //     parseInt(agent.statistics_total) >= filters.minStats) &&
        //   (!filters.maxStats ||
        //     parseInt(agent.statistics_total) <= filters.maxStats);

        return (
          matchesSearch && matchesTab && matchesRole
          // matchesLastActive &&
          // matchesStats
        );
      }
    });
  }, [agentList, searchQuery, activeTab, filters]);

  // const handleAddAgent = () => {
  //   navigate("/generate/agents-dashboard");
  // };

  const handleCloseConfigureModal = () => {
    setIsConfigureModal(false);
    setSelectedAgent(undefined);
  };

  const handleConfigureModal = () => {
    setIsConfigureModal(true);
  };

  const handleEditAgent = (agent: IAgentList) => {
    setSelectedAgent(agent);
    setIsConfigureModal(true);
  };

  const fetchAgentList = async (
    offset: number,
    limit: number,
    search: string
  ) => {
    try {
      let userFromStorage: any = localStorage.getItem(Auth.AuthKeys.user);
      setSkeleton(false);
      const data = await axios.get<PagingResponse<IAgentList>>(
        GET_AGENT_LIST_ENDPOINT,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorizationPmtx(),
          },
          params: {
            refer_user_id: JSON.parse(userFromStorage).user_id,
            offset,
            limit,
            search,
          },
        }
      );

      setAgentList(data.data);
      setSkeleton(true);
      return data;
    } catch (e) {
      console.error("Error fetching agent list:", e);
      throw e;
    }
  };

  useEffect(() => {
    fetchAgentList(0, 0, "");
  }, [agentTagList]);

  const handleAddAgent = () => {
    fetchAgentList(0, 0, "");
  };

  const handleDelete = async (agentId?: string) => {
    if (!agentId) return;
    try {
      await axios.delete(`${DELETE_AGENT_ENDPOINT}/${agentId}`, {
        headers: {
          Authorization: getAuthorizationPmtx(),
        },
      });
      fetchAgentList(0, 0, "");
    } catch (error: any) {
      console.log(error);
    }
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (agentId?: string) => {
    confirm({
      title: "Are you sure delete this task?",
      icon: <ExclamationCircleFilled />,
      content: "Once deleted, you will not be able to recover it.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDelete(agentId);
      },
      onCancel() {},
    });
  };

  const renderSkeleton = () => {
    return Array(8)
      .fill(null)
      .map((_, index) => (
        <div
          key={`skeleton-${index}`}
          className="shadow-md bg-white rounded-2xl animate-pulse"
        >
          <div className="flex flex-col items-start bg-blue-200 rounded-t-2xl p-4 h-24">
            <div className="h-6 w-32 bg-blue-300 rounded mb-2"></div>
            <div className="h-4 w-24 bg-blue-300 rounded"></div>
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div>
              <div className="h-4 w-20 bg-gray-200 rounded mb-2"></div>
              <div className="h-16 w-full bg-gray-200 rounded"></div>
            </div>
            <div className="pt-4">
              <div className="h-4 w-32 bg-gray-200 rounded mb-2"></div>
              <div className="h-4 w-24 bg-gray-200 rounded mb-2"></div>
              <div className="h-4 w-16 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      ));
  };

  return (
    <div className="dashboard-container scrollbar">
      <div className="p-6 space-y-4 ">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex flex-col sm:flex-row justify-between sm:items-center mb-6">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Find Agents
            </Typography.Title>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleConfigureModal}
            >
              Add New Agent
            </Button>
          </div>

          <div className="flex justify-between items-center gap-4 mb-6">
            <Input
              placeholder="Search by name, role, or skills..."
              prefix={<SearchOutlined className="text-gray-400" />}
              className="flex-grow"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button icon={<FilterOutlined />} onClick={handleFilterClick}>
              Filters
            </Button>
          </div>

          <div className="flex flex-wrap gap-2 md:gap-3">
            {agentTags.map((item, key) => (
              <button
                key={key}
                className={
                  activeTab === item.value ? styles.tagBtnActive : styles.tagBtn
                }
                onClick={() => setActiveTab(item.value)}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>

        <div className="flex justify-between items-center mb-4">
          <Typography.Text strong>MOST ACTIVE AGENTS</Typography.Text>
          <Space>
            <Button
              type={viewMode === "grid" ? "primary" : "text"}
              icon={<AppstoreOutlined />}
              onClick={() => setViewMode("grid")}
            />
            <Button
              type={viewMode === "list" ? "primary" : "text"}
              icon={<BarsOutlined />}
              onClick={() => setViewMode("list")}
            />
          </Space>
        </div>

        {agentList && agentList.total === 0 && skeleton ? (
          <div className="w-full h-[40vh] flex justify-center items-center text-gray-500">
            No data
          </div>
        ) : (
          <div
            className={`grid ${
              viewMode === "grid"
                ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
                : "grid-cols-1"
            } gap-4`}
          >
            {agentList && !skeleton
              ? renderSkeleton()
              : filteredAgents?.map((agent) => (
                  <div
                    key={agent.id}
                    className="shadow-md bg-white rounded-2xl"
                  >
                    <div className="flex justify-between items-start bg-blue-500 text-white rounded-t-2xl p-4">
                      <div className="flex flex-col">
                        <Typography.Title
                          level={5}
                          style={{ margin: 0, color: "#fff" }}
                          className="truncate"
                        >
                          {agent.agentName}
                        </Typography.Title>
                        <Typography.Text
                          type="secondary"
                          style={{ color: "#f9f9f9" }}
                        >
                          {agent.role}
                        </Typography.Text>
                      </div>
                      <div className="flex gap-2">
                        {/* <CommentOutlined
                          className="cursor-pointer"
                          width={20}
                          height={20}
                        /> */}
                        <EditOutlined
                          className="cursor-pointer"
                          width={20}
                          height={20}
                          onClick={() => handleEditAgent(agent)}
                        />
                        <DeleteOutlined
                          className="cursor-pointer"
                          width={20}
                          height={20}
                          onClick={() => showDeleteConfirm(agent.id)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 p-4">
                      <div>
                        <Typography.Text type="secondary">
                          <FaUser className="text-blue-500" /> PERSONA
                        </Typography.Text>
                        <Typography.Paragraph className="text-sm h-24 overflow-y-auto scrollbar">
                          {agent.personality}
                        </Typography.Paragraph>
                      </div>

                      <div className="">
                        <Typography.Text type="secondary">
                          <GoGitBranch className="text-blue-500" /> WORKFLOW
                          STATUS
                        </Typography.Text>
                        <div className="mb-2">
                          <Typography.Text type="secondary">
                            Last Executed
                          </Typography.Text>
                          {agent.expertise &&
                            agent.expertise.map((expertise, key) => (
                              <div key={key} className="text-sm">
                                {expertise.expertise_detail}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        )}

        <Drawer
          title="Filter Agents"
          placement="right"
          onClose={onFilterClose}
          open={filterVisible}
          width={320}
        >
          <Form
            layout="vertical"
            className="shadow-none flex flex-col w-full min-w-0"
            onFinish={handleSubmit(onSubmit)}
          >
            <Controller
              name="role"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Form.Item
                  label="Role"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Select
                    placeholder="Select role"
                    onChange={onChange}
                    value={value}
                    options={agentTags}
                  />
                </Form.Item>
              )}
            />

            {/* <Controller
              name="lastActive"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Form.Item
                  label="Last Active Within (hours)"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Input
                    type="number"
                    placeholder="Enter hours"
                    onChange={(e) =>
                      onChange(e.target.value ? Number(e.target.value) : null)
                    }
                    value={value ?? ""}
                    min={0}
                    max={24}
                  />
                </Form.Item>
              )}
            />

            <Controller
              name="minStats"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Form.Item
                  label="Minimum Statistics"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Input
                    type="number"
                    placeholder="Enter minimum"
                    onChange={(e) =>
                      onChange(e.target.value ? Number(e.target.value) : null)
                    }
                    value={value ?? ""}
                    min={0}
                  />
                </Form.Item>
              )}
            />

            <Controller
              name="maxStats"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Form.Item
                  label="Maximum Statistics"
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Input
                    type="number"
                    placeholder="Enter maximum"
                    onChange={(e) =>
                      onChange(e.target.value ? Number(e.target.value) : null)
                    }
                    value={value ?? ""}
                    min={0}
                  />
                </Form.Item>
              )}
            /> */}

            <div className="flex gap-2 mt-4">
              <Button type="primary" htmlType="submit">
                Apply Filters
              </Button>
              <Button
                onClick={() => {
                  reset();
                  setFilters({
                    role: undefined,
                    // lastActive: undefined,
                    // minStats: undefined,
                    // maxStats: undefined,
                  });
                  setFilterVisible(false);
                }}
              >
                Reset
              </Button>
            </div>
          </Form>
        </Drawer>

        {isConfigureModal && (
          <AgentConfigure
            onClose={handleCloseConfigureModal}
            isOpen={isConfigureModal}
            handleAddAgent={handleAddAgent}
            agent={selectedAgent}
          />
        )}
      </div>
    </div>
  );
};

export default AgentsDashboard;
