import React, {useContext, useEffect} from "react";

import styles from "./UIModalCreateFolder.module.scss";
import {useTranslation} from "react-i18next";
//@ts-ignore
import _ from "lodash";
import {FileContext, FileContextProps} from "../File.context";
import {Spin, Switch, Button} from "antd";
import {Modal} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import { createFolderRequest, getLsItem, reflectorFileFolders } from "../../util.service";
import ButtonCenterCLose from "../ButtonCenterClose";
import CenterButton from "src/shared/button/Button";

function UIModalCreateFolder(props: any) {
  const { t, i18n } = useTranslation();
  const fileContext = useContext<FileContextProps>(FileContext);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const handleCreateFolder = async () => {
    try {
      fileContext.setCreatingFolder(true);
      let can = true;
      if ("" === fileContext.createFolderName.trim()) {
        console.log("Folder Name is Empty!");
        can = false;
        fileContext.setDataErrorCreate(t("Folder Name is Empty!"));
        fileContext.setErrorCreate(true);
      }

      let folders = await getLsItem(
        "DIRECTORY",
        fileContext.parentFolderId,
        fileContext.groupId,
        fileContext.userId
      );
      for (let a = 0; a < folders.length; a++) {
        if (folders[a].file_name === fileContext.createFolderName.trim()) {
          console.log("Name already existing!");
          can = false;
          fileContext.setDataErrorCreate(t("Name already existing!"));
          fileContext.setErrorCreate(true);
        }
      }
      if (can === false) {
        fileContext.setCreatingFolder(false);
        return;
      }
      let folder = await createFolderRequest(
        fileContext.createFolderName,
        fileContext.groupId,
        fileContext.userId,
        fileContext.parentFolderId,
        fileContext.encryptFolder,
        fileContext.controlVersion,
        fileContext.moduleFileAc,
      );
      setFolder(folder, "DIRECTORY");
      fileContext.setShowCreateFolderModal(false);
      fileContext.setCreatingFolder(false);
    } catch (e) {
      console.log(e);
      fileContext.setCreatingFolder(false);
    }
  };

  async function setFolder(res: any, type: string) {
    let { fileFolders, newLenght } = reflectorFileFolders(
      fileContext.fileFolders,
      type,
      res
    );
    fileContext.setFileFolders(fileFolders);
  }

  useEffect(() => {
    fileContext.setCreateFolderName("");
    fileContext.setEncryptFolder(false);
    fileContext.setControlVersion(false);
  }, [fileContext.showCreateFolderModal]);

  return (
    <Modal
      centered
      visible={fileContext.showCreateFolderModal}
      className={styles.modalOveride}
      onCancel={() => {
        fileContext.setShowCreateFolderModal(false);
        fileContext.setErrorCreate(false);
      }}
    >
      <div

        className={styles.closeBtn}
      // style={{ backgroundImage: `url(${crossIcon})` }}
      > <ButtonCenterCLose onClick={() => {
        fileContext.setShowCreateFolderModal(false);
        fileContext.setErrorCreate(false);
      }}></ButtonCenterCLose></div>

      <div className={styles.boxCreate}>
        <div style={{ fontSize: "1rem", fontWeight: "500" }}>
          {t("New Folder")}
        </div>
        <div style={{ fontSize: "0.9rem", display: "flex", marginTop: "25px" }}>
          <input
            style={{ fontSize: "0.9rem" }}
            disabled={fileContext.creatingFolder}
            type="text"
            placeholder={t("Folder name")}
            className={styles.boxCreateInput}
            value={fileContext.createFolderName}
            onChange={(event) => {
              if (event.target.value.length > 50) {
                let v = event.target.value.substring(0, 50);
                fileContext.setDataErrorCreate(
                  t("Allow maximum folder name size value of 50 characters")+"!"
                );
                fileContext.setErrorCreate(true);
                fileContext.setCreateFolderName(v);
              } else {
                fileContext.setErrorCreate(false);
                fileContext.setCreateFolderName(event.target.value);
              }
            }}
          />
        </div>
        {fileContext.isErrorCreate ? (
          <div className={styles.BErrorMessage}>
            <div className="error-message">{fileContext.dataErrorCreate}</div>
          </div>
        ) : null}
        <div style={{ fontSize: "1rem", marginTop: "25px" }}>
          <label>{t("Encrypt folder")}</label>
          <div style={{ float: "right" }}>
            <Switch
              disabled={fileContext.creatingFolder}
              checked={fileContext.encryptFolder}
              onChange={(v) => {
                fileContext.setEncryptFolder(!fileContext.encryptFolder);
              }}
              className={
                fileContext.encryptFolder
                  ? styles.switchOverideChecked
                  : styles.switchOveride
              }
            />
          </div>
        </div>
        <div style={{ fontSize: "1rem", marginTop: "22px",paddingBottom:"22px" }}>
          <label>{t("Option control")}</label>
          <div style={{ float: "right" }}>
            <Switch
              disabled={fileContext.creatingFolder}
              checked={fileContext.controlVersion}
              onChange={(v) => {
                fileContext.setControlVersion(!fileContext.controlVersion);
              }}
              className={
                fileContext.controlVersion
                  ? styles.switchOverideChecked
                  : styles.switchOveride
              }
            />
          </div>
        </div>
        {/* <button
          className={styles.bottonBtn}
          style={{ fontSize: "1.2rem", marginTop: "25px" }}
        >
          
        </button> */}
        {/* <CenterButton> */}
        <Button type="primary">
          <div onClick={handleCreateFolder}>
            {fileContext.creatingFolder ? (
              <Spin indicator={antIcon} />
            ) : (
                t("Create Folder")
              )}
          </div>
        </Button>
      </div>
    </Modal>
  );
}

export default UIModalCreateFolder;
