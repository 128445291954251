import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./UserGroup.module.scss";

const UserGroup = React.forwardRef((props: any, ref: any) => {
  const { t } = useTranslation();
  const [urlImage, setUrlImage] = useState();

  async function getUrlObjectByUrl() {
    setUrlImage(props.row?.img);
  }
  useEffect(() => {
    // console.log("getUrlObjectByUrl", props.row?.img);
    getUrlObjectByUrl();
  }, []);

  return (
    <div className={styles.itemuser} onClick={() => {}}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            backgroundImage: `url(${urlImage})`,
            backgroundSize: "cover",
            width: 44,
            height: 44,
            borderRadius: "33px",
          }}
        ></div>
      </div>
    </div>
  );
});

export default UserGroup;
