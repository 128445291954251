// @ts-nocheck
import { Button, notification, Popover, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import styles from "../generate/Generate.module.scss";
import { useNavigate } from "react-router-dom";
import LogoPromptX from "src/assets/png/PromptX_white.png";
import ThreeStarIcon from "src/assets/svg/threeStarIcon";
import * as Auth from "src/services/auth.service";
import { v4 as uuidv4 } from "uuid";
import { pathRoutes } from "../props/util.props";

import { Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  fetchCheckAiTokenByUserID,
  handlerCreateAiTokenByProjectID,
} from "src/store/actions/ai-token/aiTokenActions";
import {
  aiTokenSelector,
  setCloseTokenDialog,
} from "src/store/slices/ai-token/aiTokenSlice";
import { useAppDispatch } from "src/store/store";
import { GenerateContext } from "../context/Generate.context";
import { SpeechToText } from "./components/SpeechToText";
import ModalWarning from "./modal/modalWarning";
import DialogAiTokenExcessive from "./specification/user-story/components/DialogAiTokenExcessive";
import { fetchUserPlans } from "src/store/slices/payments/plansSlice";
import { Workflow } from "lucide-react";
import InviteModal from "./invitation/InviteModal";
import UICoFile from "./filePage/UICoFile";
import BoxDetailFileDownload from "./filePage/component/download/BoxDetailFileDowload";

type KnowledgeBaseProps = {
  isShared?:boolean
};

function KnowledgeBase({isShared=false}: KnowledgeBaseProps) {
  return (
    <>
      <UICoFile isShared={isShared}></UICoFile>
      <BoxDetailFileDownload></BoxDetailFileDownload>
    </>
  );
}
export default KnowledgeBase;
