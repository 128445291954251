import React from "react";

import styles from "./NoData.module.css";

import noData1 from "src/assets/png/noDataImg/noData_1.png";

const NoData = (props: any) => {
  return (
    <div
      className={styles.noDataContainer}
      style={{ marginTop: props?.top || 60 }}
    >
      <div
        className={styles.noDataImg}
        style={{ backgroundImage: `url(${noData1})` }}
      />
      <div className={styles.noDataHeader}>{props?.header}</div>
      <div className={styles.noDataText}>{props?.detail}</div>
    </div>
  );
};

export default NoData;
