//@ts-ignore
const BASE_URL = `${window["env"]["REACT_APP_ZERO_URL"]}/api/v1`;
//@ts-ignore
const BASE_URL_PAGE = `${window["env"]["REACT_APP_PAGE_URL"]}/api/v2/`;
//@ts-ignore
const BASE_URL_PAGE_V3 = `${window["env"]["REACT_APP_PAGE_URL"]}/api/v3/`;
//@ts-ignore
const BASE_URL_MFD = `${window["env"]["REACT_APP_MFD_URL"]}`;

export const GET_STORY_ENYITY_ENDPOINT = `${BASE_URL}/enyity-bo/storyID`;
export const ADD_STORY_ENYITY_ENDPOINT = `${BASE_URL}/enyity-bo`;
export const REMOVE_STORY_ENYITY_ENDPOINT = `${BASE_URL}/enyity-bo`;
export const GROUP_BO_NAME_ENYITY_ENDPOINT = `${BASE_URL}/enyity-bo/groupBonameByStoryID`;

export const ADD_BO_CONFIG_ENDPOINT = `${BASE_URL}/enyity-bo-item/saveAndUpdate`;
export const REMOVE_BO_CONFIG_ENDPOINT = `${BASE_URL}/enyity-bo-item/delete`;

export const GET_DATA_REFER_ENDPOINT = `${BASE_URL}/data-refer/getDataByAspID`;
export const SAVE_SET_DATA_REFER_ENDPOINT = `${BASE_URL}/enyity-bo-item/setDataReferID`;
export const ADD_DATA_REFER_ENDPOINT = `${BASE_URL}/data-refer/saveAndUpdate`;

export const GET_TABLE_COL_ENDPOINT = `${BASE_URL}/table-and-column`;
export const GENERATE_TABLE_NODE_ENDPOINT = `${BASE_URL}/generate-tabletest`;

export const GET_ENTITY_BO_BY_ID_ENDPOINT = `${BASE_URL}/enyity-bo/byID`;
export const UPDATE_ENTITY_BO_BY_ID_ENDPOINT = `${BASE_URL}/enyity-bo`;
//get bo items by enyity bo item id
export const GET_ENTITY_BO_BY_ID_TO_EDIT_ENDPOINT = `${BASE_URL}/enyity-bo-item/boItemsID`;

export const GET_STORY_BY_ASPID_ENDPOINT = `${BASE_URL}/story/component/aspID`;
export const CREATE_STORY_COMPONENT_ENDPOINT = `${BASE_URL}/story/component/create`;
export const GET_DATA_CONNECTOR_ENDPOINT = `${BASE_URL}/data-connector/aspID`;

// API Story DB Connector
export const GET_DB_CONNECT_ENDPOINT = `${BASE_URL}/story/db-connector/storyID`;
export const GET_STORY_DB_CONNECTOR_BY_ID_ENDPOINT = `${BASE_URL}/story/db-connector`;
export const CREATE_STORY_DB_CONNECTOR_ENDPOINT = `${BASE_URL}/story/db-connector`;
export const DELETE_STORY_DB_CONNECTOR_ENDPOINT = `${BASE_URL}/story/db-connector`;

// API Data Refer
export const GET_DATA_REFER_BY_ASPID_ENDPOINT = `${BASE_URL}/data-refer/getDataByAspID`;
export const GET_DATA_REFER_BY_ID_ENDPOINT = `${BASE_URL}/data-refer/getDataReferByID`;
export const SAVE_AND_UPDATE_DATA_REFER_ENDPOINT = `${BASE_URL}/data-refer/saveAndUpdate`;
export const DELETE_DATA_REFER_ENDPOINT = `${BASE_URL}/data-refer/delete`;
export const DELETE_DATA_REFER_LIST_ENDPOINT = `${BASE_URL}/data-refer/data-list/delete`;

// API Page Generate
export const CREATE_PAGE_GENERATE_ENDPOINT = `${BASE_URL}/page-generate/create`;
export const UPDATE_PAGE_GENERATE_ENDPOINT = `${BASE_URL}/page-generate/update`;
export const DELETE_PAGE_GENERATE_ENDPOINT = `${BASE_URL}/page-generate/delete`;
export const GET_PAGE_GENERATE_BY_ID_ENDPOINT = `${BASE_URL}/page-generate/getByID`;
export const GET_PAGE_GENERATE_BY_ENYITY_BO_ID_ENDPOINT = `${BASE_URL}/page-generate/getByEnyityBoID`;
export const GET_TEMPLATE_ENDPOINT = `${BASE_URL}/template/m-template`;
export const GET_VERSION_PAGE_GENERATE_ENDPOINT = `${BASE_URL}/page-generate/get-version`;
export const UPLOAD_FILE_LIBS_ENDPOINT = `${BASE_URL}/call-api/UploadFileLib`; // /call-api/UploadFileLib?app_id=620a3640-6203-4ff9-9dc1-610e0a635461&is_created=y
export const CALL_GENERATE_PAGE_ENDPOINT = `${BASE_URL}/component/generate-pageDesigner`;
export const GET_PAGE_NODES_BY_PAGEID_ENDPOINT = `${BASE_URL}/pageDesigner/get-pagenode`;
export const GET_HEADER_PAGE_NODES_BY_PAGEID_ENDPOINT = `${BASE_URL}/pageDesigner/header-get-pagenode`;
export const GEN_PAGE_ACTION_ENDPOINT = `${BASE_URL}/pageDesigner/create/actions`;
export const GEN_PAGE_LAYOUT_ENDPOINT = `${BASE_URL_PAGE_V3}/zero/updateHeaderFooterByZero`;

//API STORY COMPONENT
export const GENERATE_MF_ENDPOINT = `${BASE_URL}/component/generate-microFlow`;
export const GET_COMPONENT_BY_STORY_ID_ENDPOINT = `${BASE_URL}/component/storyID`;
export const REMOVE_COMPONENT_BY_STORY_ID_ENDPOINT = `${BASE_URL}/component`;
export const STORY_GROUP_DB_TYPE_AND_OWNER = `${BASE_URL}/story/component/group/dbTypeAndOwner`;
export const DELETE_STORY_COMPONENT = `${BASE_URL}/story/component/delete`;

//API GEN MF
export const GET_BO_BY_STORY_ID_ENDPOINT = `${BASE_URL}/enyity-bo-item/storyID`;
export const GET_BO_DATA_REFER_BY_STORY_ID_ENDPOINT = `${BASE_URL}/enyity-bo-item/storyID/dataRefer`;

// API App Component
export const GET_MASTER_APP_COMPONENT_ENDPOINT = `${BASE_URL}/app-component/get-m-app-component`;
export const GET_APP_COMPONENT_BY_ASP_ID_ENDPOINT = `${BASE_URL}/app-component/getByAspID`;
export const CREATE_APP_COMPONENT_ENDPOINT = `${BASE_URL}/app-component/create`;
export const UPDATE_APP_COMPONENT_ENDPOINT = `${BASE_URL}/app-component/update`;
export const UPLOAD_FILE_APP_COMPONENT_ENDPOINT = `${BASE_URL}/app-component/upload-config-image`;
export const GET_FILE_BY_ID_APP_COMPONENT_ENDPOINT = `${BASE_URL}/app-component/get-file`;

// Database Connectors
export const GROUP_DB_TYPE_AND_OWNER = `${BASE_URL}/database-connectors/group/dbTypeAndOwner`;

// Mfd api
export const GET_BO_MFD = `${BASE_URL_MFD}/REST/PG/process/`;

// Page api
export const CREATE_PAGE_ACTION = `${BASE_URL_PAGE}/actions`;
export const CREATE_PAGE_OUTPUT_SCHEMA = `${BASE_URL_PAGE}/schema`;
export const CREATE_PAGE_FLOW_MAPPING = `${BASE_URL_PAGE}/flows`;
export const CREATE_PAGE_STATE = `${BASE_URL_PAGE}/objects`;
export const GET_PAGE = `${BASE_URL_PAGE}/pages`;

// Count Connector And Story
export const COUNT_CONNECTOR_AND_STORY = `${BASE_URL}/countStoryAndConnector`;

