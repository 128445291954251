// GenerateContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { boolean } from 'yup';

interface GenerateContextType {
    messageData: any;
    setMessageData: (messageData: any) => void;
    projectName: string;
    setProjectName: (projectName: string) => void;
    uuidApp: string;
    setUuidApp: (projectName: string) => void;
    fileName: string;
    setFileName: (fileName: string) => void;
    isEditedMessage: boolean;
    setIsEditedMessage: (isEditedMessage: boolean) => void;
}

const GenerateContext = createContext<GenerateContextType>({
    messageData: {},
    setMessageData: () => { },
    projectName: '',
    setProjectName: () => { },
    uuidApp: '',
    setUuidApp: () => { },
    fileName: '',
    setFileName: () => { },
    isEditedMessage: false,
    setIsEditedMessage: () => { },

});

const GenerateContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messageData, setMessageData] = useState<any>();
    const [projectName, setProjectName] = useState<string>('');
    const [uuidApp, setUuidApp] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [isEditedMessage, setIsEditedMessage] = useState<boolean>(false);

    const contextValue: GenerateContextType = {
        messageData,
        setMessageData,
        projectName,
        setProjectName,
        uuidApp,
        setUuidApp,
        fileName,
        setFileName,
        isEditedMessage,
        setIsEditedMessage,

    };

    return (
        <GenerateContext.Provider value={contextValue}>
            {children}
        </GenerateContext.Provider>
    );
};

// export const useData = (): GenerateContextType => {
//     const context = useContext(GenerateContext);
//     if (!context) {
//         throw new Error('useData must be used within a DataProvider');
//     }
//     return context;
// };

export { GenerateContextProvider, GenerateContext };
