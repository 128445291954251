import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import plansReducer from "./slices/payments/plansSlice";
import paymentsReducer from "./slices/payments/paymentsSlice";
import stripeReducer from "./slices/payments/stripeSlice";
import billingReducer from "./slices/payments/billingSlice";
import customerReducer from "./slices/payments/customerSlice";
import userReducer from "./slices/userSlice";
import aiTokenReducer from "./slices/ai-token/aiTokenSlice";
import subscriptionReducer from "./slices/payments/subscriptionSlice";
import contactReducer from "./slices/payments/contactSlice";
import dateRangeReducer from "./slices/dateRangeSlice";
import agentReducer from "./slices/agents/agentSlice";
import appReducer from "./slices/apps/appSlice";

import historyReducer from "./slices/history/historySlice";

const reducer = {
  plansReducer,
  paymentsReducer,
  stripeReducer,
  userReducer,
  aiTokenReducer,
  billingReducer,
  customerReducer,
  subscriptionReducer,
  contactReducer,
  dateRangeReducer,
  agentReducer,
  appReducer,
  history: historyReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
