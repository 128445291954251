import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AuthKeys } from "src/services/util.service";
import { FileContextProps } from "./filePage/File.context";

//@ts-ignore
export const KN_FILE_SERVICE_URL_MAIN =
  //@ts-ignore
  window["env"]["REACT_APP_KN_FILESERVICE_URL"];
//@ts-ignore
export const KN_FILE_CACHE_MAIN = window["env"]["REACT_APP_KN_FILE_CACHE"];
//@ts-ignore
export const CENTER_URL_MAIN = window["env"]["REACT_APP_CENTER_URL"];

let limit: any = 10; //window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_LIMIT_PER_FILE;
export let chunkSize = parseInt(limit) * 1024 * 1024; // Size of each chunk, set to 1 Mb
let blobSlice = File.prototype.slice;

const caches: any = {};

export const APIChatEnpoint = {
  finfmessageslink: "/api/find/messageslink",
};

export const APIFileEnpoint = {
  getFiles: "/getFiles",
  getFilesInTash: "/getFilesInTash",
  files: "api/v1/files",
  manageversions: "/getFilesAllVersion",
  getLogFiles: "/getLogFiles",
};

export const mainState = {
  init: "init",
  RSA_2048: "RSA_2048",
};

export const permFile = {
  anyoneWithTheLink: "anyoneWithTheLink",
  restricted: "restricted",
};

export const mainTab = {
  files: "files",
  medias: "medias",
  links: "links",
  menu: "menu",
};
export const constKey = {
  myId: "my-id",
};
export const chatContent = {
  history: "history",
  content: "content",
};
export const nodataFile = {
  noDataHeaderFileText: "No file available",
  nodataFiletextH: "When upload file",
  nodataFiletextL: "they will appear on your file pages",

  noDataHeaderDirText: "No folder available",
  nodataDirtextH: "When create folder",
  nodataDirtextL: "they will appear on your folder pages",

  noDataHeaderTextTrash: "No file or folder available",
  nodatatextTrashH: "When delete file or folder",
  nodatatextTrashL: "they will appear on your trash",

  noDataHeaderTextGroup: "No group available",
  nodatatextGroupH: "When join group",
  nodatatextGroupL: "they will appear on your groups",

  noDataHeaderTextLink: "No link available",
  nodatatextLinkH: "When send link to chat",
  nodatatextLinkL: "they will appear on your links",
};

export const videoType = [
  "m4v",
  "avi",
  "mpg",
  "mp4",
  "webm",
  "mov",
  "M4A",
  "M4V",
  "AVI",
  "MPG",
  "MP4",
  "WEBM",
  "MOV",
];

export const imgTypes = [
  "JPEG",
  "JPG",
  "JPEG",
  "GIF",
  "BMP",
  "360-DEGREE",
  "PNG",
  "360-DEGREE",
  "JPEG",
  "JPG",
  "JPEG",
  "GIF",
  "BMP",
  "360-DEGREE",
  "PNG",
  "360-DEGREE",
  "jpeg",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "360-degree",
  "png",
  "360-degree",
  "jpeg",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "360-degree",
  "png",
  "360-degree",
];

const pictype = ["jpeg", "jpg", "gif", "bmp", "png"];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const supportViewer = {
  images: [
    "jpeg",
    "jpg",
    "jpeg",
    "gif",
    "bmp",
    "360-degree",
    "png",
    "JPG",
    "JPEG",
    "GIF",
    "BMP",
    "360-DEGREE",
    "PNG",
    // "svg",
  ],
  // doc: ["pdf", "csv", "xslx", "docx","doc","xls","pptx","PDF", "CSV", "XSLX", "DOCX","DOC","XLS","PPTX"],
  video: [
    "m4v",
    "avi",
    "mpg",
    "mp4",
    "webm",
    "M4V",
    "AVI",
    "MPG",
    "MP4",
    "WEBM",
    "mov",
  ],
  audio: ["mp3", "m4a", "MP3", "M4A"],
  pdf: ["pdf", "PDF"],
  svg: ["svg", "SVG"],
  txt: ["txt"],
  xls: ["xls", "xlsx"],
  doc: ["doc", "docx"],
  ppt: ["ppt", "pptx"],
  // pdf: ["pdf"],
  ai: ["ai"],
  xd: ["xd"],
  bin: ["bin"],
  psd: ["psd"],
  zip: ["zip", "rar"],
};
let timeOut = 0;
let RSA_2048 = "RSA_2048";
export { pictype, supportViewer, timeOut, RSA_2048, monthsFull, months };

export const tabName = {
  myFile: "myfile",
  sharedWithMe: "sharedWithMe",
  trash: "trash",
  group: "group",
  img: "img",
  other: "other",
  unknow: "unknow",
  doc: "document",
  media: "media",
  mediavideo: "mediavideo",
  mediaimage: "mediaimage",
  file: "files",
  manageversion: "manageversion",
  search: "search",
  activitiesLog: "activitieslog",
  allactivitiesLog: "allactivitieslog",
};
export const tabNameText = {
  myFile: "My File",
  sharedWithMe: "Shared with me",
  trash: "Trash Files",
  group: "Group Files",
  mediavideo: "Video Files",
  mediaimage: "Image Files",
  file: "Files",
  unknow: "Unknown Files",
  other: "Other Files",
  doc: "Document Files",
  search: "Search",
  activitiesLog: "Activities Log",
  allactivitiesLog: "All Activities Log",
};
let pathRoutesTemp = {
  Generate: "/generate",
  KnowledgeBase: "knowledgeBase",
};
let pathfile = `/${pathRoutesTemp.Generate}/${pathRoutesTemp.KnowledgeBase}`;
export const pathRoutes = {
  Root: "/loading",
  Privacy: "/privacy",
  HeadstartRoot: "/headstart/loading",
  UserReward: "/user-rewards",
  LoginStream: "/hstart/login",
  Login: "/login",
  Feed: "/action-center",
  Group: "/groups",
  Main: "/main",
  Apps: "/apps",
  MicroApp: "/microApp",
  MicroAppPD: "/micro-app",
  File: pathfile,
  Chat: "/center-chat",
  Video: "/video",
  Example: "/citizenprofile",
  Home: "/home",
  Post: "/posts",
  Profile: "/profile",
  Setting: "/setting",
  Link: "/link",
  createGroup: "/createGroup",
  createAccount: "/createAccount",
  addFile: "/addFile",
  conference: "/conference",
  personalCall: "/personal-call",
  checkIn: "/checkIn",
  Live: "/live",
  PersonalLive: "/personal-live",
  GroupLive: "/group-live",
  Stream: "/headstart",
  StreamAdmin: "/streamAdmin",
  StreamSeeMore: "/streamSeeMore",
  StreamForUser: "/streamForUser",
  forgotPassword: "/forgot-password",
  citizenprofile: "/citizenprofile",
  centercitizenprofile: "/center-citizenprofile",
  headstartinfo: "/headstart-live",
  headstartwatch: "/headstart-watch",
  fileCheckAccess: "/file-access",
  groupCommunity: "/group-community",
  groupCommunityPath: "/group-community/:id",
  conferenceOptions: "/conference-options",
  InitHeadStart: "/init-head-start",
  VirtualBackground: "/virtual-background",
  Board: "/whiteboard",
  googleLogin: "/auth/google",
  facebookLogin: "/auth/facebook",
  azureLogin: "/auth/azure",
  DraggableBoard: "/mention",
  Custom: "/custom/:key",
  CustomMenu: "/custom",
  UICaseDesignMaster: "/case-design-master",
  UIExportCaseDesignMaster: "/case-design-master/export",
  UIImportCaseDesignMaster: "/case-design-master/import",
  HomeCaseManagement: "/home-case-management",
  ViewAllDocuments: "/view-all-documents",
  CaseDetails: "/case-details",
  CaseDetailsPath: "/case-details/:id",
  ReportsList: "/reports-list",
  UIForcePasswordReset: "/force-password-reset",
  Referral: "/referral",
};

export const isCommunity = () => {
  if (window.location.href.indexOf(pathRoutes.groupCommunity) != -1) {
    return true;
  }
  return false;
};
export const checkEmpty = (val: any) => {
  if (
    val === undefined ||
    val === null ||
    val === "null" ||
    val === "undefined"
  )
    return true;
  if (val.length === 0) return true;

  return false;
};
export const isTrue = (val: any) => {
  if (val === true || val === "true" || val === "y" || val === "Y") return true;
  else return false;
};

export const getDateFormat = (dateData: any, t: any) => {
  try {
    let date = new Date(dateData);
    // console.log(date.getDate());
    let lang: any = localStorage.getItem("lang");
    moment.locale(lang);
    let min: any = date.getMinutes();
    if (min < 10) min = "0" + min;
    let hours: any = date.getHours();
    if (hours < 10) hours = "0" + hours;
    let year = lang === "th" ? date.getFullYear() + 543 : date.getFullYear();
    let timeData =
      date.getDate() + " " + t(months[date.getMonth()]) + ", " + year;
    // " | " +
    // hours +
    // ":" +
    // min;
    return timeData;
  } catch (e) {
    console.log(e);
    return dateData;
  }
};
export const getDateFormat_time = (dateData: any, t: any) => {
  try {
    let date = new Date(dateData);
    // console.log(date.getDate());
    let lang: any = localStorage.getItem("lang");
    moment.locale(lang);
    let min: any = date.getMinutes();
    if (min < 10) min = "0" + min;
    let hours: any = date.getHours();
    if (hours < 10) hours = "0" + hours;
    let year = lang === "th" ? date.getFullYear() + 543 : date.getFullYear();
    let timeData =
      date.getDate() +
      " " +
      t(months[date.getMonth()]) +
      ", " +
      year +
      " " +
      hours +
      ":" +
      min;
    return timeData;
  } catch (e) {
    console.log(e);
    return dateData;
  }
};

export function getPathSpace(pathArray: string) {
  let path = pathArray;
  if (Array.isArray(path) && pathArray?.length > 0) {
    path = pathArray[0];
  }
  if (checkEmpty(path)) return "";
  let final = "";
  let paths = path?.split("/");
  for (let a = 0; a < paths.length - 1; a++) {
    // console.log(a, paths[a]);
    if (a != 0) final = final + " / " + paths[a];
    else {
      final = final + paths[a];
    }
  }
  return final;
}

export async function getUrlFile(
  url: any,
  id: any,
  size: any,
  type: any,
  filePreviewTemp: any
) {
  const subfn = (link: any, url: any) => {
    return new Promise((resolve, reject) => {
      // console.log(link + "==> not exist!");
      if (checkEmpty(filePreviewTemp)) resolve(url);
      else {
        let urlcache = caches[url];
        if (urlcache === true) {
          resolve(url);
        }
        fetch(url)
          .then((response: any) => {
            if (response.status === 404) {
              // console.log(url + "==> not exist!");
              let file = filePreviewTemp[id];
              let urlImage = URL.createObjectURL(file);
              if (!checkEmpty(urlImage)) {
                resolve(urlImage);
              } else {
                resolve(url);
              }
            } else {
              caches[url] = true;
              resolve(url);
            }
          })
          .catch((error: any) => {
            // console.log("error", error);
            resolve(url);
          });
      }
    });
  };

  return new Promise(async (resolve, reject) => {
    if ("post" == type || "chat" == type || "conference" === type) {
      let link = KN_FILE_CACHE_MAIN + "/" + id + "_" + size + ".jpg";
      let urlcache = caches[link];
      if (urlcache === true) {
        resolve(link);
      }
      fetch(link)
        .then((response: any) => {
          if (response.status === 404) {
            resolve(url);
          } else {
            caches[link] = true;
            resolve(link);
          }
        })
        .catch((error: any) => {
          // console.log("error", error);
          resolve(url);
        });
    } else {
      if (videoType.includes(type) || imgTypes.includes(type)) {
        let link = KN_FILE_CACHE_MAIN + "/" + id + "_" + size + ".jpg";
        if (videoType.includes(type)) {
          link = KN_FILE_CACHE_MAIN + "/" + id + "_1024" + ".jpg";
        }
        let urlcache = caches[link];
        if (urlcache === true) {
          resolve(link);
        }
        // console.log("getUrlFile link", type, link)
        fetch(link)
          .then(async (response: any) => {
            // console.log("response", response)
            if (response.status === 404) {
              url = await subfn(link, url);
              resolve(url);
            } else {
              caches[link] = true;
              resolve(link);
            }
          })
          .catch(async (error: any) => {
            // console.log("error", error);
            // resolve(url);
            url = await subfn(link, url);
            // console.log("url final", url)
            resolve(url);
          });
      } else {
        resolve(url);
      }
    }
  });
}

export async function checkURL(link: any) {
  return new Promise((resolve, reject) => {
    let urlcache = caches[link];
    if (urlcache === true) {
      resolve(true);
    }
    fetch(link)
      .then((response: any) => {
        if (response.status === 404) {
          resolve(false);
        } else {
          caches[link] = true;
          resolve(true);
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        resolve(false);
      });
  });
}

export async function previewFile(fileId: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/previewFile?fileId=" + fileId;
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export const getAuthorization = () => {
  let token = getToken();
  let authorization = "Bearer " + token;

  return authorization;
};

export function getToken() {
  let auth = localStorage.getItem("openID");
  let token: any;

  if (auth) {
    token = JSON.parse(auth);
    return token?.access_token;
  }
  return null;
  //   return (
  //     localStorage.getItem(AuthKeys.token) ||
  //     sessionStorage.getItem(AuthKeys.token)
  //   );
}
export const bytesToSizeUtil = (bytes: any) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  //@ts-ignore
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  let v = Math.round((bytes / Math.pow(1024, i) + Number.EPSILON) * 100) / 100;
  if (isNaN(v)) v = 0;
  let v2 = sizes[i];
  if (checkEmpty(v2)) v2 = "MB";
  return v + " " + v2;
};

export async function getFileDir(fileId: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getFileDir?fileId=" + fileId;
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export function addCommas(nStr: any) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export const getNameOfUserNotFriend = (user: any) => {
  let textName = "";
  if (!checkEmpty(user?.firstname)) {
    if (!checkEmpty(user?.lastname)) {
      textName = user?.firstname + " " + user?.lastname;
    } else {
      textName = user?.firstname;
    }
  } else {
    textName = user?.username;
  }
  return textName;
};

export async function getUrl(url: any, id: any, size: any, type: any) {
  return new Promise((resolve, reject) => {
    if ("post" == type || "chat" == type || "conference" === type) {
      let link = KN_FILE_CACHE_MAIN + "/" + id + "_" + size + ".jpg";
      let urlcache = caches[link];
      if (urlcache === true) {
        resolve(link);
      }
      fetch(link)
        .then((response: any) => {
          if (response.status === 404) {
            resolve(url);
          } else {
            caches[link] = true;
            resolve(link);
          }
        })
        .catch((error: any) => {
          // console.log("error", error);
          resolve(url);
        });
    } else {
      if (videoType.includes(type) || imgTypes.includes(type)) {
        let link = KN_FILE_CACHE_MAIN + "/" + id + "_" + size + ".jpg";
        if (videoType.includes(type)) {
          link = KN_FILE_CACHE_MAIN + "/" + id + "_1024" + ".jpg";
        }
        let urlcache = caches[link];
        if (urlcache === true) {
          resolve(link);
        }
        fetch(link)
          .then((response: any) => {
            if (response.status === 404) {
              console.log(link + "==> not exist!");
              resolve(url);
            } else {
              caches[link] = true;
              resolve(link);
            }
          })
          .catch((error: any) => {
            // console.log("error", error);
            resolve(url);
          });
      } else {
        resolve(url);
      }
    }
  });
}

export async function getUrlV2(url: any, id: any, size: any, type: any) {
  if (["post", "chat", "conference"].includes(type)) {
    let link = KN_FILE_CACHE_MAIN + "/" + id + "_" + size + ".jpg";
    let urlcache = caches[link];
    if (urlcache === true) {
      return link;
    }
    const response = await fetch(link);
    if (response.status === 404) {
      throw new Error("Not found");
    } else {
      caches[link] = true;
      return link;
    }
  } else {
    if (videoType.includes(type) || imgTypes.includes(type)) {
      let link = KN_FILE_CACHE_MAIN + "/" + id + "_" + size + ".jpg";
      if (videoType.includes(type)) {
        link = KN_FILE_CACHE_MAIN + "/" + id + "_1024" + ".jpg";
      }
      let urlcache = caches[link];
      if (urlcache === true) {
        return link;
      }
      const response = await fetch(link);
      if (response.status === 404) {
        console.log(link + "==> not exist!");
        throw new Error("Not found");
      } else {
        caches[link] = true;
        return link;
      }
    } else {
      return url;
    }
  }
}

export async function getUserDetail(userId: any, orgId: string) {
  let link: any = CENTER_URL_MAIN;
  if (link?.endsWith("/"))
    link =
      CENTER_URL_MAIN + `api/v1/users/detail/${userId}?orgID=${orgId || ""}`;
  else
    link =
      CENTER_URL_MAIN + `/api/v1/users/detail/${userId}?orgID=${orgId || ""}`;
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function getactivitity(
  fileId: any,
  userId: any,
  offsetAc: any,
  limitOfset: any
) {
  let link =
    KN_FILE_SERVICE_URL_MAIN +
    "/getActivity?fileId=" +
    fileId +
    "&userId=" +
    userId +
    "&offset=" +
    offsetAc +
    "&limitOfset=" +
    limitOfset;
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(async function (response: any) {
        let datas = response.data.data;

        resolve({ data: datas, total: response.data.total });
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export function getUserData() {
  let metoStr: any = localStorage.getItem("meto");
  let meto = JSON.parse(metoStr);
  return meto;
}
export function parseUserData(data: any) {
  // let d = data.split(".")[1];
  // console.log(d)
  // console.log(data)
  // d =  atob(d);
  // d = d.substring(10,d.length);
  // d= atob(d);
  // let d = atob(data);
  var d = decodeURIComponent(escape(atob(data)));
  return d;
}

export async function deleteRequest(toDeleteFile: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/moveFileToTrash";
  let p = { fileId: toDeleteFile.file_app_id, type: toDeleteFile.type_ };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function getAboutLimitMain(param: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getAboutLimit";
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        params: param,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export const reflectorFileFoldersDelete = (
  files: any,
  type: string,
  res: any
) => {
  let i = files.findIndex((item: any) => {
    return item.file_app_id === res.file_app_id;
  });
  if (i != -1) {
    files.splice(i, 1);
  }
  let newLenght = files.lenght;
  return { files, newLenght };
};

export async function getFileFoldersMethodGet(
  param: any,
  link: any,
  fileContext: FileContextProps
) {
  let idsTemp = fileContext.fileAbouts;
  console.log("idsTemp", idsTemp);
  for (let a = 0; a < idsTemp.length; a++) {
    try {
      idsTemp[a]?.cancel();
      // let lastref = localStorage.getItem("lastreffile");
      // if (lastref != idsTemp[a].ref) {
      //   idsTemp[a]?.cancel();
      // }
    } catch (e) {}
  }
  idsTemp = [];
  let CancelToken = axios.CancelToken;
  let requestAxiosCancel: any = null;

  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        params: param,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          requestAxiosCancel = c;
        }),
      })
      .then(function (response: any) {
        resolve(response);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });

    let axiosGetFilesRequestIdsTemp = idsTemp;
    let ref = uuidv4();
    let item = { ref: ref, cancel: requestAxiosCancel };
    localStorage.setItem("lastreffile", ref);
    axiosGetFilesRequestIdsTemp.push(item);
    fileContext.setFileAbouts(axiosGetFilesRequestIdsTemp);
  });
}

export async function callDeleteFromTrashAll(groupId: any, userId: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/deleteFromTrashAll";
  // console.log(fileSelected);
  let p = {
    userId: userId,
    groupId: groupId,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}
export async function deleteFromTrash(
  fileSelected: any,
  groupId: any,
  onlySelectVersion: any
) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/deleteFromTrash";
  // console.log(fileSelected);
  let p = {
    fileId: fileSelected.file_app_id,
    parentFolderId: fileSelected.parent_folder_id,
    type: fileSelected.type_,
    groupId: groupId,
    filePath: fileSelected.file_path,
    fileName: fileSelected.file_name,
    onlyVersion: onlySelectVersion,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function restore(fileSelected: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/restore";
  console.log(fileSelected);
  let p = {
    fileId: fileSelected.file_app_id,
    parentFolderId: fileSelected.parent_folder_id,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function getLsItem(
  type: string,
  parentFolderId: string,
  groupId: string,
  userId: string
) {
  let param = {
    groupId: groupId,
    userId: userId,
    offset: 0,
    parentFolderId: parentFolderId,
  };
  let res: any = await getFiles(param);
  let fileFoldersT = res.data;
  if (checkEmpty(fileFoldersT)) fileFoldersT = [];
  let lstemp = fileFoldersT.filter((item: any) => {
    return item.type_ === type;
  });
  return lstemp;
}

export async function getFiles(paramemersFile: any) {
  return new Promise((resolve, reject) => {
    let CancelToken = axios.CancelToken;
    let requestAxiosCancel = null;
    let link = KN_FILE_SERVICE_URL_MAIN + "/getFiles";
    axios
      .get(link, {
        params: paramemersFile,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
        cancelToken: new CancelToken(function executor(c) {
          requestAxiosCancel = c;
        }),
      })
      .then(function (response: any) {
        resolve({ data: response.data.data, total: response.data.total });
      })
      .catch(function (error: any) {
        if (axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log("some other reason");
        }
        reject(error);
      })
      .finally(function () {
        // always executed
      });
    // let axiosGetFilesRequestIdsTemp = axiosGetFilesIds;
    // axiosGetFilesRequestIdsTemp.push(requestAxiosCancel);
    // setAxiosGetFilesIds(axiosGetFilesRequestIdsTemp);
  });
}

export async function createFolderRequest(
  fileName: string,
  groupId: string,
  userId: string,
  parentFolderId: string,
  entryp: boolean,
  controlVersion: boolean,
  moduleFileAc: string
) {
  let paramemers = {
    fileName: fileName.trim(),
    userId: checkEmpty(userId) ? "" : userId,
    groupId: checkEmpty(groupId) ? "" : groupId,
    parentFolderId: parentFolderId,
    entryp: entryp,
    controlVersion: controlVersion,
    moduleName: moduleFileAc,
  };
  let link = KN_FILE_SERVICE_URL_MAIN + "/createFolder";
  return new Promise((resolve, reject) => {
    axios
      .post(link, paramemers, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        let folder = Object.assign({}, response.data.data);
        resolve(folder);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {});
  });
}

export async function uploadLinkRequest(
  linkName: string,
  linkDetail: string,
  groupId: string,
  userId: string,
  parentFolderId: string,
  // filePath: string
) {
  let paramemers = {
    linkName: linkName.trim(),
    linkDetail: linkDetail,
    userId: checkEmpty(userId) ? "" : userId,
    groupId: checkEmpty(groupId) ? "" : groupId,
    parentFolderId: parentFolderId,
    // filePath: filePath,
  };
  let link = KN_FILE_SERVICE_URL_MAIN + "/uploadLink";
  // let link =  "http://localhost:7880/FileService/uploadLink";
  return new Promise((resolve, reject) => {
    axios
      .post(link, paramemers, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        let link_ = Object.assign({}, response.data.data);
        resolve(link_);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {});
  });
}

export const reflectorFileFolders = (
  fileFolders: any,
  type: string,
  res: any
) => {
  let filesT = fileFolders.filter((item: any) => {
    return item.type_ === "FILE";
  });
  let foldersT = fileFolders?.filter((item: any) => {
    return item.type_ === "DIRECTORY";
  });

  let response = [];
  fileFolders = [];

  let newLenght;
  if (type === "DIRECTORY") {
    fileFolders = fileFolders.concat(res);
    fileFolders = fileFolders.concat(foldersT);
    newLenght = fileFolders?.length + 1;
  } else {
    if (!checkEmpty(res.length)) {
      for (let a = 0; a < res.length; a++) {
        let indexFile = filesT.findIndex((item: any) => {
          return (
            item.file_name === res[a].file_name &&
            item.file_type === res[a].file_type
          );
        });
        if (indexFile != -1) {
          filesT.splice(indexFile, 1);
        }
        response.push(res[a]);
      }
    } else if (!checkEmpty(res)) {
      response.push(res);
    }

    fileFolders = fileFolders.concat(foldersT);
    fileFolders = fileFolders.concat(response);
    newLenght = fileFolders?.length + response.length;
  }
  fileFolders = fileFolders.concat(filesT);
  return { fileFolders, newLenght };
};

export function calPercentProgressBtnPercent(fileToUploadShow: any) {
  let v = calPercentProgressBtnMain(fileToUploadShow);
  if (Number.isNaN(v.percent)) return 0;
  // console.log("percent : ", v.percent);

  return v.percent;
}

export function calPercentProgressBtnMain(fileToUploadShow: any) {
  let total = 0,
    used = 0;
  let cancleSize = 0;
  let oldPercent = 0;
  for (let a = 0; a < fileToUploadShow?.length; a++) {
    if (
      fileToUploadShow[a].status !== "cancel" &&
      fileToUploadShow[a].status !== "error"
    ) {
      used += fileToUploadShow[a]?.loaded ? fileToUploadShow[a]?.loaded : 0;
      total += fileToUploadShow[a]?.size ? fileToUploadShow[a]?.size : 0;
    }
    if (
      fileToUploadShow[a].status === "cancel" ||
      fileToUploadShow[a].status === "error"
    ) {
      cancleSize++;
      oldPercent += fileToUploadShow[a]?.percent;
    }
  }
  let status = "normal";
  if (cancleSize === fileToUploadShow.length) {
    status = "cancel";
    percent = oldPercent / fileToUploadShow.length;
  } else {
    var percent = (used / total) * 100;
    percent = Math.round(percent);
    if (percent >= 100) {
      percent = 100;
      status = "done";
    }
  }
  return { percent: percent, status: status };
}

export async function cancelUpload(useName: any, hash: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/cancelUploadSplit";
  return new Promise((resolve, reject) => {
    let paramemers = { name: useName, hash: hash };
    axios
      .post(link, paramemers, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export function bytesToSize(bytes: any) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  // console.log("typeOfSize: ",typeof bytes)
  if (bytes === 0 || bytes === "0") return "0 Byte";

  //@ts-ignore
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return (
    Math.round((bytes / Math.pow(1024, i) + Number.EPSILON) * 100) / 100 +
    " " +
    sizes[i]
  );
}
export function reverseArr(input: any) {
  var ret = new Array();
  for (var i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
}

export async function getCurrentFolderMain(paramemersCurrentFolder: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getCurrentFolder";
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        params: paramemersCurrentFolder,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function getModuleNames(): Promise<any> {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getModuleNames";
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function getFileByCurrentAndName(
  name: any,
  currentFolder: any,
  groupId: any,
  userId: any,
  fileSelected: any
) {
  let parentFolderId = "";
  if (!checkEmpty(currentFolder?.file_app_id))
    parentFolderId = currentFolder?.file_app_id;
  let param = {
    groupId: groupId,
    userId: userId,
    offset: 0,
    type: "BOTH",
    parentFolderId: parentFolderId,
  };
  let res: any = await getFiles(param);
  let fileFoldersT = res.data;
  if (fileSelected.type_ === "DIRECTORY") {
    if (checkEmpty(fileFoldersT)) fileFoldersT = [];
    let folders = fileFoldersT.filter((item: any) => {
      return item.type_ === "DIRECTORY";
    });
    for (let a = 0; a < folders.length; a++) {
      if (folders[a].file_name === name.trim()) {
        return 1;
      }
    }
  } else {
    let files = fileFoldersT.filter((item: any) => {
      return item.type_ === "FILE";
    });
    for (let a = 0; a < files.length; a++) {
      if (
        files[a].file_name === name.trim() &&
        files[a].file_type === fileSelected.file_type.trim()
      ) {
        return 1;
      }
    }
  }

  return 0;
}

export async function moveFile(
  groupId: any,
  userId: any,
  fileId: any,
  newFileSelectedName: any,
  currentFolderId: any
) {
  if (checkEmpty(currentFolderId)) currentFolderId = "";
  let link = KN_FILE_SERVICE_URL_MAIN + "/moveFileFolder";

  let p = {
    toFolderId: currentFolderId,
    fileSelectedId: fileId,
    groupId: groupId,
    userId: userId,
    newName: newFileSelectedName,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        // console.log(response.data.data);
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function renameDir(
  renameTo: string,
  groupId: string,
  userId: string,
  fileId: string,
  oldName: string
) {
  let paramemers = {
    renameTo: renameTo,
    userId: userId,
    groupId: groupId,
    oldName: oldName,
    fileId: fileId,
  };
  let link = KN_FILE_SERVICE_URL_MAIN + "/rename";
  return new Promise((resolve, reject) => {
    axios
      .post(link, paramemers, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        if (response.data?.success) resolve(response.data);
        else {
          reject();
        }
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {});
  });
}

export async function getFileObj(userId: any, groupId: any, file: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getFileFolderDesc";
  let p = { userId: userId, groupId: groupId, fileId: file.file_app_id };
  return new Promise((resolve, reject) => {
    if (file.type_ === "FILE") {
      resolve(file);
    } else {
      axios
        .get(link, {
          params: p,
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorization(),
          },
        })
        .then(function (response: any) {
          resolve(response.data.data);
        })
        .catch(function (error: any) {
          reject(error);
        })
        .finally(function () {
          // always executed
        });
    }
  });
}

export async function getLinkDownload(fileId: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getLinkDownload";
  let p = {
    fileId: fileId,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        // console.log(response.data.data);
        resolve({ data: response.data.data, total: response.data.total });
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function addMemberToAccessBackend(
  fileId: any,
  userId: any,
  userSelected: any,
  userUnSelected: any,
  len: any,
  level: any,
  levelperson: any
) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/addMemberToAccess";
  let p = {
    fileId: fileId,
    userId: userId,
    userSelected: userSelected,
    userUnSelected: userUnSelected,
    len: len,
    level: level,
    levelperson: levelperson,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        // console.log(response.data.data);
        resolve({ data: response.data.data, total: response.data.total });
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export function checkFileForRunningNumber(
  name: string,
  type: string,
  ls: any,
  fromUploaded: any
) {
  for (let a = 0; a < ls?.length; a++) {
    let conditionName = ls[a].name_;
    let conditionType = ls[a].type_;
    if (fromUploaded) conditionName = ls[a].file_name;
    if (fromUploaded) conditionType = ls[a].file_type;
    if (conditionName === name.trim() && conditionType === type.trim()) {
      return 1;
    }
  }

  return 0;
}
export function getClassAndType(fullName: string) {
  let names = fullName.split(".");
  let name_ = "";
  let type_ = "";
  for (let a = 0; a < names.length; a++) {
    if (a === 0) name_ = names[a];
    else if (a > 0 && a != names.length - 1) name_ = name_ + "." + names[a];
    else type_ = names[a];
  }
  return { name_, type_ };
}

export async function getFinalName(
  name_: string,
  type_: string,
  fileToUpload: any,
  currentFolder: any,
  groupId: string,
  userId: string,
  confirm: any,
  t: any,
  fileContext: any
) {
  return new Promise(async (resolve, reject) => {
    let finalName: string = name_;
    let oldName: string = name_;

    //check name in uploaded
    let text = 1;
    let needCheckUploaded = false;
    let needCheckQ = false;
    let parentFolderId = "";
    // console.log("currentFolder : ",currentFolder)
    if (isTrue(currentFolder?.control_version)) {
      // needCheckUploaded = false;
      // needCheckQ = false;
      needCheckUploaded = true;
      needCheckQ = true;
    } else {
      needCheckUploaded = true;
      needCheckQ = true;
    }
    if (checkEmpty(currentFolder?.file_app_id)) {
      needCheckUploaded = true;
      needCheckQ = true;
    } else {
      parentFolderId = currentFolder?.file_app_id;
    }
    if (checkEmpty(parentFolderId)) parentFolderId = "";
    if (needCheckUploaded) {
      let paramemersFileT: any = {};
      paramemersFileT.limitOfset = null;
      paramemersFileT.offset = 0;
      paramemersFileT.active = "all";
      paramemersFileT.groupId = groupId;
      paramemersFileT.userId = userId;
      paramemersFileT.parentFolderId = parentFolderId;
      let res_1: any = await getFiles(paramemersFileT);
      let files = res_1.data?.filter((item: any) => {
        return item.type_ === "FILE";
      });
      console.log("files", files);
      let length = checkFileForRunningNumber(name_, type_, files, true);
      if (length > 0) {
        while (true) {
          let name = name_ + " (" + text + ")";

          length = checkFileForRunningNumber(name, type_, files, true);
          if (length > 0) {
            text++;
          } else {
            finalName = name;
            break;
          }
        }
      }
    }

    // check name in Q
    if (needCheckQ) {
      let length = checkFileForRunningNumber(
        finalName,
        type_,
        fileToUpload,
        false
      );
      if (length > 0) {
        while (true) {
          let name = name_ + " (" + text + ")";

          length = checkFileForRunningNumber(name, type_, fileToUpload, false);
          if (length > 0) {
            text++;
          } else {
            finalName = name;
            break;
          }
        }
      }
    }

    // console.log("finalName", finalName);
    if (finalName != oldName && isTrue(currentFolder?.control_version)) {
      // console.log("alert name")
      let textTitle = t("Option control");
      let textContent =
        `"${oldName}" ` +
        t("already exists, You want to rename or version control");
      let okText = t("Rename");
      let cancelText = t("Version Control");
      fileContext.setCheckingFile(false);
      confirm({
        wrapClassName: "modalCenterGlobal",
        title: textTitle,
        content: textContent,
        okText: okText,
        cancelText: cancelText,
        onOk() {
          console.log("Rename");
          fileContext.setCheckingFile(true);
          resolve(finalName);
        },
        onCancel() {
          console.log("Version Control");
          fileContext.setCheckingFile(true);
          resolve(oldName);
        },
      });
    } else {
      resolve(finalName);
    }
  });
}

export const onChangeSelectFileMain = async (
  event: any,
  fileContext: FileContextProps,
  inputFileToUploadEl: any,
  confirm: any,
  t: any
) => {
  try {
    // fileContext.setShowModalUpload(true);
    let isCheckIn = false; ///////////////
    let limitPerfileText = fileContext.aboutLimit?.limitSizePerFile;
    let limitSizePerFile = sizeToBytse(limitPerfileText);
    let fileToUploadT = Object.assign([], fileContext.fileToUpload);
    let files = event.target.files;
    fileContext.setCheckingFile(true);
    for (let a = 0; a < files.length; a++) {
      let file = files[a];
      let d: any = getClassAndType(file.name);
      d.name_ = await getFinalName(
        d.name_,
        d.type_,
        fileContext.fileToUpload,
        fileContext.currentFolder,
        fileContext.groupId,
        fileContext.userId,
        confirm,
        t,
        fileContext
      );

      file.name_ = d.name_;
      file.fullName = file.name_ + "." + d.type_;
      file.type_ = d.type_;
      file.size_ = file.size;
      file.lastModified_ = file.lastModified;

      let isOversize = false;
      if (file.size_ > limitSizePerFile) isOversize = true;
      let already = checkAlready(fileToUploadT, file);
      if (isCheckIn) {
        if (
          file.name_ === fileContext.fileSelected?.file_name &&
          file.type_ === fileContext.fileSelected?.file_type
        ) {
          fileToUploadT = [];
        } else {
          //alert
          // setMessageNoti(t("Please import same file name as you checked out."));
          // setShowNoti(true);
        }
      } else {
        if (isTrue(fileContext.currentFolder?.control_version)) {
          let dataCanUpload: any = await checkFileCanUploadBackend(
            file,
            fileContext.currentFolder
          );
          if (isTrue(dataCanUpload.can)) {
            if (isOversize === true) {
              // setMessageNoti(
              //   t("Please select file less than") +
              //     " " +
              //     aboutLimit.limitSizePerFile
              // );
              // setShowNoti(true);

              fileContext.setUploadErrorMessage("The file is too large.");
              fileContext.setUploadErrorDescription(
                "Please select file less than " +
                  bytesToSize(limitSizePerFile)?.replace(" ", "")
              );
            } else {
              // fileToUpload.push(file);
              fileToUploadT.push(file);
              fileContext.setShowModalUpload(true);
            }
          } else {
            //alert
            let user_id = dataCanUpload.checkOutObj?.user_id;
            // setMessageNoti(
            //   t(
            //     "${userId} is currently checked out this file. Please wait for ${userId} to cancel check out or check in new file."
            //   )
            //     .split("${userId}")
            //     .join(user_id)
            // );
            // setShowNoti(true);
          }
        } else {
          if (!already) {
            if (isOversize === true) {
              // setMessageNoti(
              //   t("Please select file less than") +
              //     " " +
              //     fileContext.aboutLimit.limitSizePerFile
              // );
              // setShowNoti(true);

              fileContext.setUploadErrorMessage("The file is too large.");
              fileContext.setUploadErrorDescription(
                "Please select file less than " +
                  bytesToSize(limitSizePerFile)?.replace(" ", "")
              );
            } else {
              fileToUploadT.push(file);
              fileContext.setShowModalUpload(true);
            }
          }
        }
      }
      // fileContext.setFileToUpload(Object.assign([], fileToUploadT));
    }
    fileContext.setCheckingFile(false);
    fileContext.setFileToUpload(Object.assign([], fileToUploadT));
    inputFileToUploadEl.current.value = null;
  } catch (e) {
    console.error(e);
  }
};

export async function uploadFileRequestChunk(
  files: any,
  groupId: any,
  userId: any,
  currentFolder: any,
  isCheckIn: any,
  fileSelected: any,
  fileContext: any,
  appContext: any
) {
  return new Promise((resolve, reject) => {
    let sizeAll = files.length,
      checkFinish = 0,
      checkError = 0;
    let parentFolderId = "";
    if (!checkEmpty(currentFolder?.file_app_id))
      parentFolderId = currentFolder?.file_app_id;

    let lsFileUpload: any = [];
    let fileTemps: any = [];
    for (let a = 0; a < files.length; a++) {
      let file = files[a];

      let usedId: any = null;
      let useName = file.name_;
      if (isCheckIn) {
        usedId = fileSelected.file_app_id;
        useName = fileSelected.file_name;
      }

      let blockCount = Math.ceil(file.size / chunkSize); // total number of fragments
      console.log("a ", a);

      let hash = uuidv4();
      console.log("hash", hash);
      let axiosPromiseArray = [];

      let fileTemp: any = { loaded: 0 };
      fileTemps[a] = fileTemp;

      console.log(a, blockCount);
      let filePreviewTemp: any = {};
      for (let i = 0; i < blockCount; i++) {
        let CancelToken = axios.CancelToken;
        let cancel: any;
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        // Building forms
        const form = new FormData();
        form.append("file", blobSlice.call(file, start, end));
        form.append("name", useName);
        //@ts-ignore
        form.append("total", blockCount);
        //@ts-ignore
        form.append("index", i);
        form.append("size", file.size);
        //@ts-ignore
        form.append("hash", hash);

        // Ajax submits fragmentation, where content-type is multipart/form-data

        const axiosOptions = {
          onUploadProgress: (e: any) => {
            // Progress in handling uploads
            let progress: any = {};
            let loaded = fileTemps[a].loaded + e.loaded;
            progress.total = file.size;
            progress.loaded = loaded;
            if (e.loaded === e.total) {
              fileTemps[a].loaded = fileTemps[a].loaded + e.total;
            }

            // console.log(progress.total + "/" + progress.loaded);
            // console.log(i, (progress.loaded / progress.total) * 100);

            onUploadProgress(
              progress,
              file,
              fileContext,
              cancel,
              useName,
              hash,
              i,
              blockCount
            );
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorization(),
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        };
        // Add to Promise array
        let link = KN_FILE_SERVICE_URL_MAIN + "/uploadSplit";
        axiosPromiseArray.push(axios.post(link, form, axiosOptions));
      }
      // After all fragments are uploaded, request to merge the fragmented files
      console.log("a ", a, axiosPromiseArray);
      axios
        .all(axiosPromiseArray)
        // eslint-disable-next-line no-loop-func
        .then(() => {
          let CancelToken = axios.CancelToken;
          let cancel: any;

          let config = {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c;
            }),
            onUploadProgress: (progress: any) => {
              // onUploadProgress(progress, file, fileContext, cancel);
              let progressT: any = {};
              progressT.total = file.size;
              progressT.loaded = file.size;
              onUploadProgress(
                progressT,
                file,
                fileContext,
                cancel,
                useName,
                hash,
                null,
                blockCount
              );
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: getAuthorization(),
            },
          };

          let link = KN_FILE_SERVICE_URL_MAIN + "/merge_chunks";
          // Merge chunks
          console.log("file : ", file);
          const data = {
            size: file.size,
            name: useName + "." + file.type_,
            total: blockCount,

            fileName: useName,
            userId: userId,
            groupId: groupId,
            parentFolderId: parentFolderId,
            cancelCheckOut: isCheckIn,
            cancelCheckOutFileId: usedId,
            hash: hash,
            mimeType: file.type,
            moduleName: fileContext.moduleFileAc,
          };
          axios
            .post(link, data, config)
            .then((res) => {
              console.log("successful upload");
              console.log(res.data, file);
              filePreviewTemp[res.data?.data?.file_app_id] = file;

              checkFinish++;
              if (checkFinish === sizeAll && checkError === 0) {
                resolve(lsFileUpload);
                appContext.setFilePreviewTemp(filePreviewTemp);
              } else if (checkFinish === sizeAll && checkError != 0) {
                resolve(lsFileUpload);
                appContext.setFilePreviewTemp(filePreviewTemp);
              }
              let progressT: any = {};
              progressT.total = file.size;
              progressT.loaded = file.size;
              onUploadProgress(
                progressT,
                file,
                fileContext,
                cancel,
                useName,
                hash,
                null,
                blockCount
              );

              let fileT = Object.assign({}, res.data.data);
              lsFileUpload.push(fileT);
            })
            .catch(function (error: any) {
              checkError++;
              if (axios.isCancel(error)) {
                oncancelUpload(file, fileContext, "cancel");
              } else {
                console.log("some other reason", error);
                oncancelUpload(file, fileContext, "error");
              }
            })
            .finally(function () {
              console.log("finally upload");
            });
        })
        .catch(function (error: any) {
          checkError++;
          if (axios.isCancel(error)) {
            oncancelUpload(file, fileContext, "cancel");
          } else {
            console.log("some other reason", error);
            oncancelUpload(file, fileContext, "error");
          }
        });
    }
  });
}

function onUploadProgress(
  progressEvent: any,
  file: any,
  fileContext: any,
  cancel: any,
  useName: any,
  hash: any,
  index: any,
  total: any
) {
  let temp = file;
  // console.log("progressEvent : ",progressEvent)
  let fileToUploadShowT = Object.assign([], fileContext.fileToUpload);
  let i = fileToUploadShowT.findIndex((item: any) => {
    return item.name_ === file.name_ && item.type_ === file.type_;
  });
  var percent = (progressEvent.loaded / progressEvent.total) * 100;
  percent = Math.round(percent);
  let fileT = fileToUploadShowT[i];
  if (!checkEmpty(fileT)) {
    if (fileT.status !== "cancel") {
      if (checkEmpty(fileT.percent) || percent > fileT.percent)
        fileT.percent = percent;
      fileT.status = "progress";
      fileT.useName = useName;
      fileT.hash = hash;
      fileT.loaded = progressEvent.loaded;
      fileT.total = total;
      fileT.file = temp;
      let cancels = fileT.cancel;
      if (checkEmpty(cancels)) {
        cancels = [];
      }
      if (!checkEmpty(index)) {
        cancels[index] = cancel;
      }
      fileT.cancel = cancels;
      if (percent === 100) {
        fileT.status = "done";
      }
      fileT.show = true;
    }

    fileToUploadShowT[i] = fileT;
    fileContext.setFileToUpload(Object.assign([], fileToUploadShowT));
  }
}

function oncancelUpload(file: any, fileContext: any, status: any) {
  let fileToUploadShowT = Object.assign([], fileContext.fileToUpload);
  let i = fileToUploadShowT.findIndex((item: any) => {
    return item.name_ === file.name_ && item.type_ === file.type_;
  });
  let fileT = fileToUploadShowT[i];
  if (!checkEmpty(fileT)) {
    fileT.status = status;
    fileToUploadShowT[i] = fileT;
    fileContext.setFileToUpload(Object.assign([], fileToUploadShowT));
  }
}

export async function uploadFolderFileRequestChunk(
  files: any,
  groupId: any,
  userId: any,
  currentFolder: any,
  isCheckIn: any,
  fileSelected: any,
  fileContext: any
) {
  return new Promise((resolve, reject) => {
    let sizeAll = files.length,
      checkFinish = 0,
      checkError = 0;
    let parentFolderId = "";
    if (!checkEmpty(currentFolder)) parentFolderId = currentFolder;

    let lsFileUpload: any = [];
    let fileTemps: any = [];
    for (let a = 0; a < files.length; a++) {
      let file = files[a];

      let usedId: any = null;
      let useName = file.name_;
      if (isCheckIn) {
        usedId = fileSelected.file_app_id;
        useName = fileSelected.file_name;
      }

      let blockCount = Math.ceil(file.size / chunkSize); // total number of fragments
      console.log("a ", a);

      let hash = uuidv4();
      console.log("hash", hash);
      let axiosPromiseArray = [];

      let fileTemp: any = { loaded: 0 };
      fileTemps[a] = fileTemp;

      console.log(a, blockCount);
      for (let i = 0; i < blockCount; i++) {
        let CancelToken = axios.CancelToken;
        let cancel: any;
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        // Building forms
        const form = new FormData();
        form.append("file", blobSlice.call(file, start, end));
        form.append("name", useName);
        //@ts-ignore
        form.append("total", blockCount);
        //@ts-ignore
        form.append("index", i);
        form.append("size", file.size);
        //@ts-ignore
        form.append("hash", hash);

        // Ajax submits fragmentation, where content-type is multipart/form-data

        const axiosOptions = {
          onUploadProgress: (e: any) => {
            // Progress in handling uploads
            let progress: any = {};
            let loaded = fileTemps[a].loaded + e.loaded;
            progress.total = file.size;
            progress.loaded = loaded;
            if (e.loaded === e.total) {
              fileTemps[a].loaded = fileTemps[a].loaded + e.total;
            }

            // console.log(progress.total + "/" + progress.loaded);
            // console.log(i, (progress.loaded / progress.total) * 100);

            onUploadProgress(
              progress,
              file,
              fileContext,
              cancel,
              useName,
              hash,
              i,
              blockCount
            );
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorization(),
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        };
        // Add to Promise array
        let link = KN_FILE_SERVICE_URL_MAIN + "/uploadSplit";
        axiosPromiseArray.push(axios.post(link, form, axiosOptions));
      }
      // After all fragments are uploaded, request to merge the fragmented files
      console.log("a ", a, axiosPromiseArray);
      axios
        .all(axiosPromiseArray)
        // eslint-disable-next-line no-loop-func
        .then(() => {
          let CancelToken = axios.CancelToken;
          let cancel: any;

          let config = {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c;
            }),
            onUploadProgress: (progress: any) => {
              // onUploadProgress(progress, file, fileContext, cancel);
              let progressT: any = {};
              progressT.total = file.size;
              progressT.loaded = file.size;
              onUploadProgress(
                progressT,
                file,
                fileContext,
                cancel,
                useName,
                hash,
                null,
                blockCount
              );
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: getAuthorization(),
            },
          };

          let link = KN_FILE_SERVICE_URL_MAIN + "/merge_chunks";
          // Merge chunks
          console.log("file : ", file);
          const data = {
            size: file.size,
            name: useName + "." + file.type_,
            total: blockCount,

            fileName: useName,
            userId: userId,
            groupId: groupId,
            parentFolderId: parentFolderId,
            cancelCheckOut: isCheckIn,
            cancelCheckOutFileId: usedId,
            hash: hash,
            mimeType: file.type,
            moduleName: fileContext.moduleFileAc,
          };
          axios
            .post(link, data, config)
            .then((res) => {
              console.log("successful upload");
              console.log(res.data, file);
              checkFinish++;
              if (checkFinish === sizeAll && checkError === 0) {
                resolve(lsFileUpload);
              } else if (checkFinish === sizeAll && checkError != 0) {
                resolve(lsFileUpload);
              }
              let progressT: any = {};
              progressT.total = file.size;
              progressT.loaded = file.size;
              onUploadProgress(
                progressT,
                file,
                fileContext,
                cancel,
                useName,
                hash,
                null,
                blockCount
              );

              let fileT = Object.assign({}, res.data.data);
              lsFileUpload.push(fileT);
            })
            .catch(function (error: any) {
              checkError++;
              if (axios.isCancel(error)) {
                oncancelUpload(file, fileContext, "cancel");
              } else {
                console.log("some other reason", error);
                oncancelUpload(file, fileContext, "error");
              }
            })
            .finally(function () {
              console.log("finally upload");
            });
        })
        .catch(function (error: any) {
          checkError++;
          if (axios.isCancel(error)) {
            oncancelUpload(file, fileContext, "cancel");
          } else {
            console.log("some other reason", error);
            oncancelUpload(file, fileContext, "error");
          }
        });
    }
  });
}

export async function blockDownload(fileId: any, blockDownload: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/blockDownload";
  let p = {
    fileId: fileId,
    blockDownload: blockDownload,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, p, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        // console.log(response.data.data);
        // resolve({ data: response.data.data, total: response.data.total });
        resolve({});
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export const onSetFolderFileMain = async (
  fileContext: any,
  file: any,
  confirm: any,
  t: any
) => {
  try {
    let isCheckIn = false; ///////////////
    let limitPerfileText = fileContext.aboutLimit?.limitSizePerFile;
    let limitSizePerFile = sizeToBytse(limitPerfileText);
    let fileToUploadT = Object.assign([], fileContext.fileToUpload);
    let files = file;
    fileContext.setCheckingFile(true);
    for (let a = 0; a < files.length; a++) {
      let file = files[a];
      let d: any = getClassAndType(file.name);
      d.name_ = await getFinalName(
        d.name_,
        d.type_,
        fileContext.fileToUpload,
        fileContext.currentFolder,
        fileContext.groupId,
        fileContext.userId,
        confirm,
        t,
        fileContext
      );

      file.name_ = d.name_;
      file.fullName = file.name_ + "." + d.type_;
      file.type_ = d.type_;
      file.size_ = file.size;
      file.lastModified_ = file.lastModified;

      let isOversize = false;
      if (file.size_ > limitSizePerFile) isOversize = true;
      let already = checkAlready(fileToUploadT, file);
      if (isCheckIn) {
        if (
          file.name_ === fileContext.fileSelected?.file_name &&
          file.type_ === fileContext.fileSelected?.file_type
        ) {
          fileToUploadT = [];
        } else {
          //alert
          // setMessageNoti(t("Please import same file name as you checked out."));
          // setShowNoti(true);
        }
      } else {
        if (isTrue(fileContext.currentFolder?.control_version)) {
          let dataCanUpload: any = await checkFileCanUploadBackend(
            file,
            fileContext.currentFolder
          );
          if (isTrue(dataCanUpload.can)) {
            if (isOversize === true) {
              // setMessageNoti(
              //   t("Please select file less than") +
              //     " " +
              //     aboutLimit.limitSizePerFile
              // );
              // setShowNoti(true);

              fileContext.setUploadErrorMessage("The file is too large.");
              fileContext.setUploadErrorDescription(
                "Please select file less than " +
                  bytesToSize(limitSizePerFile)?.replace(" ", "")
              );
            } else {
              // fileToUpload.push(file);
              fileToUploadT.push(file);
              fileContext.setShowModalFolderUpload(true);
            }
          } else {
            //alert
            let user_id = dataCanUpload.checkOutObj?.user_id;
            // setMessageNoti(
            //   t(
            //     "${userId} is currently checked out this file. Please wait for ${userId} to cancel check out or check in new file."
            //   )
            //     .split("${userId}")
            //     .join(user_id)
            // );
            // setShowNoti(true);
          }
        } else {
          if (!already) {
            if (isOversize === true) {
              // setMessageNoti(
              //   t("Please select file less than") +
              //     " " +
              //     fileContext.aboutLimit.limitSizePerFile
              // );
              // setShowNoti(true);

              fileContext.setUploadErrorMessage("The file is too large.");
              fileContext.setUploadErrorDescription(
                "Please select file less than " +
                  bytesToSize(limitSizePerFile)?.replace(" ", "")
              );
            } else {
              fileToUploadT.push(file);
              fileContext.setShowModalFolderUpload(true);
            }
          }
        }
      }
      fileContext.setFileToUpload(Object.assign([], fileToUploadT));
    }
    fileContext.setCheckingFile(false);
    fileContext.setFileToUpload(Object.assign([], fileToUploadT));
  } catch {}
};
export function checkAlready(ls: any, file: any) {
  for (let a = 0; a < ls.length; a++) {
    if (ls[a].fullName === file.name) return true;
  }
  return false;
}

export const checkFileCanUploadBackend = (file: any, currentFolder: any) => {
  let link = KN_FILE_SERVICE_URL_MAIN + "/checkFileCanUpload";
  let currentFolderId = "";
  if (!checkEmpty(currentFolder?.file_app_id))
    currentFolderId = currentFolder?.file_app_id;
  let p = {
    currentFolderId: currentFolderId,
    fileName: file.name_,
    fileType: file.type_,
  };
  return new Promise((resolve, reject) => {
    let data = { can: false };
    axios
      .get(link, {
        params: p,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        let data = response.data.data;
        resolve(data);
      })
      .catch(function (error: any) {
        resolve(data);
      })
      .finally(function () {});
  });
};

export async function getLogFiles({
  offset,
  limitOffset,
  groupID,
  userID,
  search,
  module,
  isAdminRoleFile = false,
}: any): Promise<any> {
  let Id = groupID ? `&groupId=${groupID}` : userID ? `&userId=${userID}` : "";
  let moduleAc = module ? `&module=${module}` : "";
  moduleAc += isAdminRoleFile ? "&isAdminFile=Y" : "";
  console.log("response SearchInput:: search", search);
  return axios
    .get(
      KN_FILE_SERVICE_URL_MAIN +
        APIFileEnpoint.getLogFiles +
        `?offset=${offset}&limitOfset=${limitOffset}&search=${search}` +
        Id +
        moduleAc,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      }
    )
    .then((res) => res.data);
}

export const checkImg = async (URL: any) => {
  return new Promise((resolve, reject) => {
    var tester = new Image();
    tester.onload = function () {
      resolve(true);
    };
    tester.onerror = function () {
      resolve(false);
    };
    tester.src = URL;
  });
};

export async function getLinkDetail(url: any) {
  url = url?.replace(/\r?\n/g, "");
  let link = CENTER_URL_MAIN + "/api/v1/crawler/og";
  var token = getToken();
  let param = {
    url: url,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(link, param, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(async function (response: any) {
        let data = response.data;
        try {
          data.mainImg = data.images[0].url;
        } catch (e) {}
        resolve(data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export function fetchNextUtil(
  fileContext: any,
  data: any,
  setOffsetFile: any,
  defaultLitmitOfset: any,
  offsetFile: any
) {
  let hasMore;
  let offsetFileRes = offsetFile;
  console.log("1loading more...", data);
  console.log("2loading more...", fileContext.fileFolders);
  if (fileContext.fileFolders?.length + defaultLitmitOfset >= data?.total) {
    fileContext.setHasMore(false);
    hasMore = false;
  }
  if (fileContext.fileFolders?.length >= data?.total) {
    fileContext.setHasMore(false);
    hasMore = false;
  }
  if (fileContext.fileFolders?.length < data?.total) {
    fileContext.setLoadMore(true);
    fileContext.setHasMore(true);
    setOffsetFile(offsetFile + defaultLitmitOfset);
    offsetFileRes = offsetFile + defaultLitmitOfset;
    hasMore = true;
  }

  return { hasMore: hasMore, offsetFile: offsetFileRes };
}

export function downloadFile(file: any) {
  try {
    let link =
      KN_FILE_SERVICE_URL_MAIN +
      "/downloadFile" +
      "?fileId=" +
      file.file_app_id;
    //@ts-ignore
    document.getElementById("my_iframe_Download").src = link;
  } catch (e) {
    console.log("E :", e);
  }
}

export async function getFileByLinkId(linkId: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getDetailByLink?linkId=" + linkId;
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export async function checkUserHasAccess(userId: any, fileId: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/checkUserHasAccess";
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        params: {
          userId: userId,
          fileId: fileId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export const getUserDataMeto = () => {
  let metoStr: any = localStorage.getItem("meto");
  let meto = JSON.parse(metoStr);
  return meto;
};

export async function getCurrentSharedWithCallService(param: any) {
  let link = KN_FILE_SERVICE_URL_MAIN + "/getSharedWithMeDetail";
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        params: param,
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data.data);
      })
      .catch(function (error: any) {
        reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}

export function calFreeSize_Split(used: any, limitSize: any) {
  let fullSize = sizeToBytse(limitSize);
  let freeSize = fullSize - used;
  if (freeSize <= 0) freeSize = 0;
  let freeSizeObj = bytesToSize_Split(freeSize);
  let size = 0;
  let typeOfSize = "MB";
  if (checkEmpty(freeSizeObj)) {
    size = 0;
    typeOfSize = "MB";
  }
  size = freeSizeObj.size;
  typeOfSize = freeSizeObj.typeOfSize;
  return { size: size, typeOfSize: typeOfSize };
}
export function sizeToBytse(limitSize: any) {
  if (limitSize === null || limitSize === undefined) return 0;
  let tSizes = limitSize?.trim().split(" ");
  let bytes = 0;
  if (tSizes[1] === "TB")
    bytes = parseInt(tSizes[0]) * 1024 * 1024 * 1024 * 1024;
  if (tSizes[1] === "GB") bytes = parseInt(tSizes[0]) * 1024 * 1024 * 1024;
  if (tSizes[1] === "MB") bytes = parseInt(tSizes[0]) * 1024 * 1024;
  if (tSizes[1] === "KB") bytes = parseInt(tSizes[0]) * 1024;
  if (tSizes[1] === "Bytes") bytes = parseInt(tSizes[0]);

  return bytes;
}
export function bytesToSize_Split(bytes: any) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return { size: 0, typeOfSize: "GB" };
  //@ts-ignore
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  let size =
    Math.round((bytes / Math.pow(1024, i) + Number.EPSILON) * 100) / 100;

  let typeOfSize = sizes[i];
  if (checkEmpty(size) || Number.isNaN(size)) size = 0;
  if (checkEmpty(typeOfSize)) typeOfSize = "MB";
  return { size: size, typeOfSize: typeOfSize };
}

export function calPercent(limitSize: any) {
  let used = limitSize?.used;
  let limit = limitSize?.limitSize;
  let total = sizeToBytse(limit);
  let percent = (used / total) * 100;
  if (checkEmpty(percent) || Number.isNaN(percent)) return percent;
  return percent;
}

export async function getUserCenterData() {
  let link: any = CENTER_URL_MAIN + `/api/v1/users/me`;
  return new Promise((resolve, reject) => {
    axios
      .get(link, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorization(),
        },
      })
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        // reject(error);
      })
      .finally(function () {
        // always executed
      });
  });
}
