import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {FileContext, FileContextProps} from "../File.context";
import { callDeleteFromTrashAll, getAboutLimitMain, getUserData } from '../../util.service';
import { checkEmpty } from 'src/services/util.service';
import CenterConfirmModal from '../Modal/CenterConfirmModal';

const UIModalConfirmEmptyTrash = () => {
    const { t, i18n } = useTranslation();
    const fileContext = useContext<FileContextProps>(FileContext);
    const [loading, setLoading]: any = useState(false);
    
    const getAboutLimit = async () => {
        try {
          let data = await getAboutLimitMain({
            userId: fileContext.userId,
            groupId: fileContext.groupId,
          });
          fileContext.setAboutLimit(data);
          console.log("getAboutLimit : ", data);
        } catch (e) {
          console.error(e);
        }
      };
      
    const handleDeleteFromTrashAll = async () => {
        if (loading === true) return;
        try {
            setLoading(true);
          let id = fileContext.groupId;
          if (checkEmpty(id)) id = "";
          await callDeleteFromTrashAll(id , getUserData()?.userID);
          fileContext.setDisplayConfirmEmptyTrash(false);
          fileContext.setFileFolders([]);
          getAboutLimit();
        }catch (e) {
          console.error(e);
        } finally {
         setLoading(false);
        }
      };


    return (
        <CenterConfirmModal
            visible={fileContext.displayConfirmEmptyTrash}
            onCancel={() => {
                if (loading === false) fileContext.setDisplayConfirmEmptyTrash(false);
            }}
            //  confirmLoading={deleteCommentAPI.loading}
            onConfirm={handleDeleteFromTrashAll}
            text={t("Are you sure to empty trash")}
            confirmText={t("OK")}
            cancelText={t("Cancel")}
        />
    );

}

export default UIModalConfirmEmptyTrash;