import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import * as Constant from "../FileConstants.const";
import FileIconAAC from "src/assets/png/FileIconAAC.png";
import FileIconDOCX from "src/assets/png/FileIconDOCX.png";
import FileIconMP4 from "src/assets/png/FileIconMP4.png";
import connection_file from "src/assets/png/link.png";
import UIFileType from "../type/UIFileType";
import { supportViewer, tabName } from "../properies.file";
import styles from "./FileContainer.module.scss";
import { getClassNameByType } from "./UtilFileCard";
import { FileContext } from "../File.context";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
// import SimpleReactLightbox from "simple-react-lightbox";
//@ts-ignore
// import { SRLWrapper } from "simple-react-lightbox";
import ReactPlayer from "react-player";
import { Modal } from "antd";
import UIFileMenuPopover from "../component/UIFileMenuPopover";
import { getDateFormat, getPathSpace, getUrlFile, isCommunity, KN_FILE_SERVICE_URL_MAIN, previewFile } from "../../util.service";
import { checkEmpty, isTrue } from "src/services/util.service";
import { AppContext } from "src/page/context/App.context";

interface FileCardProps {
  fileName: string;
  used: string;
  type: string;
  member: string;
  lastModified: string;
  isTrash: boolean;
}
let file_page = "file_page";
const FileCard = (props: any) => {
  // console.log("solr file card:", props.file);
  const { showMenuVersion } = props;
  const fileContext: any = useContext(FileContext);
  const appContext: any = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const [isOpenImg, setIsOpenImg] = useState(false);
  const [urlPreviewImg, setUrlPreviewImg] = useState("");

  const [isOpenVideo, setIsOpenVideo] = useState(false);

  const [urlPreviewVideo, setUrlPreviewVideo] = useState("");

  const getIconType = (type: any) => {
    let iconType;
    if (type == Constant.fileType.AAC) {
      iconType = FileIconAAC;
    } else if (type == Constant.fileType.DOCX) {
      iconType = FileIconDOCX;
    } else if (type == Constant.fileType.MP4) {
      iconType = FileIconMP4;
    }
    return iconType;
  };

  const [url, setUrl] = useState(null);
  const checkGetUrlFile = async () => {
    let url: any =
      KN_FILE_SERVICE_URL_MAIN +
      "/downloadFile?fileId=" +
      props.file?.file_app_id +
      "&size=200";
    if (props.isTrash) {
      url = url + "&onTrashReqIgnore=true"
    }
    url = await getUrlFile(
      url,
      props.file?.file_app_id,
      200,
      props.file?.file_type,
      appContext.filePreviewTemp
    );
    setUrl(url);
  };

  useEffect(() => {
    checkGetUrlFile();
  }, []);

  const handleShowMenu = () => {
    if (props.file.file_app_id === fileContext.currentShowMenuId) {
      fileContext.setCurrentShowMenuId(null);
    } else {
      fileContext.setCurrentShowMenuClick(file_page);
      fileContext.setCurrentShowMenuId(props.file.file_app_id);
    }
  };
  useEffect(() => {
    if (
      props.file.file_app_id === fileContext.currentShowMenuId &&
      fileContext.currentShowMenuClick === file_page
    ) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [fileContext.currentShowMenuId]);

  useEffect(() => {
    setShowMenu(false);
    fileContext.setCurrentShowMenuId(null);
  }, []);

  useEffect(() => {
    // console.log("FileCard props.isShared",props.isShared)
  }, [props.isShared])

  const getInfoMemberOrPathOrVersion = () => {
    let text = "";
    let pos = "left";
    if (
      fileContext.tab === tabName.mediavideo ||
      fileContext.tab === tabName.mediaimage ||
      fileContext.tab === tabName.other ||
      fileContext.tab === tabName.doc ||
      fileContext.tab === tabName.unknow ||
      fileContext.tab === tabName.trash ||
      fileContext.tab === tabName.search
    ) {
      text = "/ " + getPathSpace(props.file?.file_path);
    } else if (fileContext.tab === tabName.sharedWithMe) {
      text = props.file?.owner_user_id;
    } else if (isTrue(props.visibleManageVersion)) {
      text = props.file?.file_version;
      pos = "center";
    } else text = "";

    return (
      <div
        className={styles.textOverflowListFileText}
        style={{ alignContent: pos }}
      >
        <div>{text}</div>
      </div>
    );
  };

  const clickPreviewImage = async () => {
    if (supportViewer.images.indexOf(props.file?.file_type) > -1) {
      let url: any =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        props.file?.file_app_id +
        "&size=1024&onTrashReqIgnore=true"
      url = await getUrlFile(
        url,
        props.file?.file_app_id,
        1024,
        props.file?.file_type,
        appContext.filePreviewTemp
      );

      setUrlPreviewImg(url);
      setIsOpenImg(true);
    } else if (supportViewer.video.indexOf(props.file?.file_type) > -1) {
      let url =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        props.file?.file_app_id +
        "&onTrashReqIgnore=true";
      setUrlPreviewVideo(url);
      setIsOpenVideo(true);
    }
    previewFile(props.file?.file_app_id)
  };
  const handleClickVideoModal = (e: any) => {
    console.log(e);
  };

  return (
    <>
      <div
        className={styles.fileCardBox}
        style={{ 
          background: isCommunity() ? "transparent" : "",
          height: fileContext.isAdvacedSearch ? "64px" : "64px",
         }}
      >
        <Row justify="start" style={{ width: "100%", alignItems: "center" }}>
          <Col
            span={
              fileContext.tab === !tabName.myFile ||
                !checkEmpty(fileContext.tab) || isTrue(props.visibleManageVersion)
                ? 10
                : 15
            }
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginLeft: !fileContext.isAdvacedSearch ? "25px" : "none",
                  margin: fileContext.isAdvacedSearch ? "auto auto auto 25px" : "none",
                }}
              >
                <div
                  className={getClassNameByType(
                    props.file.file_type,
                    false,
                    false
                  )}
                >
                  <div
                    className={[styles.verticalCenter, styles.clickcss].join(
                      " "
                    )}
                    onClick={clickPreviewImage}
                  >
                    <UIFileType file={props.file} urlImage={url}></UIFileType>
                  </div>
                </div>
              </div>
              <div
                style={{
                  // marginLeft: 41,
                  margin: "auto",
                  marginLeft: "8px",
                  width: "calc(100% - 93px)",
                }}
              >
                <div
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    color: "#3E4050",
                  }}
                >
                  <div
                    // className={styles.textOverflowListFileText}
                    style={{ alignContent: "left" }}
                  >
                    <div className={styles.textOverflowListFileText} style={{ "fontSize": "0.9rem" }}>{props.file.file_name}</div>
                    {fileContext.isAdvacedSearch &&
                      <div
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "initial",
                          display: "-webkit-box",
                          WebkitLineClamp: 3, // จำนวนบรรทัดที่ต้องการแสดง
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "95%",
                        }}
                        dangerouslySetInnerHTML={{ __html: props.file.highlighting }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col span={7}>
            <div
              style={{
                marginLeft: 41,
                margin: "auto",
              }}
            >
              <div
                style={{
                  fontSize: "0.9rem",
                  fontWeight: 600,
                  color: "#3E4050",
                }}
              >
                <div
                  className={styles.textOverflowListFileText}
                  style={{ alignContent: "left" }}
                >
                  <div>{props.file.file_name}</div>
                </div>
              </div>
            </div>
          </Col> */}

          <Col
            span={3}
            style={{
              fontSize: "0.9rem",
            }}
          >
            <div style={{}}>
              <div>{props.file.file_type}</div>
            </div>
          </Col>

          {fileContext.tab === !tabName.myFile ||
            (!checkEmpty(fileContext.tab) && (
              <Col
                span={5}
                style={{
                  fontSize: "0.9rem",
                }}
              >
                <div
                  style={{
                    width: fileContext.tab === tabName.trash ? "100%" : "100%",
                  }}
                >
                  {getInfoMemberOrPathOrVersion()}
                  {/* <div>{member}</div> */}
                </div>
              </Col>
            ))}

          {isTrue(props.visibleManageVersion) && (
            <Col
              span={5}
              style={{
                fontSize: "0.9rem",
              }}
            >
              <div
                style={{
                  width: fileContext.tab === tabName.trash ? "100%" : "100%",
                }}
              >
                {getInfoMemberOrPathOrVersion()}
                {/* <div>{member}</div> */}
              </div>
            </Col>
          )}

          <Col
            span={6}
            style={{
              fontSize: "0.9rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "200%", alignSelf: "center" }}>
                <div>{getDateFormat(props.file?.update_date_real, t)}</div>
              </div>
              <div style={{ display: "inline-flex", width: "100%" }}>
                {props.isShared &&
                  <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "space-around" }}>
                    <div
                      style={{
                        backgroundImage: `url(${connection_file})`,
                        backgroundSize: "cover",
                        width: 20,
                        height: 20,
                        opacity: 0.75
                      }}
                    />
                  </div>}
                <div
                  style={{
                    width: "100%",
                    // textAlign: "right",
                    justifyContent: "flex-end",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <UIFileMenuPopover
                    showMenu={showMenu}
                    handleShowMenu={handleShowMenu}
                    file={props.file}
                    setShowMenu={setShowMenu}
                    isShared={props.isShared}
                  ></UIFileMenuPopover>

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {isOpenImg && (
        // <Lightbox
        //   wrapperClassName={"lightboxOverride"}
        //   mainSrc={urlPreviewImg}
        //   onCloseRequest={() => setIsOpenImg(false)}
        //   reactModalStyle={{
        //     content: {
        //       zIndex: "9999",
        //     },
        //     overlay: {
        //       zIndex: "9999",
        //     },
        //   }}
        // />
        <Lightbox
            wrapperClassName={"lightboxOverride"}
            mainSrc={urlPreviewImg}
            onCloseRequest={() => setIsOpenImg(false)}
            imagePadding={20}
        />
      )}
      {isOpenVideo && (
        <Modal
          centered
          className={styles.modalOveride}
          visible={true}
          footer={null}
          onCancel={() => setIsOpenVideo(false)}
        >
          <div style={{ width: "100%" }} onClick={handleClickVideoModal}>
            <ReactPlayer
              // onReady={videAudioOnReady}
              controls={true}
              className={styles.reactPlayer}
              url={urlPreviewVideo}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default FileCard;
