import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { Spin } from "antd";
import MainPage from "./page/example/MainPage";
import MainRoutes from "./routes/MainRoutes";
import ProviderContext from "./page/context/App.context";
import { GenerateContextProvider } from "./page/context/Generate.context";
import { AuthKeys, checkEmpty } from "./services/util.service";
import LogoLoading from "src/shared/loading/LogoLoading";
import UILoading from "src/shared/loading/UILoading";
import styles from "src/shared/loading/UIFirstPage.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles/globals.css";

export default function App() {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <Suspense
      fallback={
        <div className={styles.boxIconCenterWhite}>
          <Spin indicator={antIcon} />
        </div>
      }
    >
      <ProviderContext>
        {/* <Router> */}
        <GenerateContextProvider>
          <MainRoutes />
        </GenerateContextProvider>
        {/* </Router> */}
      </ProviderContext>
    </Suspense>
  );
}
