import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
//@ts-ignore
import _, { over } from "lodash";
import NoFile from "src/assets/png/NoFile.png";
import { FileContext } from "../../File.context";
import { supportViewer } from "../../properies.file";
import styles from "./MediasContainer.module.scss";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import ReactPlayer from "react-player";
import Lightbox from "react-image-lightbox";
import check_mark from "src/assets/png/check_mark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faCircleDown } from "@fortawesome/free-regular-svg-icons";
import { getUrl, KN_FILE_SERVICE_URL_MAIN, previewFile } from "src/page/kn/util.service";
import { BgImgWithLazy } from "../../BgImgWithLazy/BgImgWithLazy";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const defaultLitmitOfset = 30;

const UIMediaItem = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const [offsetFile, setOffsetFile]: any = useState(0);
  const [offsetFolder, setOffsetFolde]: any = useState(0);
  const fileContext: any = useContext(FileContext);

  const [isOpenImg, setIsOpenImg] = useState(false);
  const [urlPreviewImg, setUrlPreviewImg] = useState("");

  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [urlPreviewVideo, setUrlPreviewVideo] = useState("");

  const [urlImage, setUrlImage]: any = useState("");
  const [imgError, setImgError] = useState(false);
  const [urlImageDownload, setUrlImageDownload]: any = useState("")
  const [isOver, setIsOver]: any = useState(false);
  let defaultBigSize = "40";
  let boxSizeWidth = "290px";
  let boxSizeHeight = "200px";

  const checkGetUrlFile = async () => {
    if (!props.file?.file_app_id) {
      setUrlImage(NoFile);
    } else {
      let url: any =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        props.file?.file_app_id +
        "&size=1024&onTrashReqIgnore=true";
      url = await getUrl(
        url,
        props.file?.file_app_id,
        1024,
        props.file?.file_type
      );
      setUrlImage(url);
    }
  };

  useEffect(() => {
    checkGetUrlFile();
  }, []);

  const clickPreviewImage = async () => {
    if (supportViewer.images.indexOf(props.file?.file_type) > -1) {
      let url: any =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        props.file?.file_app_id +
        "&size=1024&onTrashReqIgnore=true";
      url = await getUrl(
        url,
        props.file?.file_app_id,
        1024,
        props.file?.file_type
      );

      let urlDown: any =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        props.file?.file_app_id +
        "&size=1024&onTrashReqIgnore=true" + "&logType=download";
      setUrlImageDownload(urlDown);
      // console.log("URL DOWNLOAD : ",urlDown)
      setUrlPreviewImg(url);
      setIsOpenImg(true);
    } else if (supportViewer.video.indexOf(props.file?.file_type) > -1) {
      let url =
        KN_FILE_SERVICE_URL_MAIN +
        "/downloadFile?fileId=" +
        props.file?.file_app_id +
        "&onTrashReqIgnore=true";
      setUrlPreviewVideo(url);
      setIsOpenVideo(true);
    }
    previewFile(props.file?.file_app_id)
  };

  const handleClickVideoModal = (e: any) => {
    console.log(e);
  };

  const isSelected = () => {
    let filters = fileContext.filesSelected?.filter((item: any) => {
      return item.file_app_id === props.file.file_app_id;
    });
    if (filters.length === 0) return false;
    else return true;
  };

  const checkFile = () => {
    let files = fileContext.filesSelected;
    let filters = fileContext.filesSelected?.filter((item: any) => {
      return item.file_app_id === props.file.file_app_id;
    });
    if (filters.length === 0) {
      files.push(props.file);
    }
    fileContext.setFilesSelected(Object.assign([], files));
  };

  const unCheckFile = () => {
    let files = fileContext.filesSelected;
    let i = fileContext.filesSelected?.findIndex((item: any) => {
      return item.file_app_id === props.file.file_app_id;
    });
    if (i !== -1) {
      files.splice(i, 1);
    }
    fileContext.setFilesSelected(Object.assign([], files));
  };

  const mouseOver = () => {
    setIsOver(true);
  };

  const mouseLeave = () => {
    setIsOver(false);
  };

  const handleError = () => {
    setImgError(true);
  };

  const handleLoad = () => {
    setImgError(false);
  };

  return (
    <>
      <img
        src={urlImage}
        alt="Hidden Check"
        style={{ display: 'none' }}
        onError={handleError}
        onLoad={handleLoad}
      />
      {isSelected() && (
        <div className={styles.boxMediaItem}
          style={{ position: "relative" }}
        >
          <div
            className={
              isSelected() ? styles.box_symbol_selected : styles.box_symbol
            }
            onClick={unCheckFile}
          >
            <div
              style={{
                backgroundImage: `url(${check_mark})`,
              }}
              className={styles.collect_symbol}
            ></div>
          </div>
        </div>
      )}

      {!isSelected() && (isOver || fileContext.filesSelected?.length !== 0) && (
        <div className={styles.boxMediaItem}
          style={{ position: "relative" }}
        >
          <div
            className={styles.box_symbol}
            onClick={checkFile}
            onMouseEnter={mouseOver}
          >
            <div
              style={{
                backgroundImage: `url(${check_mark})`,
              }}
              className={styles.collect_symbol}
            ></div>
          </div>
        </div>
      )}

      <div
        className={styles.boxMediaItem}
        style={{ position: "relative" }}
        onClick={clickPreviewImage}
        onMouseEnter={mouseOver}
        onMouseOver={mouseOver}
        onMouseLeave={mouseLeave}
        onMouseOut={mouseLeave}
      >
        {supportViewer.video.indexOf(props.file?.file_type) > -1 && (
          <div className={styles.boxMediaItem}>
            <div className={styles.playVideoIcon} >
              <FontAwesomeIcon icon={faPlay as IconProp} style={{ color: "white", cursor: "pointer" }} size={"4x"} />
            </div>
          </div>
        )}
        {imgError
        ? <BgImgWithLazy
            containerClassName="img"
            classPlaceholder={"img-gray"}
            src={NoFile}
            style={{
              filter: false ? "sepia(1)" : "unset",
              height: boxSizeHeight,
              // maxWidth: boxSizeWidth,
              borderRadius: "0px",
              backgroundPosition: "center",
            }}
          />
        : <BgImgWithLazy
            containerClassName="img"
            classPlaceholder={"img-gray"}
            src={urlImage}
            style={{
              filter: false ? "sepia(1)" : "unset",
              height: boxSizeHeight,
              // maxWidth: boxSizeWidth,
              borderRadius: "0px",
            }}
        />
        }
      </div>
      {
        isOpenImg && (
          // <Lightbox
          //   wrapperClassName={"lightboxOverride"}
          //   mainSrc={urlPreviewImg}
          //   onCloseRequest={() => setIsOpenImg(false)}
          //   reactModalStyle={{
          //     content: {
          //       zIndex: "9999",
          //     },
          //     overlay: {
          //       zIndex: "9999",
          //     },
          //   }}
          //   toolbarButtons={[
          //     <a
          //       href={urlImageDownload} // ลิงก์ดาวน์โหลดรูปภาพ
          //       download
          //       key="download"
          //     >
          //       <FontAwesomeIcon icon={faDownload as IconProp} /> Download
          //     </a>,
          //   ]}

          // />
          <Lightbox
            wrapperClassName={"lightboxOverride"}
            mainSrc={urlPreviewImg}
            onCloseRequest={() => setIsOpenImg(false)}
            imagePadding={20}
            toolbarButtons={[
              <a
                href={urlImageDownload} // ลิงก์ดาวน์โหลดรูปภาพ
                download
                key="download"
                style={{ color: '#fff', textDecoration: 'underline' }}
              >
              <FontAwesomeIcon icon={faCircleDown as IconProp} style={{ color: "#ffffff", marginRight: "8px" }} 
              /> Download
              </a>
            ]}
        />
        )
      }
      {
        isOpenVideo && (
          <Modal
            centered
            className={styles.modalOveride}
            visible={true}
            footer={null}
            onCancel={() => setIsOpenVideo(false)}
          >
            <div
              style={{ width: "100%" }}
            // onClick={handleClickVideoModal}
            >
              <ReactPlayer
                // onReady={videAudioOnReady}
                controls={true}
                className={styles.reactPlayer}
                url={urlPreviewVideo}
              />
            </div>
          </Modal>
        )
      }
    </>
  );
};

export default UIMediaItem;
