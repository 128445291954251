import { MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification } from "antd";
import React from "react";
import axios from "axios";
import { getAuthorizationPmtx } from "src/services/util.service";
import { INVITE_USER_ENDPOINT } from "src/utils/endpoint/pmtx.endpoint";

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function InviteModal({ isOpen, onClose }: InviteModalProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => sendInvite(values))
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const sendInvite = async (values: any) => {
    setIsLoading(true);
    try {
      const userData = {
        email: values.info.email,
      };

      const response = await axios.post(INVITE_USER_ENDPOINT, userData, {
        headers: {
          Authorization: getAuthorizationPmtx(),
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        notification.success({
          message: "Invitation sent successfully!",
          description: "The invitation has been sent to the user.",
        });
        onClose();
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        notification.error({
          message: "Authorization Error",
          description: "Please check your login status and try again.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to send invitation. Please try again.",
        });
      }
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        title="Invite users"
        onCancel={onClose}
        footer={null}
        width="fit-content"
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          style={{ boxShadow: "none" }}
        >
          <Form.Item
            name={["info", "email"]}
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>
          <Form.Item>
            {isLoading ? (
              <Button type="primary" block icon={<MailOutlined />} loading>
                Sending...
              </Button>
            ) : (
              <Button
                type="primary"
                block
                icon={<MailOutlined />}
                onClick={handleSubmit}
                style={{ background: "#184c85" }}
              >
                Send Invite
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default InviteModal;
